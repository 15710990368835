import { ListingDTO, UserDTO } from '../../api/model';
import { Action } from '../root-reducer';
import { actionTypes as at } from './constants';

interface MyAccount {
    loggedIn: boolean;
    myProfile?: UserDTO;
    myListings?: ListingDTO[] | undefined;
}

const initialState: MyAccount = {
    loggedIn: false,
    myProfile: undefined,
    myListings: undefined,
};

export default (state: MyAccount = initialState, action: Action): MyAccount => {
    switch (action.type) {
        case at.GET_MY_PROFILE_SUCCESS:
            return { ...state, myProfile: action.payload, loggedIn: true };
        case at.GET_MY_LISTINGS_SUCCESS:
            return { ...state, myListings: action.payload };
        case at.GET_MY_POINTS_SUCCESS: {
            if (state.myProfile) {
                // const points = (state.myProfile.points ?? 0) + action.payload;
                return {
                    ...state,
                    myProfile: { ...state.myProfile, points: action.payload },
                };
            }
            return { ...state };
        }
        case at.LOGOUT_USER:
            return { ...state, myProfile: undefined, loggedIn: false };
        default:
            return { ...state };
    }
};
