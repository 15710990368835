import React from 'react';
import { useSelector } from 'react-redux';
import { selectMapZoom } from '../../../store/App/selectors';
import { selectGlobalListings } from '../../../store/Map/MapSelection/selectors';
import { PreviewList } from './category-maps-list';
import CategoryPreviewMultiselect from './category-preview-multiselect';

const CategoryGlobalMaps = () => {

    const globalListings = useSelector(selectGlobalListings);
    const mapZoom = useSelector(selectMapZoom);

    if (!globalListings || !globalListings.tileLayers || !globalListings.tileLayers[0]) {
        return <React.Fragment />
    }

    if (mapZoom && mapZoom > 4) {
        return <React.Fragment />
    }

    return (
        <PreviewList isHighlighted={false}>
            <CategoryPreviewMultiselect
                tileLayerGroup={globalListings}

            />
        </PreviewList>
    );
};

export default CategoryGlobalMaps;