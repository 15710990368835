import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectSideDrawerCollapse } from '../../../../store/SideDrawer/selectors';
import { actionSetSideDrawerCollapseAction } from '../../../../store/SideDrawer/actions';

interface DrawerHeaderProps {
    handleBack?: () => void;
    title?: string;
    icon?: string;
    collapsible?: boolean;
}

/**
 * DrawerHeader
 * Standardized header for wide drawer
 * @optional handleBack () => void
 * @optional title string
 * @optional icon boolean
 * @optional collapsible boolean
 * Turns on closing drawer functionality and activates icon
 */

const DrawerHeader = (props: DrawerHeaderProps) => {
    const sideDrawerCollapse = useSelector(selectSideDrawerCollapse);

    const dispatch = useDispatch();
    const handleSideDrawerCollapse = (collapse: boolean) => dispatch(actionSetSideDrawerCollapseAction(collapse));

    return (
        <Header closeDraw={sideDrawerCollapse}>
            {props.handleBack ? (
                <HeaderButton onClick={() => props.handleBack && props.handleBack()} data-testid="sentinel-back-button">
                    <BackArrow />
                    <BackText>Back</BackText>
                </HeaderButton>
            ) : null}
            <HeaderContent alignTitle={!props.handleBack}>
                {props.icon ? <Icon src={props.icon} /> : null}
                {props.title ? <Title>{props.title}</Title> : null}
            </HeaderContent>
            {props.collapsible ? (
                <ChevronIconContainer>
                    <ChevronIcon
                        onClick={() => handleSideDrawerCollapse(!sideDrawerCollapse)}
                        className={sideDrawerCollapse ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}
                    />
                </ChevronIconContainer>
            ) : null}
        </Header>
    );
};

export default DrawerHeader;

interface CloseDrawProps {
    closeDraw?: boolean;
    alignTitle?: boolean;
}

const Header = styled.div<CloseDrawProps>`
    border-bottom: ${(props) => (props.closeDraw ? '' : '1px solid rgba(255, 255, 255, 0.3)')};
    height: 49px;
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: hidden;
`;

const HeaderContent = styled.div<CloseDrawProps>`
    margin: ${(props) => (props.alignTitle ? 'auto' : '6px 0px 0px 40px')};
`;

const HeaderButton = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    cursor: pointer;
`;

const BackArrow = styled.div`
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2222%22%20height%3D%2222%22%20viewBox%3D%220%200%2022%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M1%2011H21%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M9.57153%201L0.999955%2011L9.57153%2021%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A');
    background-position: center;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    margin: 8px;
    float: left;
`;

const BackText = styled.div`
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.4rem;
    height: 32px;
    margin: 9px;
    margin-left: 0px;
    user-select: none;
`;

const ChevronIconContainer = styled.div`
    width: 100%;
    user-select: none;
`;

const ChevronIcon = styled.i`
    float: right;
    padding: 15px 12px 0px 0px;
    color: rgb(255 255 255 / 80%);
    font-size: 18px;
    cursor: pointer;
`;

const Icon = styled.img`
    width: 32px;
    height: 32px;
`;

const Title = styled.span`
    font-size: 24px;
    color: white;
    text-transform: uppercase;
    margin-left: 10px;
    vertical-align: middle;
`;
