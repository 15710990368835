import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import UriHelper from '../../lib/uri-helper';
import Analytics from '../../lib/user-analytics';
import { actionSetSideDrawerModeAction } from '../../store/SideDrawer/actions';
import { SideDrawerMode } from '../../store/SideDrawer/model';
import Share from '../Drawer/Share/Maps';
import { LoginModalMode } from '../Registration/login-enum';
import LoginRegisterDialog from '../Registration/login-register-dialog';
import { CustomCookieConsent } from '../Shared/custom-cookie-consent';
import MobileBasemapMenu from './BaseMap/mobile-basemap-menu';
import MobileBottomSheet from './BottomSheet/mobile-bottom-sheet';

import MobileMap from './mobile-map';
import MobileSearchContainer from './Search/mobile-search-container';

interface MobileMapContainerProps {
    loginMode?: LoginModalMode;
    drawerMode?: SideDrawerMode;
}

const MobileMapContainer = (props: MobileMapContainerProps) => {
    const dispatch = useDispatch();

    const [loginMode, setLoginMode] = useState<LoginModalMode | undefined>(props.loginMode);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const onLoginDialogClosed = () => {
        setLoginMode(undefined);
        UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
        Analytics.Event('Mobile - Map', 'Clicked to close login dialog');
    };

    useEffect(() => {
        const setDrawerMode = (sideDrawerMode: SideDrawerMode) =>
            dispatch(actionSetSideDrawerModeAction(sideDrawerMode));

        if (props.drawerMode) {
            setDrawerMode(props.drawerMode);
            Analytics.Event('Mobile - Map', 'Changed drawer mode', props.drawerMode);
        }
    }, [props.drawerMode, dispatch]);

    return (
        <React.Fragment>
            <MobileMap />

            <MobileSearchContainer handleIsOpen={(isOpen) => setIsSearchOpen(isOpen)} />
            <MobileBasemapMenu />

            {!isSearchOpen ? <MobileBottomSheet /> : null}
            {/* {!isSearchOpen ? <MapsBottomSheet /> : null} */}
            {/* {isProfile && !activeMap && <MobileProfile />} */}
            <Share />
            {loginMode && <LoginRegisterDialog isOpen={true} initialMode={loginMode} onClose={onLoginDialogClosed} />}
            <CustomCookieConsent />
        </React.Fragment>
    );
};

export default MobileMapContainer;
