import styled from 'styled-components';
import { ListingDTO } from '../../../api/model';
import SoarHelper from '../../../lib/soar-helper';
import MobileShareLike from '../Sharing/mobile-share-like';
import MobileShareLinks from '../Sharing/mobile-share-links';
const moment = require('moment');

interface ShareComponentProps {
    listing: ListingDTO;
}

const MobileCardShareOptions = (props: ShareComponentProps) => {
    const { listing } = props;
    const isSoar = SoarHelper.isSoar();

    return (
        <ShareContainer>
            <MobileSearchCardCreated>
                {moment(new Date(props.listing.createdAt * 1000)).fromNow()}
            </MobileSearchCardCreated>
            <MobileSearchOptions>
                <ShareIcons>{isSoar && <MobileShareLike listing={listing} />}</ShareIcons>
                <MobileShareLinks listing={listing} />
            </MobileSearchOptions>
        </ShareContainer>
    );
};

export default MobileCardShareOptions;

const ShareContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ShareIcons = styled.div`
    display: flex;
    margin-top: 3px;
`;

const MobileSearchCardCreated = styled.div`
    color: rgba(255, 255, 255, 0.67);
    line-height: 1.4;
`;

const MobileSearchOptions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 70px;
    width: 100%;
`;
