import React, { useEffect, useState } from 'react';
import { LatLng } from 'leaflet';
import { UploadLocationDistortionControl } from './upload-location-distortion-control';
import UploadLocationSearch from './upload-location-search';
import GeorectifierUploadMap from '../UploadMap/georectifier-upload-map';
import UploadSplitScreenSlider from '../Shared/upload-split-screen-slider';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectDroneImageOpacity } from '../../../store/Map/Uploads/selectors';
import { actionSetDroneImageDistortionOpacity } from '../../../store/Map/Uploads/actions';
import RectifiedUploadPreview from '../../MapView/UploadPreview/rectified-upload-preview';

interface UploadLocationDetailsProps {
    setUploadWorkFlow: () => void;
    isSelectedGCP?: (gcp: LatLng[]) => boolean;
    previewUrl?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setRef: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mapRef: any;
    calc: () => void;
}

const UploadLocationDetails = ({ setUploadWorkFlow, previewUrl, setRef, mapRef, calc }: UploadLocationDetailsProps) => {
    const [distortImage, setDistortImage] = useState<boolean>(false);
    const opacity = useSelector(selectDroneImageOpacity);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionSetDroneImageDistortionOpacity(0.6));
    }, [dispatch]);

    return (
        <React.Fragment>
            {distortImage ? (
                <React.Fragment>
                    <UploadLocationDistortionControl setUploadWorkFlow={setUploadWorkFlow} calc={calc} />
                    <GeorectifierUploadMap
                        type={'MAP'}
                        position={'RIGHT'}
                        highlightTarget={'MAP'}
                        setRef={setRef}
                        baseMap
                    >
                        {previewUrl && mapRef && <RectifiedUploadPreview previewUrl={previewUrl} mapRef={mapRef} />}
                    </GeorectifierUploadMap>
                    {previewUrl && (
                        <UploadLocationSlider>
                            <UploadSplitScreenSlider
                                opacity={opacity}
                                setOpacity={(opacity) => dispatch(actionSetDroneImageDistortionOpacity(opacity))}
                            />
                        </UploadLocationSlider>
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <UploadLocationSearch setUploadWorkFlow={() => setDistortImage(true)} />
                    <GeorectifierUploadMap
                        setRef={setRef}
                        type={'MAP'}
                        position={'RIGHT'}
                        highlightTarget={'MAP'}
                        baseMap
                        uploadMarker
                    ></GeorectifierUploadMap>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default UploadLocationDetails;

const UploadLocationSlider = styled.div`
    position: absolute;
    bottom: 24px;
    z-index: 9999;
    top: calc(66vh + 26px);
    left: 65vw; // Bit bad needs to be fixed
`;
