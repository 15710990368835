import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-leaflet';

interface ContinentClusterTooltipProps {
    previewUrl: string;
    title: string;
    subtext: string;
}

const ContinentClusterTooltip = (props: ContinentClusterTooltipProps) => {
    return (
        <React.Fragment>
            <StyledTooltip direction="top" sticky={true}>
                <Container>
                    <PreviewImageContainer>
                        <PreviewImage src={props.previewUrl || '/assets/basemap/black-pixel.png'} />
                    </PreviewImageContainer>
                    <ContentContainer>
                        <Title>{props.title}</Title>
                        <SubText>{props.subtext}</SubText>
                    </ContentContainer>
                </Container>
            </StyledTooltip>
        </React.Fragment>
    );
};

export default ContinentClusterTooltip;

const StyledTooltip = styled(Tooltip) <{ direction: string; sticky: boolean }>`
    position: absolute;
    background-color: transparent;
    border: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    margin: 0 0 0px 0;
    bottom: -52px;
    padding: 0;

    &:before {
        content: '';
        position: absolute;
        display: block;
        width: 0px;
        left: 50%;
        border: 10px solid transparent;
        border-bottom: 0;
        border-top: 10px solid rgba(0, 0, 0, 0.7);
        transform: translate(-23%, calc(100% - 12px));
    }
`;


const Container = styled.div`
    height: 64px;
    width: 320px;
    border-radius: 12px;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: horizontal;
    box-shadow: none;
`;

const PreviewImageContainer = styled.div`
    height: 100%;
    padding: 6px;
`;

const PreviewImage = styled.img`
    height: 100%;
    width: 100%;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    margin: 0;
    padding: 0;
    object-fit: cover;
`;

const ContentContainer = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    margin: 4px 0 0 8px;
    width: 75%;
`;

const Title = styled.p`
    color: #eed926;
    font-size: 1.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 0 auto;
    margin: 0;
    padding: 0;
`;

const SubText = styled.p`
    color: rgba(255, 255, 255, 1.0);
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
`;