import React from 'react';
import styled from 'styled-components';
import { StyledDrawerContentContainer } from './drawer-styles';

interface DrawerContentProps {
    children: React.ReactNode;
    usePaddingTop?: boolean;
}

/**
 * ThinDrawerContent
 * Standardized drawer content to control height and scroll behavior
 * @required children React.ReactNode
 * @optional usePaddingTop boolean - applies padding styles to drawer content
 */
const ThinDrawerContent = (props: DrawerContentProps) => {
    return <DrawerContentContainer paddingTop={props.usePaddingTop}>{props.children}</DrawerContentContainer>;
};

export default ThinDrawerContent;

interface DrawerContentContainerProps {
    paddingTop?: boolean;
}

export const DrawerContentContainer = styled(StyledDrawerContentContainer)<DrawerContentContainerProps>`
    padding-top: ${(props) => props.paddingTop && '30px'};
    margin-top: ${(props) => !props.paddingTop && '40px'};
    min-height: 0px;
    max-height: calc(100vh - 296px);
    transition: none;
    -webkit-transition: none;
`;
