import { Input as RSInput, Label, FormGroup } from 'reactstrap';
import styled from 'styled-components';

type ContactUsInputType = 'text' | 'select' | 'number' | 'email' | 'textarea';

interface ContactUsInputProps {
    type: ContactUsInputType;
    name: string;
    label: string;
    error?: string;
    placeholder?: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    dataTestId?: string;
    optionsList?: React.ReactNode;
}

export const ContactUsInput = (props: ContactUsInputProps): JSX.Element => {
    return (
        <StyledInputGroup>
            <Label for={props.type}>{props.label}</Label>
            <Input
                $hasError={props.error ? true : false}
                type={props.type}
                name={props.name}
                aria-label={props.name}
                placeholder={props.placeholder}
                data-testid={props.dataTestId}
                value={props.value}
                onChange={props.onChange}
            >
                {props.optionsList}
            </Input>
            <ErrorMessage>{props.error && props.error}</ErrorMessage>
        </StyledInputGroup>
    );
};

const StyledInputGroup = styled(FormGroup)`
    margin-bottom: 0px;
`;

interface ErrorStyleProps {
    $hasError?: boolean;
}

const Input = styled(RSInput) <ErrorStyleProps>`
    background-color: transparent;
    color: rgba(255, 255, 255, 0.6);
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
    border: 1px solid ${(props) => (props.$hasError ? 'rgb(255,100,100)' : 'rgb(255 255 255 / 0.3)')} !important;

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active,
    :focus {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.6);
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid ${(props) => (props.$hasError ? 'rgb(255,100,100)' : 'rgb(255 255 255 / 0.3)')} !important;
        box-shadow: 0 0 0 0.2rem rgb(238 227 8 / 10%);
        -webkit-box-shadow: 0 0 0 0.2rem rgb(238 227 8 / 10%);
    }

    ::placeholder {
        color: rgba(255, 255, 255, 0.6);
    }

    :hover {
        color: rgba(255, 255, 255, 0.6);
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
        background-color: transparent;
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid ${(props) => (props.$hasError ? 'rgb(255,100,100)' : 'rgba(238 227 8 / 0.6)')} !important;
        box-shadow: 0 0 0 0.2rem rgb(238 227 8 / 10%) !important;
    }

    select {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.6);
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgb(255 255 255 / 0.3);

        :focus {
            background-color: transparent;
            border-color: rgba(238 227 8 / 0.6);
            box-shadow: 0 0 0 0.2rem rgb(238 227 8 / 10%);
        }

        :hover {
            background-color: transparent;
            border: 1px solid rgba(238 227 8 / 0.6) !important;
            border-color: rgba(238 227 8 / 0.6) !important;
            box-shadow: 0 0 0 0.2rem rgb(238 227 8 / 10%) !important;
        }
    }

    option {
        background-color: rgba(0, 0, 0, 0.85);
        color: rgba(255, 255, 255, 0.6);
        font-size: 15px;

        :hover {
            background-color: rgba(0, 0, 0, 0.6);
        }
    }

    textarea {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.6);
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6);
        border: 1px solid rgb(255 255 255 / 0.3);

        :focus {
            background-color: transparent;
            border-color: rgba(238 227 8 / 0.6);
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1);
            color: rgba(255, 255, 255, 0.6);
        }

        :hover {
            border: 1px solid rgba(238 227 8 / 0.6) !important;
            border-color: rgba(238 227 8 / 0.6) !important;
            box-shadow: 0 0 0 0.2rem rgb(238 227 8 / 10%) !important;
        }
    }
`;

const ErrorMessage = styled.div`
    color: rgb(255, 100, 100) !important;
    -webkit-text-fill-color: rgb(255, 100, 100);
    padding: 4px 0px;
    display: flex;
    font-size: 0.9rem;
    height: 25px;
`;
