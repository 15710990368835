import moment from 'moment';
import styled from 'styled-components';
import { TransactionPurchaseItem } from './transaction-purchase-item';
import { isMobile } from 'react-device-detect';
import { PurchasedInfo } from '../../../api/model';

interface PurchaseListItemProps {
    purchase: PurchasedInfo;
}

export const PurchaseListItem = ({ purchase }: PurchaseListItemProps) => {
    const purchaseItems = purchase.items
        .map((purchaseInfo) => {
            return (
                <PurchaseItemDiv>
                    <TransactionPurchaseItem purchase={purchaseInfo} />
                </PurchaseItemDiv>
            );
        })
        .filter((e) => e !== undefined);

    return (
        <PurchaseTr>
            {purchaseItems}
            {isMobile ? (
                <PurchaseItemsContainer>
                    <PurchaseDateTd>
                        {purchase.paidAt ? `${moment(purchase.paidAt).format('MMM Do YYYY')} ` : 'Unknown'}
                    </PurchaseDateTd>
                    <PurchaseDownloadTd>
                        <a href={purchase.receiptUrl} target="_blank">
                            RECEIPT
                        </a>
                    </PurchaseDownloadTd>
                </PurchaseItemsContainer>
            ) : (
                <>
                    <PurchaseDateTd>
                        {purchase.paidAt ? `${moment(purchase.paidAt).format('MMM Do YYYY')} ` : 'Unknown'}
                    </PurchaseDateTd>
                    <PurchaseDownloadTd>
                        <a href={purchase.receiptUrl} target="_blank">
                            RECEIPT
                        </a>
                    </PurchaseDownloadTd>
                </>
            )}
        </PurchaseTr>
    );
};

const PurchaseTr = styled.tr`
    background-color: transparent;
    border-top: 1px solid white;

    td {
        background-color: transparent;
        color: white;
        vertical-align: middle;

        img {
            width: 83px;
            height: 57px;
            border-radius: 6px;
        }
    }
`;

const PurchaseDateTd = styled.td`
    border-top: 1px solid white;
    width: 120px;

    @media only screen and (max-width: 600px) {
        border-top: none !important;
    }
`;

const PurchaseDownloadTd = styled.td`
    border-top: 1px solid white;
    width: 145px;
    text-decoration: underline;

    @media only screen and (max-width: 600px) {
        border-top: none !important;
    }

    a {
        color: white !important;
    }
`;

const PurchaseItemDiv = styled.div`
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        justify-content: none;
    }
`;

const PurchaseItemsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -10px;
    width: 100vw;
`;
