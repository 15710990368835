import React from 'react';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
import styled from 'styled-components';
import UriHelper from '../../lib/uri-helper';
import Analytics from '../../lib/user-analytics';
import { selectMyProfile } from '../../store/Account/selectors';
import { StyledButton } from '../Shared/styled-button';

interface Props {
    toggle: () => void;
}

const RegisterConfirmation = ({ toggle }: Props) => {
    const myProfile = useSelector(selectMyProfile);

    useEffect(() => {
        Analytics.Event('Registration', 'Thank you', 'OPENED');
    }, []);

    const handleProfileButtonClick = () => {
        Analytics.Event('Registration', 'Thank you', 'CLICKED PROFILE BUTTON');
        toggle();
        UriHelper.navigateToPath(`/dashboard/profile`);
    };

    const handleUploadMapsButtonClick = () => {
        Analytics.Event('Registration', 'Thank you', 'CLICKED MAPS BUTTON');
        toggle();
        UriHelper.navigateToPath(`/upload`);
    };

    const handleExploreButtonClick = () => {
        Analytics.Event('Registration', 'Thank you', 'CLICKED EXPLORE BUTTON');
        toggle();
        UriHelper.navigateToPath(`/satellites`);
    };

    return (
        <ModalContainer isOpen={true} zIndex={100000}>
            <CloseIcon onClick={toggle} />
            <ModalContent>
                <Title>Welcome, {myProfile?.firstName}!</Title>
                <SubTitle>We're so map-py to have you!</SubTitle>
                <Line />
                <SubTitle>
                    <span>Congratulations</span>, you've officially registered to Soar.Earth.
                </SubTitle>
                <br />
                <Text>
                    There's so much you can do as Soar.Earth user, from sharing content, to exploring the world and
                    building a community. Here's a few steps to get you started.
                </Text>
                <ButtonsContainer>
                    <ButtonWithImage onClick={handleProfileButtonClick}>
                        <img style={{ padding: '5px' }} src="/assets/avatars/default_avatar_black.png" />
                        <span>
                            COMPLETE <br /> YOUR PROFILE
                        </span>
                    </ButtonWithImage>
                    {!isMobile ? (
                        <React.Fragment>
                            <ButtonWithImage onClick={handleUploadMapsButtonClick}>
                                <img
                                    src="/assets/floating-drawer-icons/maps-icon-black.svg"
                                    style={{ marginLeft: '5px' }}
                                />
                                <span>SHARE YOUR FIRST MAP</span>
                            </ButtonWithImage>
                            <ButtonWithImage onClick={handleExploreButtonClick}>
                                <img src="/assets/satellite-black-icon.png" style={{ padding: '5px' }} />
                                <span>
                                    DISCOVER <br /> NEW IMAGERY
                                </span>
                            </ButtonWithImage>
                        </React.Fragment>
                    ) : null}
                </ButtonsContainer>
            </ModalContent>
        </ModalContainer>
    );
};

export default RegisterConfirmation;

const ModalContainer = styled(Modal)`
    max-width: 730px;
    margin-top: 100px;
    border: none;

    div.modal-content {
        background-color: transparent !important;
        margin: 0 auto;
        background-size: cover;
        border-radius: 9px;
        overflow: hidden;

        border-radius: 9px;
        background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)),
            url('/assets/backgrounds-large/register-cta_large.png');
    }

    @media (max-width: 600px) {
        width: 100% !important;
        margin: 10% 0px;
    }
`;

const CloseIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 21L20.9999 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L1.00006 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    width: 18px;
    height: 25px;
    margin: 8px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 10px;
    font-weight: bold;
`;

const ModalContent = styled.div`
    color: inherit !important;
    color: white;
    padding: 50px;
    width: 100%;

    @media only screen and (max-width: 600px) {
        width: 95%;
        padding: 5%;
    }
`;

const Title = styled.h3`
    color: white;
    text-align: left;
    font-size: 3.4rem;
    font-weight: bold;
`;

const SubTitle = styled.h5`
    text-align: left;
    color: white;
    font-size: 1.7rem;
    margin-top: 5px;

    span {
        color: #eed926;
    }
`;

const Line = styled.hr`
    height: 4px;
    background-color: #eed926;
    border-radius: 4px;
    width: 40%;
    margin: 10px 0px;
`;

const Text = styled.p`
    color: white;
    font-size: 1.3rem;
    line-height: 1.7rem;
`;

const ButtonsContainer = styled.div`
    margin: 40px 0px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const ButtonWithImage = styled(StyledButton)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    width: 175px;
    height: 70px;
    border-radius: 8px;
    margin-top: 10px;

    img {
        width: 35%;
        opacity: 0.7;
    }

    span {
        margin-left: 5px;
        font-size: 1rem;
        line-height: 1.2rem;
        font-weight: 500;
    }

    @media only screen and (max-width: 600px) {
        margin-left: auto;
        margin-right: auto;
    }
`;
