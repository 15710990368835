import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { actionSetHighlightedContinent, actionSetSelectedContinent } from '../../../store/Map/MapSelection/actions';
import { ListingGroup } from '../../../store/Map/MapSelection/model';
import { selectContinentListings } from '../../../store/Map/MapSelection/selectors';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { selectLoggedIn } from '../../../store/Account/selectors';
import { LoginModalMode } from '../../Registration/login-enum';
import LoginRegisterDialog from '../../Registration/login-register-dialog';
import { PulseLoader } from '../../Shared/pulse-loader';

const SelectContinent = () => {
    const dispatch = useDispatch();
    const setHighlightedContinent = (tileLayer: ListingGroup | undefined) =>
        dispatch(actionSetHighlightedContinent(tileLayer));

    const isLoggedIn = useSelector(selectLoggedIn);
    const continentsDictionary = useSelector(selectContinentListings);
    const [showLogin, setShowLogin] = useState(false);

    const handleSelectContinent = (continentGroup: ListingGroup) => {
        if (continentGroup.continent) {
            Analytics.Event('Side Drawer', `Selected ${continentGroup.continent.name}`);
            dispatch(actionSetSelectedContinent(continentGroup));
        }
    };

    const handleSelectYourMaps = () => {
        if (!isLoggedIn) {
            setShowLogin(true);
        } else {
            Analytics.Event('Side Drawer', 'Selected Your Maps');
            UriHelper.navigateToDrawer(SideDrawerMode.YOUR_MAPS);
        }
    };

    const handleSelectYourBookmarks = () => {
        if (!isLoggedIn) {
            setShowLogin(true);
        } else {
            Analytics.Event('Side Drawer', 'Selected Your Bookmarks');
            UriHelper.navigateToDrawer(SideDrawerMode.MY_BOOKMARKS);
        }
    };

    if (!continentsDictionary) {
        return (
            <PulseLoaderSkeleton>
                <PulseLoader iconSize="2rem" />
            </PulseLoaderSkeleton>
        );
    }

    return (
        <React.Fragment>
            {showLogin && (
                <LoginRegisterDialog
                    isOpen={true}
                    initialMode={LoginModalMode.LOGIN}
                    onClose={() => setShowLogin(false)}
                />
            )}

            <UnorderedList>
                {Array.from(continentsDictionary.values()).map((t) => {
                    if (!t.continent) return <React.Fragment />;

                    return (
                        <List
                            onMouseOver={() => setHighlightedContinent(t)}
                            onMouseOut={() => setHighlightedContinent(undefined)}
                            title={`Click to explore the ${t.tileLayers.length?.toLocaleString()} maps in ${
                                t.continent.name
                            }`}
                            key={t.continent.name}
                            onClick={() => handleSelectContinent(t)}
                        >
                            <Icon
                                src={t.continent.iconUrl}
                                alt={`Click to explore the ${t.tileLayers.length?.toLocaleString()} maps in ${
                                    t.continent.name
                                }`}
                            />
                            <Link>{t.continent.name}</Link>
                        </List>
                    );
                })}

                <List title="Your maps" onClick={() => handleSelectYourMaps()}>
                    <Icon src="/assets/continent-icons/my-maps.svg" />
                    <Link>Your Maps</Link>
                </List>

                <List title="Your Bookmarks" onClick={() => handleSelectYourBookmarks()}>
                    <Icon src="/assets/continent-icons/bookmark.svg" />
                    <Link>Your Bookmarks</Link>
                </List>
            </UnorderedList>
        </React.Fragment>
    );
};

export default SelectContinent;

const Link = styled.a`
    color: #fff;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: #fff;
        text-decoration: none;
    }
`;

const Icon = styled.img`
    width: 30px;
    height: 30px;
    margin: 0 12px 0 10px;
`;

const UnorderedList = styled.ul`
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    color: white;
    list-style-type: none;
    width: 100%;
`;

const List = styled.li`
    color: #fff;
    border: none;
    font-size: 16px;
    background-color: rgb(0, 0, 0, 0) !important;
    width: 100%;
    margin: 7px 0px;
    padding: 7px;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
        border-radius: 6px;
        -webkit-box-shadow: 0px 0px 26px 10px rgba(238, 217, 38, 0.49);
        -moz-box-shadow: 0px 0px 26px 10px rgba(238, 217, 38, 0.49);
        box-shadow: 0px 0px 26px 10px rgba(238, 217, 38, 0.49);
        img {
            transform: scale(0.90909);
        }
    }
`;

const PulseLoaderSkeleton = styled.div`
    min-height: 452px;
`;
