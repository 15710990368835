import Api from './api';
import { ListingDTO, ResultDTO } from './model';
import UserHelper from '../lib/user-helper';
import ApiListings from './api-listings';
import ApiUser from './api-user';

export default class ApiSocial extends Api {
    public static likeListing(listingId: number): Promise<{ totalLikes: number }> {
        return this.axios
            .put(`/v1/listings/${listingId}/likes`)
            .then((res) => {
                return { totalLikes: res.data.totalLikes };
            })
            .then((res) => {
                ApiUser.updateMyPoints();
                return res;
            });
    }

    public static unlikeListing(listingId: number): Promise<{ totalLikes: number }> {
        return this.axios
            .delete(`/v1/listings/${listingId}/likes`)
            .then((res) => {
                return { totalLikes: res.data.totalLikes };
            })
            .then((res) => {
                ApiUser.updateMyPoints();
                return res;
            });
    }

    public static userLikes(): Promise<ListingDTO[]> {
        if (UserHelper.validateAndGetIdToken()) {
            return this.axios.get('/v1/user/likes', { cache: false }).then((res) => {
                return res.data.listings.map((item) => {
                    return ApiListings.toListingDTO(item);
                });
            });
        } else {
            return new Promise<ListingDTO[]>((resolve, _) => {
                resolve([]);
            });
        }
    }

    public static addBookmarkListing(listingId: number): Promise<ResultDTO> {
        return this.axios
            .put(`/v1/listings/${listingId}/bookmarks`)
            .then((res) => {
                return res.data;
            })
            .then((data) => {
                ApiUser.updateMyPoints();
                return data;
            });
    }

    public static removeBookmarkListing(listingId: number): Promise<ResultDTO> {
        return this.axios.delete(`/v1/listings/${listingId}/bookmarks`).then((res) => {
            return res.data;
        });
    }

    public static userBookmarks(): Promise<ListingDTO[]> {
        if (UserHelper.validateAndGetIdToken()) {
            return this.axios.get('/v1/user/bookmarks', { cache: false }).then((res) => {
                return res.data.listings.map((item) => {
                    return ApiListings.toListingDTO(item);
                });
            });
        } else {
            return new Promise<ListingDTO[]>((resolve, _) => {
                resolve([]);
            });
        }
    }
}
