import React from 'react';
import styled from 'styled-components';
import SoarHelper from '../../../lib/soar-helper';
import { BottomSheetState } from '../MapSelection/bottom-sheet';

interface MobileBottomSheetNoDataViewProps {
    currentBottomSheetState?: BottomSheetState;
}

const MobileBottomSheetNoDataView = (props: MobileBottomSheetNoDataViewProps) => {
    const isSoar = SoarHelper.isSoar();

    return (
        <NoDataView
            className={props.currentBottomSheetState === BottomSheetState.mid ? 'no-content-mid' : 'no-content'}
        >
            {!isSoar ? (
                <React.Fragment>
                    <SearchIcon className="fa fa-search" />
                    <NoDataViewText
                        className={
                            props.currentBottomSheetState === BottomSheetState.mid ? 'no-content-mid' : 'no-content'
                        }
                    >
                        There are no maps to display on your subdomain.
                        <NoDataViewSubtitle>You can upload maps using a desktop computer.</NoDataViewSubtitle>
                    </NoDataViewText>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <SearchIcon className="fa fa-search" />
                    <NoDataViewText
                        className={
                            props.currentBottomSheetState === BottomSheetState.mid ? 'no-content-mid' : 'no-content'
                        }
                    >
                        There are no maps to display in this area.
                        <NoDataViewSubtitle>Try searching in a different area.</NoDataViewSubtitle>
                    </NoDataViewText>
                </React.Fragment>
            )}
        </NoDataView>
    );
};

export default MobileBottomSheetNoDataView;

const NoDataView = styled.div`
    color: white;
    text-align: center;
    position: absolute;
    top: 100px;
    padding: 0px 20px;

    &.no-content-mid {
        scale: 0.8;
        width: auto;
        padding: inherit;
        position: inherit;
    }
`;

const SearchIcon = styled.i`
    color: #eed926;
    font-size: 74px;
    left: -15px;
    top: -15px;
    border: 3px solid #eed926;
    padding: 10px;
    z-index: 99999;
    border-radius: 8px;
    background: #191a1a;
    box-shadow: 0px 11px 14px -7px rgb(0 0 0 / 30%), 0px 23px 36px 3px rgb(0 0 0 / 24%),
        0px 9px 44px 8px rgb(0 0 0 / 22%);
    border: 2px solid #eed926;
    margin-bottom: -50px;
`;

const NoDataViewText = styled.div`
    color: white;
    font-size: 18px;
    border: 2px solid #eed926;
    border-radius: 8px;
    background: #191a1a;
    font-size: 16px;
    margin: auto;
    padding: 60px 30px 60px 30px;
    text-align: center;

    &.no-content-mid {
        padding: 30px;
    }
`;

const NoDataViewSubtitle = styled.div`
    margin-top: 20px;
    color: white;
    font-size: 14px;
`;
