import React from 'react';
import styled from 'styled-components';
import ListingHelper from '../../lib/listing-helper';

interface SearchContentCardProps {
    title: string;
    id: number;
    height: string;
    onClick: (id: number) => void;
}

const SearchContentCard = (props: SearchContentCardProps) => {

    return (
        <CardContainer height={props.height}>
            <ImageContainer onClick={(e) => e.preventDefault()}>
                <ImgContainer onClick={() => props.onClick(props.id)}>
                    <PreviewImage imageSrc={ListingHelper.getPreviewUrlForListing(props.id)} />
                </ImgContainer>
                <OverlaidDiv onClick={() => props.onClick(props.id)} data-testid="handle-image-select">
                    <OverlayDetails>
                        <OverlayTitle>{props.title}</OverlayTitle>
                    </OverlayDetails>
                </OverlaidDiv>
            </ImageContainer>
        </CardContainer>
    );
};

export default SearchContentCard;



const PreviewImage = styled.div<{ imageSrc: string }>`
    background-image: url(${props => props.imageSrc});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border: 1px solid transparent;
    width: 100%;
    display: flex;
    overflow: hidden;
    height: 100%;
`;

const CardContainer = styled.div<{ height?: string }>`
    width: 100%;
    height: ${props => props.height ? props.height : '100%'};
    flex: 1;
    padding: 4px;
`;

const ImageContainer = styled.a`
    display: block;
    height: 100%;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;
`;

const ImgContainer = styled.div`
    margin: 0;
    position: relative;
    vertical-align: middle;
    overflow: hidden;
    height: 100%;
    width: 100%;
`;

const OverlaidDiv = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    border-radius: 6px;
    background: transparent;
    color: #ffffff;

    ${ImageContainer}:hover & {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        background: rgba(0, 0, 0, 0.7);
        -webkit-transition: all 0.2s 0s ease-in-out;
        -moz-transition: all 0.2s 0s ease-in-out;
        -o-transition: all 0.2s 0s ease-in-out;
        transition: all 0.2s 0s ease-in-out;
        border: 1px solid #eed923;
    }
`;

const OverlayDetails = styled.div`
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
`;

const OverlayTitle = styled.p`
    height: 100%;
    top: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -8px;
    flex-wrap: wrap;
    color: #eed926;
    align-items: center;
    font-size: 1.3rem;
    padding: 0 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;

    ${ImageContainer}:hover & {
        -webkit-transition: all 0.3s 0s ease-in-out;
        -moz-transition: all 0.3s 0s ease-in-out;
        -o-transition: all 0.3s 0s ease-in-out;
        transition: all 0.3s 0s ease-in-out;
        opacity: 1;
    }
`;