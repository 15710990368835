import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UriHelper from '../../../lib/uri-helper';
import { actionUploadToSuperMap } from '../../../store/Dashboard/actions';
import { selectDatasetDetails } from '../../../store/Map/Uploads/selectors';
import { actionClearDroneImageUpload } from '../../../store/Map/Uploads/actions';
import { selectLoggedIn } from '../../../store/Account/selectors';
import UploadMapComplete from '../upload-map-complete';
import UploadMapMetadata from '../upload-map-metadata';
import { ListingType } from '../../../api/model';
import styled from 'styled-components';

enum Workflow {
    Metadata,
    Upload,
}

interface UploadGeoTIFFProps {
    handleShowUploadModal: () => void;
    mapUrl: string;
}

// TODO this is way hacky and needs attention
const UploadGeoTIFF = (props: UploadGeoTIFFProps) => {
    const isLoggedIn = useSelector(selectLoggedIn);
    const datasetDetails = useSelector(selectDatasetDetails);
    const dispatch = useDispatch();

    const [workflow, setWorkflow] = useState<Workflow>(Workflow.Metadata);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [file, setFile] = useState<any>(); // TODO: fix this type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [map, setMap] = useState<any>(); // TODO: fix this type
    const [title, setTitle] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [tags, setTags] = useState<string[] | undefined>(undefined);
    const [categories, setCategories] = useState<string[] | undefined>(undefined);

    const clearMetadata = () => {
        setTitle(undefined);
        setDescription(undefined);
        setTags(undefined);
        setCategories([]);
    };

    useEffect(() => {
        if (props.mapUrl) {
            fetch(props.mapUrl).then((fetched) => {
                fetched.blob().then((blob) => {
                    const map = blob as File;
                    //@ts-ignore
                    // TODO sort logic so name is correct or use a datetime : shrug
                    map.name = 'foo.tif';
                    setMap(map);
                });
            });
        }
    }, [props.mapUrl]);

    useEffect(() => {
        if (datasetDetails) {
            setFile(datasetDetails.file);
        }
    }, [datasetDetails]);

    const submitMap = async (
        mapTitle: string,
        mapDescription: string,
        mapTags: string[],
        mapCategories: string[],
        hasCheckedTerms: boolean,
        attachment?: File
    ) => {
        if (!map) {
            throw new Error('No Map found to upload');
        }

        const dto = {
            title: mapTitle,
            description: mapDescription,
            tags: mapTags,
            categories: mapCategories,
            tc: hasCheckedTerms,
        };
        dispatch(actionUploadToSuperMap(map, ListingType.TILE_LAYER, dto, JSON.stringify(dto), undefined, attachment));
        setWorkflow(Workflow.Upload);
        clearMetadata();
    };

    if (!isLoggedIn) {
        UriHelper.navigateToPath('/upload');
    }

    // TODO: This is a bit hacky, but it works for now
    switch (workflow) {
        case Workflow.Metadata:
            if (!file) return null;
            return (
                <UploadGeoTIFFContainer>
                    <UploadMapMetadata
                        onClickBack={() => {
                            props.handleShowUploadModal();
                        }}
                        submitted={(
                            title: string,
                            description: string,
                            tags: string[],
                            categories,
                            hasCheckedTerms,
                            attachment
                        ) => {
                            setTitle(title);
                            setDescription(description);
                            setTags(tags);
                            setCategories(categories);
                            submitMap(title, description, tags, categories, hasCheckedTerms, attachment);
                        }}
                        fileName={file.name}
                        title={title}
                        description={description}
                        tags={tags}
                        categories={categories}
                        listingType={ListingType.TILE_LAYER}
                    />
                </UploadGeoTIFFContainer>
            );

        case Workflow.Upload:
            if (!file) {
                throw new Error('Invalid workflow state.  File is undefined at Workflow.Upload');
            }
            return (
                <UploadGeoTIFFContainer>
                    <UploadMapComplete
                        onClickBack={() => {
                            dispatch(actionClearDroneImageUpload());
                            clearMetadata();
                            setWorkflow(Workflow.Metadata);
                            props.handleShowUploadModal();
                            UriHelper.navigateToPath('/upload');
                        }}
                        file={file}
                    />
                </UploadGeoTIFFContainer>
            );

        default:
            return <React.Fragment />;
    }
};

export default UploadGeoTIFF;

const UploadGeoTIFFContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 100px;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 600px;
    z-index: 9999;
    padding: 15px;
    border-radius: 6px;
    border: 1px solid rgb(255 255 255 / 30%);
`;
