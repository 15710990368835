import CookieConsent from 'react-cookie-consent';
import { isMobile } from 'react-device-detect';
import Analytics from '../../lib/user-analytics';

export const CustomCookieConsent = () => {
    const onAccept = () => {
        Analytics.Event('Cookie Consent', 'Clicked Accept');
    };
    return (
        <CookieConsent
            debug={false}
            style={{
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                border: 'none',
                borderRadius: '6px',
                display: 'flex',
                alignItems: 'center',
                maxWidth: isMobile ? 'auto' : '70%',
                width: isMobile ? 'auto' : '100%',
                padding: '0',
                borderBox: '0',
                marginBottom: isMobile ? '110px' : '20px',
                marginLeft: isMobile ? '10px' : '15%',
                marginRight: isMobile ? '10px' : '15%',
                position: 'fixed',
                bottom: '20px',
                zIndex: 99999,
            }}
            contentStyle={{
                color: '#fff',
                alignItems: 'center',
                justifyContent: 'center',
                margin: isMobile ? '10px' : '15px 15px 15px 20px',
            }}
            buttonText="ACCEPT"
            buttonStyle={{
                color: '#000',
                background: '#EED926',
                borderRadius: '6px',
                marginRight: isMobile ? '0px' : '20px',
                padding: '3px 20px',
                margin: isMobile && 'auto 10px 10px 10px',
                width: isMobile && '90vw',
            }}
            hideOnAccept={true}
            onAccept={onAccept}
        >
            We use cookies to provide the best experience for our users. To find out more please visit our full&nbsp;
            <a href="/privacy" style={{ color: '#EED926' }}>
                &nbsp;Privacy Policy
            </a>
            .
        </CookieConsent>
    );
};
