import React from 'react';
import { LatLng } from 'leaflet';
import SearchResultMarkerIcon, { SearchResultMarkerType } from './search-result-marker-icon';
import { selectActiveSearchPosition, selectHighlightedSearchPosition } from '../../../store/Search/selectors';
import { useSelector } from 'react-redux';

const SearchResultMarker = () => {
    const activePosition = useSelector(selectActiveSearchPosition);
    const highlightedPosition = useSelector(selectHighlightedSearchPosition);

    return (
        <React.Fragment>
            {activePosition instanceof LatLng && (
                <SearchResultMarkerIcon
                    position={activePosition}
                    type={SearchResultMarkerType.large}
                />
            )}

            {highlightedPosition instanceof LatLng && (
                <SearchResultMarkerIcon
                    position={highlightedPosition}
                    type={SearchResultMarkerType.large}
                    color="6ecc39"
                />
            )}
        </React.Fragment>
    );
};

export default SearchResultMarker;
