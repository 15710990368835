import { useState, useEffect } from 'react';
import styled from 'styled-components';
import SoarModal from '../../Shared/soar-modal';
import { StyledButton } from '../../Shared/styled-button';
import UploadHelper from './upload-tutorial-helper';
import { UploadTutorialIntroduction } from './upload-tutorial-introduction';
import { UploadTutorialRefine } from './upload-tutorial-refine';

type UploadTutorialStep = 'INTRO' | 'COMPLETE';

interface UploadTutorialGuideProps {
    isOpen: boolean;
    toggle: () => void;
    step: UploadTutorialStep;
}

export const UploadTutorialGuide = ({ isOpen, toggle, step }: UploadTutorialGuideProps) => {
    const [checkedOptOut, setCheckedOptOut] = useState<boolean>(false);

    useEffect(() => {
        if (checkedOptOut) {
            UploadHelper.setOptedOutUploadTutorial();
        }
    }, [checkedOptOut]);

    return (
        <SoarModal
            title={step === 'INTRO' ? 'Add Control Points To The Image' : 'Rectifying Image Complete!'}
            isOpen={isOpen}
            toggle={toggle}
            width={'600px'}
        >
            <UploadTutorialContainer>
                {step === 'INTRO' && <UploadTutorialIntroduction />}
                {step === 'COMPLETE' && <UploadTutorialRefine />}
                <UploadTutorialFooter>
                    <UploadTutorialOptOut>
                        <UploadOptOutCheckbox
                            type="checkbox"
                            defaultChecked={checkedOptOut}
                            onChange={() => setCheckedOptOut(true)}
                        />
                        Do not show this again
                    </UploadTutorialOptOut>
                    <UploadTutorialButton onClick={toggle}>Close</UploadTutorialButton>
                </UploadTutorialFooter>
            </UploadTutorialContainer>
        </SoarModal>
    );
};

const UploadTutorialContainer = styled.div`
    margin: 1rem;
`;

const UploadTutorialButton = styled(StyledButton)`
    float: right;
    padding: 0.375rem 1.25rem;
    text-transform: none;
`;

const UploadTutorialFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const UploadTutorialOptOut = styled.div`
    color: rgba(255, 255, 255, 0.87);
    margin: auto 0;
`;

const UploadOptOutCheckbox = styled.input`
    margin-right: 5px;
`;
