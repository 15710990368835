import React from 'react';
import { useSelector } from 'react-redux';
import ShareService from '../../../../lib/share-service';
import Analytics from '../../../../lib/user-analytics';
import { selectActiveAllMap } from '../../../../store/Map/SuperMap/selectors';
import ShareLink from './share-link';

// Soar specific social media share links
const SocialLinks = () => {
    const selectedMap = useSelector(selectActiveAllMap);

    const handleShareLink = async (mapId: number, href: string, event: string, eventType: string) => {
        const url = ShareService.shareMapUrl(mapId);
        Analytics.Event(event, eventType, mapId);

        const shareURL = `${href}${url}`;
        if (shareURL) {
            window.open(shareURL, '_blank');
        }
    };

    if (!selectedMap) {
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            <ShareLink
                title={'Share to Twitter'}
                handleOnClick={() =>
                    handleShareLink(
                        selectedMap.id,
                        'https://www.twitter.com/intent/tweet?hashtags=SoarEarth&url=',
                        'Social Media - Opened To Share',
                        'Twitter'
                    )
                }
                icon={'fa fa-twitter'}
            />
            <ShareLink
                title={'Share to Facebook'}
                handleOnClick={() =>
                    handleShareLink(
                        selectedMap.id,
                        'https://www.facebook.com/sharer.php?u=',
                        'Social Media - Opened To Share',
                        'Facebook'
                    )
                }
                icon={'fa fa-facebook'}
            />
            <ShareLink
                title={'Share to LinkedIn'}
                handleOnClick={() =>
                    handleShareLink(
                        selectedMap.id,
                        'https://www.linkedin.com/shareArticle?mini=true&url=',
                        'Social Media - Opened To Share',
                        'LinkedIn'
                    )
                }
                icon={'fa fa-linkedin'}
            />
        </React.Fragment>
    );
};

export default SocialLinks;
