
export default class MathUtil {

    /**
     * Linear interpolates (lerps) a value that is between 'from' and 'to' based on the given ratio 'value'
     */
    public static lerp(from: number, to: number, value: number): number {
        return (1 - value) * from + value * to;
    }

    /**
     * The opposite of lerp. Normalizes the ratio 'value' between 'from' and 'to'
     */
    public static inverseLerp(from: number, to: number, value: number): number {
        return (value - from ) / (to - from);
    }

    /**
     * Maps a value from one range to another
     */
    public static remap(originalFrom, originalTo, targetFrom, targetTo, value): number {
        const relativeValue = MathUtil.inverseLerp(originalFrom, originalTo, value);
        return MathUtil.lerp(targetFrom, targetTo, relativeValue);
    }

}