import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import styled from 'styled-components';
import ApiAutocomplete from '../../../api/api-autocomplete';
import ApiListings from '../../../api/api-listings';
import { AutocompleteDTO } from '../../../api/model';
import SoarHelper from '../../../lib/soar-helper';
import Analytics from '../../../lib/user-analytics';
import { selectSelectedContinent } from '../../../store/Map/MapSelection/selectors';
import { actionUpdateActiveSearchPosition } from '../../../store/Search/actions';
import { useInvalidateSearchOnEscapeKey } from '../../Search/use-invalidate-search-on-escape-key';
import MobileNavigationProfile from './mobile-navigation-profile';

interface MobileSearchInputProps {
    handleOnSearchInputHasFocus: (hasFocus: boolean) => void;
    handleOnSearchLoading: (isLoading: boolean) => void;
    handleAutocompleteResults: (autocompleteResults: AutocompleteDTO[]) => void;
    handleSearchTerm: (searchTerm: string) => void;
    handleEnterKeyPress: () => void;
    handleCloseSearch: () => void;
    searchInputRef: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const DEBOUNCE_TIME = 300; //ms

const MobileSearchInput = (props: MobileSearchInputProps) => {
    const { handleOnSearchLoading, handleAutocompleteResults, handleSearchTerm } = props;
    const [searchInput, setSearchInput] = useState<string>('');

    const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>('');
    const [searchSubject] = useState(() => new Subject<string>());

    const selectedContinent = useSelector(selectSelectedContinent);

    const dispatch = useDispatch();

    useInvalidateSearchOnEscapeKey(props.searchInputRef.current, props.handleOnSearchInputHasFocus, setSearchInput);

    useEffect(() => {
        handleSearchTerm(debouncedSearchInput);
        if (debouncedSearchInput.length <= 2) {
            handleAutocompleteResults([]);
            handleOnSearchLoading(false);
            handleSearchTerm('');
        } else if (!selectedContinent) {
            handleOnSearchLoading(true);
            ApiAutocomplete.cancelAutoComplete('Cancel autocomplete');
            ApiAutocomplete.autocomplete(debouncedSearchInput, ['ADDRESS', 'USER'], 20)
                .then((res) => {
                    handleAutocompleteResults(res);
                })
                .finally(() => {
                    handleOnSearchLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchInput]);

    useEffect(() => {
        searchSubject.next(searchInput);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput]);

    useEffect(() => {
        const subscription = searchSubject
            .pipe(
                tap((value) => {
                    if (value.length <= 2) {
                        handleAutocompleteResults([]);
                        handleOnSearchLoading(false);
                    }
                    ApiAutocomplete.cancelAutoComplete('Cancel autocomplete');
                    ApiListings.cancelGetListings('Cancel get listings');
                }),
                debounceTime(DEBOUNCE_TIME)
            )
            .subscribe((next) => {
                setDebouncedSearchInput(next);
            });

        return () => {
            subscription.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
    };

    const handleClickLogo = () => {
        Analytics.Event('Mobile - Head Navigation', 'Clicked Logo');
        // removes params and resets to base URI
        window.location.href = `${window.location.protocol}//${window.location.host}`;
    };

    const handleCloseIcon = () => {
        setSearchInput('');
        dispatch(actionUpdateActiveSearchPosition(undefined));
        Analytics.Event('Mobile - Search Input', 'Cleared Search Input');
    };
    const soarLogo = SoarHelper.isSoar()
        ? '/assets/logos/soar-icon-search.png'
        : '/assets/subdomain/subdomain-soar-icon-search.png';

    return (
        <SearchNavigationContainer>
            <SearchLogo src={soarLogo} onClick={handleClickLogo} />
            <Input
                ref={props.searchInputRef}
                value={searchInput}
                onChange={handleChange}
                onFocus={() => props.handleOnSearchInputHasFocus(true)}
                onBlur={() => props.handleOnSearchInputHasFocus(false)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.currentTarget.blur();
                        props.handleEnterKeyPress();
                    }
                }}
                type="text"
                placeholder={selectedContinent ? '' : "Search Soar's digital atlas..."}
            />
            {searchInput.length > 0 && <CloseIcon onClick={handleCloseIcon} />}
            <MobileNavigationProfile
                onOpen={() => {
                    props.handleCloseSearch();
                }}
            />
        </SearchNavigationContainer>
    );
};

export default MobileSearchInput;

const SearchNavigationContainer = styled.div`
    display: flex;
    flex-direction: row;
    background: rgba(0, 0, 0, 0.75);
    border: 1px solid #515151;
    border-radius: 6px;
    margin: 5px;
    position: fixed;
    width: 97%;
    z-index: 99999;
`;

const SearchLogo = styled.img`
    width: 35px;
    height: 28px;
    margin: 11px 8px 0 5px;
`;

const Input = styled.input`
    background: transparent;
    border: none;
    width: 100%;
    outline: none;
    color: white;
    padding: 0;
    font-size: 18px;
`;

const CloseIcon = styled.button`
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 21L20.9999 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L1.00006 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
`;
