import { RefObject } from 'react';
import { Map as LeafletMap } from 'react-leaflet';
import { LatLngBounds } from 'leaflet';
import { LatLng } from 'leaflet';
import { toast } from 'react-toastify';
import { RectifiedMap } from '../../../api/model';
import GeoUtil from '../../../lib/geo-util';
import UriHelper from '../../../lib/uri-helper';
import { DatasetDetails } from '../Model/model';
import FileUtil from '../../../lib/file-util';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const proj4 = require('proj4').default;

export default class GeoRectifyUtil {
    public static calcImageBoxHolder(imageHeight: number, imageWidth: number): LatLngBounds {
        const origin = new LatLng(0, 0);
        const bounds = origin.toBounds(300);
        const widthMeters = GeoUtil.widthKilometers(bounds) * 1000;
        const heightMeters = (widthMeters * imageHeight) / imageWidth;
        const imageBounds = GeoUtil.latLngBoundingBoxFromWidthHeightCenter(widthMeters, heightMeters, origin);
        return imageBounds;
    }

    public static calculateGCPArgs(
        gcps: LatLng[][],
        corners: LatLng[] | LatLngBounds,
        imageWidth: number,
        imageHeight: number,
        mapRef: RefObject<LeafletMap>
    ): string[] {
        if (gcps.length > 2) {
            let gcpArgs: string[] = [];
            gcps.map((gcp) => {
                const pixel = this.pixelForLatLng(gcp[0], corners, imageWidth, imageHeight, mapRef);
                if (pixel.x) {
                    const cmd = [
                        '-gcp',
                        `${pixel.x.toFixed(2)}`,
                        `${pixel.y.toFixed(2)}`,
                        `${gcp[1].lng}`,
                        `${gcp[1].lat}`,
                    ];
                    gcpArgs = gcpArgs.concat(cmd);
                }
            });
            return gcpArgs;
        }
        return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static bboxPixelForLatLng(
        latLng: LatLng,
        bbox: LatLngBounds,
        imageWidth: number,
        imageHeight: number,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mapRef: any // TODO type safe it exists as this but think namings are confusing to what is implied
    ) {
        if (mapRef) {
            //const leafletMap = mapRef;
            const northEast = bbox.getNorthEast();
            const southWest = bbox.getSouthWest();
            // const nePointBBOX = new LatLngBounds(northEast, latLng);
            // const swPointBBOX = new LatLngBounds(southWest, latLng);

            // const targetScreenCoord = leafletMap.latLngToLayerPoint(latLng);
            // const nePixelCoord = leafletMap.latLngToLayerPoint(northEast);
            // const sePixelCoord = leafletMap.latLngToLayerPoint(southWest);
            // const pixelHeight =
            //     (imageHeight * (targetScreenCoord.y - nePixelCoord.y)) / (sePixelCoord.y - nePixelCoord.y);

            const widthKM = GeoUtil.distanceKilometers(
                new LatLng(latLng.lat, northEast.lng),
                new LatLng(latLng.lat, southWest.lng)
            );

            const heightKM = GeoUtil.distanceKilometers(
                new LatLng(northEast.lat, latLng.lng),
                new LatLng(southWest.lat, latLng.lng)
            );

            const relativeWidthKM = GeoUtil.distanceKilometers(
                new LatLng(latLng.lat, latLng.lng),
                new LatLng(latLng.lat, southWest.lng)
            );
            const relativeHeightKM = GeoUtil.distanceKilometers(
                new LatLng(latLng.lat, latLng.lng),
                new LatLng(northEast.lat, latLng.lng)
            );

            //return { x: (imageWidth * relativeWidthKM) / widthKM, y: pixelHeight };
            return { x: (imageWidth * relativeWidthKM) / widthKM, y: (imageHeight * relativeHeightKM) / heightKM };
        }
        return {};
    }

    // this is fkd as well, why....
    // private static distortablePixelForLatLng(
    public static distortablePixelForLatLng(
        latLng: LatLng,
        corners: LatLng[],
        imageWidth: number,
        imageHeight: number,
        mapRef: RefObject<LeafletMap>
    ) {
        if (mapRef?.current?.leafletElement && corners.length === 4) {
            const leafletMap = mapRef.current.leafletElement;

            const targetScreenCoord = leafletMap.latLngToLayerPoint(latLng);

            const origin = leafletMap.latLngToLayerPoint(corners[0]);
            const corner1 = leafletMap.latLngToLayerPoint(corners[1]);
            // const corner2 = leafletMap.latLngToLayerPoint(corners[2]);

            const A = this.pixelDist(origin, corner1);
            const B = this.pixelDist(origin, targetScreenCoord);
            const C = this.pixelDist(corner1, targetScreenCoord);
            // const D = this.pixelDist(origin, corner2);

            const theta = Math.acos((A * A + B * B - C * C) / (2 * A * B));

            const pixelHeight = B * Math.sin(theta);
            const pixelWidth = B * Math.cos(theta);

            // const imagePixelDistForA =
            //     imageWidth > imageHeight ? (A > D ? imageWidth : imageHeight) : A > D ? imageHeight : imageWidth;

            //return { x: (pixelWidth * imagePixelDistForA) / A, y: (pixelHeight * imagePixelDistForA) / A }; // works for most
            return { x: (pixelWidth * imageWidth) / A, y: (pixelHeight * imageWidth) / A };
        }
        return {};
    }

    public static pixelForLatLng(
        latLng: LatLng,
        corners: LatLng[] | LatLngBounds,
        imageWidth: number,
        imageHeight: number,
        mapRef: RefObject<LeafletMap>
    ) {
        if (corners instanceof LatLngBounds) {
            return this.bboxPixelForLatLng(latLng, corners, imageWidth, imageHeight, mapRef);
        } else {
            return this.distortablePixelForLatLng(latLng, corners, imageWidth, imageHeight, mapRef);
        }
    }

    private static pixelDist(point1, point2) {
        return Math.pow(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2), 0.5);
    }

    private static evaluateBBOX(gtifWidth, gtifHeight, gtifWkt, gtifTransform) {
        const EPSG4326 =
            'GEOGCS["WGS 84",DATUM["WGS_1984",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.01745329251994328,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4326"]]';
        if (!(gtifWidth && gtifHeight && gtifWkt && gtifTransform)) {
            return undefined;
        }
        const cornersPx = [
            [0, 0],
            [gtifWidth, 0],
            [gtifWidth, gtifHeight],
            [0, gtifHeight],
        ];
        const cornersGeo = cornersPx.map(([x, y]) => {
            return [
                gtifTransform[0] + gtifTransform[1] * x + gtifTransform[2] * y,
                gtifTransform[3] + gtifTransform[4] * x + gtifTransform[5] * y,
            ];
        });

        const ll = proj4(gtifWkt.replace('_', ' '), EPSG4326, cornersGeo[0]);
        //const lr = proj4(gtifWkt.replace("_", " "), EPSG4326, cornersGeo[1]);
        const ur = proj4(gtifWkt.replace('_', ' '), EPSG4326, cornersGeo[2]);
        //const ul = proj4(gtifWkt.replace("_", " "), EPSG4326, cornersGeo[3]);
        return new LatLngBounds(new LatLng(ll[1], ll[0]), new LatLng(ur[1], ur[0]));
    }

    public static calculatePixelResolution = (
        corners: LatLng[] | LatLngBounds,
        gcps: LatLng[][],
        imageWidth: number,
        imageHeight: number,
        mapRef: RefObject<LeafletMap>
    ) => {
        if (corners && gcps.length > 1) {
            //gdal_translate -of GTiff -gcp 605.417 293.125 1.28997e+07 -3.7588e+06 -gcp 192.708 368.958 1.28997e+07 -3.7588e+06 -gcp 557.292 491.458 1.28997e+07 -3.7588e+06 "/home/alasdair/mappt/NWWIIM_Map_Program_Inline_1920x1400_r1.jpg" "/tmp/NWWIIM_Map_Program_Inline_1920x1400_r1.jpg"
            //gdalwarp -r near -order 1 -co COMPRESS=NONE  -t_srs EPSG:3857 "/tmp/NWWIIM_Map_Program_Inline_1920x1400_r1.jpg" "/home/alasdair/mappt/NWWIIM_Map_Program_Inline_1920x1400_r1_modified.tif"

            const sortedGCPs = gcps.sort((a, b) => {
                return GeoUtil.distanceKilometers(gcps[0][1], a[1]) - GeoUtil.distanceKilometers(gcps[0][1], b[1]);
            });
            const index1 = 0,
                index2 = gcps.length - 1;
            const sImage_1 = sortedGCPs[index1][0];
            const sImage_2 = sortedGCPs[index2][0];
            const sTarget_1 = sortedGCPs[index1][1];
            const sTarget_2 = sortedGCPs[index2][1];
            const pixelS1 = this.pixelForLatLng(sImage_1, corners, imageWidth, imageHeight, mapRef);
            const pixelS2 = this.pixelForLatLng(sImage_2, corners, imageWidth, imageHeight, mapRef);

            if (!pixelS1.x || !pixelS2.x) {
                return undefined;
            }

            const distanceMeters =
                GeoUtil.distanceKilometers(
                    new LatLng(sTarget_1.lat, sTarget_1.lng),
                    new LatLng(sTarget_2.lat, sTarget_2.lng)
                ) * 1000;
            const distancePixels = Math.pow(
                Math.pow(Math.abs(pixelS1.x - pixelS2.x), 2) + Math.pow(Math.abs(pixelS1.y - pixelS2.y), 2),
                0.5
            );
            if (distanceMeters && distancePixels) {
                return distanceMeters / distancePixels;
            }
        }
        return undefined;
    };

    public static generatePredictedGCPPoints = (dataset: DatasetDetails, imageBounds: LatLng[]) => {
        // Sets 8 specific points for a drone image with exif data so it can be pushed straight through
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const temp: any = [];
        temp.push([0, 0, imageBounds[0], imageBounds[0]]);
        const midpoint = GeoUtil.calculateMidPoint(imageBounds[0], imageBounds[1]);
        temp.push([dataset.width / 2, 0, midpoint, midpoint]);

        temp.push([dataset.width, 0, imageBounds[1], imageBounds[1]]);
        const midpoint2 = GeoUtil.calculateMidPoint(imageBounds[0], imageBounds[2]);
        temp.push([0, dataset.height / 2, midpoint2, midpoint2]);

        temp.push([0, dataset.height, imageBounds[2], imageBounds[2]]);
        const midpoint3 = GeoUtil.calculateMidPoint(imageBounds[1], imageBounds[3]);
        temp.push([dataset.width, dataset.height / 2, midpoint3, midpoint3]);

        temp.push([dataset.width, dataset.height, imageBounds[3], imageBounds[3]]);
        const midpoint4 = GeoUtil.calculateMidPoint(imageBounds[2], imageBounds[3]);
        temp.push([dataset.width / 2, dataset.height, midpoint4, midpoint4]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return temp as any;
    };

    public static isValidFileType = (file: File) => {
        if (file.type.includes('tif')) return true;
        if (file.type.includes('ecw')) return true;
        if (file.type.includes('jpeg')) return true;
        if (file.type.includes('jpg')) return true;
        if (file.type.includes('png')) return true;
        return false;
    };

    public static createDataset2(
        dataset,
        pixelResolution: number,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        gcpArray: any,
        datasetDetails?: DatasetDetails
    ): Promise<RectifiedMap> {
        /**
         * If you decide to come and play with these good luck :P - I have spent a lot of time trying to get this to work and
         * setting the warp arguments can be a bit annoying as they work for some but not for others.
         *
         * For warp Arg details to get you started see
         * https://gdal.org/programs/gdalwarp.html
         *
         * I did think that potentially some issues may possible be due to not transplating the image first to apply epsg:4326 to it
         * if time permits will have a go at it, doing so would open up more options on how the image is handled but unsure
         *
         * Some more light reading
         * https://gdal.org/programs/gdal_translate.html
         *
         * Notes:
         * The current lib is https://github.com/azavea/loam which is using gdal 2.4 (i believe at the time of writing this)
         * the warp equivalent is just warp
         * the translate equivalent is convert
         *
         * the translate has an option that may be useful which is -a_srs, this can apply a projection to the image
         * https://gis.stackexchange.com/questions/398221/python-gdal-convert-jpeg-to-geotiff-given-location-information#answer-416374
         */

        //const warpArgs = ['-order', '1', '-s_srs', 'EPSG:4326', '-t_srs', 'EPSG:4326', '-overwrite'];

        const warpArgs = ['-tps', '1', '-s_srs', 'EPSG:4326', '-t_srs', 'EPSG:4326', '-overwrite', '-refine_gcps', '4'];

        // Assume png needs nodata set
        if (datasetDetails?.type === 'image/png') {
            // https://gis.stackexchange.com/questions/283975/performing-gdal-translate-to-png-jpeg/#answer-283977
            warpArgs.push('-dstnodata', '-9999');
            //warpArgs.push('-r', 'cubic');
            //warpArgs.push('-r', 'bilinear');
            warpArgs.push('-r', 'near');
        } else {
            warpArgs.push('-r', 'near');
        }

        // Assume if it has 3 bands we want to apply alpha
        if (datasetDetails?.bands === 3) {
            warpArgs.push('-dstalpha');
        }
        console.log('The warp Args', warpArgs);
        const start = window.performance.now() / 1000;
        return (
            dataset
                //.convert(this.calculateGCPArgs(gcps, corners, imageWidth, imageHeight, mapRef))
                .convert(this.prepareGcpArguments(gcpArray))
                .then((datasetGCP) => {
                    console.log('TIME: Convert: ', window.performance.now() / 1000 - start);
                    return datasetGCP.warp(warpArgs).then((datasetWarped) => {
                        return Promise.all([
                            datasetWarped.convert(['-of', 'GTiff', '-co', 'COMPRESS=LZW']), //'-co', 'PHOTOMETRIC=YCBCR', '-co', 'TILED=YES'
                            datasetWarped.convert(['-of', 'PNG', '-co', 'COMPRESS=LZW']),
                            // datasetWarped.convert(['-of', 'GTiff', '-co', 'COMPRESS=JPEG']), //'-co', 'PHOTOMETRIC=YCBCR', '-co', 'TILED=YES'
                            // datasetWarped.convert(['-of', 'PNG', '-co', 'COMPRESS=JPEG']),
                        ]).then(([datasetGTiff, datasetPNG]) => {
                            let warpedTIFFUrl;
                            let warpedPNGUrl;

                            console.log('TIME: Warp: ', window.performance.now() / 1000 - start);
                            return Promise.all([
                                datasetGTiff.bytes(),
                                datasetGTiff.wkt(),
                                datasetGTiff.transform(),
                                datasetPNG.bytes(),
                            ]).then(([gtiffBytes, gtiffWKT, gtiffTransform, pngBytes]) => {
                                console.log('TIME: Bytes: ', window.performance.now() / 1000 - start);
                                const warpedGtiffBlob = new Blob([gtiffBytes]);
                                warpedTIFFUrl = URL.createObjectURL(warpedGtiffBlob);
                                const warpedPngBlob = new Blob([pngBytes]);
                                warpedPNGUrl = URL.createObjectURL(warpedPngBlob);
                                const reader = new FileReader();
                                reader.readAsDataURL(warpedPngBlob);

                                return new Promise((resolve, _) => {
                                    reader.onload = () => {
                                        const image = new Image();
                                        image.src = reader.result as string;
                                        image.onload = () => {
                                            console.log('TIME: Blob: ', window.performance.now() / 1000 - start);
                                            const warpedBBOX = this.evaluateBBOX(
                                                image.width,
                                                image.height,
                                                gtiffWKT,
                                                gtiffTransform
                                            );
                                            const newGCPs = gcpArray.map((gcp) => {
                                                gcp[0] = gcp[1];
                                                return gcp;
                                            });
                                            const corners = warpedBBOX && [
                                                warpedBBOX.getNorthWest(),
                                                warpedBBOX.getNorthEast(),
                                                warpedBBOX.getSouthWest(),
                                                warpedBBOX.getSouthEast(),
                                            ];
                                            resolve({
                                                blobUrlPNG: warpedPNGUrl,
                                                blobUrlTIFF: warpedTIFFUrl,
                                                dataset: datasetWarped,
                                                corners: corners,
                                                bbox: warpedBBOX,
                                                pixelWidth: image.width,
                                                pixelHeight: image.height,
                                                pixelResolution: pixelResolution,
                                                gcps: newGCPs,
                                            });
                                        };
                                    };
                                });
                            });
                        });
                    });
                })
                .catch((err) => {
                    toast.error('Error creating map', err.toString());
                    console.log(err);
                })
        );
    }

    // Evaluate and return the details from a given file
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static readFileDataset(loam: any, file: any): Promise<DatasetDetails> {
        return loam
            .open(file)
            .then((ds) => {
                return Promise.all([ds.width(), ds.height(), ds.count(), ds.wkt(), ds.transform()]).then(
                    async ([width, height, count, wkt, geoTransform]) => {
                        // TODO may be cleaner to have this attached so it is not disassociated from the dataset
                        // const EPSG4326 =
                        //     'GEOGCS["WGS 84",DATUM["WGS_1984",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.01745329251994328,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4326"]]';
                        const cornersPx = [
                            [0, 0],
                            [width, 0],
                            [width, height],
                            [0, height],
                        ];
                        const fileDetails = await FileUtil.readXmpData(file).then((result) => {
                            return result;
                        });
                        const evaluateBBox = GeoRectifyUtil.evaluateBBOX(width, height, wkt, geoTransform);
                        // I fthe image is a drone image leave it at the same quality or apply compression // 1% of original size for preview in distortion tool, not seeing any quality loss but see if it is picked up but extremly performant
                        const compressionRatio = fileDetails.exif ? 1 : 0.01;
                        const compressedPreview = await GeoRectifyUtil.compressedPreview(file, compressionRatio);
                        return {
                            width: width,
                            height: height,
                            bands: count,
                            wkt: wkt,
                            cornersPx: cornersPx,
                            transform: geoTransform,
                            name: file.name,
                            type: file.type,
                            size: file.size,
                            preview: URL.createObjectURL(file),
                            minimizedPreview: URL.createObjectURL(compressedPreview),
                            evaluatedBBOX: evaluateBBox,
                            file: file,
                        };
                    }
                );
            })
            .catch((error) => {
                console.log(error);
                alert(error);
                UriHelper.navigateToPath('/upload');
            });
    }

    // Compress a image file to a smaller size for the preview in the distortion tool
    // Makes the image adjustign smoother but does take a slightly longer time to load
    // The actual rectification is processed on the original image
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static compressedPreview(file: any, quality: number): Promise<File> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            reader.onload = (event: any) => {
                const img = new Image();
                img.src = event.target.result;

                img.onload = () => {
                    const elem = document.createElement('canvas');
                    const width = img.width;
                    const height = img.height;
                    elem.width = width;
                    elem.height = height;
                    const ctx = elem.getContext('2d');
                    // @ts-ignore
                    ctx.drawImage(img, 0, 0, width, height);
                    // @ts-ignore
                    ctx.canvas.toBlob(
                        (blob) => {
                            // @ts-ignore
                            const compressedFile = new File([blob], file.name, {
                                type: file.type,
                                lastModified: Date.now(),
                            });
                            resolve(compressedFile);
                        },
                        file.type,
                        quality
                    );
                };
                img.onerror = (error) => reject(error);
            };
        });
    }

    public static prepareGcpArguments(gcpArray): string[] {
        if (gcpArray.length > 2) {
            let gcpArgs: string[] = [];
            gcpArray.map((gcp) => {
                if (gcp) {
                    const cmd = ['-gcp', `${gcp[0]}`, `${gcp[1]}`, `${gcp[3].lng}`, `${gcp[3].lat}`];
                    gcpArgs = gcpArgs.concat(cmd);
                }
            });
            return gcpArgs;
        }
        return [];
    }

    public static setRedrag = (bounds: LatLngBounds, leafletMap) => {
        const imageRedragBounds = bounds.pad(0.5);
        leafletMap.on('drag', () => {
            leafletMap.panInsideBounds(imageRedragBounds, { animate: false });
        });

        leafletMap.on('zoomend', () => {
            leafletMap.panInsideBounds(imageRedragBounds, { animate: false });
        });
    };
}
