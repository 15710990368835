import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { StyledButton } from '../../Shared/styled-button';

interface DownloadGeorectifiedProps {
    tiffBlob: string;
}

const DownloadGeorectified = ({ tiffBlob }: DownloadGeorectifiedProps) => {
    const generateFileName = () => {
        const filename = tiffBlob.split('/')[3].replaceAll('-', '');
        return filename;
    };

    const downloadImage = () => {
        if (tiffBlob) {
            const filename = generateFileName();
            toast.dark(`Downloading Image, Enjoy`);
            const link = document.createElement('a');
            link.href = tiffBlob;
            link.download = `${filename}.tiff`;
            link.setAttribute('target', '_blank');
            link.click();
        } else {
            toast.dark(`Error downloading`);
        }
    };

    return (
        <DownloadButton onClick={() => downloadImage()}>
            Download <DownloadIcon src={'/assets/upload/download-icon.png'} />
        </DownloadButton>
    );
};

export default DownloadGeorectified;

const DownloadButton = styled(StyledButton)`
    /* position: absolute;
    right: 2.6rem;
    padding: 0.6rem 0.6rem; */
    text-transform: none;
`;

const DownloadIcon = styled.img`
    width: 14px;
    height: 14px;
    margin-left: 0.3rem;
    margin-top: -0.2rem;
`;
