import React, { useEffect, useState } from 'react';
import { LatLng } from 'leaflet';
import { Marker, Polyline } from 'react-leaflet';
import { RectifiedMap } from '../../../api/model';
import { UploadHighlightedTarget } from '../Model/model';
import { UploadTutorialGuide } from '../Tutorial/upload-tutorial-guide';
import UploadHelper from '../Tutorial/upload-tutorial-helper';
import ReactDistortableImageOverlay from 'react-leaflet-distortable-imageoverlay';
import { UploadMarker } from '../UploadMap/upload-marker';
import styled from 'styled-components';
import UploadSplitScreenSlider from '../Shared/upload-split-screen-slider';
import GeorectifierUploadMap from '../UploadMap/georectifier-upload-map';
import GCPTextHelper from '../GCPControls/gcp-text-helper';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const L = require('leaflet');

interface UploadSplitScreenProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gcpsArray: any;
    isSelectedGCP: (gcp: LatLng[]) => boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setImageMapRef: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setTargetMapRef: any;
    previewUrl: string;
    distortableBounds?: LatLng[];
    rectangleBounds?: LatLng[];
    setWarpedDataset: (warped: RectifiedMap) => void;
    highlightTarget: UploadHighlightedTarget;
    requiresLocation?: boolean;
    updateMarkerPosition?: (newPosition: LatLng, index: number) => void;
    calc?: () => void;
    isMarkerActive: boolean;
}

const MARKER_SIZE = 24;

const UploadSplitScreen = (props: UploadSplitScreenProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [editOpacity, setEditOpacity] = useState<number>(1);
    const [controlOpacity, setControlOpacity] = useState<number>(0);

    useEffect(() => {
        if (!UploadHelper.hasOptedOutUploadTutorial()) {
            setIsOpen(true);
        }
    }, []);

    return (
        <React.Fragment>
            <SplitScreenHeader>
                <div>
                    <ScreenHeader bgColor={'rgb(255 0 0 / 67%)'}>Edit</ScreenHeader>
                    {!props.isMarkerActive ? (
                        <GCPTextHelper marginLeft="-50px" message={'Click the map to add a control point'} />
                    ) : null}
                </div>
                <div>
                    <ScreenHeader bgColor={'rgb(0 128 0 / 67%)'}>Control</ScreenHeader>
                    {props.highlightTarget === 'EDIT' && props.isMarkerActive ? (
                        <GCPTextHelper
                            marginLeft="-110px"
                            message={'Click where you want the control point to be repositioned'}
                        />
                    ) : null}
                </div>
            </SplitScreenHeader>
            <GeorectifierUploadMap
                type={'IMAGE'}
                position={'LEFT'}
                setRef={props.setImageMapRef}
                highlightTarget={props.highlightTarget}
                baseMap
                borderColor={'rgba(255, 0, 0, 0.87)'}
            >
                {props.gcpsArray?.length &&
                    props.gcpsArray.map((gcp, index) => {
                        const active = props.isSelectedGCP && props.isSelectedGCP(gcp);
                        return (
                            <React.Fragment key={index}>
                                <Marker
                                    position={new LatLng(gcp[2].lat, gcp[2].lng)}
                                    icon={L.divIcon({
                                        html: `${index + 1}`,
                                        iconAnchor: L.point(MARKER_SIZE / 2, MARKER_SIZE / 2),
                                        className: `marker-selected ${active ? 'active' : ''}`,
                                    })}
                                />
                            </React.Fragment>
                        );
                    })}
                {props.previewUrl && props.distortableBounds ? (
                    <ReactDistortableImageOverlay
                        url={props.previewUrl}
                        editMode={'none'}
                        onCornersUpdated={() => ''}
                        corners={props.distortableBounds}
                        opacity={editOpacity}
                    />
                ) : null}
            </GeorectifierUploadMap>
            <GeorectifierUploadMap
                type={'MAP'}
                position={'RIGHT'}
                setRef={props.setTargetMapRef}
                highlightTarget={props.highlightTarget}
                baseMap
                borderColor={'rgba(0, 128, 0, 0.87)'}
            >
                {props.gcpsArray?.length &&
                    props.gcpsArray.map((gcp, index) => {
                        const active = props.isSelectedGCP && props.isSelectedGCP(gcp);
                        return (
                            <React.Fragment key={index}>
                                <Marker
                                    position={new LatLng(gcp[2].lat, gcp[2].lng)}
                                    icon={L.divIcon({
                                        html: `${index + 1}`,
                                        iconAnchor: L.point(MARKER_SIZE / 2, MARKER_SIZE / 2),
                                        className: `marker-selected ${active ? 'active' : ''}`,
                                    })}
                                />
                            </React.Fragment>
                        );
                    })}

                {props.gcpsArray?.length &&
                    props.gcpsArray.map((gcp, index) => {
                        const active = props.isSelectedGCP && props.isSelectedGCP(gcp);
                        return (
                            <React.Fragment key={index}>
                                <UploadMarker
                                    index={index}
                                    gcp={gcp}
                                    active={active}
                                    updateMarkerPosition={props.updateMarkerPosition}
                                />
                            </React.Fragment>
                        );
                    })}

                {props.previewUrl && props.distortableBounds ? (
                    <ReactDistortableImageOverlay
                        url={props.previewUrl}
                        editMode={'none'}
                        onCornersUpdated={() => ''}
                        corners={props.distortableBounds}
                        opacity={controlOpacity}
                    />
                ) : null}

                {props.rectangleBounds && <Polyline positions={props.rectangleBounds} />}
            </GeorectifierUploadMap>
            <UploadTransparencySliders>
                <UploadSplitScreenSlider opacity={editOpacity} setOpacity={setEditOpacity} />
                <UploadSplitScreenSlider opacity={controlOpacity} setOpacity={setControlOpacity} />
            </UploadTransparencySliders>
            {isOpen && props.distortableBounds && (
                <UploadTutorialGuide toggle={() => setIsOpen(!isOpen)} isOpen={isOpen} step={'INTRO'} />
            )}
        </React.Fragment>
    );
};

export default UploadSplitScreen;

const SplitScreenHeader = styled.div`
    position: absolute;
    top: 57px;
    width: 100%;
    z-index: 99999;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    pointer-events: none;
`;

const ScreenHeader = styled.div<{ bgColor: string }>`
    background-color: ${(props) => props.bgColor};
    width: 150px;
    font-size: 18px;
    margin: auto;
    border-radius: 6px;
    text-align: center;
    pointer-events: none;
    color: rgba(255, 255, 255, 0.87);
    border: 1px solid rgba(0, 0, 0, 0.3);
`;

const UploadTransparencySliders = styled.div`
    position: absolute;
    top: calc(66vh + 30px);
    width: 100%;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    pointer-events: none;
`;
