import React from 'react';
import styled from 'styled-components';

export const UploadTutorialRefine = () => {
    return (
        <React.Fragment>
            <UploadTutorialRefineImage>
                <UploadImageBackground src="/assets/upload/upload-distorted-background.png" />
                <UploadImageMap src="/assets/upload/upload-distorted-map.png" />
                {/* <UploadImageMarker1 src="/assets/upload/upload-distorted-marker.png" />
                <UploadImageMarker2 src="/assets/upload/upload-distorted-marker.png" /> */}
            </UploadTutorialRefineImage>
            <UploadTutorialRefineText>
                Your image is now a map in .tif format!
                <br /> If you are not happy with the result, you can go back and retry.
            </UploadTutorialRefineText>
        </React.Fragment>
    );
};

const UploadTutorialRefineText = styled.div`
    color: rgba(255, 255, 255, 0.87);
    font-size: 18px;
    margin: 0.5rem 0rem;
`;

const UploadTutorialRefineImage = styled.div`
    width: 100%;
    height: 17.25rem;
`;

const UploadImageBackground = styled.img`
    width: 100%;
    height: 100%;
    position: relative;
`;

const UploadImageMap = styled.img`
    width: 22.125rem;
    height: 15.125rem;
    position: absolute;
    left: 27%;
    top: 7rem;
`;

// const UploadImageMarker1 = styled.img`
//     width: 1.125rem;
//     height: 1.5rem;
//     position: absolute;
//     left: 50%;
//     top: 10.5rem;
// `;

// const UploadImageMarker2 = styled.img`
//     width: 1.125rem;
//     height: 1.5rem;
//     position: absolute;
//     left: 49%;
//     top: 11rem;
// `;
