import styled from 'styled-components';

interface TargetProps {
    targetPadding: number;
    top?: number;
    left?: number;
    width?: number;
    height?: number;
}

export const Target = styled.div<TargetProps>`
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 10px;

    /* direct padding wasn't working, so I manually padded by using a const targetPadding */
    margin-top: ${(props) => `calc(${props.top}px - ${props.targetPadding}px)`};
    margin-left: ${(props) => `calc(${props.left}px - ${props.targetPadding}px)`};
    width: ${(props) => `calc(${props.width}px + ${props.targetPadding * 2}px)`};
    height: ${(props) => `calc(${props.height}px + ${props.targetPadding * 2}px)`};
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.5);
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 99999;
    &:hover {
        cursor: pointer;
    }
    pointer-events: none;
`;

export const TargetBorderHighlight = styled.div<TargetProps>`
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 10px;
    margin-top: ${(props) => `calc(${props.top}px - ${props.targetPadding}px)`};
    margin-left: ${(props) => `calc(${props.left}px - ${props.targetPadding}px)`};
    width: ${(props) => `calc(${props.width}px + ${props.targetPadding * 2}px)`};
    height: ${(props) => `calc(${props.height}px + ${props.targetPadding * 2}px)`};
    border: 1px solid #eed926;
    -webkit-box-shadow: 0px 0px 26px 10px rgba(238, 217, 38, 0.49);
    -moz-box-shadow: 0px 0px 26px 10px rgba(238, 217, 38, 0.49);
    box-shadow: 0px 0px 26px 10px rgba(238, 217, 38, 0.49);
`;

export const PageContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99999;
`;
export const TutorialContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-left: 30vw;
    margin-top: 30vh;

    max-width: 50%;
    height: 60vh;
`;

interface TutorialTitleProps {
    height: number;
}
export const TutorialTitle = styled.h2<TutorialTitleProps>`
    font-style: normal;
    font-weight: bold;
    font-size: ${(props) => props.height}px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #ffffff;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 20px 40px;
`;

export const TutorialBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    order: 2;
`;

export const TutorialBodyText = styled.h5`
    font-family: Exo 2;
    font-style: normal;
    font-weight: 500;
    font-size: 2.5vh;
    text-align: center;
    letter-spacing: 0.03em;
    color: #ffffff;
    flex: none;
    flex-grow: 0;
    margin: 0px 40px;
`;

export const TutorialCaption = styled.h6`
    font-family: Exo 2;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5vh;
    line-height: 90%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: #ffffff;
    flex: none;
    order: 3;
    flex-grow: 0;
    margin-top: 15px;
`;

export const TutorialFooter = styled.div`
    width: 400px;
    order: 4;
    margin-top: 20px;
    position: absolute;
    top: 80%;
`;

export const ButtonSkip = styled.div`
    align-items: center;
    text-align: center;
    padding: 10px;
    position: static;
    width: 185px;
    height: 40px;
    top: 0px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    border-radius: 6px;
    margin-bottom: 5px;
    float: left;
    color: rgba(255, 255, 255, 0.3);
    &:hover {
        cursor: pointer;
    }
    user-select: none;
`;

export const ButtonNext = styled.div`
    align-items: center;
    text-align: center;
    padding: 10px;
    width: 185px;
    height: 40px;
    top: 0px;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 1);
    border-radius: 6px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-bottom: 5px;
    float: right;

    &:hover {
        cursor: pointer;
    }
    user-select: none;
`;

export const TutorialImage = styled.img`
    height: 15vh;
`;

export const ArrowSvg = styled.svg`
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;

    height: 100%;
    width: 100%;
`;

interface ArrowProps {
    ml: number;
    mt: number;
    cl: number;
    ct: number;
    up: number;
    down: number;
    width: number;
    height: number;
}

export const ArrowPath = styled.path<ArrowProps>`
    stroke-width: 4px;
    fill: transparent;
    stroke: #fff;
    d: path(
        '
        M${(props) => props.ml}
        ${(props) => props.mt}
        c${(props) => props.cl}
        ${(props) => props.ct}
        ${(props) => props.up}
        ${(props) => props.down}
        ${(props) => props.width}
        ${(props) => props.height}
    '
    );
`;
