import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import ApiUser from '../../../../api/api-user';
import { AutocompleteDTO } from '../../../../api/model';
import UserHelper from '../../../../lib/user-helper';
import { actionClearSelectedListing } from '../../../../store/Map/MapSelection/actions';

interface MobileSearchAutocompleteItemProps {
    autocomplete: AutocompleteDTO;
    onSelectAutocomplete: (location: AutocompleteDTO) => void;
}

const MobileSearchAutocompleteItem = (props: MobileSearchAutocompleteItemProps) => {
    const dispatch = useDispatch();
    const clearSelectedListing = (clearListing: boolean) => dispatch(actionClearSelectedListing(clearListing));

    const onClick = () => {
        clearSelectedListing(true);
        props.onSelectAutocomplete(props.autocomplete);
    };

    const [userAvatarUrl, setUserAvatarUrl] = useState('/assets/avatars/Avatar1.png');

    useEffect(() => {
        if (props.autocomplete.type === 'USER' && props.autocomplete.userId) {
            ApiUser.getUserProfile(props.autocomplete.userId).then((res) => {
                if (res.avatarUrl) {
                    setUserAvatarUrl(res.avatarUrl);
                } else {
                    const defaultAvatarIcon = UserHelper.getDefaultAvatar(res.userId);
                    setUserAvatarUrl(defaultAvatarIcon);
                }
            });
        }
    }, [props.autocomplete]);

    if (props.autocomplete.type === 'USER') {
        return (
            <MobileAutocompleteItem onClick={onClick} title={`Open ${props.autocomplete.title}'s profile`}>
                <ListItemIcon src={userAvatarUrl} />
                <ListItemText>{props.autocomplete.title}</ListItemText>
            </MobileAutocompleteItem>
        );
    }

    if (props.autocomplete.type === 'COORDINATE') {
        return (
            <MobileAutocompleteItem onClick={onClick} title={`Open the map at ${props.autocomplete.title}`}>
                <ListItemIcon src={'/assets/explore-dropdown/marker-icon-black.svg'} />
                <ListItemText>Coordinate: {props.autocomplete.title}</ListItemText>
            </MobileAutocompleteItem>
        );
    }

    return (
        <MobileAutocompleteItem onClick={onClick} title={`Open the map at ${props.autocomplete.title}`}>
            <ListItemIcon src={'/assets/explore-dropdown/marker-icon-black.svg'} />
            <ListItemText>{props.autocomplete.title}</ListItemText>
        </MobileAutocompleteItem>
    );
};

export default MobileSearchAutocompleteItem;

const MobileAutocompleteItem = styled.span`
    display: flex;
    border-radius: 6px;
    border: 1px solid #eed926;
    background: #eed926;
    margin: 4px;
    padding: 2px 6px 4px 6px;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    color: white;
    min-width: fit-content;
`;

const ListItemIcon = styled.img`
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-right: 3px;
    border-radius: 260px;
`;

const ListItemText = styled.div`
    color: black;
    width: max-content;
`;
