import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Map as LeafletMap } from 'react-leaflet';
import { LatLngBounds, LatLng } from 'leaflet';
import SatelliteLayers from '../MapView/SatelliteTracker/satellite-layers';
import TileLayers from '../MapView/TileLayers/tilelayers';
import SentinelLayers from '../MapView/Sentinel/sentinel-layers';
import Annotations from '../MapView/annotations';
import {
    selectIsMobileInteractionEnabled,
    selectShowMobileLoadingSpinner,
    selectBeingMobileSentinelSearch,
} from '../../store/Map/Mobile/selectors';
import { actionSentinelMapZoom, actionSentinelSelectAOI } from '../../store/Map/Sentinel/actions';
import { selectFlyToMapPosition } from '../../store/App/selectors';
import Analytics from '../../lib/user-analytics';
import { actionMapLoaded } from '../../store/App/actions';
import { useDispatch, useSelector } from 'react-redux';
import { SubmittingSpinner } from '../Shared/submitting-spinner';
import SatelliteProviders from '../MapView/SatelliteProviders/satellite-providers';
import MobileBasemap from './BaseMap/mobile-basemap';
import SearchResultMarker from '../MapView/SearchResultMarker/search-result-marker';

// Values that give the map an aesthetically pleasing start position
const WORLD_ZOOM = 1.5;
const MAX_ZOOM_LEVEL = 28;
const WORLD_CENTER: [number, number] = [34.5, 0];

// Level to zoom to when focusing on a point
const FLYTO_ZOOM_LEVEL = 11;

// Force the map back to these bounds while panning
const WORLD_DRAG_REBOUND_BOUNDS: [number, number][] = [
    [-80.98155760646617, -220],
    [89.99346179538875, 240],
];

const MobileMap = () => {
    const isMobileMapInteractionEnabled = useSelector(selectIsMobileInteractionEnabled);
    const beginMobileSentinelSearch = useSelector(selectBeingMobileSentinelSearch);
    const mapPosition = useSelector(selectFlyToMapPosition);
    const showMobileLoadingSpinner = useSelector(selectShowMobileLoadingSpinner);

    const dispatch = useDispatch();

    const mapRef = useRef<LeafletMap>(null);
    const [mapLoaded, setMapLoaded] = useState(false);

    useEffect(() => {
        const setSentinelAOI = (bbox: LatLngBounds) => dispatch(actionSentinelSelectAOI(bbox));

        if (beginMobileSentinelSearch && mapRef && mapRef.current) {
            const leafletMap = mapRef.current.leafletElement;
            setSentinelAOI(leafletMap.getBounds());
            Analytics.Event('Mobile Map', 'Created sentinel AOI', leafletMap.getBounds());
            Analytics.Event('Mobile - Map', 'Created sentinel AOI', leafletMap.getBounds());
        }
    }, [beginMobileSentinelSearch, dispatch]);

    // Prevent the map from wrapping by forcing in to pan inside world bounds
    useEffect(() => {
        const setMapZoomLevel = (zoom: number) => dispatch(actionSentinelMapZoom(zoom));

        if (mapRef && mapRef.current) {
            const leafletMap = mapRef.current.leafletElement;

            leafletMap.on('drag', () => {
                leafletMap.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
            });

            leafletMap.on('zoomend', () => {
                leafletMap.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
                setMapZoomLevel(leafletMap.getZoom());
            });

            setMapLoaded(true);
        }
    }, [mapRef, dispatch]);

    useEffect(() => {
        const setActionMapLoaded = () => dispatch(actionMapLoaded());

        if (mapLoaded) {
            setActionMapLoaded();
        }
    }, [mapLoaded, dispatch]);

    useEffect(() => {
        if (mapPosition) {
            flyTo(mapPosition);
        }
    }, [mapPosition]);

    useEffect(() => {
        if (mapRef && mapRef.current) {
            const leafletMap = mapRef.current.leafletElement;

            if (isMobileMapInteractionEnabled) {
                leafletMap.dragging.enable();
                leafletMap.touchZoom.enable();
                leafletMap.doubleClickZoom.enabled();
                leafletMap.scrollWheelZoom.enabled();
                leafletMap.boxZoom.enabled();
                leafletMap.keyboard.enabled();
            } else {
                leafletMap.dragging.disable();
                leafletMap.touchZoom.disable();
                leafletMap.doubleClickZoom.disable();
                leafletMap.scrollWheelZoom.disable();
                leafletMap.boxZoom.disable();
                leafletMap.keyboard.disable();
            }
        }
    }, [isMobileMapInteractionEnabled]);

    const flyTo = (position: LatLng | LatLngBounds) => {
        if (mapRef.current) {
            if (position instanceof LatLng) {
                const params = new URLSearchParams(window.location.search);
                const zoom = parseInt(params.get('pos')?.split(',')[2] || '');
                if (zoom && !Number.isNaN(zoom)) {
                    mapRef.current.leafletElement.flyTo(position, zoom);
                } else {
                    mapRef.current.leafletElement.flyTo(position, FLYTO_ZOOM_LEVEL);
                }
            } else if (position instanceof LatLngBounds) {
                mapRef.current.leafletElement.flyToBounds(position);
            }
        }
    };

    return (
        <MapContainer>
            <LeafletMap
                tap={false}
                zoom={WORLD_ZOOM}
                center={WORLD_CENTER}
                zoomControl={false}
                minZoom={WORLD_ZOOM}
                maxZoom={MAX_ZOOM_LEVEL}
                maxBounds={[
                    [-90, -180],
                    [90, 180],
                ]}
                maxBoundsViscosity={1.0}
                ref={mapRef}
            >
                {mapLoaded && mapRef?.current ? (
                    <React.Fragment>
                        <MobileBasemap />
                        <SatelliteLayers leafletMap={mapRef.current} />
                        <TileLayers leafletMap={mapRef.current} />
                        <SentinelLayers />
                        <Annotations leafletMap={mapRef.current} />
                        <SatelliteProviders />
                        <SearchResultMarker />
                    </React.Fragment>
                ) : null}
            </LeafletMap>

            {showMobileLoadingSpinner ? (
                <MobileSpinnerContainer>
                    <SubmittingSpinner />
                </MobileSpinnerContainer>
            ) : null}
        </MapContainer>
    );
};

export default MobileMap;

const MobileSpinnerContainer = styled.div`
    position: absolute;
    left: 50vw;
    top: 38vh;
    width: 35px;
    height: 35px;
    z-index: 3000;
`;

const MapContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: black;
    /* overflow-y: hidden; */
    /* overscroll-behavior-y: none !important; */

    #kml-button,
    #box-select-button,
    #ruler-button,
    #text-field-button,
    #arrow-button,
    #circle-button,
    #rectangle-button,
    #latLngTool-button,
    #box-zoom-button,
    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out,
    #export-button,
    #remove-all-button {
        visibility: hidden !important;
        width: 0 !important;
        margin: 0 !important;
    }
`;
