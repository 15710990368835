import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import NavigationFilterInput from '../Shared/navigation-filter-input';
import ImageryMasonary from './imagery-masonary';
import { ImagerySort } from './imagery-options';
import { PulseLoader } from '../../Shared/pulse-loader';
import NavigationPanel from '../Shared/navigation-panel';
import { actionGetMyListings } from '../../../store/Account/actions';
import { selectMyListings } from '../../../store/Account/selectors';
import DashboardContainer from '../Shared/dashboard-container';

const MyImagery = () => {
    const dispatch = useDispatch();
    const myListings = useSelector(selectMyListings);

    const [filterValue, setFilterValue] = useState<string>('');
    const [sortBy, setSortBy] = useState<string | undefined>(undefined);

    const handleClearFilter = () => {
        setFilterValue('');
    };

    const refresh = () => {
        dispatch(actionGetMyListings());
    };

    //These are computed values, no need to put it in state
    let filteredList = myListings;
    if (filteredList) {
        if (filterValue.length > 0) {
            filteredList = filteredList.filter((listing) =>
                listing.metadata
                    .concat(listing.review as string)
                    .toLowerCase()
                    .includes(filterValue)
            );
        }
        if (sortBy === ImagerySort.Title) {
            filteredList = filteredList.sort((a, b) => a.title.localeCompare(b.title));
        } else if (sortBy === ImagerySort.LastPosted) {
            filteredList = filteredList.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)); //descending
        } else {
            filteredList = filteredList.sort((a, b) =>
                (a.updatedAt ?? a.createdAt) > (b.updatedAt ?? b.createdAt) ? -1 : 1
            ); //default order by updatedAt desc
        }
    }

    return (
        <React.Fragment>
            <NavigationPanel label={'My Maps'} />
            <NavigationFilterInput
                placeholderText="Filter by title, tags, status or description.."
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                setSortValue={setSortBy}
                handleClearFilter={handleClearFilter}
            />
            <ProfileImageryBodyDiv>
                {myListings === undefined ? (
                    <LoaderContainer>
                        <PulseLoader iconSize="2rem" iconMargin="10px" />
                    </LoaderContainer>
                ) : (
                    <DashboardContainer marginTop={65}>
                        <ImageryMasonary
                            listings={filteredList?.length ? filteredList : []}
                            onRefresh={refresh}
                            sortBy={sortBy}
                            hasSearched={filterValue.length > 0 ? true : false}
                        />
                    </DashboardContainer>
                )}
            </ProfileImageryBodyDiv>
        </React.Fragment>
    );
};

export default MyImagery;

const ProfileImageryBodyDiv = styled.div`
    margin-top: 20px;

    @media (max-width: 900px) {
        flex-direction: column;
    }
`;

const LoaderContainer = styled.div`
    height: calc(100vh - 100px);
`;
