import React from 'react';
import styled from 'styled-components';
import ListingHelper from '../../../../lib/listing-helper';

interface ListStyleListingCardProps {
    listing: {
        id: number;
        authorName: string;
        title: string;
    };
    onSelectListing: (id: number) => void;
}

const ListStyleListingCard = (props: ListStyleListingCardProps) => {
    return (
        <Container onClick={() => props.onSelectListing(props.listing.id)}>
            <PreviewImageContainer>
                <PreviewImage src={ListingHelper.getPreviewUrlForListing(props.listing.id)} />
            </PreviewImageContainer>
            <ContentContainer>
                <Title>{props.listing.title}</Title>
                <SubText>
                    by <span>{props.listing.authorName}</span>
                </SubText>
            </ContentContainer>
        </Container>
    );
};

export default ListStyleListingCard;

const Container = styled.div`
    pointer-events: all;
    height: 48px;
    width: calc(100% - 8px);
    border-radius: 6px;
    overflow: hidden;
    margin: 0 4px 3px 4px;
    border: 1px solid white;
    border: 1px solid rgb(255 255 255 / 10%);
    background-color: hsla(0, 100%, 100%, 0.01);
    box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
        0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
    display: flex;
    flex-direction: horizontal;
`;

const PreviewImageContainer = styled.div`
    height: 100%;
    width: 25%;
    max-width: 64px;
`;

const PreviewImage = styled.img`
    height: 100%;
    width: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    margin: 0;
    padding: 0;
    object-fit: cover;
`;

const ContentContainer = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    margin: 2px 0 0 4px;
    width: 75%;
`;

const Title = styled.p`
    color: white;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    flex: 1 0 auto;
    margin: 0;
    padding: 0;
`;

const SubText = styled.p`
    color: rgba(255, 255, 255, 0.75);
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;

    span {
        color: #eed926;
    }
`;
