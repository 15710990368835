import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    actionSetHighlightedListingGroup,
    actionSetHighlightedListingId,
} from '../../../store/Map/MapSelection/actions';
import {
    selectBestFittingListingGroups,
    selectGlobalListings,
    selectHighlightedListingGroup,
} from '../../../store/Map/MapSelection/selectors';

import ListingHelper from '../../../lib/listing-helper';
import UriHelper from '../../../lib/uri-helper';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { selectSideDrawerMode } from '../../../store/SideDrawer/selectors';
import PreviewTile from '../../Shared/preview-tile';
import VisibleMapsNoResults from './visible-maps-no-results';
import Analytics from '../../../lib/user-analytics';
import ApiAnalytics from '../../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote } from '../../../api/model';

const DEFAULT_NUMBER_OF_MAPS_TO_SHOW = 10;

const VisibleMaps = () => {
    const sideDrawerMode = useSelector(selectSideDrawerMode);

    const dispatch = useDispatch();

    const setTileLayerHighlighted = (tileLayerId: number | undefined) =>
        dispatch(actionSetHighlightedListingId(tileLayerId));
    const invalidateHighlightedTileLayerGroup = () => dispatch(actionSetHighlightedListingGroup(undefined));
    const mapsInViewport = useSelector(selectBestFittingListingGroups).flatMap((t) => t.tileLayers);
    const globalListings = useSelector(selectGlobalListings);

    const [mapsInSidedrawer, setMapsInSidedrawer] = useState(mapsInViewport.slice(0, DEFAULT_NUMBER_OF_MAPS_TO_SHOW));
    const highlightedTileLayerGroup = useSelector(selectHighlightedListingGroup);
    const [numberOfMapsToShow, setNumberOfMapsToShow] = useState(DEFAULT_NUMBER_OF_MAPS_TO_SHOW);

    useEffect(() => {
        setMapsInSidedrawer(mapsInViewport.slice(0, DEFAULT_NUMBER_OF_MAPS_TO_SHOW));
        setNumberOfMapsToShow(DEFAULT_NUMBER_OF_MAPS_TO_SHOW);

        //Show first 5 global maps if no maps in the area
        if (mapsInViewport.length === 0 && globalListings) {
            setMapsInSidedrawer(globalListings.tileLayers.slice(0, 5));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapsInViewport.length, globalListings]);

    const onMouseEnter = (tileLayerId: number) => {
        setTileLayerHighlighted(tileLayerId);
        invalidateHighlightedTileLayerGroup();
    };

    const onMouseLeave = () => {
        setTileLayerHighlighted(undefined);
    };

    const onClickListing = (listingId: number) => {
        UriHelper.navigateToMap(listingId);
        ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.FEATURED_DRAW, listingId);
        Analytics.Event('Side Drawer', 'Clicked To View', listingId);
        if (mapsInViewport.length === 0) {
            UriHelper.addPositionToPath(`/maps/${listingId}`);
        } else {
            UriHelper.navigateToPath(`/maps/${listingId}`);
        }
    };

    return (
        <React.Fragment>
            <ImageContainer>
                {mapsInSidedrawer.map((tileLayer) => {
                    const isHighlighted =
                        (highlightedTileLayerGroup &&
                            highlightedTileLayerGroup.tileLayers.length === 1 &&
                            highlightedTileLayerGroup.tileLayers[0].id === tileLayer.id) ||
                        false;
                    return (
                        <Container
                            key={tileLayer.id}
                            onMouseEnter={() => onMouseEnter(tileLayer.id)}
                            onMouseLeave={() => onMouseLeave()}
                            onClick={() => onClickListing(tileLayer.id)}
                            title={tileLayer.title}
                            isHighlighted={isHighlighted}
                        >
                            <PreviewTile
                                title={tileLayer.title}
                                previewUrl={ListingHelper.getPreviewUrlForListing(tileLayer.id)}
                                subText={`by ${tileLayer.authorName}`}
                            />
                        </Container>
                    );
                })}
            </ImageContainer>

            {mapsInViewport.length > numberOfMapsToShow ? (
                <LoadMoreButton
                    onClick={() => {
                        setMapsInSidedrawer(
                            mapsInViewport.slice(0, numberOfMapsToShow + DEFAULT_NUMBER_OF_MAPS_TO_SHOW)
                        );
                        setNumberOfMapsToShow((current) => current + DEFAULT_NUMBER_OF_MAPS_TO_SHOW);
                    }}
                >
                    Load more
                </LoadMoreButton>
            ) : null}

            {sideDrawerMode === SideDrawerMode.YOUR_MAPS && !mapsInSidedrawer.length ? <VisibleMapsNoResults /> : null}
        </React.Fragment>
    );
};

export default VisibleMaps;

const Container = styled.div<{ isHighlighted: boolean }>`
    cursor: pointer;
    height: 48px;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    margin: 0 0 3px 0;
    border: ${(props) => (props.isHighlighted ? '1px solid #eed309' : '1px solid rgba(0, 0, 0, 0.3)')};

    &:hover {
        border: 1px solid #eed309;
    }
`;

const LoadMoreButton = styled.button`
    margin: 0 auto;
    width: 100%;
    align-items: normal;
    background-color: rgba(0, 0, 0, 0);
    border-style: none;
    box-sizing: content-box;
    color: #eed309;
    cursor: pointer;
    display: inline;
    font: inherit;
    height: auto;
    padding: 0;
    text-decoration: underline;
    -moz-appearance: none;

    &:focus {
        outline: none;
        border: none;
    }
`;

const ImageContainer = styled.div`
    margin: 8px;
`;
