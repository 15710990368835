import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiFeaturedContent from '../../../../api/api-featured-content';
import { ListingDTO } from '../../../../api/model';
import Analytics from '../../../../lib/user-analytics';
import { MobileSearchResultTitle } from '../Results/mobile-search-results';
import MobileSearchResultCarousel from '../Carousel/mobile-search-result-carousel';
import MobileSearchOptions from '../../Shared/mobile-card-share-options';
import UriHelper from '../../../../lib/uri-helper';
import { useDispatch } from 'react-redux';
import { actionClearSelectedListing } from '../../../../store/Map/MapSelection/actions';
import { Fade } from 'reactstrap';
import { PulseLoader } from '../../../Shared/pulse-loader';
import ListingHelper from '../../../../lib/listing-helper';

const MobileSearchCurrentEvent = () => {
    const [currentEventsListing, setCurrentEventsListing] = useState<ListingDTO | undefined>(undefined);

    const dispatch = useDispatch();
    const clearSelectedListing = (clearListing: boolean) => dispatch(actionClearSelectedListing(clearListing));

    useEffect(() => {
        ApiFeaturedContent.getCurrentEventsListing().then((res) => setCurrentEventsListing(res));
    }, []);

    const handleSelectProfile = (userId: string) => {
        // Promise chain to handle clearing the map before routing otherwise it occasionally does not update properly
        Promise.resolve()
            .then(() => {
                clearSelectedListing(true);
            })
            .finally(() => {
                Analytics.Event('Mobile - Search Bar', 'Selected Current Event Profile', userId);
                UriHelper.navigateToPath(`/profile/${userId}`);
            });
    };

    const handleSelectListing = (mapId: number) => {
        // Promise chain to handle clearing the map before routing otherwise it occasionally does not update properly
        Promise.resolve()
            .then(() => {
                clearSelectedListing(true);
            })
            .finally(() => {
                Analytics.Event('Mobile - Search Bar', 'Selected Current Event Listing', mapId);
                UriHelper.navigateToPath(`/maps/${mapId}`);
            });
    };

    if (!currentEventsListing) {
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            <MobileSearchResultTitle>
                <MobileSegmentTitleIcon src="/assets/search-content/emoji-current-events.svg" />
                Keep a bird's eye on <span>current events</span>
            </MobileSearchResultTitle>
            <SearchEventContainer>
                {currentEventsListing ? (
                    <Fade in={currentEventsListing ? true : false}>
                        <Title>{currentEventsListing.title}</Title>
                        <MobileEventUser onClick={() => handleSelectProfile(currentEventsListing.userId)}>
                            <span>By</span>
                            {currentEventsListing.userName}
                            {currentEventsListing.reputable ? (
                                <ReputableUserIcon
                                    alt="Verified"
                                    title="Verified"
                                    src="/assets/verified-user-tick.svg"
                                />
                            ) : null}
                        </MobileEventUser>
                        <MobileSearchEventImage
                            src={ListingHelper.getPreviewUrlForListing(currentEventsListing.id)}
                            onClick={() => handleSelectListing(currentEventsListing.id)}
                        />
                        <EventCardShare>
                            <MobileSearchOptions listing={currentEventsListing} />
                        </EventCardShare>
                        {currentEventsListing.tags.length ? (
                            <MobileSearchResultCarousel padding="5px 0px">
                                {currentEventsListing.tags &&
                                    currentEventsListing.tags.map((tag, index) => {
                                        return <MobileCarouselTag key={`${tag}-${index}`}>{tag}</MobileCarouselTag>;
                                    })}
                            </MobileSearchResultCarousel>
                        ) : null}
                    </Fade>
                ) : (
                    <EventLoadingContainer>
                        <EventLoader>
                            <PulseLoader iconSize="20px" />
                        </EventLoader>
                    </EventLoadingContainer>
                )}
            </SearchEventContainer>
        </React.Fragment>
    );
};

export default MobileSearchCurrentEvent;

export const MobileSegmentTitleIcon = styled.img`
    width: 16px;
    height: 16px;
    margin: -4px 8px 0 8px;
`;

const SearchEventContainer = styled.div`
    padding: 5px;
    margin: 10px 5px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(255 255 255 / 10%);
    border-radius: 6px;
    background-color: hsla(0, 100%, 100%, 0.01);
    box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
        0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
`;

const MobileSearchEventImage = styled.img`
    height: 300px;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
    background-color: hsla(0, 100%, 100%, 0.02);
    box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
        0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
`;

const EventCardShare = styled.div`
    margin: 10px 0px 3px 5px;
`;

const Title = styled.div`
    color: rgba(255, 255, 255, 0.87);
    font-size: 18px;
    font-weight: 400;
    margin: 5px 5px 10px 5px;
    line-height: 20px;
`;

const MobileCarouselTag = styled.div`
    margin: 3px;
    background-color: transparent !important;
    display: flex;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    padding: 1px 3px 3px 3px;
    line-height: 14px;
    opacity: 0.5;
    color: white;
    width: auto;
    font-size: 12px;
`;

const MobileEventUser = styled.div`
    color: #eed926;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin: 0px 0px -27px 0px;
    padding: 3px 5px;
    position: sticky;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
        0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);

    span {
        color: rgba(255, 255, 255, 0.87);
        padding-right: 3px;
    }
`;

const EventLoadingContainer = styled.div`
    height: 447px;
`;

const EventLoader = styled.div`
    padding-top: 50%;
`;

const ReputableUserIcon = styled.img`
    margin: -8px 0px 0px 3px;
    width: 12px;
    height: 12px;
`;
