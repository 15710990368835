import React from 'react';
import { isMobile } from 'react-device-detect';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { ListingDTO } from '../../../api/model';
import ListingHelper from '../../../lib/listing-helper';
import Analytics from '../../../lib/user-analytics';
import profileIconUrl from '../profile-icon-url';
const moment = require('moment');

interface RecentActivityItemProps {
    listing: ListingDTO;
}

const RecentActivityItem = (props: RecentActivityItemProps) => {
    const username = props.listing.userName || props.listing.userEmail;
    const timeAgo = moment(new Date(props.listing.createdAt * 1000)).fromNow();
    const title = props.listing.title;

    const handleClickFilename = () => {
        Analytics.Event('My Dashboard', 'Clicked recent activity item', props.listing.id);
        window.open(`/maps/${props.listing.id}`);
    };

    return (
        <RecentActivityLi>
            {isMobile ? (
                <RecentActivityMobileContainer>
                    <React.Fragment>
                        {props.listing.id ? (
                            <RecentActivityImage src={ListingHelper.getPreviewUrlForListing(props.listing.id)} />
                        ) : (
                            <RecentActivityImage src={profileIconUrl.satelliteImageUrl} />
                        )}
                    </React.Fragment>
                    <RecentActivityDetailsDiv>
                        <RecentActivityUserName>{username}</RecentActivityUserName>
                        <RecentActivityUploadDate>uploaded a file {timeAgo}</RecentActivityUploadDate>
                        <RecentActivityFileName onClick={handleClickFilename}>{title}</RecentActivityFileName>
                    </RecentActivityDetailsDiv>
                </RecentActivityMobileContainer>
            ) : (
                <Row>
                    <Col md={1}>
                        {props.listing.id ? (
                            <DashBoardImage
                                onClick={handleClickFilename}
                                src={ListingHelper.getPreviewUrlForListing(props.listing.id)}
                            />
                        ) : (
                            <DashBoardImage onClick={handleClickFilename} src={profileIconUrl.satelliteImageUrl} />
                        )}
                    </Col>
                    <Col md={11}>
                        <Row>
                            <RecentActivityUserName>{username}</RecentActivityUserName>
                            <RecentActivityUploadDate>&nbsp; uploaded {timeAgo}</RecentActivityUploadDate>
                        </Row>
                        <Row>
                            <RecentActivityFileName onClick={handleClickFilename}>{title}</RecentActivityFileName>
                        </Row>
                    </Col>
                </Row>
            )}
        </RecentActivityLi>
    );
};

export default RecentActivityItem;

const DashBoardImage = styled.img`
    width: 60px;
    height: 60px;
    cursor: pointer;
    border-radius: 6px;
    margin: 10px 0px;
`;

const RecentActivityLi = styled.li`
    border-top: 1px solid rgba(255, 255, 255, 0.37);
    list-style-type: none;
`;

const RecentActivityMobileContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;

const RecentActivityImage = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 6px;
    margin: 10px 0px;
`;

const RecentActivityDetailsDiv = styled.div`
    padding-top: 7px;
    width: calc(100vw - 140px);
`;

const RecentActivityUserName = styled.div`
    color: rgba(255, 255, 255, 0.87);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const RecentActivityUploadDate = styled.p`
    color: rgba(255, 255, 255, 0.87);
`;

const RecentActivityFileName = styled.a`
    color: rgba(255, 255, 255, 0.87) !important;
    text-decoration: underline !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    padding-top: 7px;
`;
