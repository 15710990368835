import styled from 'styled-components';

export const GlobalStyleDrawerContainer = styled.div`
    position: absolute;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.85);
    z-index: 1000;
    left: 30px;
    top: 98px;
`;

export const StyledDrawerTitle = styled.h3`
    flex: 0 1 auto;
    margin: 12px;
    color: white;
    text-align: left;
    font-weight: 700;
    font-size: 30px;
    max-width: 440px;
    -webkit-line-clamp: 2;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    line-height: 2.45rem;
`;

export const StyledDrawerSubTitle = styled.h6`
    flex: 0 1 auto;
    margin-left: 12px;
    color: white;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
`;

export const StyledDrawerContentContainer = styled.div`
    transition: all 1s;
    -webkit-transition: all 1s;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;
