import React, { useEffect } from 'react';
import styled from 'styled-components';
import Analytics from '../../lib/user-analytics';
import { CustomCookieConsent } from '../Shared/custom-cookie-consent';
import BackgroundGradientAnimation from './Animations/background-gradient-animation';
import Footer from './Footer/footer';
import LandingPageLeftContent from './landing-page-left-content';
import LandingPageRightContent from './landing-page-right-content';

const LandingPage = () => {
    const handleScroll = () => {
        const position = window.pageYOffset;
        const navbar = document.getElementsByClassName('navbar')[0] as HTMLElement;
        if (position <= 50) {
            navbar.style.backgroundColor = 'transparent';
        } else {
            navbar.style.backgroundColor = 'rgba(0,0,0,0.8)';
        }
    };

    useEffect(() => {
        const navbar = document.getElementsByClassName('navbar')[0] as HTMLElement;
        navbar.style.backgroundColor = 'transparent';

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            navbar.style.backgroundColor = 'rgba(0,0,0,0.8)';
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        Analytics.Event('LandingPage', 'Visited the landing page');
    }, []);

    return (
        <React.Fragment>
            <BackgroundGradientAnimation />
            <GridLayout>
                <GridLeft>
                    <LandingPageLeftContent />
                </GridLeft>
                <GridRight>
                    <LandingPageRightContent />
                </GridRight>
            </GridLayout>
            <CustomCookieConsent />
            <Footer />
        </React.Fragment>
    );
};

export default LandingPage;

const GridLayout = styled.div`
    display: flex;
    padding: 8% 2% 4% 2%;
    grid-gap: 20px;
    align-items: center;
    grid-area: content;
    justify-content: center;

    @media only screen and (max-height: 800px) {
        padding: 5% 2% 2% 2%;
    }
`;

const GridLeft = styled.div`
    width: 60%;
`;

const GridRight = styled.div`
    width: 33%;
    text-align: center;
`;
