import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';
import { BasemapKey } from "./model";

interface BasemapStore {
    selectedBasemap: BasemapKey
}

const initialState: BasemapStore = {
    selectedBasemap: 'Google Hybrid'
};

export default (state: BasemapStore = initialState, action: Action): BasemapStore => {
    switch (action.type) {
        case at.SET_BASEMAP:
            return {
                ...state,
                selectedBasemap: action.payload
            }
        default:
            return state;
    }
};