import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Icon, LatLng } from 'leaflet';
import { Marker } from 'react-leaflet';
import { selectDatasetDetails, selectImageManualPosition } from '../../../store/Map/Uploads/selectors';
import { actionSetImageManualPosition, actionSetImageBounds } from '../../../store/Map/Uploads/actions';
import Analytics from '../../../lib/user-analytics';
import { useDispatch, useSelector } from 'react-redux';
import GeoUtil from '../../../lib/geo-util';

interface UploadManualMarkerProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    leafletMap: any; // TODO type
}

const UploadManualMarker = ({ leafletMap }: UploadManualMarkerProps) => {
    const [markerActive, setMarkerActive] = useState<boolean>(false);
    const [position, setPosition] = useState<LatLng | undefined>(undefined);

    const manualPosition = useSelector(selectImageManualPosition);
    const datasetDetails = useSelector(selectDatasetDetails);

    const markerRef = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any

    const dispatch = useDispatch();
    const setDroneImagePosition = useCallback(
        (position: LatLng | undefined) => {
            dispatch(actionSetImageManualPosition(position));
        },
        [dispatch]
    );

    const getBounds = useCallback(
        (manualPosition: LatLng | undefined) => {
            if (manualPosition && datasetDetails) {
                const imageBounds = GeoUtil.latLngBoundingBoxFromWidthHeightCenter(
                    datasetDetails.width,
                    datasetDetails.height,
                    manualPosition
                );
                if (imageBounds) {
                    return GeoUtil.polygonAsLatLngExpression(imageBounds) as LatLng[];
                }
            }
            return undefined;
        },
        [datasetDetails]
    );

    useEffect(() => {
        setPosition(manualPosition);
    }, [manualPosition]);

    const setMarkerPosition = useCallback(
        (e: { latlng: LatLng | undefined; latLng: React.SetStateAction<LatLng | undefined> }) => {
            if (markerActive) {
                setDroneImagePosition(e.latlng);
                setPosition(e.latLng);
                dispatch(actionSetImageBounds(getBounds(e.latLng as LatLng) as LatLng[]));
            }
        },
        [dispatch, getBounds, markerActive, setDroneImagePosition]
    );

    useEffect(() => {
        if (leafletMap?.current && !manualPosition) {
            setMarkerActive(true);
            leafletMap.current.leafletElement.on('click', setMarkerPosition);
        }
        if (manualPosition) {
            leafletMap.current.leafletElement.off('click', setMarkerPosition);
        }
    }, [leafletMap, manualPosition, setMarkerPosition]);

    const onDragMarkerEnd = () => {
        const marker = markerRef.current;
        if (marker) {
            setMarkerActive(false);
            const latLng = marker.leafletElement._latlng;
            setPosition(latLng);
            setDroneImagePosition(latLng);
            Analytics.Event('Upload', 'Marker manually set for upload', latLng);
        }
    };

    useEffect(() => {
        const setUploadImageBounds = (bounds: LatLng[]) => {
            dispatch(actionSetImageBounds(bounds));
        };

        if (manualPosition) {
            setUploadImageBounds(getBounds(manualPosition as LatLng) as LatLng[]);
        }
    }, [dispatch, getBounds, manualPosition]);

    if (!manualPosition || !position) return <React.Fragment />;

    const icon = new Icon({
        iconUrl: '/assets/marker-icon.png',
        iconSize: [25, 41],
        className: 'marker-large',
        iconAnchor: [14, 40],
    });

    return <Marker ref={markerRef} onDragEnd={onDragMarkerEnd} position={position} icon={icon} draggable={true} />;
};

export default UploadManualMarker;
