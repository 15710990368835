import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled, { keyframes } from 'styled-components';
import ApiSocial from '../../../api/api-social';
import { ListingDTO } from '../../../api/model';
import Analytics from '../../../lib/user-analytics';
import { selectLoggedIn } from '../../../store/Account/selectors';
import { actionFetchLikedMaps } from '../../../store/Map/Favourites/actions';
import { selectFavouriteMaps } from '../../../store/Map/Favourites/selectors';
import { LoginModalMode } from '../../Registration/login-enum';
import LoginRegisterDialog from '../../Registration/login-register-dialog';

interface MobileShareLikeProps {
    listing: ListingDTO;
}

const MobileShareLike = (props: MobileShareLikeProps) => {
    const dispatch = useDispatch();
    const likedMaps = useSelector(selectFavouriteMaps);
    const isLoggedIn = useSelector(selectLoggedIn);

    const [likes, setLikes] = useState<number | undefined>(props.listing?.totalLikes);
    const [showLogin, setShowLogin] = useState(false);

    if (!props.listing) {
        return <React.Fragment />;
    }

    const isLikedByUser = likedMaps.find((t) => t.id === props.listing.id) ? true : false;

    const handleClickedLike = async () => {
        if (isLoggedIn) {
            Analytics.Event('Sidedraw - Maps', 'Clicked Mobile Like', props.listing.id);
            try {
                let newLikes: number;
                if (isLikedByUser) {
                    newLikes = (await ApiSocial.unlikeListing(props.listing.id)).totalLikes;
                } else {
                    newLikes = (await ApiSocial.likeListing(props.listing.id)).totalLikes;
                }
                setLikes(newLikes);
                dispatch(actionFetchLikedMaps());
            } catch (err) {
                toast.error(err.message);
            }
        } else {
            setShowLogin(true);
        }
    };

    return (
        <React.Fragment>
            <LoginRegisterDialog
                isOpen={showLogin}
                initialMode={LoginModalMode.LOGIN}
                onClose={() => setShowLogin(!showLogin)}
            />
            <HeartIcon
                onClick={handleClickedLike}
                isLiked={isLikedByUser}
                className={isLikedByUser ? 'fas fa-heart liked' : 'far fa-heart'}
            />
            <HeartValue onClick={handleClickedLike}>{likes ? likes : 0}</HeartValue>
        </React.Fragment>
    );
};

export default MobileShareLike;

interface HeartIconProps {
    isLiked: boolean;
}

const HeartPulseAnimation = keyframes`
    0% {
        transform: scale( 1 );
    } 20% {
        transform: scale( 1.25 );
    } 40% {
        transform: scale( 1 );
    } 60% {
        transform: scale( 1.25 );
    } 80% {
        transform: scale( 1 );
    } 100% {
        transform: scale( 1.25 );
    }
`;

const HeartIcon = styled.i<HeartIconProps>`
    cursor: pointer;
    color: ${(props) => (props.isLiked ? '#FF5A5A' : 'rgba(255, 255, 255, 0.87)')};
    font-size: 16px;

    &.liked {
        animation: ${HeartPulseAnimation} 1s;
        animation-iteration-count: 1;
    }
`;

const HeartValue = styled.p`
    cursor: pointer;
    color: rgba(255, 255, 255, 0.87);
    margin: -5px 0 0 6px;
    font-size: 16px;
`;
