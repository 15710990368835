import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import UserHelper from '../../../lib/user-helper';
import { DashboardMode } from '../../../store/Dashboard/model';
import { selectMyListings, selectMyProfile } from '../../../store/Account/selectors';
import profileIconUrl from '../profile-icon-url';
import MenuLink from './menu-link';
import MenuUserDetails from './menu-user-details';
import { selectPurchasedListings } from '../../../store/Purchases/selectors';
import { SaleInfo } from '../../../api/model';
import ApiUser from '../../../api/api-user';
import NavigationPanel from '../Shared/navigation-panel';
import { MobileSubdomainNavigationList } from '../../Mobile/mobile-subdomain-navigation-list';
import DashboardContainer from '../Shared/dashboard-container';

interface MenuSoarProps {
    dashboardMode: DashboardMode;
}

const MenuSoar = ({ dashboardMode }: MenuSoarProps) => {
    const myProfile = useSelector(selectMyProfile);
    const myListings = useSelector(selectMyListings);
    const myPurchases = useSelector(selectPurchasedListings);

    const [salesInfo, setSalesInfo] = useState<SaleInfo[]>([]);

    useEffect(() => {
        const idToken = UserHelper.validateAndGetIdToken();
        if (idToken) {
            ApiUser.getMySales()
                .then((salesInfo) => {
                    setSalesInfo(salesInfo);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);

    const logout = () => {
        Analytics.Event('My Dashboard', 'Clicked to sign out');
        UserHelper.resetIdToken();
        window.location.href = `${window.location.protocol}//${window.location.host}`;
    };

    const handleModeSelected = (mode: DashboardMode) => {
        Analytics.Event('My Dashboard', 'Clicked Dashboard Menu', mode);
        UriHelper.navigateToDashboard(mode);
    };

    return (
        <ProfileSideBarContainer>
            {isMobile && <NavigationPanel label={'Dashboard'} />}
            <DashboardContainer>
                <FloatingSideBarContainer>
                    <MenuContainer>
                        <MenuUserDetails myProfile={myProfile} myListings={myListings} />
                        <ProfileSideBarLinkList>
                            <MenuLink
                                handleMenuItemClick={() => handleModeSelected(DashboardMode.ACCOUNT_SETTINGS)}
                                iconLight={profileIconUrl.accountSettingsLightUrl}
                                iconDark={profileIconUrl.accountSettingsDarkUrl}
                                selected={dashboardMode === DashboardMode.ACCOUNT_SETTINGS}
                                label="Account Settings"
                            />
                            <MenuLink
                                handleMenuItemClick={() => handleModeSelected(DashboardMode.PROFILE)}
                                iconLight={profileIconUrl.profileLightUrl}
                                iconDark={profileIconUrl.profileDarkUrl}
                                selected={dashboardMode === DashboardMode.PROFILE}
                                label="Edit My Profile"
                            />
                            <MenuLink
                                handleMenuItemClick={() => handleModeSelected(DashboardMode.MY_IMAGERY)}
                                iconLight={profileIconUrl.imageryLightUrl}
                                iconDark={profileIconUrl.imageryDarkUrl}
                                selected={dashboardMode === DashboardMode.MY_IMAGERY}
                                label="My Maps"
                            />

                            <MenuLink
                                handleMenuItemClick={() => handleModeSelected(DashboardMode.MY_BOOKMARKS)}
                                iconLight={profileIconUrl.bookmarkLightUrl}
                                iconDark={profileIconUrl.bookmarkDarkUrl}
                                selected={dashboardMode === DashboardMode.MY_BOOKMARKS}
                                label="My Bookmarks"
                            />

                            {isMobile && myProfile?.domains && myProfile.domains?.length > 0 ? (
                                <MobileSubdomainNavigationList domains={myProfile.domains} />
                            ) : null}

                            <MenuLink
                                handleMenuItemClick={() => handleModeSelected(DashboardMode.SATELLITE_QUEUE)}
                                iconLight={profileIconUrl.satelliteQueueLightUrl}
                                iconDark={profileIconUrl.satelliteQueueDarkUrl}
                                selected={dashboardMode === DashboardMode.SATELLITE_QUEUE}
                                label="My Satellite Queue"
                            />

                            {myPurchases.length > 0 ? (
                                <MenuLink
                                    handleMenuItemClick={() => handleModeSelected(DashboardMode.PURCHASE_HISTORY)}
                                    iconLight={profileIconUrl.purchaseHistoryLightUrl}
                                    iconDark={profileIconUrl.purchaseHistoryDarkUrl}
                                    selected={dashboardMode === DashboardMode.PURCHASE_HISTORY}
                                    label="Purchase History"
                                />
                            ) : null}
                            {salesInfo.length > 0 ? (
                                <MenuLink
                                    handleMenuItemClick={() => handleModeSelected(DashboardMode.SALES_HISTORY)}
                                    iconLight={profileIconUrl.salesHistoryLightUrl}
                                    iconDark={profileIconUrl.salesHistoryDarkUrl}
                                    selected={dashboardMode === DashboardMode.SALES_HISTORY}
                                    label="Sales History"
                                />
                            ) : null}
                            <MenuLink
                                handleMenuItemClick={logout}
                                iconLight={profileIconUrl.logoutLightUrl}
                                iconDark={profileIconUrl.logoutDarkUrl}
                                selected={false}
                                label="Sign Out"
                            />
                        </ProfileSideBarLinkList>
                    </MenuContainer>

                    <ProfileSidebarTermsLink onClick={() => handleModeSelected(DashboardMode.TERMS_AND_CONDITION)}>
                        Terms & Conditions
                    </ProfileSidebarTermsLink>
                </FloatingSideBarContainer>
                <SideBarSpacer />
            </DashboardContainer>
        </ProfileSideBarContainer>
    );
};

export default MenuSoar;

const ProfileSideBarContainer = styled.div`
    color: white;
    width: 330px;
    margin-right: 15px;

    @media only screen and (max-width: 600px) {
        background-color: transparent !important;
        width: 100vw;
        margin-right: 0px;
    }
`;

const FloatingSideBarContainer = styled.div`
    position: fixed;

    @media only screen and (max-width: 600px) {
        position: relative;
    }
`;

const SideBarSpacer = styled.div`
    width: 330px;
    height: 20px;
`;

const MenuContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 6px;
`;

const ProfileSidebarTermsLink = styled.div`
    text-decoration-line: underline;
    background-color: transparent;
    padding-top: 10px;
    width: 330px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;

    @media only screen and (max-width: 600px) {
        text-align: center;
        margin: 3px auto;
        display: block;
    }
`;

const ProfileSideBarLinkList = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 0px 0px 6px 6px;
    background-color: rgba(0, 0, 0, 0.85);

    @media only screen and (max-width: 600px) {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 0px;
        overflow-y: auto;
    }
`;
