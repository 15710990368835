import styled from 'styled-components';

const UploadHelp = () => {
    return (
        <UploadHelperContainer>
            Adding control points to the image will help fine tune the positioning during the conversion process, a
            minimum of 6 and a maximum of 10 points are required.
            <Divider />
            <UploadHelperText>
                <UploadControlPointIcon color={'rgba(255, 0, 0, 0.87)'} />
                Edit - The image to be changed to be updated.
            </UploadHelperText>
            <UploadHelperText>
                <UploadControlPointIcon color={'rgba(0, 128, 0, 0.87)'} />
                Control - The map image that will have the correct image location.
            </UploadHelperText>
            <Divider />
            <UploadHelperText>
                To get started click on the image at a significant point that you can easily identify on the map, this
                could be a corner of a building or a road junction.
            </UploadHelperText>
            <UploadHelperText>
                Spread control points evenly across the image, this will give better results.
            </UploadHelperText>
            <UploadHelperText>
                If a marker is incorrectly placed on the control map you can clikc and drag it to the correct location
                or remove the relevant control point from the control point list .
            </UploadHelperText>
            <UploadHelperText>When you are happy with the placement generate your image</UploadHelperText>
        </UploadHelperContainer>
    );
};

export default UploadHelp;

const UploadHelperContainer = styled.div`
    padding: 5px 10px;
    background-color: #343332;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 6px;
    width: 500px;
    color: rgba(255, 255, 255, 0.87);

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #454545;
        border-radius: 4px;
    }
`;

const UploadHelperText = styled.div`
    color: inherit;
    display: flex;
    flex-direction: row;
    font-size: 14px;
`;

const UploadControlPointIcon = styled.div<{ color: string }>`
    width: 16px;
    height: 16px;
    background-color: ${(props) => props.color};
    border-radius: 260px;
    margin: 4px 5px;
`;

const Divider = styled.div`
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%;
    margin: 10px 0px;
`;
