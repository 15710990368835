import React from 'react';
import styled from 'styled-components';
import ApiAnalytics from '../../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote, ListingDTO, UserDTO } from '../../../api/model';
import ListingHelper from '../../../lib/listing-helper';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { BottomSheetState } from '../MapSelection/bottom-sheet';

interface MobileProfileFeaturedMapsProps {
    userListings: ListingDTO[];
    userProfile: UserDTO;
    listingLimit: number;
    handleSetListingLimit: (listingLimit: number) => void;
    setOpenState: (o: BottomSheetState) => void;
}

const MobileProfileFeaturedMaps = (props: MobileProfileFeaturedMapsProps) => {
    return (
        <React.Fragment>
            {props.userListings.length > 0 && <Title>Recent Maps</Title>}
            <FeaturedMaps>
                {props.userListings.slice(0, props.listingLimit).map((l, key) => {
                    return (
                        <MapsGrid key={key}>
                            {key === props.listingLimit - 1 && props.listingLimit < props.userListings.length ? (
                                <React.Fragment>
                                    <MapImage src={ListingHelper.getPreviewUrlForListing(l.id)} title={l.title || ''} />
                                    <MapGridOverlayButton
                                        onClick={() => {
                                            props.handleSetListingLimit(props.userListings.length);
                                            props.setOpenState(BottomSheetState.open);
                                            Analytics.Event(
                                                'profile',
                                                'Clicked to see all maps',
                                                props.userProfile.userId
                                            );
                                        }}
                                    >
                                        VIEW MORE
                                    </MapGridOverlayButton>
                                </React.Fragment>
                            ) : (
                                <MapImage
                                    src={ListingHelper.getPreviewUrlForListing(l.id)}
                                    title={l.title || ''}
                                    onClick={() => {
                                        UriHelper.navigateToPath(`/maps/${l.id}`);
                                        props.setOpenState(BottomSheetState.close);
                                        Analytics.Event('Profile sidedraw', 'Clicked to view', l.id);
                                        ApiAnalytics.postAnalyticsListing(
                                            AnalyticsAction.VIEW,
                                            AnalyticsNote.USER_PROFILE,
                                            l.id
                                        );
                                    }}
                                />
                            )}
                        </MapsGrid>
                    );
                })}
            </FeaturedMaps>
        </React.Fragment>
    );
};

export default MobileProfileFeaturedMaps;

const Title = styled.div`
    font-size: 16px;
    color: white;
    margin: 3px;
`;

const FeaturedMaps = styled.div``;

const MapsGrid = styled.div`
    display: inline-grid;
    pointer-events: all;
    position: relative;
`;

const MapGridOverlayButton = styled.button`
    position: absolute;
    width: calc((100vw - 20px) / 4);
    height: calc((100vw - 20px) / 4);
    background-color: rgb(0 0 0 / 50%);
    border: none;
    color: #eed926;
    font-size: 16px;
    pointer-events: all;
    border-radius: 6px;
    padding: 3px;
`;

const MapImage = styled.img`
    padding: 3px;
    object-fit: cover;
    border-radius: 6px;
    cursor: pointer;
    width: calc((100vw - 20px) / 4);
    height: calc((100vw - 20px) / 4);
`;
