import { ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { ListingDTO } from '../../../../api/model';
import { PulseLoader } from '../../../Shared/pulse-loader';
import MobileSearchCard from '../../Shared/mobile-masonry-card';

interface SearchResultsMasonaryProps {
    maybeLoadMore: () => void;
    items: ListingDTO[];
    isNoItems: boolean;
    children?: ReactNode;
}

const MobileSearchResultsMasonary = (props: SearchResultsMasonaryProps) => {
    const selectableListings: ListingDTO[] = props.items.filter((value, index, array) => {
        return array.indexOf(value) === index;
    });

    return (
        <MobileInfiniteScroll
            dataLength={selectableListings.length}
            next={props.maybeLoadMore}
            height={'calc(100vh - 95px)'}
            hasMore={!props.isNoItems}
            loader={<PulseLoader />}
            endMessage={
                <AllLoadedNotification>{`We have found ${selectableListings.length} results`}</AllLoadedNotification>
            }
        >
            {props.children}
            {selectableListings.map((listing, index) => (
                <MobileSearchCard key={index} listing={listing} />
            ))}
        </MobileInfiniteScroll>
    );
};

export default MobileSearchResultsMasonary;

const MobileInfiniteScroll = styled(InfiniteScroll)`
    overflow-x: hidden !important;

    @media only screen and (max-width: 600px) {
        // TODO - should be calculated from the visible scroll area on screen.
        // But fixes multiple issues with the scrolling the the bottom and loading in more items
        padding-bottom: 170px;
    }
`;

const AllLoadedNotification = styled.div`
    text-align: center;
    color: #eed926;
    margin: 10px 0px;
    font-size: 18px;
`;
