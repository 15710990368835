import { useState, useEffect, RefObject, useCallback } from 'react';
import { Map as LeafletMap } from 'react-leaflet';
import { LatLng, Layer } from 'leaflet';
import { selectDatasetDetails, selectMouseActive } from '../../../store/Map/Uploads/selectors';
import { useSelector } from 'react-redux';
import GeoRectifyUtil from '../Util/geo-rectify-util';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const L = require('leaflet');

interface GCPHandlerProps {
    active: boolean;
    imageMapRef: RefObject<LeafletMap>;
    targetMapRef: RefObject<LeafletMap>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setGcpArray: (gcpArray: any[]) => void; // TODO type safe it
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gcpArray: any; // TODO type safe it
    distortableBounds?: LatLng[];
    handleClickedImage: (position: LatLng) => void;
    handleClickedMap: (position: LatLng) => void;
    handleIsMarkerActive: (isMarkerActive: boolean) => void;
}

enum ListenerType {
    Image,
    Target,
}

const GCPHandler = (props: GCPHandlerProps) => {
    const mouseActive = useSelector(selectMouseActive);
    // TODO unstuff this
    const { active, setGcpArray, gcpArray, handleClickedImage, handleClickedMap, handleIsMarkerActive } = props;

    const [lastImageClick, setLastImageClick] = useState<LatLng>();
    const [lastTargetClick, setLastTargetClick] = useState<LatLng>();
    const [leafletImageClick, setLeafletImageClick] = useState<Layer>();

    const datasetDetails = useSelector(selectDatasetDetails);

    const initListener = useCallback(
        (type: ListenerType) => {
            if (checkMapsLoaded()) {
                const imageMap = props.imageMapRef.current;
                const targetMap = props.targetMapRef.current;
                if (imageMap && targetMap) {
                    const image = imageMap.leafletElement;
                    const target = targetMap.leafletElement;
                    image.off('click');
                    target.off('click');
                    if (active) {
                        if (type === ListenerType.Image) {
                            image.on('click', gcpImageListener);
                        } else if (type === ListenerType.Target) {
                            target.on('click', gcpTargetListener);
                        }
                    }
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [active, props.imageMapRef, props.targetMapRef]
    );

    useEffect(() => {
        initListener(ListenerType.Image);
    }, [props.imageMapRef, props.targetMapRef, active, initListener]);

    useEffect(() => {
        const imageMap = getLeafletMapFromRef(props.imageMapRef);
        if (imageMap && leafletImageClick) {
            imageMap.removeLayer(leafletImageClick);
        }
        if (lastTargetClick && lastImageClick) {
            if (datasetDetails && props.distortableBounds) {
                const pixels = GeoRectifyUtil.distortablePixelForLatLng(
                    lastImageClick,
                    props.distortableBounds,
                    datasetDetails?.width,
                    datasetDetails?.height,
                    props.targetMapRef // TODO image or target
                );
                const tempGcpArray = gcpArray;
                const newGCP = [pixels.x?.toFixed(2), pixels.y?.toFixed(2), lastImageClick, lastTargetClick];
                tempGcpArray.push(newGCP);
                setGcpArray(tempGcpArray);

                setLastTargetClick(undefined);
                setLastImageClick(undefined);
                initListener(ListenerType.Image);
                handleIsMarkerActive(false);
            }
        } else if (lastImageClick) {
            if (imageMap && !mouseActive) {
                initListener(ListenerType.Target);
                const marker = new L.circleMarker(lastImageClick, { color: 'purple' });
                marker.addTo(imageMap);
                setLeafletImageClick(marker);
                handleIsMarkerActive(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastTargetClick, lastImageClick]);

    const gcpImageListener = (e) => {
        if (!mouseActive) {
            handleClickedImage(e.latlng);
            setLastImageClick(e.latlng);
        }
    };

    const gcpTargetListener = (e) => {
        if (!mouseActive) {
            handleClickedMap(e.latlng);
            setLastTargetClick(e.latlng);
        }
    };

    const checkMapsLoaded = (): boolean => {
        return !!(props.imageMapRef?.current?.leafletElement && props.targetMapRef?.current?.leafletElement);
    };

    const getLeafletMapFromRef = (ref: RefObject<LeafletMap>) => {
        if (checkMapsLoaded()) {
            const mapRef = ref.current;
            if (mapRef) {
                return mapRef.leafletElement;
            }
        }
        return null;
    };

    return null;
};

export default GCPHandler;
