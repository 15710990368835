import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Constants from '../../../../constants';
import Analytics from '../../../../lib/user-analytics';
import { StyledButton } from '../../../Shared/styled-button';

// TODO: Proper typings for `configuration`.  Shouldn't be passing this around as a JSON string literal.
// TODO: Proper typings for `style`. A width and height should be enough
interface SoarEmbeddedCodeProps {
    tileLayerId: number;
    configuration: string;
    containerWidthCSS: string;
    containerHeightCSS: string;
}

const encodeConfigurationKey = (config) => {
    return btoa(encodeURIComponent(config));
};

const EmbeddedMapCode = (props: SoarEmbeddedCodeProps) => {
    const codeStringGenerator = (
        containerWidthCSS: string,
        containerHeightCSS: string,
        configurationString: string
    ): string => {
        const date = new Date().getTime();
        const containerId = `id='${date}'`;
        const configurationKey = `soar-map-configuration-${date}`;
        return `<!-- Start of Soar.Earth embedded map code -->
<link href='${Constants.EMBEDDED_MAP.HOST}/${Constants.EMBEDDED_MAP.TARGET_CSS}' rel='stylesheet'/>
<script defer='async' src='${Constants.EMBEDDED_MAP.HOST}/${Constants.EMBEDDED_MAP.TARGET_JS}'></script>

<div style='width:${containerWidthCSS}; height:${containerHeightCSS};'>
    <div class='soar-map-container' ${containerId} ${configurationKey}='${encodeConfigurationKey(
            configurationString
        )}'></div>
</div>
<!-- End of Soar.Earth embedded map code -->`;
    };

    const handleCopyEmbedded = (e) => {
        e.preventDefault();
        if (navigator.clipboard != undefined) {
            navigator.clipboard
                .writeText(codeStringGenerator(props.containerWidthCSS, props.containerHeightCSS, props.configuration))
                .then(() => {
                    Analytics.Event(
                        'Embedded Map',
                        `Clicked to copy embed code for ${props.tileLayerId}`,
                        props.configuration
                    );
                    alert('Copied code snippet to clipboard');
                })
                .catch(() => {
                    toast.error('Error copying to clipboard.');
                });
        } else {
            toast.error('Error copying to clipboard.');
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Instructions>Copy this code to embed the map in your website</Instructions>
                <CopyButton onClick={(e) => handleCopyEmbedded(e)} title="Copy Code">
                    Copy Code
                </CopyButton>
            </Row>

            <CodeSnippet
                onClick={(e) => {
                    // Select all the text when the textarea is clicked.
                    // I found this to be a more sensible user experience than trying to drag select or Ctrl+A
                    e.currentTarget.select();
                }}
                spellCheck={false}
                wrap="off"
                readOnly
                value={codeStringGenerator(props.containerWidthCSS, props.containerHeightCSS, props.configuration)}
            />
        </React.Fragment>
    );
};

export default EmbeddedMapCode;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 5px 0 0 6px;
`;

const Instructions = styled.p`
    color: white;
    margin-top: 6px;
`;

const CopyButton = styled(StyledButton)`
    cursor: pointer;
    margin-left: auto;
    margin-bottom: 4px;
`;

const CodeSnippet = styled.textarea`
    background-color: rgb(33, 33, 33);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    color: #d3d3d3;
    font-family: monospace;
    font-size: 12px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    height: 270px;
    width: 100%;
    overflow-x: scroll;
    padding: 2px;
    display: block;
`;
