import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { NotificationDTO } from '../../../api/model';
import { actionShowLoginDialog } from '../../../store/App/actions';
import { actionGetNotifications, actionSetViewedNotifications } from '../../../store/Notifications/actions';
import { selectNotificationsAllLoaded, selectNotificationsLoading } from '../../../store/Notifications/selectors';
import { selectLoggedIn } from '../../../store/Account/selectors';
import { PulseLoader } from '../../Shared/pulse-loader';
import { NotificationCard } from './notification-card';
import { NotificationRequestLogin } from './notification-request-login';
import { NotificationsRead } from './notifications-read';

interface NotificationListProps {
    showNotifications: boolean;
    notificationList: NotificationDTO[];
}

export const NotificationsList = (props: NotificationListProps): JSX.Element => {
    const { showNotifications, notificationList } = props;
    const loading = useSelector(selectNotificationsLoading);
    const allNotificationsLoaded: boolean = useSelector(selectNotificationsAllLoaded);
    const isLoggedIn = useSelector(selectLoggedIn);

    const dispatch = useDispatch();
    const [selected, setSelected] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(actionGetNotifications());
        }
    }, [dispatch, isLoggedIn]);

    useEffect(() => {
        const notViewedNotifications = notificationList.filter((notification) => !notification.viewedAt);
        const notificationIds = notViewedNotifications.map((remove) => remove.id);
        if (notificationIds.length) {
            dispatch(actionSetViewedNotifications(notificationIds));
        }
    }, [dispatch, notificationList]);

    const loadMore = () => {
        if (!allNotificationsLoaded && notificationList.length > 0) {
            dispatch(actionGetNotifications(notificationList.length));
        }
    };

    const handleScroll = (e) => {
        const bottom = Math.ceil(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
        if (bottom && !loading) {
            loadMore();
        }
    };

    return (
        <React.Fragment>
            <NotificationListContainer isVisible={showNotifications} isLoggedIn={isLoggedIn}>
                <NotificationContent
                    data-cy="notification-content"
                    isVisible={showNotifications}
                    onScroll={(e) => handleScroll(e)}
                >
                    {!isLoggedIn ? (
                        <NotificationRequestLogin setShowLogin={() => dispatch(actionShowLoginDialog(true))} />
                    ) : (
                        <React.Fragment>
                            {!loading && notificationList.length === 0 ? <NotificationsRead /> : null}
                            {notificationList.map((notification) => (
                                <NotificationCard
                                    key={notification.id}
                                    notification={notification}
                                    setSelected={() => {
                                        setSelected(notification.id);
                                    }}
                                    isSelected={selected === notification.id}
                                />
                            ))}

                            <NotificationsShowMore
                                onClick={(e) => {
                                    e.preventDefault();
                                    loadMore();
                                }}
                                allLoaded={allNotificationsLoaded}
                            >
                                {loading ? (
                                    <PulseLoader />
                                ) : allNotificationsLoaded ? (
                                    'All notifications displayed'
                                ) : (
                                    'View More'
                                )}
                            </NotificationsShowMore>
                        </React.Fragment>
                    )}
                </NotificationContent>
            </NotificationListContainer>
        </React.Fragment>
    );
};

interface NotificationListStyledProps {
    isVisible?: boolean;
    isLoggedIn?: boolean;
    allLoaded?: boolean;
}

const OpenNotifications = keyframes`
    0% {
        overflow: hidden;
        max-height: 0px;
    }
    99% {
        overflow: hidden;
    }
    100% {
        overflow: auto;
        max-height: 50vh;
    }
`;

const NotificationListContainer = styled.div<NotificationListStyledProps>`
    background-color: rgba(0, 0, 0, 0.85);
    width: 375px;
    opacity: ${(props) => (props.isVisible ? '1' : '0')};
    position: absolute;
    top: 98px;
    right: 75px;
    border-radius: 6px;
`;

const NotificationContent = styled.div<NotificationListStyledProps>`
    max-height: 50vh;
    min-height: ${(props) => (props.isLoggedIn ? '127px' : '0px')};
    overflow: auto;
    animation: ${(props) => (props.isVisible ? OpenNotifications : 'none')} 300ms linear;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const NotificationsShowMore = styled.div<NotificationListStyledProps>`
    color: #eed926;
    text-align: center;
    height: 33px;
    margin: 5px 0px;
    text-decoration: ${(props) => (props.allLoaded ? 'none' : 'underline')};
    cursor: ${(props) => (props.allLoaded ? '' : 'pointer')};
`;
