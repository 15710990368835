import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import SoarHelper from '../../lib/soar-helper';
import UserHelper from '../../lib/user-helper';
import { actionGetMyListings } from '../../store/Account/actions';
import { selectMyProfile } from '../../store/Account/selectors';
import { actionGetSubdomainListings } from '../../store/Dashboard/actions';
import { DashboardMode } from '../../store/Dashboard/model';
import { LoginModalMode } from '../Registration/login-enum';
import LoginRegisterDialog from '../Registration/login-register-dialog';
import { PulseLoader } from '../Shared/pulse-loader';
import AccountManagement from './Account/account-management';
import AccountSettings from './AccountSettings/account-settings';
import SubdomainOverview from './Dashboard/subdomain-overview';
import DataManagement from './Imagery/data-management';
import MyImagery from './Imagery/my-imagery';
import MenuSoar from './Menu/menu-soar';
import SubdomainNavigation from './Menu/menu-subdomain';
import MyBookmarks from './MyBookmarks/my-bookmarks';
import Payout from './Payout/payout';
import ProfileEdit from './Profile/profile-edit';
import SatelliteOrders from './SatelliteOrders/satellite-orders';
import PurchaseHistory from './TransactionHistory/purchase-history';
import SalesHistory from './TransactionHistory/sales-history';
import UserManagement from './Users/manage-users';
import DashboardTermsAndCondition from './dashboard-terms-and-condition';
import { actionUpdatePurchasedListings } from '../../store/Purchases/actions';

interface MyProfileProps {
    dashboardMode: DashboardMode;
}

const Dashboard: React.FC<MyProfileProps> = ({ dashboardMode }: MyProfileProps) => {
    const dispatch = useDispatch();
    const myProfile = useSelector(selectMyProfile);

    const isSoar = SoarHelper.isSoar();

    useEffect(() => {
        if (isSoar) {
            dispatch(actionGetMyListings());
            dispatch(actionUpdatePurchasedListings());
        } else {
            dispatch(actionGetSubdomainListings());
        }
    }, [dispatch, isSoar]);

    const renderContent = () => {
        if (!myProfile) return <PulseLoader />;

        switch (dashboardMode) {
            case DashboardMode.ACCOUNT_SETTINGS:
                return <AccountSettings />;
            case DashboardMode.SUBDOMAIN_OVERVIEW:
                return <SubdomainOverview />;
            case DashboardMode.USER_MANAGEMENT:
                return <UserManagement />;
            case DashboardMode.ACCOUNT_MANAGEMENT:
                return <AccountManagement />;
            case DashboardMode.PROFILE:
                return <ProfileEdit myProfile={myProfile} />;
            case DashboardMode.MY_IMAGERY:
                return <MyImagery />;
            case DashboardMode.MY_BOOKMARKS:
                return <MyBookmarks />;
            case DashboardMode.DATA_MANAGEMENT:
                return <DataManagement />;
            case DashboardMode.PAYOUT:
                return <Payout />;
            case DashboardMode.PURCHASE_HISTORY:
                return <PurchaseHistory />;
            case DashboardMode.SALES_HISTORY:
                return <SalesHistory />;
            case DashboardMode.SATELLITE_QUEUE:
                return <SatelliteOrders />;
            case DashboardMode.TERMS_AND_CONDITION:
                return <DashboardTermsAndCondition />;

            default:
                return <React.Fragment />;
        }
    };

    if (!UserHelper.validateAndGetIdToken()) {
        return <LoginRegisterDialog isOpen={true} initialMode={LoginModalMode.LOGIN} />;
    }

    const showMenu = !isMobile || dashboardMode === DashboardMode.DASHBOARD;

    return (
        <Container>
            {showMenu &&
                (isSoar ? (
                    <MenuSoar dashboardMode={dashboardMode} />
                ) : (
                    <SubdomainNavigation dashboardMode={dashboardMode} />
                ))}

            {dashboardMode !== DashboardMode.DASHBOARD && (
                <DashboardPageContainer>{renderContent()}</DashboardPageContainer>
            )}
        </Container>
    );
};

export default Dashboard;

const Container = styled.div`
    background-color: #191a1a;
    color: white;
    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    padding-top: 100px;
    display: flex;

    @media only screen and (max-width: 600px) {
        padding: 0px;
        position: fixed;
    }
`;

const DashboardPageContainer = styled.div`
    width: 100%;
    color: white !important;
    padding-right: 25px;

    @media only screen and (max-width: 600px) {
        touch-action: auto;
        overflow-x: hidden;
        flex-direction: column;
        padding: 0px;
        width: 100vw;
    }
`;
