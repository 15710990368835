import React, { RefObject, useEffect, useState } from 'react';
import { Map as LeafletMap } from 'react-leaflet';
import { LatLng } from 'leaflet';
import styled from 'styled-components';
import { GCPCard } from './gcp-card';
import GeoRectifyUtil from '../Util/geo-rectify-util';
import { StyledButton } from '../../Shared/styled-button';

interface GCPTableProps {
    corners: LatLng[] | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gcpsArray: any;
    imageWidth: number;
    imageHeight: number;
    setSelectedGCP: (gcp: LatLng[] | undefined) => void;
    selectedGCP: LatLng[] | undefined;
    deleteGCP: (index: number) => void;
    isProcessing: boolean;
    mapRef: RefObject<LeafletMap>;
    updateGCPTable: boolean;
    setUpdateGCPTable: () => void;
    resetGCPs: () => void;
}

const GCPTable = (props: GCPTableProps): JSX.Element => {
    const [gcpTable, setGcpTable] = useState<LatLng[][]>([]);
    const isSelectedGCP = (gcp: LatLng[]): boolean => {
        return !!(
            props.selectedGCP &&
            props.selectedGCP.length === 2 &&
            gcp.length === 2 &&
            gcp[2].equals(props.selectedGCP[0]) &&
            gcp[3].equals(props.selectedGCP[1])
        );
    };

    useEffect(() => {
        if (props.updateGCPTable || props.gcpsArray) {
            setGcpTable(props.gcpsArray);
            props.setUpdateGCPTable();
        }
    }, [props, props.gcpsArray, props.updateGCPTable]);

    return (
        <GCPTableContainer
            onMouseOut={(e) => {
                if (!e.target.toString().includes('HTMLButtonElement')) {
                    props.setSelectedGCP(undefined);
                }
            }}
        >
            <GCPTableHeader>
                <GCPPointHeader>Control Points</GCPPointHeader>
                <GCPTableHeaderOptions>
                    {props.gcpsArray.length ? (
                        <GCPTableButton onClick={props.resetGCPs}>Reset all points</GCPTableButton>
                    ) : null}
                </GCPTableHeaderOptions>
            </GCPTableHeader>
            {!gcpTable.length && (
                <NoGCP>
                    <NoGCPText>New points will be created automatically as you add more points to the map</NoGCPText>
                </NoGCP>
            )}
            {gcpTable?.map((gcp, index: number) => {
                const pixel = GeoRectifyUtil.pixelForLatLng(
                    gcp[2],
                    props.corners ? props.corners : [],
                    props.imageWidth,
                    props.imageHeight,
                    props.mapRef
                );
                const active = isSelectedGCP(gcp);
                return (
                    <GCPCard
                        key={index}
                        id={index}
                        gcp={gcp}
                        active={active}
                        pixel={pixel}
                        setSelectedGCP={props.setSelectedGCP}
                        deleteGCP={() => props.deleteGCP(index)}
                    />
                );
            })}
        </GCPTableContainer>
    );
};

export default GCPTable;

const GCPTableContainer = styled.div`
    background-color: #343332;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 6px;
    width: 500px;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #454545;
        border-radius: 4px;
    }
`;

const NoGCP = styled.div`
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
`;

const NoGCPText = styled.p`
    width: 50%;
    color: rgba(255, 255, 255, 0.3);
`;

const GCPPointHeader = styled.div`
    color: white;
    font-size: 16px;
    margin: 0.25rem 0.5rem;
`;

const GCPTableHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const GCPTableHeaderOptions = styled.div`
    display: flex;
    flex-direction: row;
`;

const GCPTableButton = styled(StyledButton)`
    padding: 0.1rem 0.8rem;
    margin: 0.4rem 0.5rem 0 0;
    font-size: 0.9rem;
    text-transform: none;
`;
