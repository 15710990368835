import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Analytics from '../../../lib/user-analytics';
import { actionGetDomainUsers } from '../../../store/Dashboard/actions';
import { selectAdminUsersData } from '../../../store/Dashboard/selectors';
import { selectMyProfile } from '../../../store/Account/selectors';
import NavigationPanel from '../Shared/navigation-panel';
import InviteUserModal from './invite-user-modal';
import ListUsers from './list-users';
import DashboardContainer from '../Shared/dashboard-container';

const ManageUsers = () => {
    const dispatch = useDispatch();
    const myProfile = useSelector(selectMyProfile);
    const userData = useSelector(selectAdminUsersData);
    const fetchDomainUsers = () => dispatch(actionGetDomainUsers());

    const [inviteUserOpen, setInviteUserOpen] = useState<boolean>(false);

    return (
        <React.Fragment>
            <NavigationPanel label="User Management" />

            <InviteButtonContainer>
                <InviteUserButton
                    onClick={() => {
                        setInviteUserOpen(true);
                        Analytics.Event('My Dashboard', 'Clicked invite user');
                    }}
                >
                    INVITE USER
                </InviteUserButton>
            </InviteButtonContainer>

            <DashboardContainer marginTop={50}>
                <ListUsers userData={userData} myProfile={myProfile} refreshUsers={fetchDomainUsers} />
            </DashboardContainer>
            {inviteUserOpen && (
                <InviteUserModal
                    userData={userData}
                    onClose={() => setInviteUserOpen(false)}
                    refreshUsers={fetchDomainUsers}
                />
            )}
        </React.Fragment>
    );
};

export default ManageUsers;

const InviteButtonContainer = styled.div`
    text-align: right;
    margin: 10px 0px;

    @media only screen and (max-width: 600px) {
        text-align: center;
    }
`;

const InviteUserButton = styled.button`
    margin: 5px;
    padding: 5px 10px;
    font-size: 15px;
    background-color: #eed926 !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: black;
    border-radius: 6px;

    :hover {
        color: black;
        opacity: 0.6;
    }

    :focus {
        outline: none;
    }
`;
