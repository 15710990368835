import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import styled from 'styled-components';
import UriHelper from '../../../lib/uri-helper';
import UserHelper from '../../../lib/user-helper';
import {
    actionSentinelChangeOpacity,
    actionSentinelClearFeatures,
    actionSentinelResetSelectedAOI,
    actionSentinelResetSelectedFeature,
} from '../../../store/Map/Sentinel/actions';
import { SentinelFeature } from '../../../store/Map/Sentinel/model';
import {
    selectSentinelFeatures,
    selectSentinelSelectedFeature,
    selectSentinelError,
    selectSentinelOpacity,
} from '../../../store/Map/Sentinel/selectors';
import { actionSetSideDrawerModeAction } from '../../../store/SideDrawer/actions';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import SatelliteListStyleCard from '../BottomSheet/ListingCard/satellite-list-style-card';
import { BottomSheetState } from '../MapSelection/bottom-sheet';
import MobileShareLinks from '../Sharing/mobile-share-links';

const moment = require('moment');

interface MobileSatelliteSearchResultProps {
    bottomSheetState: BottomSheetState;
    onCloseMap: () => void;
    isClosing: boolean;
}

const MobileSatelliteSearchResult = (props: MobileSatelliteSearchResultProps) => {
    const { bottomSheetState, onCloseMap, isClosing } = props;
    const [selectedFeature, setSelectedFeature] = useState<SentinelFeature | undefined>(undefined);

    const sentinelFeatures = useSelector(selectSentinelFeatures);
    const selectedSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const sentinelError = useSelector(selectSentinelError);
    const sentinelOpacity = useSelector(selectSentinelOpacity);

    const dispatch = useDispatch();

    useEffect(() => {
        onCloseMap();
        // Disables dependency as draw updates when moved firing onCloseMap
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleCloseActiveSentinelResult = () => {
            setSelectedFeature(undefined);
            dispatch(actionSentinelResetSelectedFeature());
            dispatch(actionSentinelClearFeatures());
            dispatch(actionSentinelResetSelectedAOI());
            dispatch(actionSentinelChangeOpacity(undefined));
            UriHelper.navigateToDrawer(SideDrawerMode.NONE);
            dispatch(actionSetSideDrawerModeAction(SideDrawerMode.NONE));
            onCloseMap();
        };

        if (isClosing) {
            handleCloseActiveSentinelResult();
        }
    }, [dispatch, isClosing, onCloseMap]);

    useEffect(() => {
        if (selectedSentinelFeature) {
            setSelectedFeature(selectedSentinelFeature);
        }
    }, [selectedSentinelFeature]);

    if (!selectedFeature) {
        return <React.Fragment />;
    }

    if (sentinelError) {
        return (
            <React.Fragment>
                <TopContainer bottomSheetClosed={bottomSheetState === BottomSheetState.close}>
                    <MapDetails>
                        <ErrorTitle singleLine={bottomSheetState === BottomSheetState.close}>
                            Service unavailable
                        </ErrorTitle>
                        <Author singleLine={bottomSheetState === BottomSheetState.close}>
                            <ErrorSubText>please try again later</ErrorSubText>
                        </Author>
                    </MapDetails>
                </TopContainer>
                <Divider />
                <ErrorSubText>{sentinelError ? sentinelError.message : 'An error occurred'}</ErrorSubText>
            </React.Fragment>
        );
    }

    const changeOpacity = (opacity: number) =>
        dispatch(actionSentinelChangeOpacity({ id: selectedFeature.id, opacity }));
    const opacity = sentinelOpacity[selectedFeature.id] || 1.0;

    return (
        <React.Fragment>
            <TopContainer bottomSheetClosed={bottomSheetState === BottomSheetState.close}>
                <MapDetails>
                    <Title singleLine={bottomSheetState === BottomSheetState.close}>
                        {moment(selectedFeature.date).format('MMM Do YYYY')}
                    </Title>
                    <Author singleLine={bottomSheetState === BottomSheetState.close}>
                        <SubText>
                            <LayerText>{UserHelper.formatLayerText(selectedFeature.layer)}</LayerText>
                            <PixelText>{`Pixel resolution: ${selectedFeature.resolution}`}</PixelText>
                        </SubText>
                    </Author>
                </MapDetails>
            </TopContainer>
            <Divider />
            <SentinelOptions className="opacity-slider">
                <SliderText>Transparency:</SliderText>
                <SliderValue>{(opacity * 100).toFixed()}%</SliderValue>

                <SliderInputContainer>
                    <Slider
                        percentageFilled={opacity * 100}
                        type="range"
                        defaultValue={100}
                        value={opacity * 100}
                        step={0.01}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                            const opacity = parseInt(e.target.value) / 100;
                            changeOpacity(opacity);
                        }}
                    />
                </SliderInputContainer>
                <SentinelShare>
                    <MobileShareLinks sentinelFeature={selectedSentinelFeature} />
                </SentinelShare>
            </SentinelOptions>
            <Divider />
            <ListContainer>
                {sentinelFeatures?.map((t) => {
                    return <SatelliteListStyleCard key={t.id} feature={t} />;
                })}
            </ListContainer>
        </React.Fragment>
    );
};

export default MobileSatelliteSearchResult;

const TopContainer = styled.div<{ bottomSheetClosed: boolean }>`
    height: ${(props) => (props.bottomSheetClosed ? '100px' : 'auto')};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const MapDetails = styled.div`
    width: calc(100% - 60px);
`;

const Title = styled.h1<{ singleLine: boolean }>`
    display: inline-block; /* for inline elements e.g. span, strong, em etc */
    width: calc(100%); /* The trick is here! */
    color: white;
    text-align: left;
    font-size: 22px;
    line-height: 26.4px;
    margin: 12px 0 0 12px;

    text-overflow: ${(props) => (props.singleLine ? 'ellipsis' : 'unset')};
    white-space: ${(props) => (props.singleLine ? 'nowrap' : 'unset')};
    overflow: ${(props) => (props.singleLine ? 'hidden' : 'unset')};
`;

const Author = styled.p<{ singleLine: boolean }>`
    display: inline-block; /* for inline elements e.g. span, strong, em etc */
    text-overflow: ellipsis;
    width: calc(100%); /* The trick is here! */
    text-align: left;
    font-size: 16px;
    color: white;
    margin: 0 0 16px 12px;
    line-height: 21px;

    text-overflow: ${(props) => (props.singleLine ? 'ellipsis' : 'unset')};
    white-space: ${(props) => (props.singleLine ? 'nowrap' : 'unset')};
    overflow: ${(props) => (props.singleLine ? 'hidden' : 'unset')};

    a {
        color: #eed926 !important;
        text-decoration: underline !important;
        pointer-events: all;
    }
`;

const Divider = styled.div`
    height: 1px;
    width: 100vw;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

const SubText = styled.p`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const LayerText = styled.p`
    margin-bottom: 0px;
    color: #eed926;
`;

const PixelText = styled.p`
    color: rgba(255, 255, 255, 0.75);
    margin-bottom: 0px;

    span {
        color: #eed926;
    }
`;

const SentinelOptions = styled.div`
    width: 100%;
    padding: 14px 10px;
    display: flex;
    flex-direction: row;
`;

const SliderText = styled.p`
    vertical-align: center;
    color: white;
    font-size: 16px;
    margin: 0;
    padding: 0;
`;

const SliderValue = styled.p`
    vertical-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    margin: 0px 5px;
    padding: 0;
`;

const SliderInputContainer = styled.div`
    width: 100%;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Slider = styled<any>(Input)`
    width: 100%;
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
        #eed926 ${(props) => 100 - props.value}%
    );
    height: 2px;
    padding: 0;
    box-shadow: none !important;
    border: none !important;
    -webkit-appearance: none;
    outline: none;
    border-radius: 5px;
    margin-top: 12px;
    pointer-events: all;

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        cursor: pointer;
        pointer-events: all;
    }

    &:focus {
        pointer-events: all;
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
            #eed926 ${(props) => 100 - props.value}%
        );
    }
`;

const SentinelShare = styled.div`
    padding: 2px 0px 0px 5px;
    margin-left: 10px;
`;

const ListContainer = styled.div`
    margin-top: 20px;
`;

const ErrorTitle = styled(Title)`
    color: rgb(255, 100, 100);
`;

const ErrorSubText = styled(SubText)`
    color: rgb(255, 100, 100);
`;
