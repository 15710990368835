import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSideDrawerCollapse } from '../../../../store/SideDrawer/selectors';
import { StyledDrawerContentContainer } from './drawer-styles';

interface DrawerContentProps {
    children: React.ReactNode;
    className?: string;
}

/**
 * DrawerContent
 * Standardized drawer content to control height and scroll behavior
 * @required children React.ReactNode
 * @optional className string
 */

const DrawerContent = (props: DrawerContentProps) => {
    const sideDrawerCollapse = useSelector(selectSideDrawerCollapse);

    return (
        <DrawerContentContainer className={props.className} closeDraw={sideDrawerCollapse}>
            {props.children}
        </DrawerContentContainer>
    );
};

export default DrawerContent;

interface DrawerContentContainerProps {
    closeDraw?: boolean;
}

export const DrawerContentContainer = styled(StyledDrawerContentContainer)<DrawerContentContainerProps>`
    // TODO min-height should be 0px and the component dictates if a specific height is required
    // leaving until #4366 and #4528 are merged so it can be sorted DP 30/11/2022
    min-height: ${(props) => (props.closeDraw ? '0px' : '215px')};
    max-height: ${(props) => (props.closeDraw ? '0px' : 'calc(100vh - 262px)')};
    overflow: ${(props) => (props.closeDraw ? 'hidden' : '')};

    &.display-comment-input {
        min-height: 0px;
        max-height: ${(props) => (props.closeDraw ? '0px' : 'calc(100vh - 442px)')};
    }

    &.hide-display-comment-input {
        min-height: 0px;
        max-height: ${(props) => (props.closeDraw ? '0px' : 'calc(100vh - 337px)')};
    }

    &.satellite-container {
        min-height: 0px;
        max-height: ${(props) => (props.closeDraw ? '0px' : 'calc(100vh - 296px)')};
    }
`;
