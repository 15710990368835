import SoarModal from '../Shared/soar-modal';
import styled from 'styled-components';
import { StyledButton } from '../Shared/styled-button';

interface UploadWorkflowModalProps {
    isWorkflowModalOpen: boolean;
    setIsWorkflowModalOpen: (isOpen: boolean) => void;
    handleSelectFileForGeneralUpload: () => void;
    handleSelectFileForGeorectifier: () => void;
}

const UploadWorkflowModal = (props: UploadWorkflowModalProps) => {
    return (
        <SoarModal
            title="Do you want to use the georectifier?"
            toggle={() => props.setIsWorkflowModalOpen(false)}
            isOpen={props.isWorkflowModalOpen}
            width="600px"
        >
            <SelectWorkflowModalText>
                You can select to upload as an image or use the georectifier to create a map.
            </SelectWorkflowModalText>
            <SelectWorkflowModalButtonContainer>
                <SelectWorkflowButton onClick={() => props.handleSelectFileForGeneralUpload()}>
                    Upload as an image
                </SelectWorkflowButton>
                <SelectWorkflowButton onClick={() => props.handleSelectFileForGeorectifier()}>
                    Take me to the georectifier
                </SelectWorkflowButton>
            </SelectWorkflowModalButtonContainer>
        </SoarModal>
    );
};

export default UploadWorkflowModal;

const SelectWorkflowModalText = styled.div`
    color: rgba(255, 255, 255, 0.87);
    padding: 10px 5px;
`;

const SelectWorkflowModalButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px 5px;
`;

const SelectWorkflowButton = styled(StyledButton)`
    margin-left: 5px;
    text-transform: none;
`;
