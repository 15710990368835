import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default class UploadHelper {
    private static VISITED_KEY = 'disable-tutorial';

    public static hasOptedOutUploadTutorial = (): boolean => {
        if (cookies.get(UploadHelper.VISITED_KEY)) {
            return true;
        } else {
            return false;
        }
    };

    public static setOptedOutUploadTutorial = (): void => {
        cookies.set(UploadHelper.VISITED_KEY, true, { path: '/' });
    };
}