import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { DistortionToolMode } from '../../../api/model';
import Analytics from '../../../lib/user-analytics';
import {
    actionSetDroneImageDistortionTool,
    actionSetDroneImageDistortionOpacity,
} from '../../../store/Map/Uploads/actions';
import { selectDroneImageDistortionTool } from '../../../store/Map/Uploads/selectors';
import DrawerHint from '../../Drawer/drawer-hint';
import { StyledButton } from '../../Shared/styled-button';
import UriHelper from '../../../lib/uri-helper';

interface UploadLocationDistortionControlProps {
    setUploadWorkFlow: () => void;
    calc: () => void;
}

export const UploadLocationDistortionControl = ({
    setUploadWorkFlow,
    calc, // eslint-disable-line @typescript-eslint/no-unused-vars
}: UploadLocationDistortionControlProps): JSX.Element => {
    const distortionMode = useSelector(selectDroneImageDistortionTool);

    const dispatch = useDispatch();
    const setDistortionTool = (mode: DistortionToolMode) => dispatch(actionSetDroneImageDistortionTool(mode));
    const setDroneOpacity = useCallback(
        (opacity: number) => dispatch(actionSetDroneImageDistortionOpacity(opacity)),
        [dispatch]
    );

    const handleAdjustImage = () => {
        setUploadWorkFlow();
    };

    useEffect(() => {
        setDroneOpacity(0.6);
    }, [setDroneOpacity]);

    return (
        <DistortionControlContainer>
            <Title>Step 2: Position your map</Title>
            <Content>
                <DrawerHint error>
                    We could not identify any location data please place the map in the desired location.
                </DrawerHint>
                <DrawerHint>
                    Use the positioning tools below to place the map in the correct place. <br />
                    Please click here for a quick video to assist you if you need more help 🤓
                </DrawerHint>

                <DistortionControlsContainer>
                    <DistortionToolButton
                        active={distortionMode === DistortionToolMode.Translate}
                        onClick={() => {
                            distortionMode === DistortionToolMode.Translate
                                ? setDistortionTool(DistortionToolMode.None)
                                : setDistortionTool(DistortionToolMode.Translate);
                            Analytics.Event('Upload', 'Distortion tools', 'translate');
                        }}
                    >
                        <Icon className="fa fa-hand-paper-o" />
                        Move
                    </DistortionToolButton>

                    <DistortionToolButton
                        active={distortionMode === DistortionToolMode.Scale}
                        onClick={() => {
                            distortionMode === DistortionToolMode.Scale
                                ? setDistortionTool(DistortionToolMode.None)
                                : setDistortionTool(DistortionToolMode.Scale);
                            Analytics.Event('Upload', 'Distortion tools', 'scale');
                        }}
                    >
                        <Icon className="fa fa-compress-arrows-alt" />
                        Size
                    </DistortionToolButton>

                    <DistortionToolButton
                        active={distortionMode === DistortionToolMode.Rotate}
                        onClick={() => {
                            distortionMode === DistortionToolMode.Rotate
                                ? setDistortionTool(DistortionToolMode.None)
                                : setDistortionTool(DistortionToolMode.Rotate);
                            Analytics.Event('Upload', 'Distortion tools', 'rotate');
                        }}
                    >
                        <Icon className="fa fa-sync-alt" />
                        Rotate
                    </DistortionToolButton>

                    {/* <DistortionToolButton
                        active={distortionMode === DistortionToolMode.Distort}
                        onClick={() => {
                            distortionMode === DistortionToolMode.Distort
                                ? setDistortionTool(DistortionToolMode.None)
                                : setDistortionTool(DistortionToolMode.Distort);
                            Analytics.Event('Upload', 'Distortion tools', 'distort');
                        }}
                    >
                        <Icon className="fa fa-sync-alt" />
                        Distort
                    </DistortionToolButton> */}
                </DistortionControlsContainer>
                <UploadButtonControls>
                    <Button onClick={() => UriHelper.navigateToPath('/upload')}>
                        <BackIcon className="fa fa-chevron-left" /> Back
                    </Button>
                    {/* <Button
                        onClick={() => {
                            calc();
                        }}
                    >
                        I am happy with the map position
                    </Button> */}
                    <Button onClick={handleAdjustImage}>Proceed to georeferencing</Button>
                </UploadButtonControls>
            </Content>
        </DistortionControlContainer>
    );
};

const DistortionControlContainer = styled.div`
    background-color: #222327;
    border: 4px solid gray;
    border-radius: 6px;
    height: 66vh;
    width: calc(50vw - 25px);
    margin: 90px 0px 0px 10px;
    padding: 20px;
`;

const Title = styled.h3`
    flex: 0 1 auto;
    margin-bottom: 1rem;
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 1.875rem;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 31.25rem;
    margin: 0 auto;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 0.5rem;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 0.25rem;
    }
`;

const DistortionControlsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 12px;
    justify-content: space-around;
`;

const DistortionToolButton = styled(StyledButton)<{ active: boolean }>`
    width: 33%;
    /* prefer this personally but... 
    display: flex;
    flex-direction: row;
    justify-content: space-evenly; */
    border: ${(props) => (props.active ? '1px solid rgb(0, 0, 0)' : '1px solid rgba(0, 0, 0, 0.2)')};
`;

const Icon = styled.i`
    display: block;
    font-size: 1.1rem;
    margin: 3px 0px;
`;

const BackIcon = styled.i`
    font-size: 12px;
    margin-right: 5px;
`;

const Button = styled(StyledButton)`
    margin: 5px 0px;
    text-transform: none;
    min-width: 100px;
`;

const UploadButtonControls = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 6px 14px;
`;
