import React from 'react';
import { useSelector } from 'react-redux';
import GeoUtil from '../../../lib/geo-util';
import { selectAdminListingsData, selectAdminUsersData } from '../../../store/Dashboard/selectors';
import NavigationPanel from '../Shared/navigation-panel';
import { DashboardTicker } from './dashboard-ticker';
import RecentActivity from './recent-activity';
import DashboardContainer from '../Shared/dashboard-container';

const SubdomainOverview = (): JSX.Element => {
    const subdomainListings = useSelector(selectAdminListingsData);
    const userData = useSelector(selectAdminUsersData);

    const listingsFileSize = (): number => {
        if (subdomainListings.length > 0) {
            return subdomainListings
                .map((listing) => {
                    return listing.filesize || 0;
                })
                .reduce((accumulator, currentValue) => {
                    return accumulator + currentValue;
                });
        }
        return 0;
    };

    const listingsAreaMetersSquared = (): number => {
        if (subdomainListings.length > 0) {
            return subdomainListings
                .map((listing) => {
                    return listing.geometryWKT;
                })
                .map((wkt) => {
                    return GeoUtil.latLngBoundsFromPolygonWKT(wkt);
                })
                .map((latlngBounds) => {
                    return GeoUtil.area(latlngBounds);
                })
                .reduce((accumulator, currentValue) => {
                    return accumulator + currentValue;
                });
        }
        return 0;
    };
    return (
        <React.Fragment>
            <NavigationPanel label="My Admin Dashboard" />
            <DashboardContainer>
                <DashboardTicker
                    totalFiles={subdomainListings.length || 0}
                    totalListingAreaMetersSquared={listingsAreaMetersSquared()}
                    totalListingFileSize={listingsFileSize()}
                    totalUserCount={userData.length ? userData.length : 1}
                />
                <RecentActivity listingsData={subdomainListings} />
            </DashboardContainer>
        </React.Fragment>
    );
};

export default SubdomainOverview;
