import { useState, useEffect } from 'react';
import styled from 'styled-components';
import MobileSearchBar from './mobile-search-bar';

interface MobileSearchContainerProps {
    handleIsOpen: (isOpen: boolean) => void;
}

const MobileSearchContainer = (props: MobileSearchContainerProps) => {
    const [isContainerOpen, setIsContainerOpen] = useState<boolean>(false);

    useEffect(() => {
        props.handleIsOpen(isContainerOpen);
    }, [isContainerOpen, props]);

    return (
        <SearchContainer isContainerOpen={isContainerOpen}>
            <MobileSearchBar setIsContainerOpen={setIsContainerOpen} />
        </SearchContainer>
    );
};

export default MobileSearchContainer;

interface SearchContainerProps {
    isContainerOpen: boolean;
}

const SearchContainer = styled.div<SearchContainerProps>`
    position: ${(props) => (props.isContainerOpen ? 'absolute' : '')};
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    overflow: auto; // TODO this is not ok
    z-index: 9999;
`;
