import React, { useEffect, useState } from 'react';
import { ListingDTO, SaleInfo } from '../../../api/model';
import moment from 'moment';
import UserHelper from '../../../lib/user-helper';
import styled from 'styled-components';
import { Badge } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import ListingHelper from '../../../lib/listing-helper';
import ApiListings from '../../../api/api-listings';
import Analytics from '../../../lib/user-analytics';

interface TransactionListItemProps {
    item: SaleInfo;
}

export const TransactionListItem = (props: TransactionListItemProps) => {
    const item = props.item;
    const [listing, setListing] = useState<ListingDTO | undefined>(undefined);
    const [title, setTitle] = useState<string>('');
    const [status, setStatus] = useState<boolean>(false);
    const [link, setLink] = useState<string>('');

    useEffect(() => {
        const accessToken = UserHelper.validateAndGetIdToken();
        if (accessToken) {
            ApiListings.getListing(item.listingId).then((listing) => {
                if (listing) {
                    setListing(listing);
                    const title = ListingHelper.getTitleFromListing(listing);
                    setTitle(title);
                    if (item.payoutId) {
                        setStatus(true);
                    }
                    if (listing.id) {
                        setLink(`/maps/${listing.id}`);
                    }
                }
            });
        }
    }, [item.listingId, item.payoutId]);

    const handleOpenImage = () => {
        if (listing) {
            window.open('/maps/' + listing.id, '_blank');
            Analytics.Event('My Dashboard', 'Clicked open image from transaction history', listing.id);
        }
    };

    const salesPrice = `US$${(item.amount - item.fee).toFixed(2)}`;

    return (
        <SalesTr>
            {isMobile ? (
                <React.Fragment>
                    <SalesImgTd>
                        {listing?.id ? (
                            <img src={ListingHelper.getPreviewUrlForListing(listing.id)} onClick={handleOpenImage} />
                        ) : (
                            'Not Available'
                        )}
                    </SalesImgTd>
                    <SalesItemDetails>
                        <SalesItemTitle>
                            {title ? (
                                <TitleLink href={link} target="_blank">
                                    {title}
                                </TitleLink>
                            ) : (
                                'Unknown Name'
                            )}
                            <SalesMobileCurrency>{salesPrice}</SalesMobileCurrency>
                        </SalesItemTitle>
                        <SalesMobileDiv>
                            <SalesMobileDivDate>{moment(item.createdAt).format('MMMM Do YYYY')}</SalesMobileDivDate>
                            {status ? (
                                <SalesMobileBadge color="success">{`Paid: ${moment(item.paidOutAt).format(
                                    'YYYY-MM-DD'
                                )}`}</SalesMobileBadge>
                            ) : (
                                <SalesMobileBadge color="info">Awaiting Payment</SalesMobileBadge>
                            )}
                        </SalesMobileDiv>
                    </SalesItemDetails>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <SalesImgTd>
                        {listing?.id ? (
                            <img src={ListingHelper.getPreviewUrlForListing(listing.id)} onClick={handleOpenImage} />
                        ) : (
                            'Not Available'
                        )}
                    </SalesImgTd>
                    <td>
                        {title ? (
                            <TitleLink href={link} target="_blank">
                                {title}
                            </TitleLink>
                        ) : (
                            'Unknown Listing Name'
                        )}
                    </td>
                    <td>Drone Photo</td>
                    <td>{moment(item.createdAt).format('MMMM Do YYYY')}</td>
                    <td>
                        {status ? (
                            <SalesBadge color="success">{`Paid: ${moment(item.paidOutAt).format(
                                'YYYY-MM-DD'
                            )}`}</SalesBadge>
                        ) : (
                            <SalesBadge color="info">Awaiting Payment</SalesBadge>
                        )}
                    </td>
                    <td>{salesPrice}</td>
                </React.Fragment>
            )}
        </SalesTr>
    );
};

const SalesTr = styled.tr`
    background-color: transparent;
    border-top: 1px solid white;
    td {
        background-color: transparent;
        color: white;
        vertical-align: middle;

        img {
            width: 83px;
            height: 57px;
            border-radius: 6px;
        }
    }
`;

const SalesImgTd = styled.td`
    width: 85px !important;
    cursor: pointer;
`;

const TitleLink = styled.a`
    color: white;
    text-decoration: none !important;
    &:visited,
    &:hover {
        color: white !important;
    }
`;

const SalesBadge = styled(Badge)`
    padding: 10px 10px 10px 10px;
`;

const SalesMobileBadge = styled(Badge)`
    padding: 5px 10px 5px 10px;
    height: fit-content;
    font-size: 10px;
    margin-left: 2px;
`;

const SalesItemDetails = styled.div`
    position: relative;
    height: 100%;
    min-height: 75px;
    margin: 5px;
    color: white;
`;

const SalesItemTitle = styled.div`
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
        color: white;
    }
`;

const SalesMobileDiv = styled.div`
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: space-evenly;
    justify-content: space-between;
    width: -webkit-fill-available;
`;

const SalesMobileDivDate = styled.p`
    font-size: 10px;
    color: white;
    padding-top: 3px;
`;

const SalesMobileCurrency = styled.div`
    color: white;
    font-size: 20px;
`;
