import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import Analytics from '../../../lib/user-analytics';
import { actionSetSelectedCategory, actionSetSelectedContinent } from '../../../store/Map/MapSelection/actions';
import MobileCategoryFilter from './Results/mobile-category-filter';
import { Collapse } from 'reactstrap';
import MobileContinentFilter from './Results/mobile-continent-filter';

interface MobileSearchTouchContainerProps {
    isSearchOpen: boolean;
    searchInputRef: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    children: ReactNode;
    containerHeight?: string;
    handleHasFocus: (hasFocus: boolean) => void;
    handleIsSearchOpen: (isSearchOpen: boolean) => void;
    filtersAvailable: boolean;
}
const MobileSearchTouchContainer = (props: MobileSearchTouchContainerProps) => {
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const [showFilters, setShowFilters] = useState<boolean>(false);

    const dispatch = useDispatch();
    const clearSelectedCategory = () => dispatch(actionSetSelectedCategory(undefined));
    const clearSelectedContinent = () => dispatch(actionSetSelectedContinent(undefined));

    const handleCloseSearch = () => {
        props.handleHasFocus(false);
        props.handleIsSearchOpen(false);
        clearSelectedContinent();
        clearSelectedCategory();
        Analytics.Event('Search Results Bar', 'Search Results Closed');
    };

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 5;

    const handleSearchFilter = () => {
        setShowFilters(!showFilters);
        Analytics.Event('Mobile - Search Results Filter', 'Clicked Search Results Filter');
    };

    const onTouchStart = (e) => {
        setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientY);
        props.searchInputRef.current?.blur();
        props.handleHasFocus(false);
    };

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isSwipeUp = distance > minSwipeDistance;
        const isSwipeDown = distance < -minSwipeDistance;
        if (isSwipeUp || isSwipeDown) {
            props.searchInputRef.current?.blur();
            props.handleHasFocus(false);
        }
    };

    useEffect(() => {
        if (!props.filtersAvailable) {
            setShowFilters(false);
        }
    }, [props.filtersAvailable]);

    if (!props.isSearchOpen) {
        return <React.Fragment />;
    }

    return (
        <Container
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            className={props.isSearchOpen ? 'opening' : 'closing'}
        >
            <ContainerOptions filtersAvailable={props.filtersAvailable}>
                {props.filtersAvailable ? <MobileFilterOptions onClick={handleSearchFilter} /> : null}
                <CloseIcon id="close-icon" onClick={() => handleCloseSearch()} src="/assets/close.png" />
            </ContainerOptions>
            <MobileFilterCollapse isOpen={showFilters}>
                <MobileContinentFilter />
                <MobileCategoryFilter />
            </MobileFilterCollapse>
            {props.children}
        </Container>
    );
};

export default MobileSearchTouchContainer;

const DrawerOpenAnimation = () => keyframes`
    0% {
        height: 0px;
    }
    100% {
        height: 100vh;
    }
`;

const DrawerCloseAnimation = () => keyframes`
    0% {
        height: 100vh;
    }
    100% {
        height: 0px;
    }
`;

const CONTAINER_ANIMATION = 500; // ms

const Container = styled.div`
    padding-top: 60px;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(2px);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.opening {
        animation: ${DrawerOpenAnimation()} ${CONTAINER_ANIMATION}ms linear;
    }

    &.closing {
        animation: ${DrawerCloseAnimation()} ${CONTAINER_ANIMATION}ms linear;
    }
`;

const ContainerOptions = styled.div<{ filtersAvailable }>`
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => (props.filtersAvailable ? 'space-between' : 'flex-end')};
    padding-bottom: 10px;
    border-bottom: ${(props) => !props.filtersAvailable && '1px solid rgb(255 255 255 / 30%)'};
`;

const CloseIcon = styled.img`
    margin: 5px 10px 0px 0px;
    width: 24px;
    height: 24px;
`;

const MobileFilterOptions = styled.div`
    opacity: 0.87;
    margin: 5px 0px 0px 7px;
    width: 25px;
    height: 24px;
    background-image: url('/assets/search-content/icon-filter.svg');
`;

const MobileFilterCollapse = styled(Collapse)``;
