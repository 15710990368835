import React from 'react';
import { ListingDTO, UserDTO } from '../../../api/model';
import ProfileHeader from '../../Drawer/Profile/profile-header';
import MobileProfileNoMaps from './mobile-profile-no-maps';
import MobileProfileUserMapStats from './mobile-profile-user-map-stats';

interface MobileProfileOpenViewProps {
    userProfile: UserDTO;
    userListings: ListingDTO[];
}

const MobileProfileOpenView = (props: MobileProfileOpenViewProps) => {
    return (
        <React.Fragment>
            <ProfileHeader userProfile={props.userProfile} shareURL={null} />
            <MobileProfileUserMapStats listings={props.userListings} displayProfileIcons={true} />
            <MobileProfileNoMaps />
        </React.Fragment>
    );
};

export default MobileProfileOpenView;
