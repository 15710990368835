import { AppState } from '../../root-reducer';

export const selectDroneImagePreviewUrl = (state: AppState) => state.uploadDomain.droneImagePreviewUrl;

export const selectDroneImagePositions = (state: AppState) => state.uploadDomain.droneImagePositions;

export const selectRectifiedMap = (state: AppState) => state.uploadDomain.rectifiedMap;

export const selectCandidateFile = (state: AppState) => state.uploadDomain.candidateFile;

export const selectDatasetDetails = (state: AppState) => state.uploadDomain.datasetDetails;

export const selectDroneImageDistortionTool = (state: AppState) => state.uploadDomain.droneImageDistortionTool;

export const selectDroneImageOpacity = (state: AppState) => state.uploadDomain.droneImageOpacity;

export const selectDroneImageDistortionCorners = (state: AppState) => state.uploadDomain.droneImageDistortionCorners;

export const selectImageManualPosition = (state: AppState) => state.uploadDomain.imageManualPosition;

export const selectImageBounds = (state: AppState) => state.uploadDomain.imageBounds;

export const selectCustomAOIDisplay = (state: AppState) => state.uploadDomain.customAOIDisplay;

export const selectMouseActive = (state: AppState) => state.uploadDomain.mouseActive;