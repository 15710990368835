import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ListingDTO } from '../../../../api/model';
import { MobileSearchResultTitle } from '../Results/mobile-search-results';
import MobileSearchResultCarousel from './mobile-search-result-carousel';
import MobileCardItem from '../../Shared/mobile-card-item';
import { PulseLoader } from '../../../Shared/pulse-loader';

interface MobileSearchContentProps {
    handleApiRequest: () => Promise<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
    displayDivider?: boolean;
    carouselTitle?: ReactNode;
}

/**
 * Reusable carousel to display imagery in various styles
 *
 * @param handleApiRequest - () => Promise<T> - Api request for listings to be displayed
 * @param displayDivider - boolean - optional divider line to display under the carousel
 * @param carouselTitle - ReactNode - optional standardised title
 * @returns scrollable carousel
 */
const MobileSearchCarousel = (props: MobileSearchContentProps) => {
    const [carouselImages, setCarouselImages] = useState<ListingDTO[]>([]);
    const [isLoadingImages, setIsLoadingImages] = useState<boolean>(false);

    useEffect(() => {
        const handleApiRequest = async () => {
            setIsLoadingImages(true);
            await props
                .handleApiRequest()
                .then((res) => {
                    setCarouselImages(res);
                })
                .finally(() => {
                    setIsLoadingImages(false);
                });
        };

        handleApiRequest();
        // Disables props triggering rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!carouselImages?.length && !isLoadingImages) {
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            {props.carouselTitle ? <MobileSearchResultTitle>{props.carouselTitle}</MobileSearchResultTitle> : null}
            <MobileSearchResultCarousel>
                <React.Fragment></React.Fragment>
                {carouselImages?.length && !isLoadingImages ? (
                    <React.Fragment>
                        {carouselImages.map((listing, index) => {
                            return <MobileCardItem listing={listing} key={index} />;
                        })}
                    </React.Fragment>
                ) : (
                    <CarouselLoadingContainer>
                        {Array(6)
                            .fill(true)
                            .map((_, i) => (
                                <CarouselLoadingCard key={i}>
                                    <CarouselLoadingLoader>
                                        <PulseLoader />
                                    </CarouselLoadingLoader>
                                </CarouselLoadingCard>
                            ))}
                    </CarouselLoadingContainer>
                )}
            </MobileSearchResultCarousel>
            {props.displayDivider ? <Divider /> : null}
        </React.Fragment>
    );
};

export default MobileSearchCarousel;

const Divider = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.075);
`;

const CarouselLoadingContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const CarouselLoadingLoader = styled.div`
    padding-top: 40%;
`;

const CarouselLoadingCard = styled.div`
    height: 148px;
    margin: 4px;
    display: flex;
    flex-direction: column;
    width: 40vw;
    border: 1px solid white;
    border: 1px solid rgb(255 255 255 / 10%);
    border-radius: 6px;
    background-color: hsla(0, 100%, 100%, 0.01);
    box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
        0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
`;
