import React from 'react';
import styled from 'styled-components';
import { ListingDTO } from '../../../api/model';
import GeoUtil from '../../../lib/geo-util';
import SoarHelper from '../../../lib/soar-helper';

interface ProfileUserMapStatsProps {
    listings: ListingDTO[];
    displayProfileIcons?: boolean;
    midView?: boolean;
}

const MobileProfileUserMapStats = (props: ProfileUserMapStatsProps) => {
    const mapCount = props.listings?.length;

    let likeCount: number | undefined = undefined;

    let mappedArea: string | undefined;
    if (props.listings) {
        likeCount = props.listings
            .map((t) => t.totalLikes)
            .reduce((acc, val) => {
                return acc + val;
            }, 0);

        const mappedAreaMetersSquared = props.listings
            .map((t) => GeoUtil.area(t.boundingBox))
            .reduce((acc, val) => {
                return acc + val;
            }, 0);
        if (mappedAreaMetersSquared / 1000 / 1000 > 1000000) {
            const number = mappedAreaMetersSquared / 1000 / 1000 / 1000000;
            mappedArea = `${Math.round(number).toLocaleString()}M km²`;
        } else {
            const number = mappedAreaMetersSquared / 1000 / 1000;
            mappedArea = `${Math.round(number).toLocaleString()} km²`;
        }
        if (mappedAreaMetersSquared === 0) {
            mappedArea = undefined;
        }
    }

    return (
        <ProfileStatsContainer isSubdomain={!SoarHelper.isSoar()}>
            <React.Fragment>
                <ProfileStats>
                    <Stats>
                        {props.displayProfileIcons ? <StatsImage src="/assets/user-profile-logos/maps.png" /> : null}
                        <StatsCount>{mapCount}</StatsCount>
                        <StatsLabel>Maps</StatsLabel>
                    </Stats>
                </ProfileStats>
                <Divider className={props.midView ? 'mid-view' : ''} />
            </React.Fragment>

            {SoarHelper.isSoar() ? (
                <React.Fragment>
                    <ProfileStats>
                        <Stats>
                            {props.displayProfileIcons ? (
                                <StatsImage src="/assets/user-profile-logos/likes.png" />
                            ) : null}
                            <StatsCount>{likeCount}</StatsCount>
                            <StatsLabel>Likes</StatsLabel>
                        </Stats>
                    </ProfileStats>
                    <Divider className={props.midView ? 'mid-view' : ''} />
                </React.Fragment>
            ) : null}

            <ProfileStats>
                <Stats>
                    {props.displayProfileIcons ? <StatsImage src="/assets/user-profile-logos/covered.png" /> : null}
                    <StatsCount>{mappedArea ? mappedArea : 0}</StatsCount>
                    <StatsLabel>Mapped Area</StatsLabel>
                </Stats>
            </ProfileStats>
        </ProfileStatsContainer>
    );
};

export default MobileProfileUserMapStats;

const ProfileStatsContainer = styled.div<{ isSubdomain: boolean }>`
    flex-direction: row;
    display: flex;
    justify-content: ${(props) => (props.isSubdomain ? 'space-evenly' : 'center')};
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

const ProfileStats = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% / 3);
`;

const Stats = styled.div`
    padding: 5px;
    text-align: center;
`;

const StatsImage = styled.img``;

const StatsCount = styled.p`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 0px;
    white-space: nowrap;
`;

const StatsLabel = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    color: #ffffff;
    opacity: 0.5;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin-bottom: 0px;
`;

const Divider = styled.div`
    border-left: 1px solid white;
    opacity: 0.15;
    height: 50px;
    margin-top: 15px;

    &.mid-view {
        height: 35px;
        margin-top: 10px;
    }
`;
