import React, { useEffect, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import Analytics from '../../lib/user-analytics';
import { handleResize, Steps } from './steps';
import Tutorial from './tutorial';

interface TutorialStarterProps {
    onSkip: () => void;
}

const TutorialStarter = (props: TutorialStarterProps) => {
    const [stepIndex, setStepIndex] = useState(0);
    const [completed, setCompleted] = useState(false);
    const windowWidth = useWindowWidth();

    const [steps, setSteps] = useState(Steps);
    const onSkip = props.onSkip;

    useEffect(() => {
        steps[stepIndex].onTutorialStepStarted();
        Analytics.Event('Tutorial', 'Steps', stepIndex);
    }, [stepIndex, steps]);

    const handleNext = () => {
        if (stepIndex < steps.length - 1) {
            setStepIndex(stepIndex + 1);
        } else {
            setCompleted(true);
        }
    };

    const handlePrevious = () => {
        if (stepIndex > 1) {
            setStepIndex(stepIndex - 1);
        }
    };

    useEffect(() => {
        handleResize();
        setSteps(Steps);
    }, [windowWidth]);

    useEffect(() => {
        if (completed) {
            Analytics.Event('Tutorial', 'Completed tutorial');
            onSkip();
        }
    }, [completed, onSkip]);

    return (
        <React.Fragment>
            {!completed ? (
                <Tutorial
                    stepIndex={stepIndex}
                    steps={steps}
                    nextStep={handleNext}
                    previousStep={handlePrevious}
                    onSkip={props.onSkip}
                />
            ) : null}
        </React.Fragment>
    );
};

export default TutorialStarter;
