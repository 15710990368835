import { isMobile } from 'react-device-detect';
import { Table } from 'reactstrap';
import styled from 'styled-components';
import { OrderDTO } from '../../../api/model';
import OrderItemMobile from './order-item-mobile';
import OrderItem from './order-item';

interface ProfileTasksListProps {
    orders: OrderDTO[];
}

const OrderList = (props: ProfileTasksListProps) => {
    const { orders: filteredTasks } = props;
    return (
        <TasksTable>
            <thead>
                {!isMobile && (
                    <tr>
                        <th>Image</th>
                        <th>Type</th>
                        <th>Order Number</th>
                        <th>Date</th>
                        <th>Size</th>
                        <th>Discount</th>
                        <th>Paid</th>
                        <th>Total Price</th>
                        <th>Receipt</th>
                        <th>Options</th>
                    </tr>
                )}
            </thead>
            <tbody>
                {filteredTasks.map((task) => {
                    return isMobile ? (
                        <OrderItemMobile key={`task-item-${task.id}`} task={task} />
                    ) : (
                        <OrderItem key={`task-item-${task.id}`} task={task} />
                    );
                })}
            </tbody>
        </TasksTable>
    );
};

export default OrderList;

const TasksTable = styled(Table)`
    background-color: transparent;
    color: white;

    th {
        color: white !important;
        border-top: none;
        border-bottom: none !important;
    }

    @media only screen and (max-width: 1600px) {
        th {
            font-size: 12px;
        }
        tbody {
            font-size: 12px;
        }
    }
`;
