import React from 'react';
import styled, { keyframes } from 'styled-components';

interface BackdropProps {
    children: React.ReactElement;
    onClose: (e) => void;
    fade: boolean;
}

export function Backdrop({ children, onClose, fade }: BackdropProps) {
    const handleOnClose = (e) => {
        if (e.target === e.currentTarget) {
            onClose(e);
        }
    };

    return (
        <BackdropContainer onClick={handleOnClose} className={fade ? 'fade-in' : 'fade-out'}>
            {children}
        </BackdropContainer>
    );
}

const FadeIn = keyframes`
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const FadeOut = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const FADE_TIME = '300ms';

const BackdropContainer = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    z-index: 9999;

    .fade-in {
        animation: ${FadeIn} ${FADE_TIME};
        animation-fill-mode: both;
    }

    .fade-out {
        animation: ${FadeOut} ${FADE_TIME};
        animation-fill-mode: both;
    }
`;
