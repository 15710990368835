import React from 'react';
import styled from 'styled-components';
import { AutocompleteDTO } from '../../../../api/model';
import MobileSearchResultCarousel from '../Carousel/mobile-search-result-carousel';
import MobileSearchAutocompleteItem from './mobile-search-autocomplete-item';

interface MobileSearchResultsAutocompleteProps {
    autocomplete: AutocompleteDTO[];
    autocompleteCoordinate: AutocompleteDTO | undefined;
    handleSelectAutocomplete: (location: AutocompleteDTO) => void;
}

const MobileSearchResultsAutocomplete = (props: MobileSearchResultsAutocompleteProps) => {
    if (props.autocomplete.length === 0) return <React.Fragment />;

    if (props.autocompleteCoordinate?.latlng) {
        props.autocomplete.unshift(props.autocompleteCoordinate)
    }

    return (
        <React.Fragment>
            <MobileSearchResultCarousel padding={'0px 0px 10px 0px'}>
                {props.autocomplete.map((item, index) => {
                    return (
                        <MobileSearchAutocompleteItem
                            key={index}
                            autocomplete={item}
                            onSelectAutocomplete={props.handleSelectAutocomplete}
                        />
                    );
                })}
            </MobileSearchResultCarousel>
            <Divider />
        </React.Fragment>
    );
};

export default MobileSearchResultsAutocomplete;

const Divider = styled.div`
    margin: 0px -5px;
    border: 1px solid rgba(255, 255, 255, 0.075);
`;
