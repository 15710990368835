import store from '../../store/store';
import { actionOpenNavbarDropdownForTutorial } from '../../store/App/actions';
import { TutorialVideos } from './tutorial-videos';

const FULL_WIDTH = 1400;
const SMALL_WIDTH = 760;

let isWindowSmall = window.innerWidth < FULL_WIDTH;
let isWindowVerySmall = window.innerWidth < SMALL_WIDTH;

export const handleResize = () => {
    isWindowSmall = window.innerWidth < FULL_WIDTH;
    isWindowVerySmall = window.innerWidth < SMALL_WIDTH;

    Steps = getSteps();
};

function getSteps() {
    return [
        {
            title: 'Welcome to Soar',
            body: 'A digital atlas for all the worlds maps and imagery',
            caption: 'Would you like a quick tour?',
            target: '_blank',
            onTutorialStepStarted: () => { null },
        },
        {
            title: 'Maps',
            body: 'The Sidedrawer gives you quick access to maps and imagery',
            target: 'drawer',
            caption: 'When you zoom in, the sidedrawer will update to show maps in that area',
            onTutorialStepStarted: () => {
                store.dispatch(actionOpenNavbarDropdownForTutorial(isWindowVerySmall));
            },
        },
        {
            title: 'Explore',
            body: 'Use the Search bar to discover the digital atlas',
            target: 'search-input',
            onTutorialStepStarted: () => {
                store.dispatch(actionOpenNavbarDropdownForTutorial(isWindowSmall));
            },
        },
        {
            title: 'Satellites',
            body: 'Access near real time orbital satellite imagery from the Satellites tab. You can select from a variety of feeds sourced via different satellites.',
            target: isWindowVerySmall ? 'navbar-dropdown-satellites' : 'navbar-menu-satellites',
            onTutorialStepStarted: () => {
                store.dispatch(actionOpenNavbarDropdownForTutorial(isWindowVerySmall));
            },
        },
        {
            title: 'Contribute',
            body: 'Do you have maps that you want to share with the world? Upload your files to Soar.Earth directly from here',
            caption: "(Let's map the world together!)",
            target: isWindowSmall ? 'navbar-dropdown-share' : 'navbar-menu-share',
            onTutorialStepStarted: () => {
                store.dispatch(actionOpenNavbarDropdownForTutorial(isWindowSmall));
            },
        },
        {
            title: 'Annotation Tools',
            body: 'Measure and draw information over the map with these annotation tools.',
            target: 'annotation-tool-tutorial-container',
            onTutorialStepStarted: () => {
                store.dispatch(actionOpenNavbarDropdownForTutorial(false));
            },
        },
        {
            title: 'Basemaps',
            body: 'Change your base map here (street, satellite, etc).',
            target: 'basemap-tool-tutorial-container',
            onTutorialStepStarted: () => {
                store.dispatch(actionOpenNavbarDropdownForTutorial(false));
            },
        },
        {
            title: 'Minimap',
            body: 'The minimap displays the GPS position under your cursor.',
            caption: '(Click to change format between Decimal, DMS and MGRS)',
            target: 'coordinate-control-button',
            onTutorialStepStarted: () => {
                store.dispatch(actionOpenNavbarDropdownForTutorial(false));
            },
        },
        {
            title: "You've finished the basics",
            body: 'Want to learn more? Watch these quick tutorials',
            component: <TutorialVideos />,
            target: '_blank',
            onTutorialStepStarted: () => {
                store.dispatch(actionOpenNavbarDropdownForTutorial(false));
            },
        },
    ];
}

export let Steps = getSteps();
