export enum ImagerySort {
    Title = 'Title',
    LastPosted = 'LastPosted',
    Modified = 'Modified',
}

export const ImagerySortOptions = {
    title: 'Sort By',
    options: [
        { value: ImagerySort.Title, label: 'Title' },
        { value: ImagerySort.LastPosted, label: 'Last Posted' },
        { value: ImagerySort.Modified, label: 'Last Modified' },
    ],
    icons: {
        light: '/assets/explore-dropdown/sort-by-filter_white.svg',
        dark: '/assets/explore-dropdown/sort-by-filter_white_empty.svg',
    },
};
