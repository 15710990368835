import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import styled, { keyframes } from 'styled-components';

interface DrawerHintProps {
    children: React.ReactNode;
    error?: boolean;
    isActive?: boolean;
    forceInactive?: boolean;
    id?: string;
}

const defaultProps = {
    isActive: true,
    error: false,
    forceInactive: false,
    id: '',
};

const DrawerHint = (props: DrawerHintProps) => {
    props = { ...defaultProps, ...props };

    const [isVisible, setIsVisible] = useState(false);
    const [fadeOut, setFadeOut] = useState(false);
    const [numberOfLines, setNumberOfLines] = useState(0);
    const hintTextRef = useRef<HTMLParagraphElement | null>(null);

    useLayoutEffect(() => {
        if (hintTextRef.current && isVisible) {
            const lineHeight = hintTextRef.current.clientHeight;
            setNumberOfLines(Math.round(lineHeight / 22));
        }
    }, [isVisible]);

    useEffect(() => {
        if (props.forceInactive) {
            setIsVisible(false);
            setFadeOut(false);
        }
    }, [props.forceInactive]);

    useEffect(() => {
        if (props.isActive) {
            setIsVisible(true);
            setFadeOut(false);
        } else {
            setTimeout(() => {
                setIsVisible(false);
            }, 200);
            setFadeOut(true);
        }
    }, [props.isActive]);

    return (
        <Container isVisible={isVisible} fadeOut={fadeOut}>
            <HintIcon
                src={
                    props.error
                        ? '/assets/floating-drawer-icons/error-icon.svg'
                        : '/assets/floating-drawer-icons/info-icon.svg'
                }
            />
            <HintContainer>
                <HintText error={props.error || false} ref={hintTextRef} numberOfLines={numberOfLines}>
                    {props.children}
                </HintText>
            </HintContainer>
        </Container>
    );
};

export default DrawerHint;

interface ContainerProps {
    isVisible: boolean;
    fadeOut: boolean;
}

const Container = styled.div<ContainerProps>`
    min-height: 44px;
    display: ${(props) => (props.isVisible ? 'flex' : 'none')};
    animation: ${(props) => (props.fadeOut ? FadeOut : 'none')} 200ms linear;
    margin: 12px;
    flex-direction: row;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    vertical-align: middle;
`;

const FadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const HintIcon = styled.img`
    width: 34px;
    height: 34px;
    margin: auto 5px;
`;

const HintContainer = styled.div`
    flex-direction: column;
    display: flex;
    justify-content: center;
`;

interface HintTextProps {
    numberOfLines: number;
    error: boolean;
}

const HintText = styled.p<HintTextProps>`
    line-height: 22px;
    margin: ${(props) => (props.numberOfLines === 1 ? '10px 0 0 5px' : '2px 0 0 5px')};
    color: ${(props) => (props.error ? 'red' : 'white')};
    vertical-align: middle;
`;
