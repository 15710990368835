import styled from 'styled-components';
import React from 'react';
import { useWindowHeight } from '@react-hook/window-size';

interface Props {
    marginTop?: number;
    children: React.ReactNode;
}

const DashboardContainer = ({ children, marginTop = 0 }: Props) => {
    const screenHeight = useWindowHeight();
    return <Container height={screenHeight - 60 - marginTop}>{children}</Container>;
};

export default DashboardContainer;

interface ContainerProps {
    height: number;
}

const Container = styled.div<ContainerProps>`
    @media only screen and (max-width: 600px) {
        height: ${(props) => props.height}px;
        width: 100vw;
        overflow-x: hidden;
        overflow-y: auto;
    }
`;
