import React from 'react';
import styled from 'styled-components';
import { ListingDTO, UserDTO } from '../../../api/model';
import UserHelper from '../../../lib/user-helper';
import { BottomSheetState } from '../MapSelection/bottom-sheet';
import MobileProfileNoMaps from './mobile-profile-no-maps';
import MobileProfileUserMapStats from './mobile-profile-user-map-stats';

interface MobileProfileClosedViewProps {
    userProfile: UserDTO;
    userListings: ListingDTO[];
    sheetState: BottomSheetState;
}

const MobileProfileClosedView = (props: MobileProfileClosedViewProps) => {
    return (
        <React.Fragment>
            <ProfileClosedContainer>
                <ProfileImage
                    src={props.userProfile.avatarUrl || UserHelper.getDefaultAvatar(props.userProfile.userId)}
                />
                <ProfileClosedContainerDetails>
                    <ProfileNameContainer>
                        <ProfileName>{props.userProfile.name}</ProfileName>
                        {props.userProfile.reputable ? (
                            <ReputableUserIcon alt="Verified" title="Verified" src="/assets/verified-user-tick.svg" />
                        ) : null}
                    </ProfileNameContainer>
                    <MobileProfileUserMapStats listings={props.userListings} midView />
                </ProfileClosedContainerDetails>
            </ProfileClosedContainer>
            <MobileProfileNoMaps midView />
        </React.Fragment>
    );
};

export default MobileProfileClosedView;

const ProfileClosedContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const ProfileClosedContainerDetails = styled.div`
    width: 100%;
    margin: 0px 20px 0px 8px;
`;

const ProfileImage = styled.img`
    width: 80px;
    height: 80px;
    border: 2px solid #eed926;
    box-sizing: border-box;
    border-radius: 50%;
    margin: 5px 0px 0px 5px;
`;

const ProfileNameContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
    margin-left: 8px;
    max-width: calc(100vw - 160px);
`;

const ProfileName = styled.div`
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const ReputableUserIcon = styled.img`
    margin-left: 4px;
    width: 16px;
`;
