import React from 'react';
import Analytics from '../../../lib/user-analytics';
import { SitemapLink } from './footer';

const ResourcesLinks = () => {
    const onClickBlog = () => {
        Analytics.Event('LandingPage', 'Clicked footer blog');
        window.open('https://about.soar.earth/blog.html', '_blank');
    };

    const onClickTutorials = () => {
        Analytics.Event('LandingPage', 'Clicked footer tutorials');
        window.open('https://about.soar.earth/tutorial-videos.html', '_blank');
    };

    const onClickLearnMore = () => {
        Analytics.Event('LandingPage', 'Clicked footer learn more');
        window.open('https://about.soar.earth/soar.html', '_blank');
    };

    const onClickHelp = () => {
        Analytics.Event('LandingPage', 'Clicked footer help');
        window.open('https://about.soar.earth/help.html', '_blank');
    };

    return (
        <React.Fragment>
            <SitemapLink title="Blog" onClick={onClickBlog}>
                Blog
            </SitemapLink>
            <SitemapLink title="Tutorials" onClick={onClickTutorials}>
                Tutorials
            </SitemapLink>
            <SitemapLink title="Learn more" onClick={onClickLearnMore}>
                Learn more
            </SitemapLink>
            <SitemapLink title="Help" onClick={onClickHelp}>
                Help
            </SitemapLink>
        </React.Fragment>
    );
};

export default ResourcesLinks;
