import { LatLng, LatLngBounds } from 'leaflet';
import GeoUtil from '../lib/geo-util';
import Api from './api';
import ApiListings from './api-listings';
import { ListingDTO } from './model';

export interface GeolocationDTO {
    city: string;
    regionName: string;
    country: string;
    boundingBox: LatLngBounds;
}

export interface NearbyListingsDTO {
    location: GeolocationDTO;
    listings: ListingDTO[];
}

const BOUNDS_EXTENTS_IN_METERS = 1000;

export default class ApiGeolocation extends Api {
    public static getGeolocation(): Promise<GeolocationDTO> {
        return fetch('http://ip-api.com/json')
            .then((res) => {
                return res.json();
            })
            .then((json) => {
                const lat = json.lat;
                const lon = json.lon;
                const latLng = new LatLng(lat, lon);
                const bounds = latLng.toBounds(BOUNDS_EXTENTS_IN_METERS);

                const geolocation: GeolocationDTO = {
                    city: json.city,
                    regionName: json.regionName,
                    country: json.country,
                    boundingBox: bounds,
                };

                return geolocation;
            });
    }

    public static getListingsNearMe(): Promise<NearbyListingsDTO> {
        return this.getGeolocation().then((location) => {
            const locationWkt = GeoUtil.latLngBoundsToWKT(location.boundingBox);
            const nearbyListings = ApiListings.getListings({ aoi: locationWkt, limit: 100 })
                .then((listings) => {
                    return {
                        listings: listings,
                        location: location,
                    };
                })
                .then((res) => {
                    // Sort by closest bounding box area
                    // TODO: shift this logic to the backend to reduce limit of network call
                    const sorted = res.listings.sort((a, b) => {
                        const nearMeArea = GeoUtil.quickArea(res.location.boundingBox);
                        const areaA = GeoUtil.quickArea(a.boundingBox);
                        const areaB = GeoUtil.quickArea(b.boundingBox);
                        return areaA / nearMeArea - areaB / nearMeArea;
                    });

                    return {
                        listings: sorted,
                        location: res.location,
                    };
                });

            nearbyListings;

            return nearbyListings;
        });
    }
}
