import React from 'react';
import { useState } from 'react';
import styled, { keyframes } from 'styled-components';

interface PlusDetailsSectionProps {
    title: string;
    text: string;
    img: string;
    lightboxImage: string;
    imageReverse: boolean;
}

export const PlusDetailsSection = ({ title, text, img, lightboxImage, imageReverse }: PlusDetailsSectionProps) => {
    const [openLightBox, setOpenLightBox] = useState<boolean>(false);

    return (
        <React.Fragment>
            <PlusDetailsSectionContainer imageReverse={imageReverse}>
                <PlusDetailsSectionContent>
                    <h1>{title}</h1>
                    <Divider src="/assets/plus-images/divider.svg" loading="lazy" alt="divider" />
                    <p>{text}</p>
                </PlusDetailsSectionContent>
                <PlusDetailsSectionImage loading="lazy" src={img} onClick={() => setOpenLightBox(!openLightBox)} />
            </PlusDetailsSectionContainer>
            {openLightBox && (
                <PlusDetailsLightbox onClick={() => setOpenLightBox(!openLightBox)}>
                    <PlusDetailsLightboxCloseIcon
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMTggMTciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMCAwaDd2LTdoNXY3aDd2NWgtN3Y3aC01di03aC03eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDd2LTdoM3Y3aDd2M2gtN3Y3aC0zdi03aC03eiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4="
                        onClick={() => setOpenLightBox(!openLightBox)}
                    />
                    <PlusDetailsLightboxImage loading="lazy" src={lightboxImage} />
                </PlusDetailsLightbox>
            )}
        </React.Fragment>
    );
};

interface PlusDetailsSectionContainerProps {
    imageReverse: boolean;
}

const PlusDetailsSectionContainer = styled.div<PlusDetailsSectionContainerProps>`
    display: flex;
    flex-direction: ${(props) => (props.imageReverse ? 'row-reverse' : 'row')};

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

const PlusDetailsSectionContent = styled.div`
    width: 50%;
    color: white;
    margin-block: auto;
    //padding: 50px;

    @media only screen and (max-width: 600px) {
        width: 100%;
        text-align: center;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 0px;
    }

    h1 {
        text-transform: uppercase;
        color: inherit;
        font-weight: 700;
        text-align: left;
        font-size: 30px;
        max-width: 772px;

        @media only screen and (max-width: 600px) {
            text-align: center;
        }
    }

    p {
        color: inherit;
        font-size: 15px;
        max-width: 95%;

        @media only screen and (max-width: 600px) {
            max-width: 100%;
        }
    }
`;

const PlusDetailsSectionImage = styled.img`
    width: 50%;
    cursor: pointer;
    padding: 35px;

    @media only screen and (max-width: 600px) {
        width: 100%;
        padding: 0px 10px;
    }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const PlusDetailsLightbox = styled.div`
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    animation: ${fadeIn} 400ms linear;
`;

const PlusDetailsLightboxImage = styled.img`
    padding: 10px 120px;
    width: 100%;

    @media only screen and (max-width: 600px) {
        padding: 0px 0px;
        margin-top: 50%;
    }
`;

const PlusDetailsLightboxCloseIcon = styled.img`
    position: fixed;
    right: 25px;
    top: 20px;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
        position: absolute;
        right: 20px;
    }
`;

const Divider = styled.img`
    margin-top: 15px;
    margin-bottom: 20px;
`;
