import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Category, ListingCategories } from '../../../../api/model';
import { actionSetSelectedCategory } from '../../../../store/Map/MapSelection/actions';
import { selectSelectedCategory } from '../../../../store/Map/MapSelection/selectors';
import MobileSearchResultCarousel from '../Carousel/mobile-search-result-carousel';
import { MobileFilterItem } from './mobile-search-results';
import Analytics from '../../../../lib/user-analytics';

const MobileCategoryFilter = () => {
    const dispatch = useDispatch();
    const selectedCategory = useSelector(selectSelectedCategory);
    const setSelectedCategory = (category: Category | undefined) => dispatch(actionSetSelectedCategory(category));

    return (
        <MobileSearchResultCarousel padding={'0px 0px 5px 0px'}>
            {Object.values(ListingCategories).map((category) => {
                const selectedFilter = selectedCategory?.title === category.title;
                const handleCategoryFilter = () => {
                    selectedCategory ? setSelectedCategory(undefined) : setSelectedCategory(category)
                    Analytics.Event('Mobile - Search Results Category Filter', 'Clicked Category Filter');
                }
                
                return (
                    <MobileFilterItem
                        onClick={handleCategoryFilter}
                        key={category.title}
                        backgroundColor={selectedFilter ? 'rgba(0, 162, 255, 0.3)' : ''}
                    >
                        <React.Fragment>{category.title}</React.Fragment>
                    </MobileFilterItem>
                );
            })}
        </MobileSearchResultCarousel>
    );
};

export default MobileCategoryFilter;
