import { useEffect } from 'react';
import { LayersControl, TileLayer as LeafletTileLayer } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import Constants from '../../constants';
import UriHelper from '../../lib/uri-helper';
import { selectBasemap } from '../../store/Map/Basemap/selector';
import { BasemapKey, basemapKeys } from '../../store/Map/Basemap/model';
import { actionSetBasemap } from '../../store/Map/Basemap/actions';
import { useLocation } from 'react-router';
import Analytics from '../../lib/user-analytics';

const GOOGLE_STREET_URL = Constants.MAPS.GOOGLE_STREET;
const GOOGLE_HYBRID_URL = Constants.MAPS.GOOGLE_HYBRID;
const GOOGLE_SATELLITE_URL = Constants.MAPS.GOOGLE_SATELLITE;
const OSM_STREET_URL = Constants.MAPS.OSM_STREET;
const OPEN_TOPO_URL = Constants.MAPS.OSM_TOPO;

const GOOGLE_ATTRIBUTION = Constants.MAPS_ATTRIBUTION.GOOGLE_ATTRIBUTION;
const OSM_TOPO_ATTRIBUTION = Constants.MAPS_ATTRIBUTION.OSM_TOPO_ATTRIBUTION;
const OSM_ATTRIBUTION = Constants.MAPS_ATTRIBUTION.OSM_ATTRIBUTION;

interface BasemapProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mapRef: any;
    setBasemap?: boolean;
}

const Basemaps = (props: BasemapProps) => {
    const selectedBasemap = useSelector(selectBasemap);
    const url = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const urlSelectedBaseMap = params.get(UriHelper.BASEMAP_URI_KEY);

        const isBasemapKey = (key: string): key is BasemapKey => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return basemapKeys.indexOf(key as any) !== -1;
        };

        if (urlSelectedBaseMap && isBasemapKey(decodeURI(urlSelectedBaseMap))) {
            dispatch(actionSetBasemap(decodeURI(urlSelectedBaseMap) as BasemapKey));
        }
    }, [dispatch]);

    useEffect(() => {
        if (props.mapRef) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            props.mapRef.leafletElement.on('baselayerchange', (e: any) => {
                dispatch(actionSetBasemap(e.name));
            });
        }
    }, [props.mapRef, dispatch]);

    //add basemap parameter for every url change, only if the selected base is not the default 'Google Hybrid'
    useEffect(() => {
        if (selectedBasemap !== 'Google Hybrid') {
            UriHelper.addParameterToUri(UriHelper.BASEMAP_URI_KEY, selectedBasemap);
            Analytics.Event('Main View', 'Clicked to change basemap', selectedBasemap);
        }
        // If the selected basemap is 'Google Hybrid' remove the basemap param so the default basemap
        // displays when generating annotation/share links
        if (selectedBasemap === 'Google Hybrid') {
            UriHelper.removeParameterFromUri('basemap');
        }
    }, [url, selectedBasemap]);


    // maxZoom: set to the same as the maximum zoom level available on the map in map-view.tsx
    // maxNativeZoom: set to the maximum zoom level served from the tilelayer provider set at the most consistent coverage level
    if (props.setBasemap) {
        return <LeafletTileLayer maxZoom={28} maxNativeZoom={19} url={OSM_STREET_URL} attribution={OSM_ATTRIBUTION} />;
    }
    return (
        <LayersControl className="basemap-control" position="bottomright">
            <LayersControl.BaseLayer name="Open Elevation" checked={selectedBasemap === 'Open Elevation'}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={17} url={OPEN_TOPO_URL} attribution={OSM_TOPO_ATTRIBUTION} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Google Satellite" checked={selectedBasemap === 'Google Satellite'}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={20} url={GOOGLE_SATELLITE_URL} attribution={GOOGLE_ATTRIBUTION} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Google Hybrid" checked={selectedBasemap === 'Google Hybrid'}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={20} url={GOOGLE_HYBRID_URL} attribution={GOOGLE_ATTRIBUTION} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Google Street" checked={selectedBasemap === 'Google Street'}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={20} url={GOOGLE_STREET_URL} attribution={GOOGLE_ATTRIBUTION} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="OpenStreetMaps" checked={selectedBasemap === 'OpenStreetMaps'}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={19} url={OSM_STREET_URL} attribution={OSM_ATTRIBUTION} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Dark Blank" checked={selectedBasemap === 'Dark Blank'}>
                <LeafletTileLayer url="/assets/basemap/black-pixel.png" />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="White Blank" checked={selectedBasemap === 'White Blank'}>
                <LeafletTileLayer url="/assets/basemap/white-pixel.png" />
            </LayersControl.BaseLayer>
        </LayersControl>
    );
};

export default Basemaps;
