export const actionTypes = {
    ADD_DRONE_IMAGE_FOR_UPLOAD: 'UPLOAD/ADD_DRONE_IMAGE_FOR_UPLOAD',
    CLEAR_DRONE_IMAGE_FOR_UPLOAD: 'UPLOAD/CLEAR_DRONE_IMAGE_FOR_UPLOAD',
    SET_DRONE_IMAGE_DISTORTION_TOOL: 'UPLOAD/SET_DRONE_IMAGE_DISTORTION_TOOL',
    SET_DRONE_IMAGE_OPACITY: 'UPLOAD/SET_DRONE_IMAGE_OPACITY',
    SET_DRONE_IMAGE_DISTORTION_CORNERS: 'UPLOAD/SET_DRONE_IMAGE_DISTORTION_CORNERS',
    SET_RECTIFIED_MAP: 'UPLOAD/SET_RECTIFIED_MAP',
    SET_CANDIDATE_FILE: 'UPLOAD/SET_CANDIDATE_FILE',
    SET_DATASET_DETAILS: 'UPLOAD/SET_DATASET_DETAILS',
    SET_IMAGE_BOUNDS: 'UPLOAD/SET_IMAGE_BOUNDS',
    SET_IMAGE_MANUAL_POSITION: 'UPLOAD/SET_IMAGE_MANUAL_POSITION',
    SET_CUSTOM_AOI_DISPLAY: 'UPLOAD/SET_CUSTOM_AOI_DISPLAY',
    SET_MOUSE_ACTIVE: 'UPLOAD/SET_MOUSE_ACTIVE',
};
