import React from 'react';
import Analytics from '../../../lib/user-analytics';
import UriHelper from '../../../lib/uri-helper';
import { SitemapLink } from './footer';
import { SideDrawerMode } from '../../../store/SideDrawer/model';

const ProductLinks = () => {
    const onClickViewDigitalAtlas = () => {
        Analytics.Event('LandingPage', 'Clicked footer view digital atlas');
        UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
    };

    const onClickRegisterNow = () => {
        Analytics.Event('LandingPage', 'Clicked footer register now');
        UriHelper.navigateToPath('/register');
    };

    const onClickUploadImagery = () => {
        Analytics.Event('LandingPage', 'Clicked footer upload imagery');
        UriHelper.navigateToDrawer(SideDrawerMode.SHARE_MAP);
    };

    const onClickSoarPlus = () => {
        Analytics.Event('LandingPage', 'Clicked footer soar+');
        UriHelper.navigateToPath('/plus');
    };

    return (
        <React.Fragment>
            <SitemapLink title="View the digital atlas" onClick={onClickViewDigitalAtlas}>
                View the digital atlas
            </SitemapLink>
            <SitemapLink title="Register now" onClick={onClickRegisterNow}>
                Register now
            </SitemapLink>
            <SitemapLink title="Upload imagery" onClick={onClickUploadImagery}>
                Upload imagery
            </SitemapLink>
            <SitemapLink title="Soar+" onClick={onClickSoarPlus}>
                Soar+
            </SitemapLink>
        </React.Fragment>
    );
};

export default ProductLinks;
