import { ReactFragment, useRef, useState } from 'react';
import styled from 'styled-components';
import Analytics from '../../../../lib/user-analytics';

interface MobileSearchResultCarouselProps {
    children: ReactFragment;
    padding?: string;
    shadow?: boolean;
}

/**
 * Touch controlled carousel
 *
 * @param children - React.Fragment - Items to display in carousel
 * @param padding - string - apply padding to carousel
 * @param shadow - boolean - display box shadow
 * @returns Scrollable Carousel
 */
const MobileSearchResultCarousel = (props: MobileSearchResultCarouselProps) => {
    const carouselContainer = useRef<HTMLDivElement | null>(null);
    const [scrollLeft, setScrollLeft] = useState(0);

    const [isActive, setIsActive] = useState(false);
    const [startX, setStartX] = useState(0);

    return (
        <CarouselContainer
            ref={carouselContainer}
            onTouchStart={(e) => {
                e.stopPropagation();
                setIsActive(true);
                if (carouselContainer.current) {
                    setStartX(e.touches[0].clientX - carouselContainer.current.offsetLeft);
                    setScrollLeft(carouselContainer.current.scrollLeft);
                    Analytics.Event('Mobile - Search Carousel', 'Swiped Carousel');
                }
            }}
            onTouchEnd={(e) => {
                e.stopPropagation();
                setIsActive(false);
                Analytics.Event('Mobile - Search Carousel', 'Stop Swipe Carousel');
            }}
            onTouchMove={(e) => {
                e.stopPropagation();
                if (isActive && carouselContainer.current) {
                    const x = e.touches[0].pageX - carouselContainer.current.offsetLeft;
                    const walk = (x - startX) * 2;
                    carouselContainer.current.scrollLeft = scrollLeft - walk;
                }
            }}
            padding={props.padding}
            shadow={props.shadow}
        >
            {props.children}
        </CarouselContainer>
    );
};

export default MobileSearchResultCarousel;

interface CarouselContainerProps {
    padding?: string;
    shadow?: boolean;
}

const CarouselContainer = styled.div<CarouselContainerProps>`
    overflow: hidden;
    display: flex;
    flex-direction: row;
    scroll-behavior: auto;
    padding: ${(props) => (props.padding ? props.padding : '0px 0px 20px 0px')};
    box-shadow: ${(props) =>
        props.shadow
            ? `0px 16px 24px 2px hsla(0, 0%, 0%, 0.21), 0px 6px 30px 5px hsla(0, 0%, 0%, 0.21),
        0px 8px 10px -5px hsla(0, 0%, 0%, 0.3)`
            : 'none'};
`;
