import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink as RRNavLink, useLocation } from 'react-router-dom';
import {
    Nav as BootstrapNav,
    Navbar as BootstrapNavbar,
    NavbarBrand as BootstrapNavbarBrand,
    NavItem as BootstrapNavItem,
    NavLink as BootstrapNavLink,
} from 'reactstrap';
import styled from 'styled-components';
import SoarHelper from '../../lib/soar-helper';
import UriHelper from '../../lib/uri-helper';
import Analytics from '../../lib/user-analytics';
import UserHelper from '../../lib/user-helper';
import { selectConfig } from '../../store/App/selectors';
import { actionGetNotifications } from '../../store/Notifications/actions';
import { selectMyProfile } from '../../store/Account/selectors';
import { StyledButton } from '../Shared/styled-button';
import { Notifications } from '../SocialMapping/Notifications/notifications';
import NavbarProfile from './navbar-profile';
import { NavbarSubdomainList } from './navbar-subdomain-list';
import { actionShowLoginDialog } from '../../store/App/actions';

const NavigationBar = () => {
    const config = useSelector(selectConfig);
    const myProfile = useSelector(selectMyProfile);

    const dispatch = useDispatch();
    const url = useLocation();

    // Check for new notifications
    const checkNotifications = () => {
        if (myProfile) {
            dispatch(actionGetNotifications());
        }
    };

    const onClickLogo = () => {
        Analytics.Event('Navigation', 'Clicked Logo');
        checkNotifications();
        UriHelper.navigateToPath('/');
    };

    const onClickMaps = () => {
        Analytics.Event('Navigation', 'Clicked Maps');
        checkNotifications();
    };

    const onClickLogin = () => {
        Analytics.Event('Navigation', 'Clicked Login');
        dispatch(actionShowLoginDialog(true));
    };

    const onClickSatellites = () => {
        Analytics.Event('Navigation', 'Clicked Satellites');
    };

    const onClickUpload = () => {
        Analytics.Event('Navigation', 'Clicked Upload');
    };

    const onClickMyMaps = () => {
        Analytics.Event('Navigation', 'Clicked My Maps');
    };

    const onClickBookmarks = () => {
        Analytics.Event('Navigation', 'Clicked Bookmarks');
    };

    const isSoar = SoarHelper.isSoar();
    const isMaps = url.pathname.includes('/maps');
    const isSatellite = url.pathname.includes('/satellites');
    const isUpload = url.pathname.includes('/upload');
    const isMyMaps = url.pathname.includes('/my-maps');
    const isBookmarks = url.pathname.includes('/my-bookmarks');

    const logoUrl = config.LOGO || '/assets/logos/soar_logo.png';

    return (
        <Navbar fixed="top">
            <SoarNavbarBrand onClick={onClickLogo}>
                {isSoar ? <img src={logoUrl} alt="Soar" /> : <SubdomainImg src={logoUrl} alt="Soar" />}
            </SoarNavbarBrand>
            {myProfile?.domains && <NavbarSubdomainList subdomains={myProfile.domains} />}

            <Nav className="ml-auto">
                <NavItem selected={isMaps}>
                    <NavLink onClick={onClickMaps} to="/maps" tag={RRNavLinkStyled} id="navbar-menu-maps">
                        Maps
                    </NavLink>
                </NavItem>
                <NavItem selected={isSatellite}>
                    <NavLink
                        onClick={onClickSatellites}
                        to="/satellites"
                        tag={RRNavLinkStyled}
                        id="navbar-menu-satellites"
                    >
                        Satellites
                    </NavLink>
                </NavItem>

                {myProfile && isSoar ? (
                    <React.Fragment>
                        <NavItem selected={isMyMaps}>
                            <NavLink
                                onClick={onClickMyMaps}
                                to="/my-maps"
                                tag={RRNavLinkStyled}
                                id="navbar-menu-my-maps"
                            >
                                Your Maps
                            </NavLink>
                        </NavItem>

                        <NavItem selected={isBookmarks}>
                            <NavLink
                                onClick={onClickBookmarks}
                                to="/my-bookmarks"
                                tag={RRNavLinkStyled}
                                id="navbar-menu-bookmarks"
                            >
                                Bookmarks
                            </NavLink>
                        </NavItem>
                    </React.Fragment>
                ) : null}

                {isSoar || UserHelper.hasWritePermissionsForSubdomain(myProfile) ? (
                    <NavItem selected={isUpload}>
                        <NavLink onClick={onClickUpload} to="/upload" tag={RRNavLinkStyled} id="navbar-menu-share">
                            <ShareButton>
                                <UploadIcon className="fa fa-plus" />
                                Add your maps
                            </ShareButton>
                        </NavLink>
                    </NavItem>
                ) : null}

                <NavItem>
                    <Notifications />
                </NavItem>

                {myProfile ? (
                    <NavbarProfile userProfile={myProfile} isSoar={isSoar} />
                ) : (
                    <SignInNavItem>
                        <NavLink onClick={onClickLogin} data-cy="Sign in">
                            Sign in
                        </NavLink>
                    </SignInNavItem>
                )}
            </Nav>
        </Navbar>
    );
};

export default NavigationBar;

interface NavItemProps {
    selected?: boolean;
}

export const Navbar = styled(BootstrapNavbar)`
    background: rgba(0, 0, 0, 0.8);
    height: 78px;
    margin-left: 0;
    z-index: 1501;
    color: white;
`;

export const Nav = styled(BootstrapNav)`
    margin-right: 12px;
`;

const NavItem = styled(BootstrapNavItem)<NavItemProps>`
    a {
        color: ${(props) => (props.selected ? '#EED926 !important' : 'white')};
    }

    &:hover {
        color: #eed926;
    }
`;

const SignInNavItem = styled(BootstrapNavItem)`
    color: white;

    &:hover {
        color: #eed926;
    }

    @media (max-width: 1400px) {
        margin-right: -20px;
    }
`;

interface NavLinkProps {
    to?: string;
}

const NavLink = styled(BootstrapNavLink)<NavLinkProps>`
    color: white !important;
    cursor: pointer;
    &:hover {
        color: #eed926 !important;
    }
`;

const RRNavLinkStyled = styled(RRNavLink)`
    color: white;
    text-align: center;
    &:hover {
        color: #eed926;
    }
`;

const ShareButton = styled(StyledButton)`
    margin: -6px 0 -6px 0;
    padding: 6px 12px;
`;

const SoarNavbarBrand = styled(BootstrapNavbarBrand)`
    cursor: pointer;
`;

const SubdomainImg = styled.img`
    width: 152px;
    height: 30px;
`;

const UploadIcon = styled.i`
    color: white;
    width: 17px;
    margin: -2px 4px 0 0;
`;
