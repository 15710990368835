import React, { useEffect, useState } from 'react';
import { DivIcon, LatLng } from 'leaflet';
import { Marker, Polygon, GeoJSON } from 'react-leaflet';
import GeoUtil from '../../../../lib/geo-util';
import ListingHelper from '../../../../lib/listing-helper';
import { ListingGroup } from '../../../../store/Map/MapSelection/model';
import MultiPreviewTooltip from './multi-preview-tooltip';
import MultiSelectModal from './multi-select-modal';
import PreviewTooltip from './preview-tooltip';

interface HighlightedPolygonClusterProps {
    leafletMap: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    highlightedListingGroup: ListingGroup;
    onSelectListing: (listingId: number) => void;
    onNextMousePosition: (mousePosition: LatLng) => void;
}

const HighlightedPolygonCluster = (props: HighlightedPolygonClusterProps) => {
    const [showMultiSelectModal, setShowMultiSelectModal] = useState(false);
    const [renderAsMarker, setRenderAsMarker] = useState(false);

    // Calculate the approximate size of the highlighted cluster.
    // If it's too small then render a marker instead
    useEffect(() => {
        setRenderAsMarker(false);
        if (props.highlightedListingGroup.tileLayers.length > 0) {
            const map = props.leafletMap.leafletElement;
            const positions = GeoUtil.polygonForBounds(props.highlightedListingGroup.latlngBounds);
            const projectedTopLeft = map.project(positions[0], map.getZoom());
            const projectedBottomRight = map.project(positions[2], map.getZoom());
            const difference = projectedTopLeft.subtract(projectedBottomRight);

            if (Math.abs(difference.x) < 5 || Math.abs(difference.y) < 5) {
                setRenderAsMarker(true);
            }
        }

        return () => {
            setRenderAsMarker(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.highlightedListingGroup]);

    if (props.highlightedListingGroup.tileLayers.length === 0) return <React.Fragment />;

    if (renderAsMarker) {
        const highlightedDotMarkerIcon = new DivIcon({
            iconSize: [12.0, 12.0],
            iconAnchor: [6.0, 6.0],
            className: 'leaflet-cluster-marker-highlighted-dot',
        });

        return (
            <Marker position={props.highlightedListingGroup.latlngBounds.getCenter()} icon={highlightedDotMarkerIcon} />
        );
    }

    return (
        <React.Fragment>
            {showMultiSelectModal ? (
                <MultiSelectModal
                    toggle={() => setShowMultiSelectModal(false)}
                    tileLayerGroup={props.highlightedListingGroup}
                    onSelectTileLayer={(id) => props.onSelectListing(id)}
                />
            ) : null}

            {props.highlightedListingGroup.continent ? (
                <GeoJSON
                    data={props.highlightedListingGroup.continent.geoJSON}
                    weight={1}
                    color="rgba(255, 255, 255, 0.4)"
                    fillColor="transparent"
                    opacity={1}
                    interactive={true}
                    onEachFeature={(feature, layer) => {
                        layer.on('mouseover', (e) => {
                            e.target.setStyle({
                                fillColor: '#eed923',
                                color: '#eed923',
                                weight: 1,
                            });
                        });
                        layer.on('mouseout', (e) => {
                            e.target.setStyle({
                                fillColor: 'transparent',
                                color: 'rgba(255, 255, 255, 0.4)',
                                weight: 1,
                            });
                        });
                        layer.on('click', (_) => {
                            if (props.highlightedListingGroup.tileLayers.length === 1) {
                                props.onSelectListing(props.highlightedListingGroup.tileLayers[0].id);
                            } else {
                                setShowMultiSelectModal(true);
                            }
                        });
                    }}
                >
                    <MultiPreviewTooltip
                        key={props.highlightedListingGroup.tileLayers[0].id}
                        previewUrl={ListingHelper.getPreviewUrlForListing(
                            props.highlightedListingGroup.tileLayers[0].id
                        )}
                        title={props.highlightedListingGroup.continent.name}
                        subText={`Click to explore the ${props.highlightedListingGroup.tileLayers.length?.toLocaleString()} maps in ${
                            props.highlightedListingGroup.continent.name
                        }`}
                    />
                </GeoJSON>
            ) : (
                <Polygon
                    key={props.highlightedListingGroup.tileLayers[0].id}
                    positions={GeoUtil.polygonForBounds(props.highlightedListingGroup.latlngBounds)}
                    weight={1}
                    color="#eed309"
                    onmouseover={(e) => {
                        props.onNextMousePosition(e.latlng);
                    }}
                    onmouseout={(e) => {
                        props.onNextMousePosition(e.latlng);
                    }}
                    interactive={true}
                    onclick={() => {
                        if (props.highlightedListingGroup.tileLayers.length === 1) {
                            props.onSelectListing(props.highlightedListingGroup.tileLayers[0].id);
                        } else {
                            setShowMultiSelectModal(true);
                        }
                    }}
                >
                    {props.highlightedListingGroup.tileLayers &&
                    props.highlightedListingGroup.tileLayers.length === 1 ? (
                        <PreviewTooltip
                            key={props.highlightedListingGroup.tileLayers[0].id}
                            previewUrl={ListingHelper.getPreviewUrlForListing(
                                props.highlightedListingGroup.tileLayers[0].id
                            )}
                            title={props.highlightedListingGroup.tileLayers[0].title}
                            subText={`by ${props.highlightedListingGroup.tileLayers[0].authorName}`}
                        />
                    ) : null}

                    {props.highlightedListingGroup.tileLayers && props.highlightedListingGroup.tileLayers.length > 1 ? (
                        <MultiPreviewTooltip
                            key={props.highlightedListingGroup.tileLayers[0].id}
                            previewUrl={ListingHelper.getPreviewUrlForListing(
                                props.highlightedListingGroup.tileLayers[0].id
                            )}
                            title={props.highlightedListingGroup.tileLayers[0].title}
                            subText={`and ${props.highlightedListingGroup.tileLayers.length - 1} other map${
                                props.highlightedListingGroup.tileLayers.length > 2 ? 's' : ''
                            }`}
                        />
                    ) : null}
                </Polygon>
            )}
        </React.Fragment>
    );
};

export default HighlightedPolygonCluster;
