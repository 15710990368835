import { actionTypes as at } from './constants';
import { DistortionToolMode, RectifiedMap } from '../../../api/model';
import { DatasetDetails } from '../../../components/Upload/Model/model';
import { LatLng } from 'react-leaflet';
import { LatLngBounds } from 'leaflet';

export const actionAddDroneImageForUpload = (previewUrl: string, positions?: number[][]) => {
    return {
        type: at.ADD_DRONE_IMAGE_FOR_UPLOAD,
        payload: {
            droneImagePreviewUrl: previewUrl,
            droneImagePositions: positions,
        },
    };
};
export const actionSetRectifiedMapAction = (rectifiedMap?: RectifiedMap) => {
    return {
        type: at.SET_RECTIFIED_MAP,
        payload: rectifiedMap,
    };
};
export const actionSetCandidateFileAction = (file: File | undefined) => {
    return {
        type: at.SET_CANDIDATE_FILE,
        payload: file,
    };
};

export const actionClearDroneImageUpload = () => {
    return {
        type: at.CLEAR_DRONE_IMAGE_FOR_UPLOAD,
    };
};

export const actionSetDroneImageDistortionTool = (mode: DistortionToolMode | string) => {
    return {
        type: at.SET_DRONE_IMAGE_DISTORTION_TOOL,
        payload: mode,
    };
};

export const actionSetDroneImageDistortionOpacity = (opacity: number) => {
    return {
        type: at.SET_DRONE_IMAGE_OPACITY,
        payload: opacity,
    };
};

export const actionSetImageManualPosition = (position: LatLng | undefined) => {
    return {
        type: at.SET_IMAGE_MANUAL_POSITION,
        payload: position,
    };
};

export const actionSetDatasetDetails = (datasetDetails: DatasetDetails | undefined) => {
    return {
        type: at.SET_DATASET_DETAILS,
        payload: datasetDetails,
    };
};

export const actionSetImageBounds = (imageBounds: LatLng[] | undefined) => {
    return {
        type: at.SET_IMAGE_BOUNDS,
        payload: imageBounds,
    };
};

export const actionSetCustomAOIDisplay = (bbox?: LatLngBounds) => {
    return {
        type: at.SET_CUSTOM_AOI_DISPLAY,
        payload: bbox,
    };
};

export const actionSetMouseActive = (active: boolean) => {
    return {
        type: at.SET_MOUSE_ACTIVE,
        payload: active,
    };
};

export const actionSetDroneImageDistortionCorners = (corners: number[][]) => {
    return {
        type: at.SET_DRONE_IMAGE_DISTORTION_CORNERS,
        payload: corners,
    };
};
