import React from 'react';
import { Marker } from 'react-leaflet';
import { LatLng } from 'leaflet';
import { useDispatch } from 'react-redux';
import { actionSetMouseActive } from '../../../store/Map/Uploads/actions';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const L = require('leaflet');

interface UploadMarkerProps {
    index: number;
    updateMarkerPosition?: (newPosition: LatLng, index: number) => void;
    gcp: LatLng[];
    active?: boolean | undefined;
}

const MARKER_SIZE = 24;
// A timer on the mouse activity is stop the GCPHandler placing a new marker on mouse up
const MARKER_INACTIVE_TIME = 300;

export const UploadMarker = (props: UploadMarkerProps): JSX.Element => {
    const dispatch = useDispatch();
    return (
        <Marker
            // Disabled dragging for now as cleaning up code caused a bug....
            // draggable
            ondragend={() => {
                // TODO the change to the gcp has caused this to crash the app and the objetc needs extending.
                // Disabling for now so it is in working order
                //const updatedLatLng = new LatLng(e.target.getLatLng().lat, e.target.getLatLng().lng);
                //props.updateMarkerPosition && props.updateMarkerPosition(updatedLatLng, props.index);
                setTimeout(() => {
                    dispatch(actionSetMouseActive(false));
                }, MARKER_INACTIVE_TIME);
            }}
            onmouseout={() => {
                setTimeout(() => {
                    dispatch(actionSetMouseActive(false));
                }, MARKER_INACTIVE_TIME);
            }}
            onmouseover={() => {
                dispatch(actionSetMouseActive(true));
            }}
            position={new LatLng(props.gcp[3].lat, props.gcp[3].lng)}
            icon={L.divIcon({
                html: `${props.index + 1}`,
                iconAnchor: L.point(MARKER_SIZE / 2, MARKER_SIZE / 2),
                className: `marker-target ${props.active ? 'active' : ''}`,
            })}
        />
    );
};
