import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import UriHelper from '../../../lib/uri-helper';
import { useMobileCanSearchForSatelliteImagery } from '../../../lib/use-mobile-can-search-for-sattelite-imagery';
import { selectBestFittingListingGroups } from '../../../store/Map/MapSelection/selectors';
import { selectActiveAllMap, selectActiveAllMapIsFetching } from '../../../store/Map/SuperMap/selectors';
import { SIDE_DRAWER_MOBILE_SATELLITE_MODES, SIDE_DRAWER_PROFILE_MODES } from '../../../store/SideDrawer/model';
import { selectSideDrawerMode } from '../../../store/SideDrawer/selectors';
import { StyledButton } from '../../Shared/styled-button';
import ActiveMapBottomSheet from '../MapSelection/active-map-bottom-sheet';
import BottomSheet, { BottomSheetState } from '../MapSelection/bottom-sheet';
import MobileSatelliteBottomSheetContainer from '../Satellite/mobile-satellite-bottom-sheet-container';
import MobileSatelliteSearchResult from '../Satellite/mobile-satellite-search-result';
import { MobileSegmentTitleIcon } from '../Search/Discovery/mobile-search-current-event';
import { MobileSearchResultTitle } from '../Search/Results/mobile-search-results';
import MobileBottomSheetContent from './mobile-bottom-sheet-content';
import MobileBottomSheetNoDataView from './mobile-bottom-sheet-no-content';
import MobileProfileSheet from '../Profile/mobile-profile-sheet';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import ActiveMapLoading from '../MapSelection/active-map-loading';

const MobileBottomSheet = () => {
    const bestFittingMapsInViewport = useSelector(selectBestFittingListingGroups).flatMap((t) => t.tileLayers);
    const activeMap = useSelector(selectActiveAllMap);
    const activeMapFetching = useSelector(selectActiveAllMapIsFetching);
    const currentSideDrawerMode = useSelector(selectSideDrawerMode);
    const [desiredBottomSheetState, setDesiredBottomSheetState] = useState<BottomSheetState | undefined>(undefined);
    const [currentBottomSheetState, setCurrentBottomSheetState] = useState<BottomSheetState | undefined>(undefined);
    const [isBottomSheetClosing, setIsBottomSheetClosing] = useState<boolean>(false);

    const searchForSatelliteImagery = useMobileCanSearchForSatelliteImagery();

    useEffect(() => {
        requestAnimationFrame(() => {
            setDesiredBottomSheetState(currentBottomSheetState);
        });
    }, [currentBottomSheetState]);

    const openMap = (id: number) => {
        UriHelper.navigateToPath(`/maps/${id}`);
        setDesiredBottomSheetState(BottomSheetState.close);
    };

    const swipeButtonHandler = useSwipeable({
        delta: 0,
        onSwiping: (e: SwipeEventData) => {
            if (e.dir === 'Up') {
                setDesiredBottomSheetState(BottomSheetState.mid);
            }
        },
    });

    if (SIDE_DRAWER_PROFILE_MODES.includes(currentSideDrawerMode)) {
        return (
            <BottomSheet
                setOpenState={desiredBottomSheetState}
                onOpenStateChanged={setCurrentBottomSheetState}
                handleCloseButton={() => setIsBottomSheetClosing(!isBottomSheetClosing)}
            >
                <MobileProfileSheet
                    openState={desiredBottomSheetState ?? BottomSheetState.close}
                    setOpenState={setDesiredBottomSheetState}
                    onClose={() => {
                        setDesiredBottomSheetState(BottomSheetState.close);
                        setIsBottomSheetClosing(false);
                    }}
                    isClosing={isBottomSheetClosing}
                />
            </BottomSheet>
        );
    }

    if (activeMapFetching) {
        return (
            <BottomSheet
                setOpenState={desiredBottomSheetState}
                onOpenStateChanged={(openState: BottomSheetState) => setCurrentBottomSheetState(openState)}
                handleCloseButton={() => setIsBottomSheetClosing(!isBottomSheetClosing)}
            >
                <ActiveMapLoading />
            </BottomSheet>
        );
    }

    if (activeMap) {
        return (
            <BottomSheet
                setOpenState={desiredBottomSheetState}
                onOpenStateChanged={setCurrentBottomSheetState}
                handleCloseButton={() => setIsBottomSheetClosing(!isBottomSheetClosing)}
            >
                <ActiveMapBottomSheet
                    bottomSheetState={currentBottomSheetState || BottomSheetState.close}
                    listing={activeMap}
                    onCloseMap={() => {
                        setDesiredBottomSheetState(BottomSheetState.close);
                        setIsBottomSheetClosing(false);
                    }}
                    isClosing={isBottomSheetClosing}
                />
            </BottomSheet>
        );
    }

    if (SIDE_DRAWER_MOBILE_SATELLITE_MODES.includes(currentSideDrawerMode)) {
        return (
            <BottomSheet
                setOpenState={desiredBottomSheetState}
                onOpenStateChanged={setCurrentBottomSheetState}
                handleCloseButton={() => setIsBottomSheetClosing(!isBottomSheetClosing)}
            >
                <MobileSatelliteSearchResult
                    bottomSheetState={currentBottomSheetState || BottomSheetState.close}
                    onCloseMap={() => {
                        setDesiredBottomSheetState(BottomSheetState.close);
                        setIsBottomSheetClosing(false);
                    }}
                    isClosing={isBottomSheetClosing}
                />
            </BottomSheet>
        );
    }

    return (
        <BottomSheet setOpenState={desiredBottomSheetState} onOpenStateChanged={setCurrentBottomSheetState}>
            {currentBottomSheetState === BottomSheetState.close ? (
                <ButtonContainer>
                    <Button onClick={() => setDesiredBottomSheetState(BottomSheetState.open)} {...swipeButtonHandler}>
                        {bestFittingMapsInViewport && bestFittingMapsInViewport.length > 0 ? (
                            <>
                                Explore{' '}
                                <span>
                                    {bestFittingMapsInViewport.length} map
                                    {bestFittingMapsInViewport.length !== 1 ? 's' : ''}
                                </span>{' '}
                                in this area
                            </>
                        ) : searchForSatelliteImagery ? (
                            <> Search Satellite Imagery </>
                        ) : (
                            <>No maps in this area</>
                        )}
                    </Button>
                </ButtonContainer>
            ) : (
                <React.Fragment>
                    {bestFittingMapsInViewport?.length === 0 && !searchForSatelliteImagery ? (
                        <MobileBottomSheetNoDataView currentBottomSheetState={currentBottomSheetState} />
                    ) : (
                        <React.Fragment>
                            {bestFittingMapsInViewport.length > 0 ? (
                                <MobileSearchResultTitle>
                                    <MobileSegmentTitleIcon src="/assets/search-content/emoji-current-events.svg" />
                                    Explore maps <span>in this area</span>
                                </MobileSearchResultTitle>
                            ) : null}

                            <MobileBottomSheetContent
                                listings={bestFittingMapsInViewport}
                                onSelectListing={(id) => openMap(id)}
                            />
                            <MobileSatelliteBottomSheetContainer
                                isMapsInView={bestFittingMapsInViewport?.length ? true : false}
                                currentBottomSheetState={currentBottomSheetState}
                                handleDesiredBottomSheetState={setDesiredBottomSheetState}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </BottomSheet>
    );
};

export default MobileBottomSheet;

const ButtonContainer = styled.div`
    height: 100px;
    margin-top: 19px;
    span {
        font-weight: bolder;
    }
`;

const Button = styled(StyledButton)`
    margin: 0 auto;
    pointer-events: auto;
`;
