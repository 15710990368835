import React from 'react';
import { selectSideDrawerMode } from '../../../store/SideDrawer/selectors';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { useDispatch, useSelector } from 'react-redux';
import AOISelect from '../aoi-select';
import Constants from '../../../constants';
import { LatLngBounds } from 'leaflet';
import { actionSatelliteSelectAOI, actionSatelliteResetAOI } from '../../../store/Map/SatelliteArchive/actions';
import {
    selectIsSatelliteBoxSelectActive,
    selectSatelliteHighlightedFeature,
} from '../../../store/Map/SatelliteArchive/selectors';
import GeoUtil from '../../../lib/geo-util';
import { Polygon } from 'react-leaflet';
import SatelliteFeatures from './satellite-features';

const SatelliteProviders = () => {
    const dispatch = useDispatch();
    const selectAOI = (aoi: LatLngBounds) => dispatch(actionSatelliteSelectAOI(aoi));
    const resetAOI = () => dispatch(actionSatelliteResetAOI());

    const sideDrawerMode = useSelector(selectSideDrawerMode);
    const isArchiveBoxSelectActive = useSelector(selectIsSatelliteBoxSelectActive);
    const highlightedFeature = useSelector(selectSatelliteHighlightedFeature);

    const getAOIParameters = () => {
        switch (sideDrawerMode) {
            case SideDrawerMode.SATELLITE_SKYMAP50:
            case SideDrawerMode.SATELLITE_SKYMAP50_SEARCH_ARCHIVE:
                return Constants.SKYMAP50_PARAMETERS.ARCHIVAL;
            case SideDrawerMode.SATELLITE_SKYMAP50_IMAGE_THE_FUTURE:
                return Constants.SKYMAP50_PARAMETERS.NEW_COLLECT;

            case SideDrawerMode.SATELLITE_CGSTL_ARCHIVE_50:
            case SideDrawerMode.SATELLITE_CGSTL_ARCHIVE_75:
            case SideDrawerMode.SATELLITE_CGSTL_ARCHIVE_100:
            case SideDrawerMode.SATELLITE_CGSTL_ARCHIVE_NIGHT:
            case SideDrawerMode.SATELLITE_CGSTL_NEW_COLLECT:
                return Constants.CGSTL_PARAMETERS.ARCHIVAL;
            default:
                return undefined;
        }
    };

    const aoiParameters = getAOIParameters();

    if (!isArchiveBoxSelectActive) {
        return <React.Fragment />;
    }
    if (!aoiParameters) {
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            <AOISelect
                isActive={true}
                onAOISelected={(aoi) => {
                    selectAOI(aoi);
                }}
                onAOIReset={() => {
                    resetAOI();
                }}
                aoiParameters={aoiParameters}
            />

            <SatelliteFeatures />

            {highlightedFeature ? (
                <Polygon
                    positions={GeoUtil.polygonFromPolygonWKT(highlightedFeature.geometryWKT)}
                    weight={1}
                    interactive={false}
                    key="overlayFilledPolygon"
                />
            ) : null}
        </React.Fragment>
    );
};

export default SatelliteProviders;
