import { LatLng, LatLngBounds } from 'leaflet';
import Api from '../api/api';
import Constants from '../constants';
import Geocoder from '../lib/geocoder-service';
import {
    pixelResolutionForBounds,
    downloadUrlForFeature,
    fullResolutionUrlForFeature,
    previewUrlForFeature,
} from '../lib/sentinel-service';
import GeoUtil from './geo-util';
import { SentinelFeature } from '../store/Map/Sentinel/model';
import ApiShort from '../api/api-short';
import UriHelper from './uri-helper';
import { CreatedShortUrlDTO } from '../api/model';

const BASE_URL = `https://${Api.getSubdomain()}`

export default class ShareService {



    public static sentinelFeatureFromUri(uri: string): SentinelFeature | undefined {
        const url = ShareService.parseQueryString(uri);
        const layer = url['layers'] || url['layerKey'];
        if (url['bbox'] === undefined || url['time'] === undefined || layer === undefined) {
            return undefined;
        }

        //Backward compatibility to ensure old share links are not broken --> default to Sentinel 2
        let layerKey = url['layerKey'] ? url['layerKey'] : 'TRUE_COLOR';
        if (layerKey === 'TRUE_COLOR_PREVIEW') layerKey = 'TRUE_COLOR';

        const bb = url['bbox'].split(',');
        let bbox: LatLngBounds = new LatLngBounds(
            GeoUtil.EPSG3857ToLatLng(bb[0], bb[1]),
            GeoUtil.EPSG3857ToLatLng(bb[2], bb[3])
        );
        if (Math.abs(bbox.getSouthWest().lat) < 0.01 && Math.abs(bbox.getSouthWest().lng) < 0.01) {
            bbox = new LatLngBounds([
                [parseFloat(bb[1]), parseFloat(bb[0])],
                [parseFloat(bb[3]), parseFloat(bb[2])],
            ]);
        }

        const northEast = new LatLng(bbox.getNorth(), bbox.getEast());
        const southWest = new LatLng(bbox.getSouth(), bbox.getWest());
        const northEastXY = GeoUtil.latLngToEPSG3857(northEast);
        const southWestXY = GeoUtil.latLngToEPSG3857(southWest);
        const bboxString = northEastXY[0] + ',' + northEastXY[1] + ',' + southWestXY[0] + ',' + southWestXY[1];

        const resolution = pixelResolutionForBounds(bbox);

        const time = url['time'].split('/')[0];

        let organisation = '';
        Object.keys(Constants.OVERLAY_DATA).map((_organisation) => {
            if (Constants.OVERLAY_DATA[_organisation][layerKey]) {
                organisation = _organisation;
            }
        });
        const sentinelFeature: SentinelFeature = {
            id: 0,
            bbox: bbox,
            date: new Date(time),
            previewUrl: previewUrlForFeature(url['bbox'], url['time'], layer.split('_PREVIEW')[0]),
            highResolutionPreviewUrl: fullResolutionUrlForFeature(
                url['bbox'],
                url['time'],
                layer.split('_PREVIEW')[0],
                resolution
            ),
            downloadUrl: downloadUrlForFeature(bboxString, time, layerKey, resolution),
            layer: layer,
            layerKey: layerKey,
            satellite: Constants.OVERLAY_DATA[organisation][layerKey].satellite,
            satelliteAnalytics: Constants.OVERLAY_DATA[organisation][layerKey].satelliteAnalytics,
            resolution: resolution,
        };
        return sentinelFeature;
    }

    public static addParamsToUrl = (url, key) => {
        const param = UriHelper.tryGetParam(key);
        return param ? url.searchParams.set(key, param) : '';
    }

    public static shareMapUrl(mapId: number): string {
        const url = new URL(`${BASE_URL}/maps/${mapId}`);
        ShareService.addParamsToUrl(url, 'pos');
        ShareService.addParamsToUrl(url, 'basemap');
        return url.toString();
    }

    public static shareSentinelFeatureUrl(sentinelFeature: SentinelFeature): Promise<string> {
        return this.shareFeatureUrl(sentinelFeature.bbox, window.location.href, 'sentinel').then(dto => {
            return `${BASE_URL}/short/${dto.key}`;
        });
    }

    public static parseQueryString(query: string) {
        query = query.split('?')[1];
        if (!query) {
            return {};
        }
        const vars = query.split('&');
        const queryString = {};
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            const key = decodeURIComponent(pair[0]);
            const value = decodeURIComponent(pair[1]);
            // If first entry with this name
            if (typeof queryString[key] === 'undefined') {
                queryString[key] = decodeURIComponent(value);
                // If second entry with this name
            } else if (typeof queryString[key] === 'string') {
                const arr = [queryString[key], decodeURIComponent(value)];
                queryString[key] = arr;
                // If third or later entry with this name
            } else {
                queryString[key].push(decodeURIComponent(value));
            }
        }
        return queryString;
    }

    private static shareFeatureUrl(boundingBox: LatLngBounds, url: string, type: string): Promise<CreatedShortUrlDTO> {
        return new Promise<CreatedShortUrlDTO>((resolve, reject) => {
            const latLng = boundingBox.getCenter();

            if (isNaN(latLng.lat) || isNaN(latLng.lng)) {
                reject('Invalid Feature');
            }

            if (latLng.lat === 0 && latLng.lng === 0) {
                reject('Invalid Feature');
            }
            Geocoder.fromLatLng(latLng)
                .then((results) => {
                    if (!results[0]) reject('No results');

                    const address = results[0].address;
                    const miniUri = ApiShort.minify(url, type, address);
                    resolve(miniUri);
                })
                .catch((_) => {
                    resolve(ApiShort.minify(url, type));
                });
        });
    }
}
