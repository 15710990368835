import React, { useCallback, useEffect, useState } from 'react';
import { LatLng } from 'leaflet';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Geocoder, { GeocoderResult } from '../../../lib/geocoder-service';
import Analytics from '../../../lib/user-analytics';
import { actionFlyTo } from '../../../store/App/actions';
import { actionSetImageManualPosition } from '../../../store/Map/Uploads/actions';
import { selectImageManualPosition } from '../../../store/Map/Uploads/selectors';
import DrawerHint from '../../Drawer/drawer-hint';
import { StyledButton } from '../../Shared/styled-button';
import SoarAutocomplete from '../../Search-old/soar-autocomplete';
import UriHelper from '../../../lib/uri-helper';

// Level to zoom to when focusing on a few places probably worth consolidating this control to one
const FLYTO_ZOOM_LEVEL = 14;

interface UploadLocationSearchProps {
    setUploadWorkFlow: () => void;
}

const UploadLocationSearch = ({ setUploadWorkFlow }: UploadLocationSearchProps) => {
    const manualPosition = useSelector(selectImageManualPosition);

    const dispatch = useDispatch();
    const setImagePosition = useCallback(
        (position: LatLng | undefined) => dispatch(actionSetImageManualPosition(position)),
        [dispatch]
    );

    const [searchedLocation, setSearchedLocation] = useState<LatLng | undefined>(undefined);
    const [address, setAddress] = useState<string>();

    // TODO a bit of funk going on here that can be cleaned up
    useEffect(() => {
        if (!searchedLocation && manualPosition) {
            setSearchedLocation(manualPosition);
        }
        if (manualPosition && searchedLocation) {
            if (!searchedLocation.equals(manualPosition)) {
                Geocoder.fromLatLng(manualPosition).then((res: GeocoderResult[]) => {
                    if (res && res[0]) {
                        setAddress(res[0].address);
                    }
                });
            }
        }
    }, [manualPosition, searchedLocation]);

    useEffect(() => {
        const flyTo = (latlng: LatLng) => dispatch(actionFlyTo(latlng, FLYTO_ZOOM_LEVEL, true));
        if (!searchedLocation) {
            setImagePosition(undefined);
        } else {
            flyTo(searchedLocation);
        }
    }, [dispatch, searchedLocation, setImagePosition]);

    // NOTE USING OLD AUTOCOMPELTE
    return (
        <LocationContainer>
            <Title>Step 1: Choose rough location</Title>
            <Content>
                <DrawerHint error>This file does not have any position data that we could identify.</DrawerHint>
                <DrawerHint>
                    Enter an address below to find a location for the image,
                    <br />
                    or click the map to select the location.
                    <br />
                    You can drag the marker to fine tune the location.
                </DrawerHint>

                <LocationSearchContainer>
                    <SoarAutocomplete
                        autocompleteType={['ADDRESS']}
                        onLatlngSelected={(latlng: LatLng) => {
                            setSearchedLocation(latlng);
                            setImagePosition(latlng);
                        }}
                        placeholderAddress={address}
                    />
                </LocationSearchContainer>

                <UploadButtonControls>
                    <Button onClick={() => UriHelper.navigateToPath('/upload')}>
                        <BackIcon className="fa fa-chevron-left" /> Back
                    </Button>
                    <Button
                        disabled={!manualPosition}
                        onClick={() => {
                            setUploadWorkFlow();
                            setImagePosition(undefined);
                            Analytics.Event('Upload', `Clicked confirm position at location ${address}`);
                        }}
                    >
                        Confirm location
                    </Button>
                </UploadButtonControls>
            </Content>
        </LocationContainer>
    );
};

export default UploadLocationSearch;

const LocationContainer = styled.div`
    background-color: #222327;
    border: 4px solid gray;
    border-radius: 6px;
    height: 66vh;
    width: calc(50vw - 25px);
    margin: 90px 0px 0px 10px;
    padding: 20px;
`;

const Title = styled.h3`
    flex: 0 1 auto;
    margin-bottom: 1rem;
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 1.875rem;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 31.25rem;
    margin: 0 auto;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 0.5rem;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 0.25rem;
    }
`;

const LocationSearchContainer = styled.div`
    margin: 12px;
    width: 100%;

    .react-autosuggest__container {
        margin: 0px;
    }

    .react-autosuggest__suggestions-list {
        width: 94%;
        overflow-y: auto;
    }

    .react-autosuggest__input {
        width: 94% !important;
        border-color: white;
    }

    .react-autosuggest-search-icon {
        left: 8px;
    }

    .react-autosuggest__input--focused {
        outline: none;
    }

    .react-autosuggest-spinner {
        position: absolute;
        top: 0px;
        padding: 8px;
        right: 26px;
        color: #eed926;
    }

    .react-autosuggest-close-search {
        position: absolute;
        top: 0px;
        padding: 8px;
        right: 26px;
    }

    .react-autosuggest__suggestions-container--open {
        top: 29px;
        width: 94%;
        border-width: 0;
    }

    .react-autosuggest__suggestion {
        padding: 5px;
    }

    img.react-autosuggest-result-icon {
        width: 20px;
        margin-right: 3px;
    }
`;

const BackIcon = styled.i`
    font-size: 12px;
    margin-right: 5px;
`;

const Button = styled(StyledButton)`
    margin: 5px 0px;
    text-transform: none;
    min-width: 100px;
`;

const UploadButtonControls = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 6px 14px;
`;
