import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ApiListings, { GetListingsParams } from '../../../../api/api-listings';
import { AutocompleteDTO, ListingDTO } from '../../../../api/model';
import GeoUtil from '../../../../lib/geo-util';
import {
    selectSelectedCategory,
    selectSelectedContinent,
    selectSelectedSearchTerm,
} from '../../../../store/Map/MapSelection/selectors';
import SearchResultsBreadcrumbs from '../../../Search/search-results-breadcrumbs';
import SearchResultsEmptyView from '../../../Search/search-results-empty-view';
import MobileSearchResultsAutocomplete from './mobile-search-results-autocomplete';
import MobileSearchResultsMasonary from './mobile-search-results-masonary';

const LIMIT = 20;

interface SearchResultsProps {
    handleSelectAutocomplete?: (location: AutocompleteDTO) => void;
    autoCompleteResults?: AutocompleteDTO[];
    children?: ReactNode;
}

const MobileSearchResults = (props: SearchResultsProps) => {
    const selectedCategory = useSelector(selectSelectedCategory);
    const selectedContinent = useSelector(selectSelectedContinent);
    const selectedSearchTerm = useSelector(selectSelectedSearchTerm);

    const [coordinateAutocomplete, setCoordinateAutocomplete] = useState<AutocompleteDTO | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isNoItems, setIsNoItems] = useState(false);
    const [items, setItems] = useState<ListingDTO[]>([]);

    const fetchListings = (limit: number, offset: number) => {
        const searchParams: GetListingsParams = {
            aoi:
                selectedContinent && selectedContinent.continent
                    ? GeoUtil.latLngBoundsToWKT(selectedContinent.latlngBounds)
                    : undefined,
            category: selectedCategory ? selectedCategory.searchTerm : undefined,
            keywords: selectedSearchTerm ? selectedSearchTerm : undefined,
            offset: offset,
            limit: limit,
            orderBy: 'AREA',
        };
        setIsNoItems(false);
        ApiListings.cancelGetListings('Cancel search results changed');
        ApiListings.getListings(searchParams)
            .then((res) => {
                // Filter duplicates
                const newItems = res.filter((t) => !items.includes(t));

                // This controls the no maps notification
                if (!newItems?.length) {
                    setIsNoItems(true);
                } else {
                    setIsNoItems(false);
                }

                setItems((current) => [...current, ...newItems]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        setItems([]);
        fetchListings(LIMIT, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategory, selectedContinent, selectedSearchTerm]);

    useEffect(() => {
        if (selectedSearchTerm) {
            const coordinate = GeoUtil.getCoordinateFromSearchTerm(selectedSearchTerm);
            if (coordinate) {
                const autocomplete: AutocompleteDTO = {
                    title: selectedSearchTerm,
                    type: 'COORDINATE',
                    latlng: coordinate,
                };
                setCoordinateAutocomplete(autocomplete);
            } else {
                setCoordinateAutocomplete(undefined);
            }
        }
    }, [selectedSearchTerm]);

    const maybeLoadMore = () => {
        setIsLoading(true);
        fetchListings(items.length + LIMIT, items.length);
    };

    return (
        <React.Fragment>
            {props.autoCompleteResults && props.handleSelectAutocomplete && (
                <MobileSearchResultsAutocomplete
                    autocomplete={props.autoCompleteResults}
                    autocompleteCoordinate={coordinateAutocomplete}
                    handleSelectAutocomplete={props.handleSelectAutocomplete}
                />
            )}
            {selectedCategory || selectedContinent || selectedSearchTerm ? (
                <MobileBrowseResultsTitle>
                    Browsing results for <SearchResultsBreadcrumbs />
                </MobileBrowseResultsTitle>
            ) : null}

            {items && items.length > 0 ? (
                <MobileSearchResultsMasonary items={items} maybeLoadMore={maybeLoadMore} isNoItems={isNoItems}>
                    {props.children}
                </MobileSearchResultsMasonary>
            ) : (
                <React.Fragment>
                    {!isLoading && isNoItems ? (
                        <SearchResultsEmptyView
                            isLoading={isLoading}
                            continent={selectedContinent}
                            category={selectedCategory}
                            term={selectedSearchTerm}
                            noMaps={items.length === 0 && selectedSearchTerm?.length === 0}
                        />
                    ) : null}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default MobileSearchResults;

export const MobileSearchResultTitle = styled.div`
    font-size: 16px;
    color: white;
    margin: 10px 0px;

    span {
        color: #eed926;
    }
`;

const MobileBrowseResultsTitle = styled.div`
    font-size: 14px;
    color: white;
    padding: 6px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.075);
    box-shadow: 0px 16px 24px 2px hsla(0, 0%, 0%, 0.21), 0px 6px 30px 5px hsla(0, 0%, 0%, 0.21),
        0px 8px 10px -5px hsla(0, 0%, 0%, 0.3);

    span {
        color: #eed926;
    }
`;

interface MobileFilterItemProps {
    borderColor?: string;
    backgroundColor?: string;
    fontColor?: string;
}

export const MobileFilterItem = styled.span<MobileFilterItemProps>`
    border-radius: 6px;
    margin: 4px;
    padding: 2px 6px 4px 6px;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    border: 1px solid ${(props) => (props.borderColor ? props.borderColor : '#00a2ff')};
    background: ${(props) => (props.backgroundColor ? props.backgroundColor : '#0f0f0f96')};
    color: ${(props) => (props.fontColor ? props.fontColor : 'white')};
    display: block;
    min-width: fit-content;
`;
