import { RenderComponentProps } from 'masonic';
import { ComponentType } from 'react';
import styled from 'styled-components';
import ListingHelper from '../../lib/listing-helper';
import { SelectableListing } from './search-results-masonary';


const SearchResultsMasonaryCard: ComponentType<RenderComponentProps<SelectableListing>> = (props) => {

    return (
        <ExploreImageContainer>
            <ImageContainer href={`/maps/${props.data.id}`} onClick={(e) => e.preventDefault()}>

                <ImgContainer onClick={() => props.data.handleSelectListing(props.data.id)}>
                    <ExploreCardImage src={ListingHelper.getPreviewUrlForListing(props.data.id)} />
                </ImgContainer>

                <OverlaidDiv onClick={() => props.data.handleSelectListing(props.data.id)}>
                    <OverlayDetails>
                        <OverlayTitle>{props.data.title}</OverlayTitle>
                    </OverlayDetails>
                </OverlaidDiv>

            </ImageContainer>
        </ExploreImageContainer>
    );
};

export default SearchResultsMasonaryCard;


const ExploreCardImage = styled.img`
    cursor: pointer;
    border: 1px solid transparent;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    display: flex;
`;

const ExploreImageContainer = styled.div`
    min-height: 85px;
`;

const ImageContainer = styled.a`
    display: block;
    position: relative;
    cursor: pointer;
`;

const ImgContainer = styled.div`
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    position: relative;
    vertical-align: middle;
    padding: 0px;
    border-radius: 6px;
`;

const OverlaidDiv = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 6px;
    background: transparent;
    color: #ffffff;

    ${ImageContainer}:hover & {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        background: rgba(0, 0, 0, 0.7);
        -webkit-transition: all 0.2s 0s ease-in-out;
        -moz-transition: all 0.2s 0s ease-in-out;
        -o-transition: all 0.2s 0s ease-in-out;
        transition: all 0.2s 0s ease-in-out;
        border: 1px solid #eed923;
    }
`;


const OverlayDetails = styled.div`
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
`;

const OverlayTitle = styled.p`
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    margin-top: -8px;
    flex-wrap: wrap;
    color: #eed926;
    align-items: center;
    font-size: 1.3rem;
    padding: 0 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;
    flex-direction: row;
    justify-content: center;

    ${ImageContainer}:hover & {
        -webkit-transition: all 0.3s 0s ease-in-out;
        -moz-transition: all 0.3s 0s ease-in-out;
        -o-transition: all 0.3s 0s ease-in-out;
        transition: all 0.3s 0s ease-in-out;
        opacity: 1;
    }
`;