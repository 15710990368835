import styled from 'styled-components';
import { TutorialCarousel } from './tutorial-carousel';
import { TutorialCarouselItem } from './tutorial-item';
import { videoList } from './tutorial-options';

// To disable as a Carousel only use the same number of images as what is passed to the carousel
export const TutorialVideos = () => {
    return (
        <TutorialContainer>
            <TutorialCarousel show={3}>
                <TutorialCarouselItem videoId={videoList[4].videoId} altText={videoList[4].title} />
                <TutorialCarouselItem videoId={videoList[2].videoId} altText={videoList[2].title} />
                <TutorialCarouselItem videoId={videoList[0].videoId} altText={videoList[0].title} />
                <TutorialCarouselItem videoId={videoList[5].videoId} altText={videoList[5].title} />
                <TutorialCarouselItem videoId={videoList[1].videoId} altText={videoList[1].title} />
                <TutorialCarouselItem videoId={videoList[3].videoId} altText={videoList[3].title} />
                <TutorialCarouselItem videoId={videoList[6].videoId} altText={videoList[6].title} />
                <TutorialCarouselItem videoId={videoList[7].videoId} altText={videoList[7].title} />
            </TutorialCarousel>
        </TutorialContainer>
    );
};

const TutorialContainer = styled.div`
    padding: 10px 0px;
`;
