import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import { useSelector } from 'react-redux';
import { LayersControl, TileLayer as LeafletTileLayer, WMSTileLayer } from 'react-leaflet';
import { selectPinnedTileLayers, selectTileLayersOpacity } from '../../store/Map/TileLayer/selectors';
import ReactDistortableImageOverlay from 'react-leaflet-distortable-imageoverlay';
import { ListingDTO, ListingType } from '../../api/model';
import { useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import './pinned-tilelayers.css';
import GeoUtil from '../../lib/geo-util';
import ListingHelper from '../../lib/listing-helper';

interface PinnedTileLayersProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mapRef: any;
}

const PinnedTileLayers = (props: PinnedTileLayersProps) => {
    const url = useLocation();
    const pinnedTileLayers: ListingDTO[] = useSelector(selectPinnedTileLayers);
    const tileLayersOpacity = useSelector(selectTileLayersOpacity);
    const [isVisible, setIsVisible] = useState(false);
    const [isNotificationVisible, setNotificationVisible] = useState(false);

    //toggle pinnedMaps class in map container, pinned-tilelayers.css will hide/adjust pinned layers, icon, scale tool
    useEffect(() => {
        if (isVisible) {
            L.DomUtil.addClass(props.mapRef.leafletElement.getContainer(), 'pinnedMaps');

            // After a 4 second delay, allow the notification to be presented again if needed
            setTimeout(() => {
                setNotificationVisible(false);
            }, 4000);
        } else {
            L.DomUtil.removeClass(props.mapRef.leafletElement.getContainer(), 'pinnedMaps');
        }
    }, [props.mapRef, isVisible]);

    useEffect(() => {
        if (pinnedTileLayers.length > 0 &&
            url.pathname.includes('maps') ||
            url.pathname.includes('category')) {
            setIsVisible(true);
            setNotificationVisible(true);
        } else {
            setIsVisible(false);
            setNotificationVisible(false);
        }
    }, [pinnedTileLayers, url]);

    // LeafletTileLayer is ignoring the zIndex={1001} but give a classname and assign a z-index it works as expected
    return (
        <React.Fragment>
            {isNotificationVisible && pinnedTileLayers.length > 0 && <Tooltip>Check out your pinned map here!</Tooltip>}
            <LayersControl position="bottomright" className="pinned-map-control" style={{ display: pinnedTileLayers.length === 0 ? 'none' : '' }}>
                {pinnedTileLayers.map((pinned, id) => {

                    if (pinned.listingType === ListingType.TILE_LAYER && pinned.tileUrl) {
                        return (
                            <LayersControl.Overlay name={pinned.title || 'Tile Layer'} checked key={id}>
                                <LeafletTileLayer
                                    className="pinned-tile-layer"
                                    url={pinned.tileUrl}
                                    tms={true}
                                    bounds={pinned.boundingBox}
                                    opacity={tileLayersOpacity[pinned.id] || 1.0}
                                />
                            </LayersControl.Overlay>
                        );
                    }

                    if (pinned.listingType === ListingType.WMS && pinned.serverUrl) {
                        return (
                            <LayersControl.Overlay name={pinned.title || 'Tile Layer'} checked key={id}>
                                <WMSTileLayer
                                    className="pinned-tile-layer"
                                    url={pinned.serverUrl}
                                    layers={pinned.layerName}
                                    opacity={tileLayersOpacity[pinned.id] || 1.0}
                                    transparent={true}
                                    format={pinned.layerImageFormat || 'image/png'}
                                    zIndex={1000}
                                />
                            </LayersControl.Overlay>
                        )
                    }

                    if (pinned.listingType === ListingType.IMAGE) {
                        const distoredGeometryWKT = pinned.distoredGeometry;
                        const points = GeoUtil.polygonFromPolygonWKT(distoredGeometryWKT);
                        const corners = [points[2], points[3], points[1], points[0]];
                        return (
                            <LayersControl.Overlay name={pinned.title || 'Tile Layer'} checked key={id}>
                                <ReactDistortableImageOverlay
                                    url={ListingHelper.getPreviewUrlForListing(pinned.id)}
                                    className="pinned-tile-layer"
                                    editMode="NONE"
                                    opacity={tileLayersOpacity[pinned.id] || 1.0}
                                    zIndex={1000}
                                    corners={corners}
                                />
                            </LayersControl.Overlay>
                        );
                    }

                    return <React.Fragment />;
                })}
            </LayersControl>
        </React.Fragment>
    );
};

export default PinnedTileLayers;

const TooltipAnimation = keyframes`
    0% {
        opacity: 1;
    } 80% {
        opacity: 1;
    } 100% {
        opacity: 0;
    }
`;

const Tooltip = styled.span`
    position: fixed;
    bottom: 64px;
    right: 58px;
    z-index: 3999;
    pointer-events: none;

    width: 180px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px 30px;
    opacity: 0;
    font-size: 16px;

    animation: ${TooltipAnimation} 2s;
    animation-iteration-count: 1;

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
    }
`;
