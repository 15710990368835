import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { actionActiveMapCleared } from '../../../store/Map/SuperMap/actions';
import { actionFetchUserProfileWithListings } from '../../../store/Map/UserProfile/actions';
import { selectUserProfile, selectUserListings } from '../../../store/Map/UserProfile/selectors';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { selectSideDrawerMode } from '../../../store/SideDrawer/selectors';
import { BottomSheetState } from '../MapSelection/bottom-sheet';
import MobileProfileClosedView from './mobile-profile-closed-view';
import MobileProfileFeaturedMaps from './mobile-profile-featured-maps';
import MobileProfileLoading from './mobile-profile-loading';
import MobileProfileOpenView from './mobile-profile-open-view';

type RouteParams = {
    wallet?: string;
};

interface MobileProfileSheetProps {
    openState: BottomSheetState;
    setOpenState: (o: BottomSheetState) => void;
    onClose: () => void;
    isClosing: boolean;
}

const MobileProfileSheet = (props: MobileProfileSheetProps) => {
    const sideDrawerMode = useSelector(selectSideDrawerMode);
    const userProfile = useSelector(selectUserProfile);
    const userListings = useSelector(selectUserListings);
    const [sheetState, setSheetState] = useState(props.openState);
    const [listingLimit, setListingLimit] = useState(4);

    const dispatch = useDispatch();

    const { wallet } = useParams<RouteParams>();

    useEffect(() => {
        if (wallet) {
            dispatch(actionFetchUserProfileWithListings(wallet));
        }
    }, [wallet, dispatch]);

    useEffect(() => {
        if (userProfile) {
            Analytics.Event('Profile', `Viewing profile: ${userProfile.userId}`);
            Analytics.Event('Mobile - Profile', `Viewing profile: ${userProfile.userId}`);
        }
    }, [userProfile]);

    useEffect(() => {
        if (props.openState === BottomSheetState.transitioning) return;
        if (props.openState === BottomSheetState.close || props.openState === BottomSheetState.mid) {
            setListingLimit(4);
        }
        setSheetState(props.openState);
    }, [props.openState]);

    useEffect(() => {
        const onClose = () => {
            UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
            dispatch(actionActiveMapCleared());
            props.onClose();
            Analytics.Event('Mobile - Profile Sheet', 'Closed Mobile Profile Sheet');
        };

        if (props.isClosing) {
            onClose();
        }
    }, [dispatch, props, props.isClosing]);

    if (!userProfile) {
        return <MobileProfileLoading />;
    }

    return (
        <MobileProfileContainer>
            {sheetState === BottomSheetState.open && sideDrawerMode === SideDrawerMode.PROFILE && (
                <MobileProfileOpenView userProfile={userProfile} userListings={userListings} />
            )}

            {sheetState !== BottomSheetState.open && sideDrawerMode === SideDrawerMode.PROFILE && (
                <MobileProfileClosedView
                    userProfile={userProfile}
                    userListings={userListings}
                    sheetState={sheetState}
                />
            )}

            {sheetState !== BottomSheetState.close &&
            sideDrawerMode === SideDrawerMode.PROFILE &&
            userListings.length > 0 &&
            userListings === undefined ? (
                <MobileProfileLoading />
            ) : (
                <MobileProfileFeaturedMaps
                    userListings={userListings}
                    userProfile={userProfile}
                    listingLimit={listingLimit}
                    handleSetListingLimit={setListingLimit}
                    setOpenState={props.setOpenState}
                />
            )}
        </MobileProfileContainer>
    );
};

export default MobileProfileSheet;

const MobileProfileContainer = styled.div`
    margin: 0px 10px;
`;
