import { LatLng, LatLngBounds } from 'leaflet';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiSubdomain from '../../api/api-subdomain';
import { FileInfo, ImageMetadata, ListingType, RectifiedMap } from '../../api/model';
import FileUtil from '../../lib/file-util';
import GeoUtil from '../../lib/geo-util';
import UriHelper from '../../lib/uri-helper';
import { actionReuploadMapId, actionUploadToSuperMap } from '../../store/Dashboard/actions';
import { selectReuploadMapId, selectUploadToSuperMapStatusReport } from '../../store/Dashboard/selectors';
import {
    actionAddDroneImageForUpload,
    actionClearDroneImageUpload,
    actionSetDatasetDetails,
    actionSetRectifiedMapAction,
} from '../../store/Map/Uploads/actions';
import { SideDrawerMode } from '../../store/SideDrawer/model';
import { selectLoggedIn, selectMyListings } from '../../store/Account/selectors';
import { LoginModalMode } from '../Registration/login-enum';
import LoginRegisterDialog from '../Registration/login-register-dialog';
import UploadMapImageLocation from './upload-map-image-location';
import UploadMapChoose from './upload-map-choose';
import UploadMapMetadata from './upload-map-metadata';
import UploadMapComplete from './upload-map-complete';
import Analytics from '../../lib/user-analytics';
import GeoRectifyUtil from './Util/geo-rectify-util';
import { DatasetDetails } from './Model/model';
import { actionSetSideDrawerModeAction } from '../../store/SideDrawer/actions';
import { GCPLoader } from './GCPControls/gcp-loader';
import { actionFlyToOnMap } from '../../store/App/actions';
import UploadMapImageNoLocation from './upload-map-image-no-location';
import { toast } from 'react-toastify';
import UploadWorkflowModal from './upload-worflow-modal';

// @ts-ignore // suppress type error
import loam from 'loam';

export enum Workflow {
    None,
    ChooseFile,
    Location,
    NoLocation,
    Metadata,
    Upload,
}

// TODO Amir requested 100mb, should handle it but will see
const MAX_FILE_SIZE = 100000000; // 100mb

interface UploadMapWorkflowProps {
    onWorkflowChange: (workflow: Workflow) => void;
    onListingTypeChange: (listingType: ListingType) => void;
}

const UploadMapWorkflow = ({ onWorkflowChange, onListingTypeChange }: UploadMapWorkflowProps) => {
    const [isLoginOpen, setIsLoginOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isLoggedIn = useSelector(selectLoggedIn);
    const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState<boolean>(false);

    const dispatch = useDispatch();
    const clearDroneImageUpload = () => dispatch(actionClearDroneImageUpload());
    const setDrawerMode = (sideDrawerMode: SideDrawerMode) => dispatch(actionSetSideDrawerModeAction(sideDrawerMode));
    const setDatasetDetails = (datasetDetails: DatasetDetails) => dispatch(actionSetDatasetDetails(datasetDetails));
    const setRectifiedMap = (rectifiedMap?: RectifiedMap) => dispatch(actionSetRectifiedMapAction(rectifiedMap));
    const flyTo = useCallback((bounds: LatLngBounds) => dispatch(actionFlyToOnMap(bounds)), [dispatch]);

    const [workflow, setWorkflow] = useState<Workflow>(Workflow.ChooseFile);
    const [file, setFile] = useState<File>();
    const [error, setError] = useState<string | undefined>(undefined);

    const [title, setTitle] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [tags, setTags] = useState<string[] | undefined>(undefined);
    const [categories, setCategories] = useState<string[]>([]);

    const [listingType, setListingType] = useState(ListingType.TILE_LAYER);

    // TODO do we still need exif???
    const [exif, setExif] = useState<any>(undefined); // eslint-disable-line @typescript-eslint/no-explicit-any
    const [latlng, setLatlng] = useState<LatLng | undefined>(undefined);
    const [positions, setPositions] = useState<LatLng[] | undefined>(undefined);

    //FOR REUPLOAD listing
    const reuploadMapId = useSelector(selectReuploadMapId);
    const myListings = useSelector(selectMyListings);
    const uploadStatusReport = useSelector(selectUploadToSuperMapStatusReport);

    // TODO this way of initilize could be a problem but loam is been a bitch
    // Not sure if best place to initialize it..
    useEffect(() => {
        const url = new URL(window.location.href);
        loam.initialize(`${url.origin}/`);
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            setIsLoginOpen(true);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        return () => {
            dispatch(actionClearDroneImageUpload());
        };
    }, [dispatch]);

    useEffect(() => {
        onWorkflowChange(workflow);
        Analytics.Event('Upload', 'Workflow changed', workflow);
    }, [workflow, onWorkflowChange]);

    useEffect(() => {
        onListingTypeChange(listingType);
    }, [listingType, onListingTypeChange]);

    useEffect(() => {
        if (reuploadMapId && myListings) {
            const listing = myListings.filter((l) => l.id.toString() === reuploadMapId && l.review === 'REJECTED')[0];
            if (listing) {
                setTitle(listing.title);
                setDescription(listing.description);
                setTags(listing.tags);
                setCategories(listing.categories);
                return;
            }
        }
        //remove reupload if not in myListings
        if (reuploadMapId) {
            UriHelper.navigateToDrawer(SideDrawerMode.SHARE_MAP);
        }
    }, [reuploadMapId, myListings, dispatch]);

    useEffect(() => {
        if (reuploadMapId && uploadStatusReport?.status === 100) {
            Analytics.Event('Upload', 'ReUploaded', `${reuploadMapId}`);
            ApiSubdomain.deleteListing(Number(reuploadMapId)).then(() => actionReuploadMapId(undefined));
        }
    }, [reuploadMapId, uploadStatusReport]);

    const showFileToMapLocation = (file: File, positions: number[][]) => {
        const reader = new FileReader();
        reader.onload = () => {
            const image = new Image();
            image.src = reader.result as string;
            image.onload = () => {
                const imagePreviewUrl = reader.result as string;

                dispatch(actionAddDroneImageForUpload(imagePreviewUrl, positions));
            };
        };
        reader.readAsDataURL(file);
    };

    // TODO looks the same as the one been used?
    const setImageFromPosition = (file: File, position: LatLng) => {
        setLatlng(position);

        const bounds = position.toBounds(300);
        flyTo(bounds);

        const positions = [
            new LatLng(bounds.getSouthEast().lat, bounds.getSouthEast().lng),
            new LatLng(bounds.getSouthWest().lat, bounds.getSouthWest().lng),
            new LatLng(bounds.getNorthWest().lat, bounds.getNorthWest().lng),
            new LatLng(bounds.getNorthEast().lat, bounds.getNorthEast().lng),
            new LatLng(bounds.getSouthEast().lat, bounds.getSouthEast().lng),
        ];
        setPositions(positions);

        const latlngs = [
            [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
            [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
            [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
            [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
            [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
        ];
        showFileToMapLocation(file, latlngs);
    };

    const determineFileType = async (file: File) => {
        if (GeoRectifyUtil.isValidFileType(file)) {
            const datasetDetails = await GeoRectifyUtil.readFileDataset(loam, file);
            setIsLoading(false);
            if (!datasetDetails) {
                alert('Failed to read file dataset, that is upsetting');
                UriHelper.navigateToPath('/upload');
                return;
            }

            setDatasetDetails(datasetDetails);

            if (datasetDetails?.bands && (datasetDetails?.bands < 3 || datasetDetails?.bands > 4)) {
                //setError(Errors.Bands);
                alert('Errors.Bands');
                UriHelper.navigateToPath('/upload');
                return;
            }

            setDrawerMode(SideDrawerMode.RECTIFIER);
            setRectifiedMap(undefined);
            UriHelper.navigateToPath('/upload/georectifier');
        } else {
            // Should not make it here but if the upload input is broken bail out
            UriHelper.navigateToPath('/upload');
        }
    };

    const handleSelectUpload = (file: File) => {
        Analytics.Event('Upload', 'Added File', file.type);
        // TODO amir would like the tif headers checked as well but this components needs a refactor
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
            if (file.size > MAX_FILE_SIZE) {
                setError(`File should be less than ${MAX_FILE_SIZE / 1000000}MB in size`);
                return;
            }
            setListingType(ListingType.IMAGE);
            setIsWorkflowModalOpen(true);
        } else {
            setListingType(ListingType.TILE_LAYER);
            setWorkflow(Workflow.Metadata);
        }
    };

    const handleSelectFileForGeorectifier = () => {
        setIsWorkflowModalOpen(false);
        if (!file) {
            toast.error('Could not find the file, please try again');
            return;
        }
        setIsLoading(true);
        determineFileType(file);
    };

    const handleSelectFileForGeneralUpload = () => {
        setIsWorkflowModalOpen(false);
        if (!file) {
            toast.error('Could not find the file, please try again');
            return;
        }

        setListingType(ListingType.IMAGE);

        FileUtil.readXmpData(file).then((result) => {
            setIsLoading(false);
            if (result.exif && result.exif.PixelXDimension && result.exif.PixelYDimension) {
                if (result.exif.PixelXDimension * result.exif.PixelYDimension < 500000) {
                    setError('File should have a resolution of at least 0.5 mega pixels');
                    return;
                }
            }

            setExif(result.exif);

            const position = FileUtil.parseLocation(result.exif);
            const imageBounds = FileUtil.calculateBoundingBoxFromExifData(result.xmp, result.exif);
            if (imageBounds) {
                const positions = GeoUtil.toLeafletPositionsClock(imageBounds);
                const bounds = new LatLngBounds(positions[0], positions[1])
                    .extend(positions[2])
                    .extend(positions[3])
                    .extend(positions[4]);

                flyTo(bounds);

                setTimeout(() => {
                    showFileToMapLocation(file, GeoUtil.toDistortablePositionsClock(positions));
                }, 3000);

                setLatlng(bounds.getCenter());

                const newPositions = imageBounds.map((p) => {
                    return new LatLng(p[1], p[0]);
                });
                setPositions(newPositions);
                setWorkflow(Workflow.Location);
            } else if (position && (position.lat || position.lng)) {
                setImageFromPosition(file, position);
                setWorkflow(Workflow.Location);
            } else {
                setWorkflow(Workflow.NoLocation);
            }
        });
    };

    const clearMetadata = () => {
        setTitle(undefined);
        setDescription(undefined);
        setTags(undefined);
        setCategories([]);
    };

    const submitMap = async (
        mapTitle: string,
        mapDescription: string,
        mapTags: string[],
        mapCategories: string[],
        hasCheckedTerms: boolean,
        attachment?: File
    ) => {
        if (!file) {
            throw new Error('Invalid workflow state. File does not exist during DroneImageWorkflow.Confirm');
        }

        if (listingType === ListingType.TILE_LAYER) {
            const dto = {
                title: mapTitle,
                description: mapDescription,
                tags: mapTags,
                categories: mapCategories,
                tc: hasCheckedTerms,
            };
            dispatch(
                actionUploadToSuperMap(file, ListingType.TILE_LAYER, dto, JSON.stringify(dto), undefined, attachment)
            );
        } else {
            if (!positions) {
                throw new Error('Invalid workflow state. Positions does not exist during DroneImageWorkflow.Confirm');
            }

            if (!latlng) {
                throw new Error('Invalid workflow state. Latlng does not exist during DroneImageWorkflow.Confirm');
            }

            const imageDetails: ImageMetadata = {
                make: exif.Make ? exif.Make.replace(/[\W_-]+/g, '') : '',
                model: exif.Model ? exif.Model.replace(/[\W_-]+/g, '') : '',
                focalLength: exif.FocalLength ? exif.FocalLength : '',
                fNumber: exif.FNumber ? exif.FNumber : '',
                shutterSpeed: exif.ShutterSpeedValue ? exif.ShutterSpeedValue : '',
                apertureValue: exif.ApertureValue ? exif.ApertureValue : '',
                lightSource: exif.LightSource ? exif.LightSource : '',
                altitude: exif.GPSAltitude ? exif.GPSAltitude.toString() : '',
                dateTaken: exif.DateTimeOriginal ? exif.DateTimeOriginal : '',
            };

            const distoredGeometry = GeoUtil.latLngListsToWKT(positions as unknown as LatLng[]);
            const fileInfo: FileInfo = {
                filehash: await FileUtil.getMd5Hash(file),
                geometryWKT: 'POINT(' + latlng.lng + ' ' + latlng.lat + ')',
                geohash: FileUtil.computeGeohash(latlng),
                // geometryWKT: 'POINT(0 0)',
                // geohash: '0',
                // eslint-disable-next-line
                exif: JSON.stringify((file as any).exifdata),
                contentType: file.type,
                distoredGeometry,
            };
            const metadata = {
                ...imageDetails,
                title: mapTitle,
                description: mapDescription,
                tags: mapTags,
                categories: mapCategories,
            };
            const dto = {
                title: mapTitle,
                description: mapDescription,
                tags: mapTags,
                categories: mapCategories,
            };

            Analytics.Event('Upload', 'Uploading Image', mapTitle);
            dispatch(
                actionUploadToSuperMap(file, ListingType.IMAGE, dto, JSON.stringify(metadata), fileInfo, attachment)
            );
        }

        clearMetadata();
    };

    if (isWorkflowModalOpen) {
        return (
            <UploadWorkflowModal
                isWorkflowModalOpen={isWorkflowModalOpen}
                setIsWorkflowModalOpen={() => setIsWorkflowModalOpen(false)}
                handleSelectFileForGeneralUpload={handleSelectFileForGeneralUpload}
                handleSelectFileForGeorectifier={handleSelectFileForGeorectifier}
            />
        );
    }

    if (isLoginOpen && !isLoggedIn) {
        return (
            <LoginRegisterDialog
                isOpen={isLoginOpen}
                initialMode={LoginModalMode.LOGIN}
                onClose={() => {
                    setIsLoginOpen(!isLoginOpen);
                    Analytics.Event('Upload', 'Closed Login');
                    UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
                }}
            />
        );
    }

    if (isLoading) {
        return <GCPLoader loadingText={'Loading map... one moment'} isProcessing={isLoading} />;
    }

    switch (workflow) {
        case Workflow.None:
            return <React.Fragment />;

        case Workflow.ChooseFile:
            return (
                <UploadMapChoose
                    onSelectFile={(file) => {
                        setFile(file);
                        setError('');
                        handleSelectUpload(file);
                    }}
                    handleSetError={setError}
                    error={error}
                />
            );

        // Leaving this here if we want to remove the georectification it will mean uncommenting this and adjusting the workflow back
        case Workflow.NoLocation:
            if (!file) return null;
            return (
                <UploadMapImageNoLocation
                    onClickBack={() => {
                        setFile(undefined);
                        clearDroneImageUpload();
                        setWorkflow(Workflow.ChooseFile);
                    }}
                    onClickConfirmPosition={(position: LatLng) => {
                        setImageFromPosition(file, position);
                        setWorkflow(Workflow.Location);
                    }}
                />
            );

        case Workflow.Location:
            return (
                <UploadMapImageLocation
                    onClickBack={() => {
                        setWorkflow(Workflow.ChooseFile);
                        setPositions(undefined);
                        clearDroneImageUpload();
                        setFile(undefined);
                    }}
                    onSubmit={(updatedCorners: number[][] | undefined) => {
                        if (updatedCorners && updatedCorners.length > 0 && updatedCorners[0].length > 0) {
                            const newPositions = updatedCorners.map((p) => {
                                return new LatLng(p[1], p[0]);
                            });
                            setPositions(newPositions);
                        }

                        setWorkflow(Workflow.Metadata);
                    }}
                />
            );

        case Workflow.Metadata:
            if (!file) return null;
            return (
                <UploadMapMetadata
                    onClickBack={() => {
                        if (listingType === ListingType.IMAGE) {
                            setWorkflow(Workflow.Location);
                        } else {
                            setWorkflow(Workflow.ChooseFile);
                        }
                    }}
                    submitted={(
                        title: string,
                        description: string,
                        tags: string[],
                        categories,
                        hasCheckedTerms,
                        attachment
                    ) => {
                        setTitle(title);
                        setDescription(description);
                        setTags(tags);
                        setCategories(categories);
                        setWorkflow(Workflow.Upload);
                        submitMap(title, description, tags, categories, hasCheckedTerms, attachment);
                    }}
                    fileName={file.name}
                    title={title}
                    description={description}
                    tags={tags}
                    categories={categories}
                    listingType={listingType}
                />
            );

        case Workflow.Upload:
            if (!file) {
                throw new Error('Invalid workflow state.  File is undefined at Workflow.Upload');
            }
            return (
                <UploadMapComplete
                    onClickBack={() => {
                        clearDroneImageUpload();
                        clearMetadata();
                        setWorkflow(Workflow.ChooseFile);
                        UriHelper.navigateToDrawer(SideDrawerMode.SHARE_MAP);
                    }}
                    file={file}
                />
            );

        default:
            return <React.Fragment />;
    }
};

export default UploadMapWorkflow;
