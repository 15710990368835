import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import * as Wellknown from 'wellknown';
import ApiContactUs from '../../api/api-contact-us';
import { ContactUsFields } from '../../api/model';
import Analytics from '../../lib/user-analytics';
import UserHelper from '../../lib/user-helper';
import { selectLoggedIn, selectMyProfile } from '../../store/Account/selectors';
import { LoginModalMode } from '../Registration/login-enum';
import LoginRegisterDialog from '../Registration/login-register-dialog';
import SoarModal from '../Shared/soar-modal';
import { StyledButton } from '../Shared/styled-button';
import { SubmittingSpinner } from '../Shared/submitting-spinner';
import { ContactUsInput } from './contact-us-input';
import './contact-us.css';

const omnivore = require('leaflet-omnivore');

enum Industry {
    Undefined = 'Select your industry',
    Mining = 'Mining / Oil / Gas',
    Agriculture = 'Agriculture',
    RealEstate = 'Real Estate',
    Security = 'Security / Defense',
    News = 'Media / News',
    Government = 'Government',
    Other = 'Other',
}

enum Requirement {
    Undefined = 'Select requirements',
    LargerAOI = 'Larger AOI',
    NaturalColor = 'Natural Color',
    MultiSpectural = 'Multi-spectural',
    Stereo = 'Stereo / Elevation',
}

export enum ContactSubject {
    Undefined = 'Select a subject',
    CSWCustomOrder = 'Custom satellite orders',
    SoarEnterprise = 'Soar+ Enterprise',
    Corporate = 'Corporate',
    General = 'General',
    Support = 'Support',
    ReportAProblem = 'Report a problem',
    Other = 'Other',
}

interface ContactUsProps {
    isOpen: boolean;
    toggle: () => void;
    listingId?: string | number;
    askForIndustry?: boolean;
    askForRequirement?: boolean;
    askForSubject?: boolean;
    askForCountry?: boolean;
    askForKML?: boolean;
    preselectContactSubject?: ContactSubject;
    customTitle?: string;
    customMessage?: string;
    customMessageLabel?: string;
    customSubmitText?: string;
    handleSubmit?: (fields: ContactUsFields) => Promise<void>;
}

const DefaultErrorMessages = {
    email: '',
    subject: '',
    industry: '',
    message: '',
    requirements: '',
};

const ContactUs = (props: ContactUsProps) => {
    const myProfile = useSelector(selectMyProfile);
    const isLoggedIn = useSelector(selectLoggedIn);

    const [email, setEmail] = useState<string>('');
    const [industry, setIndustry] = useState<Industry>(Industry.Undefined);
    const [subject, setSubject] = useState<string>(props.preselectContactSubject ? props.preselectContactSubject : '');
    const [requirements, setRequirements] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [errors, setErrors] =
        useState<{ email?: string; subject?: string; industry?: string; message?: string; requirements?: string }>(
            DefaultErrorMessages
        );
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [countryCode, setCountryCode] = useState<string | undefined>(undefined);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [geometry, setGeometry] = useState<string | undefined>(undefined);
    const [geometryError, setGeometryError] = useState<string | undefined>(undefined);
    const [submitError, setSubmitError] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (myProfile && myProfile.email) {
            setEmail(myProfile.email);
        }
    }, [myProfile]);

    const handleSuccess = () => {
        props.toggle();
        setIsSubmitting(false);
        setSubmitted(true);
    };

    const handleSubmitError = () => {
        setIsSubmitting(false);
        setSubmitted(false);
        toast.error(`Error: ${message}`);
    };

    const reset = () => {
        setIndustry(Industry.Undefined);
        setSubject(props.preselectContactSubject ? props.preselectContactSubject : '');
        setMessage('');
        setErrors({ email: '', subject: '', industry: '', message: '' });
        setIsSubmitting(false);
        setCountryCode(undefined);
        setSubmitted(false);
        setGeometry(undefined);
        setGeometryError(undefined);
        setSubmitError(undefined);
    };

    const handleSetError = (name: string, message: string) => {
        setErrors((errors) => ({
            ...errors,
            [name]: message,
        }));
    };

    const handleResetError = (e: { target: { name: string } }) => {
        const { name } = e.target;
        setErrors((errors) => ({
            ...errors,
            [name]: '',
        }));
    };

    const handleSubmitContact = (e): void => {
        e.preventDefault();

        if (!UserHelper.isEmailValid(email)) {
            handleSetError('email', 'Please enter a valid email');
        }

        if (props.askForIndustry && industry === Industry.Undefined) {
            handleSetError('industry', 'Please select an industry');
        }

        if (props.askForSubject && !subject) {
            handleSetError('subject', 'Please select a subject');
        }

        if (!message || message.length <= 5) {
            handleSetError('message', 'Please enter a valid message');
        }

        const reportingImage = props.listingId ? true : false;

        if (
            (UserHelper.isEmailValid(email) &&
                (!props.askForIndustry || (props.askForIndustry && industry !== Industry.Undefined)) &&
                subject &&
                message.length > 5 &&
                !geometryError) ||
            (reportingImage && UserHelper.isEmailValid(email) && message.length > 5)
        ) {
            setErrors(DefaultErrorMessages);
            Analytics.Event('Contact', 'User has sent a contact email');

            setIsSubmitting(true);
            if (props.handleSubmit) {
                const listingId = props.listingId;
                props
                    .handleSubmit({
                        email,
                        message,
                        subject,
                        industry: industry === Industry.Undefined ? '' : industry,
                        requirements,
                        geometry,
                        listingId,
                        countryCode,
                    })
                    .then(() => {
                        handleSuccess();
                    })
                    .catch(() => {
                        handleSubmitError();
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                    });
            } else {
                if (props.listingId) {
                    ApiContactUs.reportProblem(email, message, props.listingId)
                        .then(() => {
                            handleSuccess();
                        })
                        .catch(() => {
                            handleSubmitError();
                        })
                        .finally(() => {
                            setIsSubmitting(false);
                        });
                } else {
                    ApiContactUs.postContactUs(email, subject, message, industry)
                        .then((_) => {
                            handleSuccess();
                        })
                        .catch(() => {
                            handleSubmitError();
                        })
                        .finally(() => {
                            setIsSubmitting(false);
                        });
                }
            }
        }
    };

    return (
        <>
            <LoginRegisterDialog
                isOpen={!isLoggedIn && props.isOpen}
                initialMode={LoginModalMode.LOGIN}
                onClose={() => {
                    setIsSubmitting(false);
                    setSubmitted(false);
                    props.toggle();
                }}
            />
            <SoarModal
                title={props.customTitle ?? 'Contact Us'}
                isOpen={isLoggedIn && props.isOpen}
                toggle={() => {
                    setIsSubmitting(false);
                    setSubmitted(false);
                    reset();
                    props.toggle();
                }}
                width={'400px !important'}
            >
                {!isSubmitting && !submitted ? (
                    <ContactModalBody>
                        <Form onSubmit={(e) => handleSubmitContact(e)}>
                            <ContactUsInput
                                type="email"
                                name="email"
                                label="Email"
                                error={errors.email}
                                value={email}
                                placeholder={'Email Address'}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    handleResetError(e);
                                }}
                            />
                            {props.askForIndustry && (
                                <ContactUsInput
                                    type="select"
                                    label="Industry"
                                    name="industry"
                                    error={errors.industry}
                                    value={Industry[industry]}
                                    dataTestId={'industry-select'}
                                    onChange={(e) => {
                                        setIndustry(Industry[e.target.value]);
                                        handleResetError(e);
                                    }}
                                    optionsList={
                                        <React.Fragment>
                                            {Object.keys(Industry).map((key) => {
                                                return (
                                                    <option data-testid="industry-select-option" key={key} value={key}>
                                                        {Industry[key]}
                                                    </option>
                                                );
                                            })}
                                        </React.Fragment>
                                    }
                                />
                            )}
                            {props.askForRequirement && (
                                <ContactUsInput
                                    type="select"
                                    label="Requirements"
                                    name="requirements"
                                    error={errors.requirements}
                                    value={Requirement[requirements]}
                                    dataTestId={'requirement-select'}
                                    onChange={(e) => {
                                        setRequirements(Requirement[e.target.value]);
                                        handleResetError(e);
                                    }}
                                    optionsList={
                                        <React.Fragment>
                                            {Object.keys(Requirement).map((key) => {
                                                return (
                                                    <option
                                                        data-testid="requirement-select-option"
                                                        key={key}
                                                        value={key}
                                                    >
                                                        {Requirement[key]}
                                                    </option>
                                                );
                                            })}
                                        </React.Fragment>
                                    }
                                />
                            )}
                            {props.askForSubject && (
                                <ContactUsInput
                                    type="select"
                                    label="Subject"
                                    name="subject"
                                    error={errors.subject}
                                    value={ContactSubject[subject]}
                                    onChange={(e) => {
                                        setSubject(ContactSubject[e.target.value]);
                                        handleResetError(e);
                                    }}
                                    optionsList={
                                        <React.Fragment>
                                            {Object.keys(ContactSubject).map((key) => {
                                                return (
                                                    <option data-testid="subject-select-option" key={key} value={key}>
                                                        {ContactSubject[key]}
                                                    </option>
                                                );
                                            })}
                                        </React.Fragment>
                                    }
                                />
                            )}
                            <ContactUsInput
                                type="textarea"
                                name="message"
                                label={props.customMessageLabel ? props.customMessageLabel : 'Message'}
                                error={errors.message}
                                value={message}
                                placeholder={
                                    props.customMessage ? props.customMessage : 'How can our experts help you today?'
                                }
                                onChange={(e) => {
                                    setMessage(e.target.value);
                                    handleResetError(e);
                                }}
                            />
                            {props.askForKML ? (
                                <React.Fragment>
                                    <label>Upload a KML of your Area of Interest (optional)</label>
                                    <UploadKMLInput
                                        className="contact-us-upload-kml"
                                        type="file"
                                        accept=".kml"
                                        hasError={geometryError}
                                        onInput={(value) => {
                                            setGeometryError(undefined);
                                            //@ts-ignore
                                            const files = value.target.files;
                                            if (files && files[0]) {
                                                files[0].text().then((kml) => {
                                                    if (kml) {
                                                        try {
                                                            const leaflet = omnivore.kml.parse(kml);
                                                            const geometryGeoJSON = leaflet.toGeoJSON();
                                                            let parsedWKT: string;
                                                            let polygonString = '';
                                                            if (geometryGeoJSON.features.length > 1) {
                                                                geometryGeoJSON.features.forEach(
                                                                    (collection, index) => {
                                                                        // Check kml for polygons
                                                                        if (
                                                                            Wellknown.stringify(
                                                                                geometryGeoJSON.features[index]
                                                                            ).indexOf('POLYGON') !== -1
                                                                        ) {
                                                                            polygonString += Wellknown.stringify(
                                                                                geometryGeoJSON.features[index]
                                                                            );
                                                                            if (
                                                                                index !==
                                                                                geometryGeoJSON.features.length - 1
                                                                            ) {
                                                                                polygonString += ',';
                                                                            }
                                                                        }
                                                                    }
                                                                );

                                                                if (polygonString.length === 0) {
                                                                    setGeometryError('Invalid KML - Check file');
                                                                    return;
                                                                } else {
                                                                    parsedWKT = `GEOMETRYCOLLECTION (${polygonString})`;
                                                                }
                                                            } else {
                                                                parsedWKT = Wellknown.stringify(
                                                                    geometryGeoJSON.features[0]
                                                                );
                                                            }
                                                            setGeometry(parsedWKT);
                                                        } catch (err) {
                                                            setGeometryError('Invalid KML');
                                                        }
                                                    } else {
                                                        setGeometryError('Invalid KML');
                                                    }
                                                });
                                            }
                                        }}
                                    />
                                    <ErrorMessage>{geometryError && geometryError}</ErrorMessage>
                                </React.Fragment>
                            ) : null}

                            {submitError && (
                                <ContactModalError>
                                    <ContactModalErrorIcon />
                                    {submitError}
                                </ContactModalError>
                            )}

                            {!isSubmitting && !submitted && (
                                <ContactSubmitButton onClick={(e) => handleSubmitContact(e)}>
                                    SUBMIT
                                </ContactSubmitButton>
                            )}
                            {isSubmitting && !submitted && <SubmittingSpinner />}
                        </Form>
                    </ContactModalBody>
                ) : (
                    <ContactModalBody>
                        <ContactUsSubmitted>
                            <h3>Submission Successful</h3>
                            <h3>Thank you for contacting us</h3>
                            <p>A member of our team will email you as soon as possible.</p>
                        </ContactUsSubmitted>
                    </ContactModalBody>
                )}
            </SoarModal>
        </>
    );
};

export default ContactUs;

const ErrorMessage = styled.div`
    color: rgb(255, 100, 100) !important;
    -webkit-text-fill-color: rgb(255, 100, 100);
    padding: 4px 0px;
    display: flex;
    font-size: 0.9rem;
    height: 25px;
`;

const ContactModalError = styled(ModalBody)`
    color: rgb(255, 100, 100) !important;
    -webkit-text-fill-color: rgb(255, 100, 100);
    padding: 10px;
    padding-top: 0px;
    display: flex;
`;

const ContactModalErrorIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 17V29' stroke='%23FF6464' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20 11V13' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='20' cy='20' r='19.5' stroke='%23FF0000'/%3E%3C/svg%3E ");
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

const ContactModalBody = styled(ModalBody)`
    border-top: none;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    .form-group {
        margin-bottom: 0px;
    }

    label {
        color: rgba(255, 255, 255, 0.87);
        width: 100%;
        margin-bottom: 0px;
    }
`;

const ContactSubmitButton = styled(StyledButton)`
    margin: 20px auto;
    background-color: gray;
    width: 100%;

    :not(:disabled) {
        cursor: pointer;
        background-color: #eed926 !important;
        -webkit-text-fill-color: black;
    }
`;

const ContactUsSubmitted = styled.div`
    h3 {
        color: #eed926;
        -webkit-text-fill-color: #eed926;
    }

    p {
        color: white;
        -webkit-text-fill-color: white;
    }
`;

interface ErrorStyleProps {
    hasError?: string;
}

const UploadKMLInput = styled.input<ErrorStyleProps>`
    box-shadow: none !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.6);
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
    width: 100%;
    border-radius: 0.25rem;

    border: 1px solid ${(props) => (props.hasError ? 'rgb(255,100,100)' : 'rgba(255 255 255 / 0.3)')} !important;
    :hover {
        color: rgba(255, 255, 255, 0.6);
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
        background-color: transparent;
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid ${(props) => (props.hasError ? 'rgb(255,100,100)' : 'rgba(238 227 8 / 0.6)')} !important;
        box-shadow: 0 0 0 0.2rem rgb(238 227 8 / 10%) !important;
    }

    ::-webkit-file-upload-button {
        color: green !important;
    }

    ::file-selector-button {
        background-color: #eed926;
        color: black !important;
        -webkit-text-fill-color: black !important;

        border: solid;
        border-width: 1px;
        border-color: #eed926;
        border-radius: 3px;
        cursor: pointer;
        text-transform: uppercase;
    }
`;
