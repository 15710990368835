import styled, { keyframes } from 'styled-components';

interface GCPTextHelperProps {
    message: string;
    marginLeft: string;
}

const GCPTextHelper = (props: GCPTextHelperProps) => {
    return (
        <GCPActiveContainer className={props.message ? 'active' : 'inactive'} marginLeft={props.marginLeft}>
            {props.message}
        </GCPActiveContainer>
    );
};

export default GCPTextHelper;

const AnimateActive = keyframes`
    0% {
        transform: scale( 1 );
    } 50% {
        transform: scale( 1.25 );
    } 100% {
        transform: scale( 1 );
    }
`;

const GCPActiveContainer = styled.div<{ marginLeft: string }>`
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    margin-top: 15px;
    padding: 5px;
    border-radius: 6px;
    border: 1px solid rgba(238, 218, 38, 0.6);
    box-shadow: 0px 0px 5px 0px rgba(238, 218, 38, 0.8);
    text-align: center;
    position: absolute;
    margin-left: ${(props) => props.marginLeft};

    &.active {
        animation: ${AnimateActive} 400ms;
        animation-iteration-count: 2;
    }
`;
