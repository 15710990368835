import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ApiAnalytics from '../../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote } from '../../../api/model';
import ListingHelper from '../../../lib/listing-helper';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { actionSetHighlightedListingId } from '../../../store/Map/MapSelection/actions';
import { ListingGroup } from '../../../store/Map/MapSelection/model';
import { selectHighlightedListingGroup } from '../../../store/Map/MapSelection/selectors';
import { selectHighlightedTileLayerId } from '../../../store/Map/TileLayer/selectors';
import PreviewTile from '../../Shared/preview-tile';
import CategoryPreviewMultiselect from './category-preview-multiselect';

interface CategoryMapsList {
    visibleMaps: ListingGroup[];
}

const DEFAULT_NUMBER_OF_TILE_LAYERS_TO_SHOW = 10;

const CategoryMapsList = (props: CategoryMapsList) => {
    const dispatch = useDispatch();
    const [numberOfTileLayersToShow, setNumberOfTileLayersToShow] = useState(DEFAULT_NUMBER_OF_TILE_LAYERS_TO_SHOW);
    const highlightedTileLayerGroup = useSelector(selectHighlightedListingGroup);
    const highlightedTileLayerId = useSelector(selectHighlightedTileLayerId);
    const setTileLayerHighlighted = (tileLayerId: number | undefined) =>
        dispatch(actionSetHighlightedListingId(tileLayerId));

    const onMouseOver = (tileLayerId: number) => {
        setTileLayerHighlighted(tileLayerId);
    };

    const onMouseOut = () => {
        setTileLayerHighlighted(undefined);
    };

    const onClick = (tileLayerId: number) => {
        UriHelper.navigateToMap(tileLayerId);
        ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.FEATURED_DRAW, tileLayerId);
        Analytics.Event('Category', 'Clicked view', tileLayerId);
    };

    return (
        <React.Fragment>
            {props.visibleMaps.slice(0, numberOfTileLayersToShow).map((tileLayerGroup) => {
                if (tileLayerGroup && tileLayerGroup.tileLayers && tileLayerGroup.tileLayers.length === 1) {
                    const tileLayer = tileLayerGroup.tileLayers[0];
                    const isHighlighted =
                        (highlightedTileLayerGroup &&
                            highlightedTileLayerGroup.tileLayers.length === 1 &&
                            highlightedTileLayerGroup.tileLayers[0].id === tileLayer.id) ||
                        highlightedTileLayerId === tileLayer.id ||
                        false;
                    return (
                        <PreviewList
                            key={tileLayer.id}
                            onMouseOver={() => onMouseOver(tileLayer.id)}
                            onMouseOut={() => onMouseOut()}
                            onClick={() => onClick(tileLayer.id)}
                            title={tileLayer.title}
                            isHighlighted={isHighlighted}
                        >
                            <PreviewTile
                                title={tileLayer.title}
                                previewUrl={ListingHelper.getPreviewUrlForListing(tileLayer.id)}
                                subText={`by ${tileLayer.authorName}`}
                            />
                        </PreviewList>
                    );
                }

                if (tileLayerGroup && tileLayerGroup.tileLayers && tileLayerGroup.tileLayers.length > 1) {
                    return (
                        <CategoryPreviewMultiselect
                            key={tileLayerGroup.tileLayers[0].id}
                            tileLayerGroup={tileLayerGroup}
                        />
                    );
                }

                return <React.Fragment />;
            })}

            {props.visibleMaps.flatMap((t) => t.tileLayers).length > numberOfTileLayersToShow ? (
                <LoadMoreButton
                    onClick={() => {
                        setNumberOfTileLayersToShow((current) => current + DEFAULT_NUMBER_OF_TILE_LAYERS_TO_SHOW)
                        Analytics.Event('Side Drawer', 'Clicked Load More');
                    }}
                >
                    Load more
                </LoadMoreButton>
            ) : null}
        </React.Fragment>
    );
};

export default CategoryMapsList;

export const PreviewList = styled.div<{ isHighlighted: boolean }>`
    cursor: pointer;
    height: 48px;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    margin: 0 0 3px 0;
    border: ${(props) => (props.isHighlighted ? '1px solid #eed309' : '1px solid rgba(0, 0, 0, 0.3)')};

    &:hover {
        border: 1px solid #eed309;
    }
`;

const LoadMoreButton = styled.button`
    margin: 0 auto;
    width: 100%;
    align-items: normal;
    background-color: rgba(0, 0, 0, 0);
    border-style: none;
    box-sizing: content-box;
    color: #eed309;
    cursor: pointer;
    display: inline;
    font: inherit;
    height: auto;
    padding: 0;
    text-decoration: underline;
    -moz-appearance: none;

    &:focus {
        outline: none;
        border: none;
    }
`;
