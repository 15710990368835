import React, { useEffect, useState } from 'react';
import { TileLayer as LeafletTileLayer, WMSTileLayer } from 'react-leaflet';
import ReactDistortableImageOverlay from 'react-leaflet-distortable-imageoverlay';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ListingType } from '../../../api/model';
import GeoUtil from '../../../lib/geo-util';
import ListingHelper from '../../../lib/listing-helper';
import Analytics from '../../../lib/user-analytics';
import { actionFlyToOnMap } from '../../../store/App/actions';
import { actionActiveMapTileLayerLoading } from '../../../store/Map/SuperMap/actions';
import { selectActiveAllMap } from '../../../store/Map/SuperMap/selectors';
import { selectActiveOrder } from '../../../store/Map/Tasks/selectors';
import { selectTileLayersOpacity } from '../../../store/Map/TileLayer/selectors';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { selectSideDrawerMode, selectSideDrawerOpen } from '../../../store/SideDrawer/selectors';
import AllMapsProvider from './TileLayerProvider/all-maps-provider';
import MyBookmarksProvider from './TileLayerProvider/my-bookmarks-provider';
import MyMapsProvider from './TileLayerProvider/my-maps-provider';
import ProfileMapsProvider from './TileLayerProvider/profile-maps-provider';

interface TileLayerProps {
    leafletMap: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const TileLayers = (props: TileLayerProps) => {
    const dispatch = useDispatch();

    const sideDrawerMode = useSelector(selectSideDrawerMode);
    const activeMap = useSelector(selectActiveAllMap);
    const tileLayersOpacity = useSelector(selectTileLayersOpacity);
    const sideDrawerOpen = useSelector(selectSideDrawerOpen);
    const activeOrder = useSelector(selectActiveOrder);

    //#4505 For distorted image as it is showing on top left corner initially, so need to hide it first, then show it when zoom ends
    // TODO this is causing an issue when the map is displayed from a copy share link
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        if (!activeMap) {
            dispatch(actionActiveMapTileLayerLoading(false));
        } else if (activeMap.listingType === ListingType.IMAGE) {
            const leafletMap = props.leafletMap?.leafletElement;
            if (leafletMap) {
                leafletMap.on('zoomend', () => {
                    setOpacity(1);
                    leafletMap.off('zoomend');
                });
            }
        }
    }, [activeMap, props.leafletMap, dispatch]);

    useEffect(() => {
        if (activeOrder === 'FETCHING') {
            dispatch(actionActiveMapTileLayerLoading(true));
        } else if (activeOrder === 'ERROR') {
            dispatch(actionActiveMapTileLayerLoading(false));
            toast.dark('Unable to open this map.');
        } else if (activeOrder) {
            dispatch(actionActiveMapTileLayerLoading(false));
            dispatch(actionFlyToOnMap(activeOrder.boundingBox));
        }
    }, [activeOrder, dispatch]);

    //if (!mapZoom || mapZoom < 3.5) return <React.Fragment />

    if (!sideDrawerOpen) return <React.Fragment />;

    if (activeMap) {
        Analytics.Event('Main View', `Active map type ${activeMap.listingType}`, activeMap.id);
        switch (activeMap.listingType) {
            case ListingType.TILE_LAYER:
            case ListingType.ORDER:
                return (
                    <LeafletTileLayer
                        key={activeMap.id}
                        url={activeMap.tileUrl}
                        tms={true}
                        opacity={tileLayersOpacity[activeMap.id] || 1.0}
                        bounds={activeMap.boundingBox}
                        zIndex={1000}
                        onloading={() => dispatch(actionActiveMapTileLayerLoading(true))}
                        onload={() => dispatch(actionActiveMapTileLayerLoading(false))}
                    />
                );

            case ListingType.IMAGE: {
                const distoredGeometryWKT = activeMap.distoredGeometry;
                const points = GeoUtil.polygonFromPolygonWKT(distoredGeometryWKT);
                const corners = [points[2], points[3], points[1], points[0]];
                return (
                    <ReactDistortableImageOverlay
                        url={ListingHelper.getPreviewUrlForListing(activeMap.id)}
                        editMode="NONE"
                        opacity={tileLayersOpacity[activeMap.id] || opacity}
                        zIndex={1000}
                        corners={corners}
                    />
                );
            }

            case ListingType.WMS:
                if (!activeMap.serverUrl) {
                    toast.dark('This WMS layer is missing a server URL');
                    return <React.Fragment />;
                }
                return (
                    <WMSTileLayer
                        key={activeMap.id}
                        url={activeMap.serverUrl}
                        layers={activeMap.layerName}
                        opacity={tileLayersOpacity[activeMap.id] || 1.0}
                        transparent={true}
                        format={activeMap.layerImageFormat || 'image/png'}
                        zIndex={1000}
                        onloading={() => dispatch(actionActiveMapTileLayerLoading(true))}
                        onload={() => dispatch(actionActiveMapTileLayerLoading(false))}
                    />
                );

            case ListingType.ANIMATED:
                //TODO
                toast.dark('Unable to open this listing. Animated maps are coming soon.');
                break;

            case ListingType.NOT_SUPPORTED:
                // TODO
                toast.dark('Unable to open this listing. Listing is not supported');
                break;

            default:
                return <React.Fragment />;
        }
    }

    if (typeof activeOrder === 'object') {
        return (
            <LeafletTileLayer
                key={activeOrder.id}
                url={activeOrder.url}
                tms={true}
                opacity={tileLayersOpacity[activeOrder.id] || 1.0}
                bounds={activeOrder.boundingBox}
                zIndex={1000}
                onloading={() => dispatch(actionActiveMapTileLayerLoading(true))}
                onload={() => dispatch(actionActiveMapTileLayerLoading(false))}
            />
        );
    }

    if (sideDrawerMode === SideDrawerMode.MAPS || sideDrawerMode === SideDrawerMode.SUBDOMAIN_MAPS) {
        return <AllMapsProvider leafletMap={props.leafletMap} />;
    }

    if (sideDrawerMode === SideDrawerMode.YOUR_MAPS) {
        return <MyMapsProvider leafletMap={props.leafletMap} />;
    }

    if (sideDrawerMode === SideDrawerMode.MY_BOOKMARKS) {
        return <MyBookmarksProvider leafletMap={props.leafletMap} />;
    }

    if (sideDrawerMode === SideDrawerMode.PROFILE || sideDrawerMode === SideDrawerMode.PROFILE_ALL_MAPS) {
        return <ProfileMapsProvider leafletMap={props.leafletMap} />;
    }

    return <React.Fragment />;
};

export default TileLayers;
