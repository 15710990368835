import React from 'react';
import Analytics from '../../../lib/user-analytics';
import UriHelper from '../../../lib/uri-helper';
import { SitemapLink } from './footer';
import { SideDrawerMode } from '../../../store/SideDrawer/model';

const SatelliteImageryLinks = () => {
    const onClickSkymap50 = () => {
        Analytics.Event('LandingPage', 'Clicked footer Skymap50 link');
        UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE_SKYMAP50);
    };

    const onClickSentinel = () => {
        Analytics.Event('LandingPage', 'Clicked footer Sentinel link');
        UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE_SENTINEL);
    };

    const onClickLandsat = () => {
        Analytics.Event('LandingPage', 'Clicked footer Landsat link');
        UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE_LANDSAT);
    };

    const onClickCGSTL = () => {
        Analytics.Event('LandingPage', 'Clicked footer GCSTL link');
        UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE_CGSTL);
    };

    return (
        <React.Fragment>
            <SitemapLink title="Skymap50" onClick={onClickSkymap50}>
                SkyMap50
            </SitemapLink>
            <SitemapLink title="Sentinel-2" onClick={onClickSentinel}>
                Sentinel-2
            </SitemapLink>
            <SitemapLink title="Landsat-8" onClick={onClickLandsat}>
                Landsat-8
            </SitemapLink>
            <SitemapLink title="CGSTL" onClick={onClickCGSTL}>
                CGSTL
            </SitemapLink>
        </React.Fragment>
    );
};

export default SatelliteImageryLinks;
