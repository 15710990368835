import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled, { keyframes } from 'styled-components';
import ApiSocial from '../../../../api/api-social';
import Analytics from '../../../../lib/user-analytics';
import { selectLoggedIn } from '../../../../store/Account/selectors';
import { selectFavouriteMaps } from '../../../../store/Map/Favourites/selectors';
import { selectActiveAllMap } from '../../../../store/Map/SuperMap/selectors';
import { LoginModalMode } from '../../../Registration/login-enum';
import LoginRegisterDialog from '../../../Registration/login-register-dialog';

const ShareLike = () => {
    const selectedTileLayer = useSelector(selectActiveAllMap);
    const likedMaps = useSelector(selectFavouriteMaps);
    const isLoggedIn = useSelector(selectLoggedIn);

    const [likes, setLikes] = useState<number | undefined>(selectedTileLayer?.totalLikes);
    const [showLogin, setShowLogin] = useState(false);

    if (!selectedTileLayer) {
        return <React.Fragment />;
    }

    const isLikedByUser = likedMaps.find((t) => t.id === selectedTileLayer.id) ? true : false;

    const handleClickedLike = async () => {
        if (isLoggedIn) {
            Analytics.Event('Sidedraw - Maps', 'Clicked Like');
            try {
                let newLikes: number;
                if (isLikedByUser) {
                    newLikes = (await ApiSocial.unlikeListing(selectedTileLayer.id)).totalLikes;
                } else {
                    newLikes = (await ApiSocial.likeListing(selectedTileLayer.id)).totalLikes;
                }
                setLikes(newLikes);
            } catch (err) {
                toast.error(err.message);
            }
        } else {
            setShowLogin(true);
        }
    };

    return (
        <React.Fragment>
            <LoginRegisterDialog
                isOpen={showLogin}
                initialMode={LoginModalMode.LOGIN}
                onClose={() => setShowLogin(!showLogin)}
            />
            <HeartIcon
                onClick={handleClickedLike}
                isLiked={isLikedByUser}
                className={isLikedByUser ? 'fas fa-heart liked' : 'far fa-heart'}
            />
            <HeartValue onClick={handleClickedLike}>{likes ? likes : 0}</HeartValue>
        </React.Fragment>
    );
};

export default ShareLike;

interface HeartIconProps {
    isLiked: boolean;
}

const HeartPulseAnimation = keyframes`
    0% {
        transform: scale( 1 );
    } 20% {
        transform: scale( 1.25 );
    } 40% {
        transform: scale( 1 );
    } 60% {
        transform: scale( 1.25 );
    } 80% {
        transform: scale( 1 );
    } 100% {
        transform: scale( 1.25 );
    }
`;

const HeartIcon = styled.i<HeartIconProps>`
    cursor: pointer;
    color: ${(props) => (props.isLiked ? '#FF5A5A' : 'white')};
    font-size: 18px;
    height: 20px;
    pointer-events: all;

    &.liked {
        animation: ${HeartPulseAnimation} 1s;
        animation-iteration-count: 1;
    }
`;

const HeartValue = styled.p`
    cursor: pointer;
    color: white;
    margin: -5px 0 0 6px;
    font-size: 18px;
`;
