import React, { cloneElement, memo, useCallback } from 'react';
import { ListingDTO } from '../../../../api/model';
import { SentinelFeature } from '../../../../store/Map/Sentinel/model';
import MobileShareSocial from '../mobile-share-social';
import { Backdrop } from './backdrop';
import { Portal } from './portal';
import { useDisclosure } from './use-disclosure';

interface ShareContainerProps {
    listing?: ListingDTO;
    sentinelFeature?: SentinelFeature;
    children: React.ReactElement;
}

const ShareContainer = memo((props: ShareContainerProps) => {
    const { onOpen, onClose, isOpen } = useDisclosure();

    const handleOnClick = useCallback(async () => {
        onOpen();
    }, [onOpen]);

    return (
        <>
            {/* Overrides Children element's `onClick` event */}
            {cloneElement(props.children, {
                ...props.children?.props,
                onClick: handleOnClick,
            })}

            {/* Share Component */}
            {isOpen && (
                <Portal>
                    <Backdrop onClose={onClose} fade={isOpen}>
                        <MobileShareSocial
                            onClose={onClose}
                            listing={props.listing}
                            sentinelFeature={props.sentinelFeature}
                            fade={isOpen}
                        />
                    </Backdrop>
                </Portal>
            )}
        </>
    );
});

export default ShareContainer;
