import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { selectLoggedIn } from '../../../store/Account/selectors';
import { LoginModalMode } from '../../Registration/login-enum';
import LoginRegisterDialog from '../../Registration/login-register-dialog';
import { StyledButton } from '../../Shared/styled-button';

const CategoryMapsNoResults = () => {
    const isLoggedIn = useSelector(selectLoggedIn);
    const [mode, setMode] = useState<SideDrawerMode | undefined>(undefined);
    const [showLogin, setShowLogin] = useState(false);

    const onSelected = (mode: SideDrawerMode) => {
        setMode(mode);
        if (!isLoggedIn) {
            setShowLogin(true);
        }
    };

    useEffect(() => {
        if (mode && isLoggedIn) {
            UriHelper.navigateToDrawer(mode);
        }
    }, [mode, isLoggedIn]);

    return (
        <React.Fragment>
            {showLogin && (
                <LoginRegisterDialog
                    isOpen={true}
                    initialMode={LoginModalMode.LOGIN}
                    onClose={() => setShowLogin(false)}
                />
            )}
            <React.Fragment>
                <Container>
                    <Button
                        onClick={() => {
                            UriHelper.navigateToPath('/satellites');
                            Analytics.Event('My Maps', 'Clicked Search for Satellites', 'Existing User');
                        }}
                    >
                        Search for satellite imagery
                    </Button>

                    <Button
                        onClick={() => {
                            onSelected(SideDrawerMode.SHARE_MAP);
                            Analytics.Event('My Maps', 'Clicked upload a map', 'Existing User');
                        }}
                    >
                        <MyMapsUploadIcon src="/assets/floating-drawer-icons/maps-icon-black.svg" />
                        Add your maps!
                    </Button>
                </Container>
            </React.Fragment>
        </React.Fragment>
    );
};

export default CategoryMapsNoResults;

const Container = styled.div`
    margin-top: 48px;
`;

const Button = styled(StyledButton)`
    margin: 12px auto;
    display: block;
    width: 93%;
    text-transform: uppercase;
`;

const MyMapsUploadIcon = styled.img`
    width: 25px;
    margin: -4px 7px 0px 5px;
`;
