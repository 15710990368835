import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { LatLngBounds } from 'leaflet';
import {
    actionSentinelEndBoxSelect,
    actionSentinelFeatureLoading,
    actionSentinelFetchFeaturesError,
    actionSentinelResetSelectedAOI,
    actionSentinelResetSelectedFeature,
    actionSentinelSelectAOI,
} from '../../../store/Map/Sentinel/actions';
import { SatelliteAOI } from '@takor/react-leaflet-annotation-tools';
import { selectSentinelIsBoxSelectActive } from '../../../store/Map/Sentinel/selectors';
import Analytics from '../../../lib/user-analytics';
import { useDispatch, useSelector } from 'react-redux';
import SatelliteUtil from '../../../lib/satellite-util';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { SentinelImageDimension } from '../../../api/model';
import { selectSideDrawerMode } from '../../../store/SideDrawer/selectors';
import { cancelFeatures } from '../../../lib/sentinel-service';

const SentinelAOISelect = () => {
    const [invalidate, setInvalidate] = useState<boolean>(false);

    const isBoxSelectActive = useSelector(selectSentinelIsBoxSelectActive);
    const sideDrawerMode = useSelector(selectSideDrawerMode);

    const dispatch = useDispatch();
    const endBoxSelect = () => dispatch(actionSentinelEndBoxSelect());
    const selectSentinelAOI = (bbox: LatLngBounds) => dispatch(actionSentinelSelectAOI(bbox));

    const sentinelAOIControlRef = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any

    // TODO Refactor this. refer notes on useLayoutEffect in skymap50-aoi-select.tsx
    // Not ideal but something has changed to the behavior again and could not narrow it down,
    // changed to use the `useLayoutEffect` approach to fix the clearing aoi when navigating issues (#3766).
    useLayoutEffect(() => {
        // Using a variable for the ref inside the useEffect will allow us to clean it up even if it has changed
        const satelliteRef = sentinelAOIControlRef;
        return () => {
            cancelFeatures('Cancel Sentinel Search AOI');
            dispatch(actionSentinelResetSelectedAOI());
            dispatch(actionSentinelResetSelectedFeature());
            if (satelliteRef.current !== null) {
                satelliteRef.current.removeAll();
            }
        };
    }, [dispatch, sentinelAOIControlRef, sideDrawerMode]);

    useEffect(() => {
        if (isBoxSelectActive && sentinelAOIControlRef?.current) {
            sentinelAOIControlRef.current.start();
        }

        if (!isBoxSelectActive && sentinelAOIControlRef?.current) {
            sentinelAOIControlRef.current.removeAll();
        }
    }, [isBoxSelectActive]);

    const sentinelAOIText = (quality: SentinelImageDimension[]) => {
        if (quality[0].isAvailable) {
            //10m Resolution
            return {
                color: 'green',
                text: 'Best quality',
            };
        } else if (quality[1].isAvailable) {
            //20m Resolution
            return {
                color: 'yellow',
                text: 'Low quality',
            };
        } else if (quality[2].isAvailable) {
            //50m Resolution
            return {
                color: 'orange',
                text: 'Low quality',
            };
        } else if (quality[3].isAvailable) {
            //100m Resolution
            return {
                color: 'red',
                text: 'Worst quality',
            };
        } else {
            return {
                color: 'red',
                text: 'Area of Interest is too large',
            };
        }
    };

    const landsatAOIText = (quality: SentinelImageDimension[]) => {
        // Landsat based on - Constants.OVERLAY_DATA.LANDSAT.LANDSAT_8.resolution
        const landsatResMeters = quality[0];
        if (
            landsatResMeters.heightPixels < landsatResMeters.maxPixels &&
            landsatResMeters.widthPixels < landsatResMeters.maxPixels &&
            landsatResMeters.isAvailable
        ) {
            return {
                color: 'green',
                text: '',
            };
        } else {
            return {
                color: 'red',
                text: 'Area of Interest is too large',
            };
        }
    };

    // Separated landsat and sentinel logic for validation so easier to control but could be merged
    const validateAOI = (aoi: LatLngBounds): boolean => {
        const isLandsat = sideDrawerMode === SideDrawerMode.SATELLITE_LANDSAT;
        const isSentinel = sideDrawerMode === SideDrawerMode.SATELLITE_SENTINEL;

        if (isLandsat) {
            const quality: SentinelImageDimension[] = SatelliteUtil.landsatQualityEvaluation(aoi);
            const landsatValid: boolean =
                quality[0].heightPixels < quality[0].maxPixels &&
                quality[0].widthPixels < quality[0].maxPixels &&
                quality[0].isAvailable;

            if (landsatValid) {
                dispatch(actionSentinelFetchFeaturesError(undefined));
                return true;
            } else {
                // If the aoi is invalid and the user has started a search intercept and stop
                cancelFeatures('Changing Sentinel Search AOI');
                dispatch(actionSentinelFeatureLoading(false));
                const errorMessage = SatelliteUtil.sentinelErrorMessageResponse(quality[0]);
                dispatch(actionSentinelFetchFeaturesError(errorMessage));
                return false;
            }
        } else {
            const quality: SentinelImageDimension[] = SatelliteUtil.sentinelQualityEvaluation(aoi);
            const sentinelValid: boolean = quality[3].isAvailable;
            if (sentinelValid && isSentinel) {
                dispatch(actionSentinelFetchFeaturesError(undefined));
                return true;
            } else {
                // If the aoi is invalid and the user has started a search intercept and stop
                cancelFeatures('Changing Sentinel Search AOI');
                dispatch(actionSentinelFeatureLoading(false));
                const errorMessage = SatelliteUtil.sentinelErrorMessageResponse(quality[3]);
                dispatch(actionSentinelFetchFeaturesError(errorMessage));
                return false;
            }
        }
    };

    // Note: Have to invalidate the Satellite AOI when changing draw modes otherwise it will not update the
    // validation or display settings
    useEffect(() => {
        if (sideDrawerMode) {
            setInvalidate(true);
            setTimeout(() => {
                setInvalidate(false);
            }, 10);
        }
    }, [sideDrawerMode]);

    if (invalidate) {
        return <React.Fragment />;
    }

    return (
        <SatelliteAOI
            position="topright"
            sticky={false}
            scaleActive={false}
            ref={sentinelAOIControlRef}
            receiveSelectedAOI={(aoi: LatLngBounds) => {
                if (aoi) {
                    const isValidAOI = validateAOI(aoi);
                    if (isValidAOI) {
                        Analytics.Event('Main View', 'Sentinel aoi select', aoi.toBBoxString());
                        selectSentinelAOI(aoi);
                    }
                } else {
                    endBoxSelect();
                }
            }}
            fetchDisplaySettings={(aoi: LatLngBounds) => {
                if (sideDrawerMode === SideDrawerMode.SATELLITE_LANDSAT) {
                    const quality = SatelliteUtil.landsatQualityEvaluation(aoi);
                    return landsatAOIText(quality);
                } else {
                    const quality = SatelliteUtil.sentinelQualityEvaluation(aoi);
                    return sentinelAOIText(quality);
                }
            }}
        />
    );
};

export default SentinelAOISelect;
