import React, { useEffect, useState } from 'react';
import { LatLng, LatLngBounds } from 'leaflet';
import { RectifiedMap } from '../../../api/model';
import { UploadHighlightedTarget } from '../Model/model';
import { UploadSlider } from '../Shared/upload-slider';
import UploadHelper from '../Tutorial/upload-tutorial-helper';
import { UploadTutorialGuide } from '../Tutorial/upload-tutorial-guide';
import { ImageOverlay } from 'react-leaflet';
import GeorectifierUploadMap from '../UploadMap/georectifier-upload-map';

interface UploadFullScreenProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gcpArray: any;
    isSelectedGCP: (gcp: LatLng[]) => boolean;
    previewUrl: string;
    distortableBounds?: LatLng[];
    setWarpedDataset: (warped: RectifiedMap) => void;
    highlightTarget: UploadHighlightedTarget;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFullscreenMapRef: any;
    warpedDataset?: RectifiedMap;
    rectifiedMap?: RectifiedMap; // todo NOT USED ??
    updateMarkerPosition: (newPosition: LatLng, index: number) => void;
}

const UploadFullScreen = (props: UploadFullScreenProps) => {
    const [opacity, setOpacity] = useState<number>(1);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!UploadHelper.hasOptedOutUploadTutorial()) {
            setIsOpen(true);
        }
    }, []);

    const corners = props.warpedDataset && props.warpedDataset.corners;
    const bounds = corners && new LatLngBounds(corners[0], corners[1]).extend(corners[2]).extend(corners[3]);

    return (
        <React.Fragment>
            <GeorectifierUploadMap
                type={'FULL'}
                position={'FULL'}
                setRef={props.setFullscreenMapRef}
                highlightTarget={props.highlightTarget}
                baseMap
            >
                {props.warpedDataset && (
                    <ImageOverlay
                        url={props.warpedDataset.blobUrlPNG}
                        interactive={false}
                        bounds={bounds}
                        opacity={opacity}
                    />
                )}
            </GeorectifierUploadMap>
            <UploadSlider opacity={opacity} setOpacity={setOpacity} />
            {isOpen && <UploadTutorialGuide toggle={() => setIsOpen(!isOpen)} isOpen={isOpen} step={'COMPLETE'} />}
        </React.Fragment>
    );
};

export default UploadFullScreen;
