import { Input } from 'reactstrap';
import styled from 'styled-components';

interface UploadSliderProps {
    opacity: number;
    setOpacity: (opacity: number) => void;
}

export const UploadSlider = ({ opacity, setOpacity }: UploadSliderProps): JSX.Element => {
    return (
        <CalculateOpacityContainer>
            <CalculateOpacitySlider>
                <SliderText>Transparency: </SliderText>
                <Slider
                    type="range"
                    defaultValue={100}
                    value={opacity * 100}
                    step={0.01}
                    onChange={(e) => {
                        const opacity = parseInt(e.target.value) / 100;
                        setOpacity(opacity);
                    }}
                />
                <SliderValue>{(opacity * 100).toFixed()}%</SliderValue>
            </CalculateOpacitySlider>
        </CalculateOpacityContainer>
    );
};

interface SliderProps {
    value: number;
}

const SliderText = styled.div`
    color: white;
    margin-right: 5px;
    margin-top: -2px;
`;

const CalculateOpacityContainer = styled.div`
    position: absolute;
    top: calc(100vh - 120px);
    width: 100vw;
    z-index: 2000;
`;

const CalculateOpacitySlider = styled.div`
    margin: 0 auto;
    width: 400px;
    padding: 0.5rem 1rem;
    display: flex;
    background-color: rgba(0, 0, 0, 0.67);
    border-radius: 0.6rem;
    align-items: center;
    z-index: 2000;
`;

const Slider = styled(Input)<SliderProps>`
    width: 100%;
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
        #eed926 ${(props) => 100 - props.value}%
    );
    height: 0.2rem;
    padding: 0;
    box-shadow: none !important;
    border: none !important;
    -webkit-appearance: none;
    outline: none;
    border-radius: 0.6rem;

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
    }

    &:focus {
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
            #eed926 ${(props) => 100 - props.value}%
        );
    }
`;

const SliderValue = styled.p`
    vertical-align: center;
    color: white;
    font-size: 1rem;
    margin: 0;
    padding: 0;
    margin-left: 5px;
`;
