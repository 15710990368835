import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import styled from 'styled-components';
import { DomainPermissionDTO } from '../../api/model';

interface MobileSubdomainNavigationListProps {
    domains: DomainPermissionDTO[];
}

export const MobileSubdomainNavigationList = ({ domains }: MobileSubdomainNavigationListProps) => {
    const [openSubdomainList, setOpenSubdomainList] = useState<boolean>(false);

    const handleOpenSubdomain = (subdomain: string) => {
        window.open(`https://${subdomain}`, '_blank');
    };

    return (
        <React.Fragment>
            <SubdomainMenuListItem onClick={() => setOpenSubdomainList(!openSubdomainList)}>
                <SubdomainIcon src="/assets/subdomain/subdomain-white.png" />
                My Domains
            </SubdomainMenuListItem>
            <SubdomainCollapse isOpen={openSubdomainList} height={window.innerHeight / 10}>
                <SubdomainMenuListItem
                    className="subdomain-list-item"
                    onClick={() => handleOpenSubdomain('soar.earth')}
                >
                    soar.earth
                </SubdomainMenuListItem>
                {domains.map((domain: DomainPermissionDTO, index: number) => (
                    <SubdomainMenuListItem
                        onClick={() => handleOpenSubdomain(domain.domain)}
                        className="subdomain-list-item"
                        key={index}
                    >
                        {domain.domain}
                    </SubdomainMenuListItem>
                ))}
            </SubdomainCollapse>
        </React.Fragment>
    );
};

interface SubdomainMenuListProps {
    height: number;
}

const SubdomainIcon = styled.img`
    margin-left: 20px;
    margin-right: 20px;
    width: 25px;
    height: 30px;
`;

const SubdomainMenuListItem = styled.li`
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    height: 55px;
    width: 100vw;
    color: white;
    padding: 15px 0px;
    font-size: 16px;
    list-style: none;
    box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
        0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);

    &.subdomain-list-item {
        font-size: 14px;
        height: auto;
        padding: 6px 75px;
    }

    &:last-child {
        border-bottom: none;
    }
`;

const SubdomainCollapse = styled(Collapse)<SubdomainMenuListProps>`
    max-height: ${(props) => props.height}px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eee308;
        border-radius: 4px;
    }
`;
