import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import Constants from '../../../../constants';
import UriHelper, { useSentinelQuery } from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import {
    actionSentinelBeginBoxSelect,
    actionSentinelClearFeatures,
    actionSentinelEndBoxSelect,
    actionSentinelFetchFeatures,
    actionSentinelResetSelectedAOI,
    actionSentinelResetSelectedFeature,
    actionSentinelSelectAOI,
    actionSentinelSelectFeature,
} from '../../../../store/Map/Sentinel/actions';
import {
    selectSentinelError,
    selectSentinelFeatures,
    selectSentinelFeaturesLoading,
    selectSentinelIsBoxSelectActive,
    selectSentinelSelectedAOI,
    selectSentinelSelectedFeature,
} from '../../../../store/Map/Sentinel/selectors';
import { SideDrawerMode } from '../../../../store/SideDrawer/model';
import SatelliteLoading from '../satellite-loading';
import SentinelItem from '../Sentinel/sentinel-item';
import LandsatFilter, { DateRange, last30Days } from './landsat-filter';
import { addSentinelParamsToUrl, getDateFromShareLink } from '../../../../lib/sentinel-service';
import { actionFlyTo } from '../../../../store/App/actions';
import { SentinelFeature } from '../../../../store/Map/Sentinel/model';
import DrawerHint from '../../drawer-hint';
import {
    BackArrow,
    BackText,
    Button,
    ChevronIcon,
    Container,
    Content,
    Header,
    LogoContainer,
    Logo,
    ShowMoreButton,
    ShowMoreContainer,
    TeaserIcon,
    TeaserText,
    EmptyTeaserTitle,
} from '../satellite-drawer-styles';

const moment = require('moment');

const LandsatDrawer = () => {
    const bbox = useSelector(selectSentinelSelectedAOI);
    const sentinelFeatures = useSelector(selectSentinelFeatures);
    const selectedSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const sentinelFeaturesLoading = useSelector(selectSentinelFeaturesLoading);
    const sentinelError = useSelector(selectSentinelError);
    const isBoxSelectActive = useSelector(selectSentinelIsBoxSelectActive);

    const dispatch = useDispatch();
    const beginBoxSelect = () => dispatch(actionSentinelBeginBoxSelect());
    const endBoxSelect = () => dispatch(actionSentinelEndBoxSelect());
    const resetSelectedAOI = () => dispatch(actionSentinelResetSelectedAOI());
    const resetSelectedSentinelFeature = () => dispatch(actionSentinelResetSelectedFeature());
    const clearSentinelFeatures = () => dispatch(actionSentinelClearFeatures());

    const [dateRange, setDateRange] = useState<DateRange>(last30Days);
    const [showMoreLoading, setShowMoreLoading] = useState(false);
    const [closeDraw, setCloseDraw] = useState<boolean>(false);

    const sentinelShareFeature: SentinelFeature | undefined = useSentinelQuery();

    const invalidateSentinelResults = () => {
        clearSentinelFeatures();
        resetSelectedAOI();
        resetSelectedSentinelFeature();
        endBoxSelect();
    };

    useEffect(() => {
        if (sentinelShareFeature) {
            dispatch(actionFlyTo(sentinelShareFeature.bbox, undefined, true));
            dispatch(actionSentinelSelectAOI(sentinelShareFeature.bbox));
            if (UriHelper.tryGetParam('time')) {
                setDateRange(getDateFromShareLink(sentinelShareFeature.date));
            }
            dispatch(actionSentinelSelectFeature(sentinelShareFeature));
        }

        return () => {
            invalidateSentinelResults();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedSentinelFeature) {
            addSentinelParamsToUrl(selectedSentinelFeature);
        }
    }, [selectedSentinelFeature]);

    useEffect(() => {
        if (bbox) {
            dispatch(
                actionSentinelFetchFeatures(
                    bbox,
                    dateRange.startDate,
                    dateRange.endDate,
                    Constants.OVERLAY_DATA.LANDSAT.LANDSAT_8
                )
            );
        }
    }, [bbox, dateRange, dispatch]);

    const handleShowMore = () => {
        setShowMoreLoading(true);
        const newStartDate = moment(dateRange.startDate).subtract(1, 'months').toDate();
        setDateRange({ ...dateRange, startDate: newStartDate });
    };

    useEffect(() => {
        if (sentinelFeatures) {
            setShowMoreLoading(false);
        }
    }, [sentinelFeatures]);

    const reset = () => {
        if (sentinelFeatures) {
            invalidateSentinelResults();
        } else {
            clearSentinelFeatures();
            resetSelectedAOI();
            UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE);
            endBoxSelect();
            Analytics.Event('Satellite - Landsat', 'Clicked back arrow');
        }
    };

    return (
        <Container closeDraw={closeDraw}>
            <Header closeDraw={closeDraw}>
                <BackArrow data-testid="landsat-back-button" onClick={reset} />
                <BackText onClick={reset}>Back</BackText>
                <ChevronIcon
                    onClick={() => setCloseDraw(!closeDraw)}
                    className={closeDraw ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}
                />
                <LogoContainer>
                    <Logo src="/assets/floating-drawer-satellite-icons/satellite-nasa-logo.png" />
                </LogoContainer>
            </Header>

            {!sentinelFeatures && !sentinelFeaturesLoading && sentinelError ? (
                <Content>
                    <EmptyTeaserTitle />
                    <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
                    <Button
                        id="draw-aoi-button"
                        onClick={() => {
                            invalidateSentinelResults();
                            Analytics.Event('Satellite - Landsat', 'Clicked redraw area of interest');
                        }}
                    >
                        RESET AREA OF INTEREST
                    </Button>
                </Content>
            ) : null}

            {!sentinelFeatures && !sentinelFeaturesLoading && !sentinelError ? (
                <Content>
                    <EmptyTeaserTitle />
                    <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
                    <TeaserText>Click the button and drag an Area of Interest.</TeaserText>
                    <TeaserText>Let`s see what we can find!</TeaserText>
                    <Button
                        disabled={isBoxSelectActive}
                        id="draw-aoi-button"
                        onClick={() => {
                            beginBoxSelect();
                            Analytics.Event('Satellite - Landsat', 'Clicked draw area of interest');
                        }}
                    >
                        DRAW AOI
                    </Button>
                    {!isBoxSelectActive && (
                        <DrawerHint isActive={true}>Now includes the newest NASA satellite, Landsat 9!</DrawerHint>
                    )}
                </Content>
            ) : null}

            {sentinelFeaturesLoading && !showMoreLoading && !sentinelError ? (
                <Content>
                    <SatelliteLoading />
                </Content>
            ) : null}

            {sentinelFeatures && !sentinelError && (!sentinelFeaturesLoading || showMoreLoading) ? (
                <React.Fragment>
                    <LandsatFilter
                        dateRange={dateRange}
                        onSelectDateRange={(dateRange) => {
                            setDateRange(dateRange);
                        }}
                    />
                    <Content>
                        {sentinelFeatures.map((t, index) => {
                            return (
                                <SentinelItem
                                    key={`landsat-feature-${index}`}
                                    feature={t}
                                    selectedFeature={selectedSentinelFeature}
                                />
                            );
                        })}
                        <ShowMoreContainer>
                            {showMoreLoading ? (
                                <ShowMoreButton>
                                    <Spinner color="light" />
                                </ShowMoreButton>
                            ) : (
                                <ShowMoreButton
                                    onClick={() => {
                                        handleShowMore();
                                        Analytics.Event('Satellite - Landsat', 'Clicked show more');
                                    }}
                                >
                                    SHOW MORE
                                </ShowMoreButton>
                            )}
                        </ShowMoreContainer>
                    </Content>
                </React.Fragment>
            ) : null}
            {sentinelError ? <DrawerHint error>{`${sentinelError}` || sentinelError.message}</DrawerHint> : null}
        </Container>
    );
};

export default LandsatDrawer;
