import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { DomainDTO, InvoiceDTO } from '../../../api/model';
import UserHelper from '../../../lib/user-helper';

const moment = require('moment');

interface AdminInvoicesProps {
    subdomainInfo: DomainDTO;
}

const AccountInvoices = (props: AdminInvoicesProps) => {
    const [currency, setCurrency] = useState<string>('');
    const [reverseOrder, setReverseOrder] = useState<boolean>(false);
    const [invoices, setInvoices] = useState<InvoiceDTO[] | undefined>(undefined);

    useEffect(() => {
        if (props.subdomainInfo.subscription.currency) {
            setCurrency(UserHelper.getUsersCurrency(props.subdomainInfo.subscription.currency));
        }
    }, [props.subdomainInfo.subscription.currency]);

    useEffect(() => {
        if (props.subdomainInfo?.invoices?.length !== 0) {
            setInvoices(props.subdomainInfo.invoices.reverse());
        }
        return () => {
            setInvoices(props.subdomainInfo.invoices.reverse());
        };
    }, [props.subdomainInfo?.invoices]);

    useEffect(() => {
        if (invoices) {
            setInvoices(invoices.reverse());
        }
    }, [invoices, reverseOrder]);

    if (!props.subdomainInfo || props.subdomainInfo.invoices.length === 0) {
        return <React.Fragment />;
    }

    const invoiceRows = invoices?.map((invoice, idx) => {
        return (
            <RowResults key={idx}>
                {!isMobile ? (
                    <>
                        <Col md={4} className="tableResults">
                            {moment(Number(invoice.paidAt) * 1000).format('MMMM Do YYYY')}
                        </Col>
                        <Col md={4} className="tableResults">{`${currency ? currency : 'US'}  $${(
                            invoice.amount / 100
                        ).toFixed(2)}`}</Col>
                        <Col md={4} className="tableResults">
                            <a href={invoice.url} target="_blank">
                                <i className="fa fa-cloud-download"></i>
                            </a>
                        </Col>
                    </>
                ) : (
                    <ResultsMobileRow>
                        <p>{moment(Number(invoice.paidAt) * 1000).format('MMMM Do YYYY')}</p>
                        <p>{`${currency ? currency : 'US'} $${(invoice.amount / 100).toFixed(2)}`}</p>
                        <p>
                            {
                                <a href={invoice.url} target="_blank">
                                    <i className="fa fa-cloud-download"></i>
                                </a>
                            }
                        </p>
                    </ResultsMobileRow>
                )}
            </RowResults>
        );
    });

    return (
        <InvoiceDetailContainer>
            <h4>Invoices</h4>
            {!isMobile && (
                <RowTable>
                    <Col md={4} className="tableHead">
                        Date
                        <DateSortIcon
                            onClick={() => setReverseOrder(!reverseOrder)}
                            className={reverseOrder ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}
                        />
                    </Col>
                    <Col md={4} className="tableHead">
                        Amount
                    </Col>
                    <Col md={4} className="tableHead">
                        Invoice
                    </Col>
                </RowTable>
            )}
            {invoiceRows}
        </InvoiceDetailContainer>
    );
};

export default AccountInvoices;

const InvoiceDetailContainer = styled.div`
    padding: 15px;
    background-color: rgb(0, 0, 0, 0.6);
    border-radius: 6px;

    h4 {
        color: white;
    }
`;

const RowTable = styled(Row)`
    padding: 10px;
    .tableHead {
        color: white;
    }

    .tableHead:first-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const RowResults = styled(Row)`
    margin: 10px;
    .tableResults {
        color: white;
        padding: 5px;
        border-top: 1px solid rgb(255, 255, 255, 0.5);

        i {
            color: white;
        }
    }
`;

const ResultsMobileRow = styled.div`
    border-top: 1px solid white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: -17px;
    padding-top: 5px;

    i,
    p {
        color: white;
    }
`;

const DateSortIcon = styled.i`
    font-size: 15px;
    padding-top: 5px;
`;
