import styled from 'styled-components';

const TermsAndConditions = () => {
    return (
        <TermsAndConditionsContainer>
            <TermsAndConditionsHeader>
                <h3>Terms & Conditions</h3>
                <p>Last updated: April 28th, 2021</p>
            </TermsAndConditionsHeader>
            <TermsAndConditionsBody>
                <h4>1. ACCEPTANCE OF TERMS</h4>
                <ul>
                    <li>
                        1.1. The user (“You”) agree to and are bound by the Terms of Service set out below and in any
                        modified or additional terms that Soar.Earth Ltd and any related entity (together “Soar”, “We”,
                        “Us”)) may publish from time to time (collectively, the "Terms"). Soar may change these Terms
                        from time to time. Your continued access or use of the Site constitutes your acceptance of such
                        changes. Your access and use of the Site will be subject to the current version of the Terms,
                        rules and guidelines posted on the Site at the time of such use. If you do not agree to these
                        Terms, do not access or use this Site.
                    </li>
                    <li>
                        1.2. Soar may change these Terms from time to time. Your continued access or use of the Site
                        constitutes your acceptance of such changes.{' '}
                    </li>
                    <li>
                        1.3. Your access and use of the Site will be subject to the current version of the Terms, rules
                        and guidelines posted on the Site at the time of such use.{' '}
                    </li>
                    <li>1.4. If you do not agree to these Terms, do not access or use this Site. </li>
                </ul>

                <h4>2. General Terms</h4>
                <ul>
                    <li>
                        2.1. Soar operates through our website located at{' '}
                        <a href="https://soar.earth">https://soar.earth</a> (the &ldquo;Site &rdquo;) which includes
                        text, images, audio, code and other materials (collectively, the &ldquo;Content &rdquo;) and all
                        the features, and services provided. The Site, and any other features, tools, materials, or
                        other services offered from time to time by Soar are referred to here as the
                        &ldquo;Service.&rdquo;
                    </li>
                    <li>2.2. You accept Soar &rsquo;s use of any data collected under the Privacy Policy </li>
                    <li>
                        2.3. You must be at least 13 years old to use the Service or have the permission of a parent or
                        guardian.
                    </li>
                    <li>
                        2.4. You may not post violent, nude, partially nude, discriminatory, unlawful, infringing,
                        hateful, pornographic or sexually suggestive content via the Service.
                    </li>
                    <li>2.5. You are responsible for any activity that occurs through your account.</li>
                    <li>
                        2.6. You agree you will not sell, transfer, license or assign any account or rights without the
                        express written permission of Soar.{' '}
                    </li>
                    <li>
                        2.7. You understand and agree that Soar is not responsible for the Content posted on the Service
                        and you use the Service at your own risk.{' '}
                    </li>
                    <li>2.8. You may not use the Service for any illegal or unauthorized purpose.</li>
                    <li>
                        2.9. You agree to comply with all laws, rules and regulations (for example federal, state and
                        local) applicable to your use of the Service and your Content (see below), including but not
                        limited to, copyright laws.
                    </li>
                    <li>
                        2.10.You must not attempt to change, modify, adapt or alter the Service or change, modify or
                        alter another website to falsely imply that it is associated with the Service or Soar.
                    </li>
                    <li>
                        2.11. You must not interfere or disrupt the Service or servers, or networks connected to the
                        Service, including by transmitting any worms, viruses, spyware, malware, or any other code of a
                        destructive or disruptive nature.{' '}
                    </li>
                    <li>
                        2.12. You may not inject content or code or otherwise alter or interfere with the way any Soar
                        page is rendered or displayed in a user &#39;s browser or device.
                    </li>
                    <li>2.13. You must comply with any Community Guidelines provided by Soar.</li>
                    <li>
                        2.14. You must not attempt to restrict another user from using or enjoying the Service and you
                        must not encourage or facilitate violations of these Terms or any other Soar terms.
                    </li>
                    <li>
                        2.15. Violation of these Terms or any Community Guidelines may, in Soar &#39;s sole discretion,
                        result in block your access to the service.
                    </li>
                    <li>
                        2.16. We reserve the right to modify or terminate the Service or your access to the Service for
                        any reason, without notice, at any time, and without liability to you.{' '}
                    </li>
                    <li>2.17. To use the services, you will be required to:</li>
                    <ul>
                        <li>2.17.1. Create an account.</li>
                        <li>2.17.2. Submit a valid email address.</li>
                        <li>2.17.3. Create a username.</li>
                    </ul>
                    <li>
                        2.18. You can deactivate your Soar account at any time via the Account Services Tab in the
                        Dashboard. Once deactivated:
                    </li>
                    <ul>
                        <li>2.18.1 Your account may not be able to be reactivated in the future.</li>
                        <li>
                            2.18.2 Soar may continue to retain, but not display, any User Content uploaded by You and
                            any records related to your account to comply with legal requirements or in the case of a
                            dispute.
                        </li>
                    </ul>
                    <li>
                        2.19. Upon termination of your access to Soar, all licenses and other rights granted to you in
                        these Terms will cease immediately.
                    </li>
                    <li>
                        2.20. Soar reserves the right to refuse access to the Service to anyone for any reason at any
                        time.
                    </li>
                    <li>
                        2.21. Soar may, but has no obligation to, remove, edit, block, and/or monitor Content or
                        accounts containing Content that we determine in our sole discretion violates these Terms.
                    </li>
                    <li>
                        2.22. You are solely responsible for your interactions with other Users of the Service, whether
                        online or offline. You agree that Soar is not responsible or liable for the conduct of any User.{' '}
                    </li>
                    <li>
                        2.23. Soar reserves the right, but has no obligation, to monitor or become involved in disputes
                        between You and other Users.{' '}
                    </li>
                    <li>2.24. You agree that you are responsible for all data charges you incur using the Service.</li>
                    <li>
                        2.25. We prohibit crawling, scraping, caching or otherwise accessing any content on the Service
                        via automated means, except as may be the result of standard search engine protocols or
                        technologies used by a search engine with Soar &#39;s express consent.
                    </li>
                </ul>

                <h4>3. User Content </h4>
                <ul>
                    <li>
                        3.1. &ldquo;User Content &rdquo;is Content that is submitted to the platform by Users, published
                        on the Service and made available for purchase by other users.
                    </li>
                    <li>3.2. User Content does not include &ldquo;Third Party Content &rdquo;covered in clause 5.</li>
                    <li>3.3. You warrant that:</li>
                    <ul>
                        <li>
                            3.3.1. &nbsp;You own the User Content posted by You through the Service or otherwise have
                            the right to grant the rights and licenses set forth in these Terms.
                        </li>
                        <li>
                            3.3.2. The posting and use of Your User Content on or through the Service does not violate,
                            misappropriate or infringe on the rights of any third party, including, without limitation,
                            privacy rights, publicity rights, copyrights, trademark or any form of other intellectual
                            property rights;
                        </li>
                        <li>
                            3.3.3. You agree to pay for all royalties, fees, and any other monies owed by reason of Use
                            Content You post on or through the Service; and{' '}
                        </li>
                        <li>
                            3.3.4. You have the legal right and capacity to agree to these Terms in your jurisdiction.
                        </li>
                    </ul>
                    <li>3.4. User Content includes but is not limited to:</li>
                    <ul>
                        <li>3.4.1. Photographic images in any format.</li>
                        <li>3.4.2. Video images in any format, including any associated audio content.</li>
                        <li>3.4.3. Maps, both 2 Dimensional (2D) or 3 Dimensional (3D) in any format.</li>
                        <li>3.4.4. Any other image-based content in any format.</li>
                    </ul>
                    <li>
                        3.5. You bear legal responsibility for any User Content submitted by You that is accessible
                        through Soar.
                    </li>
                    <li>
                        3.6. You warrant that any User Content provided by You and uploaded to Soar was collected
                        legally under any regulatory requirements in force at the time or with all required permissions.
                    </li>
                    <li>
                        3.7. You agree to grant the license terms for any User Content made available by You through
                        Soar and will be solely responsible for the monitoring of the use or misuse of any User Content
                        provided by You.
                    </li>
                    <li>3.8. Soar does not claim ownership of any Content that You post on or through the Service. </li>
                    <li>
                        3.9. You grant to Soar an unlimited, worldwide, non-exclusive, royalty-free license and right to
                        copy, transmit, distribute, publicly perform and display (through all media now known or
                        hereafter created), and make derivative works from User Content for the purpose of allowing us
                        to edit and display User Content using the Site, promotion of the Site and Services, and
                        archiving or preserving Your Content for disputes, legal proceedings, or investigations.
                    </li>
                    <li>
                        3.10. Soar reserves the right to remove any User Content from the Service for any reason,
                        without prior notice. User Content removed from the Service may continue to be stored by Soar,
                        including, without limitation, to comply with any legal obligations, but may not be retrievable
                        without a valid court order.{' '}
                    </li>
                    <li>
                        3.11. Soar is not a backup service, and You agree that You will not rely on the Service for the
                        purposes of User Content backup.{' '}
                    </li>
                    <li>
                        3.12. Soar will not be liable to you for any modification, suspension, or discontinuation of the
                        Services, or the loss of any Content.{' '}
                    </li>
                    <li>
                        3.13. You acknowledge that the Internet may be subject to breaches of security and that the
                        submission of Content or other information may not be secure.
                    </li>
                    <li>
                        3.14. You agree that Soar is not responsible for, and does not endorse, Content posted through
                        the Service.{' '}
                    </li>
                    <li>
                        3.15. Soar does not have any obligation to prescreen, monitor, edit, or remove any Content.{' '}
                    </li>
                </ul>

                <h4>4. User content license conditions</h4>
                <ul>
                    <li>
                        4.1. You grant Users who purchase Content from You a non-exclusive, non-transferable right to
                        use, modify (except as expressly prohibited herein) and reproduce User Content worldwide, in
                        perpetuity, as expressly permitted and subject to the limitations set forth herein to use the
                        content as:
                    </li>
                    <ul>
                        <li>
                            4.1.1. As a digital reproduction, including on websites, in online advertising, in social
                            media, in mobile advertising, mobile apps, software, e-cards, e-publications (e-books,
                            e-magazines, blogs, etc.), email marketing and online media (including on video-sharing
                            services such as YouTube, Dailymotion, Vimeo, etc.,{' '}
                        </li>
                        <li>
                            4.1.2. Printed in physical form as part of product packaging and labeling, letterhead and
                            business cards, point of sale advertising, billboards, CD and DVD cover art, or in the
                            advertising and copy of tangible media, including magazines, newspapers, and books provided
                            no Image is reproduced more than 500,000 times in the aggregate.
                        </li>
                        <li>
                            4.1.3. As part of an &quot;Out-of-Home &quot;advertising campaign, including on billboards,
                            street furniture, etc., provided the intended audience for such campaign is less than
                            500,000 gross impressions.
                        </li>
                        <li>
                            4.1.4. Incorporated into film, video, television series, advertisement, or other
                            audio-visual productions for distribution in any medium now known or hereafter devised,
                            without regard to audience size, provided the budget for any such production does not exceed
                            USD $10,000.
                        </li>
                        <li>
                            4.1.5. For personal, non-commercial use (not for resale, download, distribution, or any
                            commercial use of any kind).
                        </li>
                        <li>
                            4.1.6. Process, alter, change or interpret one or more pieces of Content for internal
                            commercial use.
                        </li>
                    </ul>
                    <li>4.2. As a purchaser of User content, You MAY NOT:</li>
                    <ul>
                        <li>4.2.1. Use any Content other than as specified in Section 4.1 above.</li>
                        <li>
                            4.2.2. Resell, trade, or share any Content with any third party by providing access to such
                            Content on shared disk drives, computer networks, servers, cloud network or other intranets
                            of any nature or otherwise. This excludes common access or use within a single organisation
                            by employees or contractors of that organisation;
                        </li>
                        <li>
                            4.2.3. Use or display any Content on websites or in connection with any service designed to
                            sell or induce sales of user-commissioned &ldquo;print-on demand &quot;products using or
                            incorporating Image(s), including, by way of example only, postcards, mugs, tee shirts,
                            posters, gicl &eacute;e prints, wallpaper, artwork and other items.
                        </li>
                        <li>
                            4.2.4. Use Content in such a manner that it infringes upon any third party &#39;s trademark
                            or other intellectual property.
                        </li>
                        <li>
                            4.2.5. Use any Content (in whole or in part) as a trademark, service mark, logo, or other
                            indication of origin, or as part thereof, or to otherwise endorse or imply endorsement of
                            any goods and/or services.
                        </li>
                        <li>
                            4.2.6. Use or display any Content in such a manner that gives the impression that the
                            Content was created by You or a person other than the copyright holder of that Content.{' '}
                        </li>
                    </ul>
                </ul>

                <h4>5. Third party content and licenses</h4>
                <ul>
                    <li>
                        5.1. Soar may make available Content that is provided by third parties (together &ldquo;Third
                        Party Content &rdquo;), which may be made available through the Service.
                    </li>
                    <li>
                        5.2. Soar does not guarantee the availability of any Third-Party Content through the Service as
                        availability is beyond the control of Soar and may be restricted or blocked due to your
                        location.
                    </li>
                    <li>5.3. Third Party Content may include but is not limited to:</li>
                    <ul>
                        <li>5.3.1. Base map images provided as part of the platform.</li>
                        <li>
                            5.3.2. Image or map content viewed, purchased or downloaded through the platform from a
                            third party &nbsp;service and includes but is not limited to:
                        </li>
                        <ul>
                            <li>5.3.2.1.Satellite image content</li>
                            <li>5.3.2.2. Aerial image content</li>
                            <li>5.3.2.3. Map content</li>
                            <li>5.3.2.4. Other image content from any other source</li>
                        </ul>
                        <li>5.3.3. Information on satellite orbits</li>
                        <li>5.3.4. Weather information, and</li>
                        <li>5.3.5. Third party services offered though the platform.</li>
                    </ul>
                    <li>
                        5.4. Third Party Content provided through Soar is each governed by license terms as set out by
                        the third party.{' '}
                    </li>
                    <li>
                        5.5. The use of Third-Party Content is governed by license conditions separate from those for
                        User Content as outlined above.
                    </li>
                </ul>

                <h4>6. Soar Content</h4>
                <ul>
                    <li>
                        6.1. The Service contains content owned or licensed by Soar (&quot;Soar Content &quot;). Soar
                        Content is protected by copyright, trademark, patent, trade secret and other laws, and Soar owns
                        and retains all rights in the Soar Content and the Service. You will not remove, alter, or
                        conceal any copyright, trademark, service mark or other proprietary rights notices incorporated
                        in or accompanying the Soar Content.
                    </li>
                    <li>
                        6.2. You will not reproduce, modify, adapt, prepare derivative works based on, perform, display,
                        publish, distribute, transmit, broadcast, sell, license, or otherwise exploit the Soar Content.
                    </li>
                    <li>
                        6.3. The Soar name and logo are trademarks of Soar, and may not be copied, imitated, or used, in
                        whole or in part, without the prior written permission of Soar.{' '}
                    </li>
                    <li>
                        6.4. All page headers, custom graphics, button icons and scripts are service marks and/or
                        trademarks of Soar (whether formally registered or otherwise) and may not be copied, imitated,
                        or used, in whole or in part, without prior written permission from Soar.
                    </li>
                </ul>

                <h4>7. Purchasing of Content</h4>
                <ul>
                    <li>7.1. As a Purchaser, when purchasing Content via the Soar platform, you agree that:</li>
                    <ul>
                        <li>
                            7.1.1. The purchase is for a license for the Content, you do not own the content as outlined
                            in clause 4 and clause 5.4 of these Terms.
                        </li>
                        <li>
                            7.1.2. Any purchase of the license for the Content from the Service is an agreement between
                            You and the User who has originally provided the Content and is governed by the licensing
                            terms as set out in Sections 4 and 5 of these Terms.
                        </li>
                        <li>
                            7.1.3. You accept the content &ldquo;as is &rdquo;and without warranty of merchantability,
                            fitness for purpose, non-infringement, title, custom, trade, quiet enjoyment, and freedom
                            from computer virus.
                        </li>
                        <li>
                            7.1.4. Unless otherwise specified You will abide by any license conditions for the use of
                            any User Content or Third-Party Content purchased by You.
                        </li>
                    </ul>
                    <li>7.2. Your payment will be processed at the time of placement of the order.</li>
                </ul>

                <h4>8. Payment for User Generated Content</h4>
                <ul>
                    <li>
                        8.1. When Your User Generated Content is purchased from the Soar platform, You agree to be paid
                        according to Soar &rsquo;s Payment Policy as in force at the time of the purchase.
                    </li>
                </ul>

                <h4>9. Payment for Soar services</h4>
                <ul>
                    <li>
                        9.1. Some services available through the Soar platform are paid services and you agree that:
                    </li>
                    <ul>
                        <li>
                            9.1.1. Where the service is a subscription and this extends beyond the first period of
                            payment, Your subscription will be automatically renewed unless cancelled prior to its
                            expiry.{' '}
                        </li>
                        <li>9.1.2. Any service or subscription may be cancelled by You at any time.</li>
                        <li>
                            9.1.3. You will be able to access that subscription services up until the time at which any
                            previous payment amount expires.
                        </li>
                        <li>9.1.4. We are not liable to provide a refund on any non-use of any feature or service.</li>
                    </ul>
                </ul>

                <h4>10. Replacements and refunds</h4>
                <ul>
                    <li>
                        10.1. If the Content delivered is faulty, does not match what has been described or is not
                        within any stated specification provided by a third party, you will be entitled to a replacement
                        or refund for that Content.
                    </li>
                    <li>
                        10.2. The right to a refund does not apply if You got what You asked for but simply changed your
                        mind, found it cheaper somewhere else, decided you did not like the purchase or had no use for
                        it.
                    </li>
                </ul>

                <h4>11. Third party services</h4>
                <ul>
                    <li>
                        11.1. You acknowledge that we may not always identify paid services, sponsored content, or
                        commercial communications as such.
                    </li>
                    <li>
                        11.2. There may be links from the Service, or from communications you receive from the Service
                        to third-party web sites or features. There may also be links to third-party web sites or
                        features in images or comments within the Service. The Service also includes third-party content
                        that we do not control, maintain or endorse.{' '}
                    </li>
                    <li>
                        11.3. Functionality on the Service may also permit interactions between the Service and a
                        third-party web site or feature, including applications that connect the Service or your profile
                        on the Service with a third-party web site or feature.{' '}
                    </li>
                    <li>
                        11.4. Soar does not control any of these third-party web services or any of their content. You
                        expressly acknowledge and agree that Soar is in no way responsible or liable for any such
                        third-party services or features.{' '}
                    </li>
                </ul>

                <h4>12. Reporting Copyright and Other IP Violations</h4>
                <ul>
                    <li>
                        12.1. If you see User Content on the Service of which you are the rightful owner and it has not
                        been posted by you, you have the right to inform Soar of the infringement of your rights.{' '}
                    </li>
                    <li>
                        12.2. To report a Content violation please report this to Soar by emailing skysponsor@soar.earth
                        and using the phrase &lsquo;Copyright content violation &rsquo;in the email subject header and
                        provide the following information:
                    </li>
                    <ul>
                        <li>12.2.1. Your name</li>
                        <li>12.2.2. Your contact details including physical and email addresses</li>
                        <li>12.2.3. A screenshot or other evidence identifying the allegedly infringing content</li>
                        <li>
                            12.2.4. Evidence that you are the owner of the rights to that Content including, if
                            possible, a copy of the original Content.
                        </li>
                    </ul>
                    <li>
                        12.3. Soar takes no responsibility for the posting of third-party content and you agree that, if
                        any infringement allegation is proven, then Soar &rsquo;s sole remedial action will be the
                        removal of the infringing content.
                    </li>
                </ul>

                <h4>13. Disclaimer of Warranties</h4>
                <ul>
                    <li>
                        13.1. The Service, including, without limitation, Soar Content, is provided on an &quot;as is
                        &quot;, &quot;as available &quot;and &quot;with all faults &quot;basis.{' '}
                    </li>
                    <li>
                        13.2. Some or all features available through the Service may not be available in your region due
                        to various factors and Soar does not guarantee access to the Service.
                    </li>
                    <li>
                        13.3. To the fullest extent permissible by law, neither Soar nor any of their employees,
                        managers, officers or agents make any representations or warranties or endorsements of any kind
                        whatsoever, express or implied, as to:{' '}
                    </li>
                    <ul>
                        <li>13.3.1. The Service</li>
                        <li>13.3.2. The Soar Content</li>
                        <li>13.3.3. User Content</li>
                        <li>13.3.4. Third Party Content; or </li>
                        <li>
                            13.3.5. Security associated with the transmission of information to Soar or via the Service.{' '}
                        </li>
                    </ul>
                    <li>
                        13.4. In addition, Soar hereby disclaims all warranties, express or implied, including, but not
                        limited to, the warranties of merchantability, fitness for a particular purpose,
                        non-infringement, title, custom, trade, quiet enjoyment, system integration and freedom from
                        computer virus.{' '}
                    </li>
                    <li>
                        13.5. Soar does not represent or warrant that the Service will be error-free or uninterrupted;
                        that defects will be corrected; or that the Service or the server that makes the Service
                        available is free from any harmful components, including, without limitation, viruses.{' '}
                    </li>
                    <li>
                        13.6. Soar does not make any representations or warranties that the information on the Service
                        is accurate, complete, or useful. You acknowledge that your use of the Service is at your sole
                        risk.{' '}
                    </li>
                    <li>
                        13.7. Soar does not warrant that your use of the Service is lawful in any particular
                        jurisdiction, and Soar specifically disclaim such warranties. Some jurisdictions limit or do not
                        allow the disclaimer of implied or other warranties so the above disclaimer may not apply to you
                        to the extent such jurisdiction &#39;s law is applicable to you and these Terms.
                    </li>
                    <li>
                        13.8. By accessing or using the Service, You represent and warrant that your activities are
                        lawful in every jurisdiction where You access or use the Service.{' '}
                    </li>
                    <li>
                        13.9. Soar does not endorse Content and specifically disclaim any responsibility or liability to
                        any person or entity for any loss, damage (whether actual, consequential, punitive or
                        otherwise), injury, claim, liability or other cause of any kind or character based upon or
                        resulting from any content.{' '}
                    </li>
                </ul>

                <h4>14. Limitation of liability; waiver</h4>
                <ul>
                    <li>
                        14.1. Under no circumstances will Soar be liable to you for any loss or damages of any kind
                        (including, without limitation, for any direct, indirect, economic, exemplary, special,
                        punitive, incidental or consequential losses or damages) that are directly or indirectly related
                        to:{' '}
                    </li>
                    <ul>
                        <li>14.1.1. The Service</li>
                        <li>14.1.2. The Soar Content</li>
                        <li>14.1.3. User Content</li>
                        <li>14.1.4. Your use of, inability to use, or the performance of the service; </li>
                        <li>
                            14.1.5. Any action taken in connection with an investigation by Soar or law enforcement
                            authorities regarding your or any other party &#39;s use of the service
                        </li>
                        <li>
                            14.1.6. Any action taken in connection with copyright or other intellectual property owners
                        </li>
                        <li>14.1.7. Any errors or omissions in the Service &#39;s operation; or </li>
                        <li>
                            14.1.8. Any damage to any user &#39;s computer, mobile device, or other equipment or
                            technology including, without limitation, damage from any security breach or from any virus,
                            bugs, tampering, fraud, error, omission, interruption, defect, delay in operation or
                            transmission, computer line or network failure or any other technical or other malfunction;
                            including, without limitation, damages for lost profits, loss of goodwill, loss of data,
                            work stoppage, accuracy of results, or computer failure or malfunction, even if foreseeable
                            or even if Soar has been advised of or should have known of the possibility of such damages,
                            whether in an action of contract, negligence, strict liability or tort (including, without
                            limitation, whether caused in whole or in part by negligence, acts of God,
                            telecommunications failure, or theft or destruction of the service).{' '}
                        </li>
                    </ul>
                    <li>
                        14.2. In no event will Soar be liable to You or anyone else for loss, damage or injury,
                        including, without limitation, death or personal injury. Some jurisdictions do not allow the
                        exclusion or limitation of incidental or consequential damages, so the above limitation or
                        exclusion may not apply to You. In no event will Soar &rsquo;s total liability to you for all
                        damages, losses or causes or action exceed one hundred United States Dollars (USD 100.00).{' '}
                    </li>
                    <li>
                        14.3. You agree that in the event You incur any damages, losses or injuries that arise out of
                        Soar &#39;s acts or omissions, the damages, if any, caused to you are not irreparable or
                        sufficient to entitle You to an injunction preventing any exploitation of any web site, service,
                        property, product or other content owned or controlled by Soar, and You will have no rights to
                        enjoin or restrain the development, production, distribution, advertising, exhibition or
                        exploitation of any web site, property, product, service, or other content owned or controlled
                        by the Soar parties.{' '}
                    </li>
                    <li>
                        14.4. Soar is not responsible for the actions, content, information, or data of third parties,
                        and You release Us, our directors, officers, employees, and agents from any claims and damages,
                        known and unknown, arising out of or in any way connected with any claim You have against any
                        such third parties.{' '}
                    </li>
                </ul>

                <h4>15. Indemnity</h4>
                <ul>
                    <li>
                        15.1. You (and also any third party for whom You operate an account or activity on the Service)
                        agree to defend (at Soar &#39;s request), indemnify and hold Soar harmless from and against any
                        claims, liabilities, damages, losses, and expenses, including without limitation, reasonable
                        attorney &#39;s fees and costs, arising out of or in any way connected with any of the following
                        (including as a result of Your direct activities on the Service or those conducted on Your
                        behalf):{' '}
                    </li>
                    <ul>
                        <li>15.1.1. Your User Content or your access to or use of the Service</li>
                        <li>15.1.2. Your breach or alleged breach of these Terms</li>
                        <li>
                            15.1.3. Your violation of any third-party right, including without limitation, any
                            intellectual property right, publicity, confidentiality, property or privacy right
                        </li>
                        <li>
                            15.1.4. Your violation of any laws, rules, regulations, codes, statutes, ordinances or
                            orders of any governmental and quasi-governmental authorities, including, without
                            limitation, all regulatory, administrative and legislative authorities; or{' '}
                        </li>
                        <li>15.1.5. Any misrepresentation made by You. </li>
                    </ul>
                    <li>
                        15.2. You will cooperate as fully required by Soar in the defense of any claim. Soar reserves
                        the right to assume the exclusive defense and control of any matter subject to indemnification
                        by You, and You will not in any event settle any claim without the prior written consent of
                        Soar.{' '}
                    </li>
                </ul>

                <h4>16. Time Limitation on Claims</h4>
                <ul>
                    <li>
                        16.1. You agree that any claim you may have arising out of or related to Your relationship with
                        Soar must be filed within one (1) year of the cause of such a claim arising; otherwise, Your
                        claim is permanently barred.{' '}
                    </li>
                </ul>

                <h4>17. Governing Law and & Venue</h4>
                <ul>
                    <li>
                        17.1. These Terms are governed by and construed in accordance with the laws of Western
                        Australia, without giving effect to any principles of conflicts of law.
                    </li>
                    <li>
                        17.2. These Terms WILL SPECIFICALLY NOT BE GOVERNED BY THE UNITED NATIONS CONVENTIONS ON
                        CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS, IF OTHERWISE APPLICABLE.
                    </li>
                    <li>
                        17.3. If any provision of these Terms is held to be unlawful, void, or for any reason
                        unenforceable during arbitration or by a court of competent jurisdiction, then that provision
                        will be deemed severable from these Terms and will not affect the validity and enforceability of
                        any remaining provisions. Soar &#39;s failure to insist upon or enforce strict performance of
                        any provision of these Terms will not be construed as a waiver of any provision or right.
                    </li>
                    <li>
                        17.4. No waiver of any of these Terms will be deemed a further or continuing waiver of such term
                        or condition or any other term or condition.{' '}
                    </li>
                    <li>
                        17.5. Soar reserves the right to change this dispute resolution provision, but any such changes
                        will not apply to disputes arising before the effective date of the amendment.{' '}
                    </li>
                    <li>
                        17.6. This dispute resolution provision will survive the termination of any or all of Your
                        transactions with Soar.{' '}
                    </li>
                </ul>

                <h4>18. Entire Agreement</h4>
                <ul>
                    <li>
                        18.1. If you are using the Service on behalf of a legal entity, You represent that You are
                        authorized to enter into an agreement on behalf of that legal entity.{' '}
                    </li>
                    <li>
                        18.2. These Terms constitute the entire agreement between You and Soar and governs Your use of
                        the Service, superseding any prior agreements between You and Soar.{' '}
                    </li>
                    <li>
                        18.3. You will not assign the Terms or assign any rights or delegate any obligations hereunder,
                        in whole or in part, whether voluntarily or by operation of law, without the prior written
                        consent of Soar.{' '}
                    </li>
                    <li>
                        18.4. Any purported assignment or delegation by You without the appropriate prior written
                        consent of Soar will be null and void.{' '}
                    </li>
                    <li>18.5. Soar may assign these Terms or any rights hereunder without your consent. </li>
                    <li>
                        18.6. Neither the course of conduct between the parties nor trade practice will act to modify
                        the Terms.{' '}
                    </li>
                    <li>18.7. These Terms do not confer any third-party beneficiary rights. </li>
                </ul>

                <h4>19. Territorial Restrictions</h4>
                <ul>
                    <li>
                        19.1. The information provided within the Service is not intended for distribution to or use by
                        any person or entity in any jurisdiction or country where such distribution or use would be
                        contrary to law or regulation or which would subject Soar to any registration requirement within
                        such jurisdiction or country. We reserve the right to limit the availability of the Service or
                        any portion of the Service, to any person, geographic area, or jurisdiction, at any time and in
                        our sole discretion, and to limit the quantities of any content, program, product, service or
                        other feature that Soar provides.{' '}
                    </li>
                    <li>
                        19.2. Software related to, or made available by, the Service may be subject to United States
                        export controls. Thus, no software from the Service may be downloaded, exported or re-exported:
                        (a) into (or to a national or resident of) any country to which the United States has embargoed
                        goods; or (b) to anyone on the U.S. Treasury Department &#39;s list of Specially Designated
                        Nationals or the U.S. Commerce Department &#39;s Table of Deny Orders. By downloading any
                        software related to the Service, you represent and warrant that you are not located in, under
                        the control of, or a national or resident of, any such country or on any such list.
                    </li>
                </ul>
            </TermsAndConditionsBody>
        </TermsAndConditionsContainer>
    );
};

export default TermsAndConditions;

const TermsAndConditionsContainer = styled.div`
    padding-right: 50px;
    padding-bottom: 20px;
    padding-top: 60px;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-top: 0px;
    }
`;

const TermsAndConditionsHeader = styled.div`
    -webkit-text-fill-color: white;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.8);
    height: 60px;
    width: 100%;
    border-radius: 6px;
    display: flex;
    color: white;
    margin: auto;
    text-align: center;
    flex-direction: none;
    border-top: 0px;
    padding-top: 0px;

    h3 {
        color: inherit;
        justify-content: center;
        align-self: center;
        display: flex;
        padding-left: 20px;
    }

    p {
        color: inherit;
        opacity: 0.8;
        margin: 18px;
    }

    @media only screen and (max-width: 600px) {
        justify-content: none;
        background-color: none;
        height: 55px;
        border-radius: 0px;
        flex-direction: column;
        border-top: none;
        padding-top: 0px;
        text-align: left;

        h3 {
            justify-content: start;
            align-self: flex-start;
            padding-left: 10px;
        }

        p {
            margin: 0px 10px;
        }
    }
`;

const TermsAndConditionsBody = styled.div`
    color: white;
    margin: 0px 0px 0px 20px;

    h4 {
        font-weight: bold;
        color: #eed926;
        -webkit-text-fill-color: #eed926;
        text-align: left;
        font-size: 1.5rem;
        font-weight: 200;
    }

    li {
        color: white;
        list-style-type: none;
    }

    a {
        color: #eed926;
    }

    @media only screen and (max-width: 900px) {
        padding-left: 10px;
        padding-top: 5px;
        padding-right: 5px;
        flex-direction: column;

        ul {
            padding-inline-start: 0px;
        }
    }
`;
