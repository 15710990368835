import { ImageOverlay as LeafletImageOverlay } from 'leaflet';

import { withLeaflet, MapLayer } from 'react-leaflet';

/* eslint-disable @typescript-eslint/no-explicit-any */
class ImageOverlayEvented extends MapLayer<any, any> {
    imageOverlay: any;

    createLeafletElement(props: any): any {
        this.imageOverlay = new LeafletImageOverlay(props.url, props.bounds, {
            interactive: false,
            opacity: props.opacity,
        });

        this.imageOverlay.addEventListener('error', () => {
            // The image overlay does not give the actual url error so generic response supplied
            // We could add a xhr request but seems it will only compound the issue with more requests made
            props.onError('High Resolution Image failed');
        });

        props.onStart();

        this.imageOverlay.addEventListener('load', () => {
            props.onLoad();
        });

        return this.imageOverlay;
    }

    updateLeafletElement(fromProps: any, toProps: any) {
        if (toProps.opacity !== fromProps.opacity) {
            this.leafletElement.setOpacity(toProps.opacity);
        }
    }
}

export default withLeaflet(ImageOverlayEvented);
