import React, { useEffect, useState } from 'react';
import { LayersControl, FeatureGroup } from 'react-leaflet';
import { Terminator } from '@takor/react-leaflet-terminator';
import { Satellite } from '../../../lib/satellite-util';
import SatelliteTracker from './satellite-tracker';
import Analytics from '../../../lib/user-analytics';
import { useDispatch, useSelector } from 'react-redux';
import { selectSatelliteOverlays } from '../../../store/Map/SatelliteOverlay/selectors';
import { actionAddSatelliteOverlay, actionRemoveSatelliteOverlay } from '../../../store/Map/SatelliteOverlay/actions';
import { selectIsSatelliteBoxSelectActive, selectSatelliteAOI } from '../../../store/Map/SatelliteArchive/selectors';

const { Overlay } = LayersControl;

interface SatelliteLayersProps {
    leafletMap: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const SatelliteLayers = (props: SatelliteLayersProps) => {
    const isArchivalBoxSelectActive = useSelector(selectIsSatelliteBoxSelectActive);
    const selectedAOI = useSelector(selectSatelliteAOI);
    const satelliteOverlaysLayer = useSelector(selectSatelliteOverlays);

    const dispatch = useDispatch();
    const [activeSatelliteOverlays, setActiveSatelliteOverlays] = useState<string[]>([]);

    useEffect(() => {
        const addSatelliteOverlay = (name: string) => {
            Analytics.Event('Main View', 'Satellite Overlay', 'Add ' + name);
            dispatch(actionAddSatelliteOverlay(name));
            props.leafletMap.leafletElement.off('overlayadd', (e: { name: string }) => addSatelliteOverlay(e.name));
        };

        const removeSatelliteOverlay = (name: string) => {
            Analytics.Event('Main View', 'Satellite Overlay', 'Remove ' + name);
            dispatch(actionRemoveSatelliteOverlay(name));
            props.leafletMap.leafletElement.off('overlayremove', (e: { name: string }) =>
                removeSatelliteOverlay(e.name)
            );
        };

        props.leafletMap.leafletElement.on('overlayadd', (e: { name: string }) => addSatelliteOverlay(e.name));
        props.leafletMap.leafletElement.on('overlayremove', (e: { name: string }) => removeSatelliteOverlay(e.name));
    }, [dispatch, props.leafletMap.leafletElement]);

    useEffect(() => {
        if (satelliteOverlaysLayer) {
            setActiveSatelliteOverlays(satelliteOverlaysLayer);
        }
    }, [satelliteOverlaysLayer]);

    // TODO this needs checking
    if (!selectedAOI && isArchivalBoxSelectActive) {
        return <React.Fragment />;
    }

    return (
        <LayersControl position="bottomright" className="satellite-overlay-control">
            <Overlay name="Day / Night" checked={activeSatelliteOverlays.indexOf('Day / Night') !== -1} key="Day night">
                <FeatureGroup>
                    <Terminator />
                </FeatureGroup>
            </Overlay>

            <Overlay name="SkyMap50 1" checked={activeSatelliteOverlays.indexOf('SkyMap50 1') !== -1} key="SkyMap50 1">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Skymap501}
                        mapRef={props.leafletMap}
                        isActive={activeSatelliteOverlays.indexOf('SkyMap50 1') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay name="SkyMap50 2" checked={activeSatelliteOverlays.indexOf('SkyMap50 2') !== -1}>
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Skymap502}
                        mapRef={props.leafletMap}
                        isActive={activeSatelliteOverlays.indexOf('SkyMap50 2') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay checked={activeSatelliteOverlays.indexOf('SkyMap50 3') !== -1} name="SkyMap50 3">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Skymap503}
                        mapRef={props.leafletMap}
                        isActive={activeSatelliteOverlays.indexOf('SkyMap50 3') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay checked={activeSatelliteOverlays.indexOf('SkyMap50 4') !== -1} name="SkyMap50 4">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Skymap504}
                        mapRef={props.leafletMap}
                        isActive={activeSatelliteOverlays.indexOf('SkyMap50 4') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay checked={activeSatelliteOverlays.indexOf('Sentinel 1A') !== -1} name="Sentinel 1A">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Sentinel1A}
                        mapRef={props.leafletMap}
                        isActive={activeSatelliteOverlays.indexOf('Sentinel 1A') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay checked={activeSatelliteOverlays.indexOf('Sentinel 2A') !== -1} name="Sentinel 2A">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Sentinel2A}
                        mapRef={props.leafletMap}
                        isActive={activeSatelliteOverlays.indexOf('Sentinel 2A') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay checked={activeSatelliteOverlays.indexOf('Sentinel 1B') !== -1} name="Sentinel 1B">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Sentinel1B}
                        mapRef={props.leafletMap}
                        isActive={activeSatelliteOverlays.indexOf('Sentinel 1B') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay checked={activeSatelliteOverlays.indexOf('Sentinel 2B') !== -1} name="Sentinel 2B">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Sentinel2B}
                        mapRef={props.leafletMap}
                        isActive={activeSatelliteOverlays.indexOf('Sentinel 2B') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay checked={activeSatelliteOverlays.indexOf('Landsat 8') !== -1} name="Landsat 8">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.Landsat8}
                        mapRef={props.leafletMap}
                        isActive={activeSatelliteOverlays.indexOf('Landsat 8') !== -1}
                    />
                </FeatureGroup>
            </Overlay>

            <Overlay checked={activeSatelliteOverlays.indexOf('Int. Space Station') !== -1} name="Int. Space Station">
                <FeatureGroup>
                    <SatelliteTracker
                        satelliteTracker={Satellite.ISS}
                        mapRef={props.leafletMap}
                        isActive={activeSatelliteOverlays.indexOf('Int. Space Station') !== -1}
                    />
                </FeatureGroup>
            </Overlay>
        </LayersControl>
    );
};

export default SatelliteLayers;
