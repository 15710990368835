import { ListingDTO } from '../api/model';

const VIEWED_MAPS_KEY = 'viewed-maps';

export interface ViewedMapSessionObject {
    id: number;
    title: string;
    author: string;
    datestamp: number;
}

export default class MapViewHistoryHelper {
    public static getViewedListingHistory = (): ViewedMapSessionObject[] => {
        const storage = localStorage.getItem(VIEWED_MAPS_KEY);
        if (!storage) {
            return [];
        }

        try {
            const maps: ViewedMapSessionObject[] = JSON.parse(storage);
            return maps;
        } catch (err) {
            console.log(err);
            return [];
        }
    };

    static addMapToViewedMapsHistory = (viewedMap: ViewedMapSessionObject) => {
        const storedMaps = this.getViewedListingHistory().filter((t) => t.id !== viewedMap.id);
        storedMaps.unshift(viewedMap);
        localStorage.setItem(VIEWED_MAPS_KEY, JSON.stringify(storedMaps));
    };

    public static removeMapToViewedMapsHistory = (id: number) => {
        const storedMaps = this.getViewedListingHistory().filter((t) => t.id !== id);
        localStorage.setItem(VIEWED_MAPS_KEY, JSON.stringify(storedMaps));
    };

    public static addListingToViewedMapsHistory = (viewedListing: ListingDTO) => {
        const item: ViewedMapSessionObject = {
            id: viewedListing.id,
            title: viewedListing.title,
            author: viewedListing.userName,
            datestamp: new Date().getTime(),
        };
        this.addMapToViewedMapsHistory(item);
    };
}
