import React from 'react';
import styled from 'styled-components';

export const UploadTutorialIntroduction = (): JSX.Element => {
    return (
        <React.Fragment>
            <UploadTutorialIntroductionImage src="/assets/upload/upload-intro-image.png" />
            <UploadTutorialIntroductionText>
                Add control points to your image by clicking on the edit map and placing it in the correct location in
                the edit screen.
                <br />
                <b>The minimum number of points </b>is <b>6</b>, you can add more but too many can cause the image to
                generate incorrectly.
            </UploadTutorialIntroductionText>
        </React.Fragment>
    );
};

const UploadTutorialIntroductionImage = styled.img`
    width: 100%;
    height: 17.25rem;
`;

const UploadTutorialIntroductionText = styled.div`
    color: rgba(255, 255, 255, 0.87);
    font-size: 18px;
    margin: 0.5rem 0rem;
`;
