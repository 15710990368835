import Api from './api';

// TODO: #3059 - Refactor 'any'
/* eslint-disable @typescript-eslint/no-explicit-any */
export default class ApiContactUs extends Api {
    public static reportProblem(email: string, message: string, listingId: number | string): Promise<any> {
        const data = {
            email: email,
            message: message,
            listingId: listingId,
        };
        return this.axios.post('/v1/contact-us/problem', data).then((res) => {
            return res.data;
        });
    }

    public static postContactUs(email: string, subject: string, message: string, industry: string): Promise<any> {
        const data = {
            email: email,
            subject: subject,
            message: message,
            industry: industry,
        };
        return this.axios.post('/v1/contact-us', data).then((res) => {
            return res.data;
        });
    }

    public static postCSWCustomOrder(
        email: string,
        mobile: string | undefined,
        subject: string,
        message: string,
        industry: string,
        requirements: string,
        geometryWKT: string | undefined,
        countryCode: string | undefined
    ): Promise<void> {
        const data = {
            email: email,
            mobile: mobile,
            subject: subject,
            message: message,
            industry: industry,
            requirements: requirements,
            geometryWKT: geometryWKT,
            countryCode: countryCode,
        };
        return this.axios.post('/v1/contact-us/csw', data).then((res) => {
            return res.data;
        });
    }
}
