import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import styled from 'styled-components';
import Constants from '../../constants';
import Analytics from '../../lib/user-analytics';
import UserHelper from '../../lib/user-helper';
import { actionGetSubdomain } from '../../store/Dashboard/actions';
import { selectSubdomainInfo } from '../../store/Dashboard/selectors';
import { selectLoggedIn } from '../../store/Account/selectors';
import AccountDetails from '../Dashboard/Account/account-details';
import ChangeCreditCard from '../Dashboard/Account/change-credit-card';
import { LoginModalMode } from '../Registration/login-enum';
import LoginRegisterDialog from '../Registration/login-register-dialog';
import { StyledButton } from '../Shared/styled-button';

const SuspendedSubdomain = () => {
    const dispatch = useDispatch();

    const domain = useSelector(selectSubdomainInfo);
    const loggedIn = useSelector(selectLoggedIn);

    const [token, setToken] = useState<string | undefined>(undefined);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!domain) {
            dispatch(actionGetSubdomain());
            setToken(UserHelper.validateAndGetIdToken());
        }
    }, [domain, token, loggedIn, dispatch]);

    const invalidate = () => {
        window.location.reload();
    };

    const logoutUser = () => {
        UserHelper.resetIdToken();
        window.location.reload();
        Analytics.Event('Plus', 'Logged out user at suspended account page');
    };

    return (
        <SuspendedBackgroundContainer>
            <SuspendedNoticeContainer>
                <Logo src="/assets/Soar+-logo_FINAL_white_crop.png" />
                <SuspendedNoticeContent>
                    <SuspendedTitle>This domain has expired, please contact the admin</SuspendedTitle>
                    {!domain ? (
                        <SuspendedContainer>
                            {!token ? (
                                <>
                                    <SuspendedNoticeText>
                                        If you are the admin please sign in for more details.
                                    </SuspendedNoticeText>
                                    <Button onClick={() => setIsOpen(true)}>SIGN IN</Button>
                                </>
                            ) : (
                                <>
                                    <SuspendedNoticeText className="error">
                                        Sorry you are not the admin for this domain
                                    </SuspendedNoticeText>
                                    <Button onClick={logoutUser}>SIGN OUT</Button>
                                </>
                            )}
                        </SuspendedContainer>
                    ) : (
                        <SuspendedContainer>
                            <AccountDetails domain={domain} />
                            <StripeProvider apiKey={Constants.STRIPE_API_KEY}>
                                <Elements>
                                    <ChangeCreditCard handleUpdatedCreditCard={(_) => invalidate()} />
                                </Elements>
                            </StripeProvider>
                        </SuspendedContainer>
                    )}
                </SuspendedNoticeContent>

                {isOpen ? (
                    <LoginRegisterDialog
                        isOpen={isOpen}
                        initialMode={LoginModalMode.LOGIN}
                        onClose={() => setIsOpen(!isOpen)}
                    />
                ) : null}
            </SuspendedNoticeContainer>
        </SuspendedBackgroundContainer>
    );
};

export default SuspendedSubdomain;

const SuspendedBackgroundContainer = styled.div`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)),
        url('/assets/subdomain/subdomain-image.jpg');
    background-size: cover;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
`;

const SuspendedNoticeContainer = styled.div`
    max-width: 600px;
    margin: auto;
`;

const SuspendedNoticeContent = styled.div`
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    min-width: 400px;
    padding: 20px;
    margin: 0px auto;
    display: block;
    text-align: left;
    margin-bottom: 20px;

    @media only screen and (max-width: 600px) {
        min-width: 90%;
    }
`;

const SuspendedNoticeText = styled.p`
    color: white;
    font-size: 16px;
    padding: 10px 0px;

    &.error {
        color: red;
    }
`;

const SuspendedTitle = styled.h3`
    color: white;
    font-size: 20px;
`;

const Logo = styled.img`
    margin: 0px 10% 20px 10%;
    width: 80%;
`;
const SuspendedContainer = styled.div`
    width: 100%;
    margin: auto;
    display: block;
    text-align: center;

    // Overrides the stripe component styles
    div {
        background-color: transparent !important;
        text-align: center;
        margin-left: 0px;
        width: 100%;
        max-width: 100%;
        flex: auto;

        label {
            background-color: transparent;
        }

        button {
            width: 100%;
            font-size: 16px !important;
            margin: 0px 10px;
        }

        img {
            float: right;
            margin: 10px 0px;
        }
    }
`;

const Button = styled(StyledButton)`
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
`;
