import axios from 'axios';
import Api from './api';
import { AutocompleteDTO, AutocompleteType } from './model';

let CANCEL_TOKEN = axios.CancelToken.source();

export default class ApiAutocomplete extends Api {
    public static autocomplete(text: string, types: AutocompleteType[], limit?: number): Promise<AutocompleteDTO[]> {
        const query = {
            text,
            types: types.join(','),
            limit: limit || 10,
        };
        return this.axios
            .get('/v1/platform/autocomplete', { params: query, cancelToken: CANCEL_TOKEN.token, cache: false })
            .then((res) => res.data.list);
    }

    public static autocompleteFromMagic(magicKey: string): Promise<AutocompleteDTO[]> {
        return this.axios
            .get(`/v1/platform/autocomplete/magic/${magicKey}`, { cache: false })
            .then((res) => res.data.list);
    }

    public static generateAutoCompleteCancelToken() {
        CANCEL_TOKEN = axios.CancelToken.source();
    }

    public static cancelAutoComplete(cancelReason: string) {
        // Allow a reason for cancellation to be added
        CANCEL_TOKEN.cancel(cancelReason);
        // Generate a new token each time a request is cancelled;
        ApiAutocomplete.generateAutoCompleteCancelToken();
    }

    public static categoryAutoComplete(text: string): Promise<AutocompleteDTO[]> {
        const CATEGORY_LIMIT = 10;
        const CATEGORY_TYPES: AutocompleteType = 'SEARCH';
        const query = {
            text,
            types: CATEGORY_TYPES,
            limit: CATEGORY_LIMIT,
        };
        return this.axios
            .get('/v1/platform/autocomplete', { params: query, cancelToken: CANCEL_TOKEN.token, cache: false })
            .then((res) => res.data.list)
            .then((list) => {
                // Uppercase first character of the title
                return list.map((t) => {
                    return { title: `${t.title.charAt(0).toUpperCase()}${t.title.substring(1)}`, type: t.type };
                });
            })
            .catch((_) => {
                // Catch cancel token silently
            });
    }
}
