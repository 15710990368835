import React, { useState } from 'react';
import styled from 'styled-components';
import { MarkdownViewer } from './markdown-viewer';

interface TruncatedTextProps {
    text: string;
    length: number;
    isDashboard?: boolean;
}

export const TruncatedDashboardText = ({ text, length, isDashboard }: TruncatedTextProps) => {
    const [showMore, setShowMore] = useState<boolean>(false);
    const lineCount = text.split('\n\n');

    return (
        <React.Fragment>
            <TruncatedTextContainer showMore={showMore} maxViewableLines={length}>
                <MarkdownViewer text={text} />
            </TruncatedTextContainer>
            {!isDashboard && lineCount.length >= length ? (
                <ShowMoreString onClick={() => setShowMore(!showMore)}>
                    {showMore ? '... less' : '... more'}
                </ShowMoreString>
            ) : null}
            {isDashboard && lineCount.length >= length ? <ShowMoreString>..redacted description</ShowMoreString> : null}
        </React.Fragment>
    );
};

interface TruncatedTextStyledProps {
    showMore: boolean;
    maxViewableLines: number;
}

const TruncatedTextContainer = styled.div<TruncatedTextStyledProps>`
    --lh: 1.4rem;
    --max-lines: ${props => props.maxViewableLines};
    max-height: ${props => props.showMore ? "100%" : "calc(var(--lh) * var(--max-lines))"};
    overflow: hidden;
`;

const ShowMoreString = styled.span`
    color: #eed926;
    float: right;

    :hover {
        opacity: 0.8;
        cursor: pointer;
    }
`;
