import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import UriHelper from '../../../../lib/uri-helper';
import Analytics, { ConversionEvent } from '../../../../lib/user-analytics';
import UserHelper from '../../../../lib/user-helper';
import { SideDrawerMode } from '../../../../store/SideDrawer/model';
import { selectMyProfile } from '../../../../store/Account/selectors';
import ComparisonModal from '../../../Shared/ImageComparisonModal/comparison-modal';
import { StyledButton } from '../../../Shared/styled-button';
import DrawerContainerHeader from '../../drawer-container-header';
import DrawerHint from '../../drawer-hint';
import { Container, Content, Logo, LogoContainer, TeaserIcon } from '../satellite-drawer-styles';

enum Hint {
    NewCollect = 1,
    Archive = 2,
}

const Skymap50Drawer = () => {
    const [showComparisonModal, setShowComparisonModal] = useState(false);
    const [showHint, setShowHint] = useState<Hint | undefined>(undefined);

    const myProfile = useSelector(selectMyProfile);

    const back = () => {
        UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE);
        Analytics.Event('Satellite - Skymap50', 'Clicked back arrow');
    };

    return (
        <Container>
            <DrawerContainerHeader handleBack={() => back()} data-testid="sentinel-back-button">
                <LogoContainer>
                    <Logo src="/assets/floating-drawer-satellite-icons/satellite-skymap50-logo.png" />
                </LogoContainer>
            </DrawerContainerHeader>

            <Content>
                <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
                <TeaserList>
                    <TeaserListItem>
                        Purchase high resolution{' '}
                        <Link onClick={() => setShowComparisonModal(true)}>50cm per pixel satellite imagery</Link>
                    </TeaserListItem>
                    <TeaserListItem>
                        Mosaiced and colour balanced file delivered directly to your Soar.Earth account
                    </TeaserListItem>
                    <TeaserListItem>View the imagery online with Soar.Earth or download as a GeoTIFF</TeaserListItem>
                    <TeaserListItem>No tools or skills are required</TeaserListItem>
                    <TeaserListItem>Click &amp; collect credit card payments</TeaserListItem>
                </TeaserList>

                {!UserHelper.hasWritePermissionsForSubdomain(myProfile) ? (
                    <DrawerHint isActive={true}>You do not have permission to order satellite imagery!</DrawerHint>
                ) : (
                    <React.Fragment>
                        <Button
                            onMouseOver={() => setShowHint(Hint.NewCollect)}
                            onMouseOut={() => setShowHint(undefined)}
                            onClick={() => {
                                UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE_SKYMAP50_IMAGE_THE_FUTURE);
                                Analytics.Event('Satellite - SkyMap50', 'Clicked new collect');
                                Analytics.Conversion(ConversionEvent.SELECT_ORDER_TYPE);
                            }}
                        >
                            NEW COLLECT
                        </Button>

                        <Button
                            onMouseOver={() => setShowHint(Hint.Archive)}
                            onMouseOut={() => setShowHint(undefined)}
                            onClick={() => {
                                UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE_SKYMAP50_SEARCH_ARCHIVE);
                                Analytics.Event('Satellite - SkyMap50', 'Clicked archival');
                                Analytics.Conversion(ConversionEvent.SELECT_ORDER_TYPE);
                            }}
                        >
                            ARCHIVAL
                        </Button>

                        <DrawerHint
                            isActive={showHint === Hint.NewCollect}
                            forceInactive={showHint !== Hint.NewCollect}
                        >
                            Task a new collect for your Area of Interest
                        </DrawerHint>

                        <DrawerHint isActive={showHint === Hint.Archive} forceInactive={showHint !== Hint.Archive}>
                            Browse archived scenes
                        </DrawerHint>
                    </React.Fragment>
                )}
            </Content>

            <ComparisonModal
                isOpen={showComparisonModal}
                toggle={() => setShowComparisonModal(false)}
                image1="/assets/skymap50-comparison/skymap50.png"
                image2="/assets/skymap50-comparison/sentinel.png"
                image1_label="SkyMap50"
                image2_label="Sentinel"
            />
        </Container>
    );
};

export default Skymap50Drawer;

const TeaserList = styled.ul`
    color: white;
`;

const TeaserListItem = styled.li`
    color: white;
    margin-right: 16px;
`;

const Button = styled(StyledButton)`
    margin: 0 auto 15px auto;
    padding: 8px 25px;
    min-width: 148px;
`;

const Link = styled.a`
    color: #eed926 !important;
    text-decoration: underline !important;
    cursor: pointer;
`;
