import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSentinelMapZoom } from '../store/Map/Sentinel/selectors';

const MAX_SENTINEL_ZOOM = 9;

export function useMobileCanSearchForSatelliteImagery() {
    const mapZoomLevel = useSelector(selectSentinelMapZoom);
    const [canSearchForSatellite, setCanSearchForSatellite] = useState(false);

    const handleCanSearchForImagery = useCallback((zoomLevel: number) => {
        if (zoomLevel >= MAX_SENTINEL_ZOOM) {
            setCanSearchForSatellite(true);
            return;
        }
        setCanSearchForSatellite(false);
    }, []);

    useEffect(() => {
        if (mapZoomLevel) {
            handleCanSearchForImagery(mapZoomLevel);
        }
    }, [handleCanSearchForImagery, mapZoomLevel]);

    return canSearchForSatellite;
}
