import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { actionGetSubdomain } from '../../../store/Dashboard/actions';
import { selectSubdomainInfo } from '../../../store/Dashboard/selectors';
import NavigationPanel from '../Shared/navigation-panel';
import AccountActions from './account-actions';
import AccountBillingDetails from './account-billing-details';
import AccountDetails from './account-details';
import Invoices from './account-invoices';
import DashboardContainer from '../Shared/dashboard-container';

const AccountManagement = () => {
    const dispatch = useDispatch();
    const domain = useSelector(selectSubdomainInfo);

    const fetchSubdomain = () => dispatch(actionGetSubdomain());

    return (
        <AccountManagementContainer>
            <NavigationPanel label="Account Billing" />
            {domain && (
                <DashboardContainer>
                    <AccountDetails domain={domain} />
                    <AccountBillingDetails domain={domain} invalidate={fetchSubdomain} />
                    <Invoices subdomainInfo={domain} />
                    <AccountActions domain={domain} invalidate={fetchSubdomain} />
                </DashboardContainer>
            )}
        </AccountManagementContainer>
    );
};

export default AccountManagement;

const AccountManagementContainer = styled.div`
    @media (max-width: 600px) {
        padding-right: 0px;
    }
`;
