import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import styled from 'styled-components';
import { DomainPermissionDTO } from '../../api/model';
import Analytics from '../../lib/user-analytics';

interface NavbarSubdomainListProps {
    subdomains: DomainPermissionDTO[];
}

export const NavbarSubdomainList = (props: NavbarSubdomainListProps) => {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    const selectSubdomain = (domain: string) => {
        Analytics.Event('Navigation - Main Nav', 'Changed Subdomain', domain);
        Analytics.Event('Navigation', 'Changed Subdomain', domain);
        window.location.href = window.location.protocol + '//' + domain;
    };

    if (props.subdomains.length === 0) return <React.Fragment />;

    return (
        <SubdomainDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
            <SubdomainDropdownToggle color="link" caret />
            <SubdomainDropdownMenu right>
                <SubdomainDropdownItem header>Select a Soar+ Account</SubdomainDropdownItem>
                <SubdomainDropdownItem divider />
                <SubdomainDropdownItem onClick={(e) => selectSubdomain(e.target.innerText)}>
                    soar.earth
                </SubdomainDropdownItem>
                {props.subdomains.map((subdomain, idx) => (
                    <SubdomainDropdownItem onClick={(e) => selectSubdomain(e.target.innerText)} key={idx}>
                        {subdomain.domain}
                    </SubdomainDropdownItem>
                ))}
            </SubdomainDropdownMenu>
        </SubdomainDropdown>
    );
};

const SubdomainDropdown = styled(Dropdown)``;

const SubdomainDropdownToggle = styled(DropdownToggle)`
    color: white;
    width: 8px;
    padding-left: 0;
    margin-left: -5px;

    &:hover {
        color: #eed926;
    }

    &:after {
        display: block;
    }
`;

const SubdomainDropdownMenu = styled(DropdownMenu)`
    width: 320px;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    margin-top: 35px;
    border-radius: 6px;
    max-height: 500px;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const SubdomainDropdownItem = styled(DropdownItem)`
    font-size: 1rem;
    color: white;

    &.dropdown-header {
        font-size: 16px;
    }

    &:hover {
        background: #eed926;
        color: #000;

        &.dropdown-header {
            background: none;
            color: white;
        }
    }

    &:focus {
        outline: none;
    }

    &.dropdown-divider {
        border-top: 1px solid rgba(255, 255, 255, 0.8) !important;
    }
`;
