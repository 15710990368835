import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ListingDTO } from '../../../api/model';
import ListingHelper from '../../../lib/listing-helper';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { actionClearSelectedListing } from '../../../store/Map/MapSelection/actions';

interface MobileCarouselItemCardProps {
    listing: ListingDTO;
    imageHeight?: string;
}

const MobileCardItem = (props: MobileCarouselItemCardProps) => {
    const dispatch = useDispatch();
    const clearSelectedListing = (clearListing: boolean) => dispatch(actionClearSelectedListing(clearListing));

    const handleSelectListing = (mapId: number) => {
        clearSelectedListing(true);
        Analytics.Event('Mobile - Search Bar', 'Selected Carousel Listing', mapId);
        requestAnimationFrame(() => {
            UriHelper.navigateToPath(`/maps/${mapId}`);
        });
    };

    return (
        <MobileCarouselCard
            onClick={() => {
                handleSelectListing(props.listing.id);
            }}
            key={props.listing.id}
        >
            <MobileCarouselCardImage
                src={ListingHelper.getPreviewUrlForListing(props.listing.id)}
                imageHeight={props.imageHeight}
            />
            <MobileCarouselCardTitle>{props.listing.title}</MobileCarouselCardTitle>
        </MobileCarouselCard>
    );
};

export default MobileCardItem;

export const MobileCarouselCard = styled.div`
    margin: 4px;
    display: flex;
    flex-direction: column;
    width: 40vw;
    border: 1px solid white;
    border: 1px solid rgb(255 255 255 / 10%);
    border-radius: 6px;
    background-color: hsla(0, 100%, 100%, 0.01);
    box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
        0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
`;

interface MobileCarouselCardImageProps {
    imageHeight?: string;
}

export const MobileCarouselCardImage = styled.img<MobileCarouselCardImageProps>`
    height: ${(props) => (props.imageHeight ? props.imageHeight : '120px')};
    object-fit: cover;
    width: inherit;
    border-radius: 6px;
    padding-right: 2px;
`;

export const MobileCarouselCardTitle = styled.div`
    margin: 3px 0px;
    font-size: 14px;
    width: inherit;
    color: rgba(255, 255, 255, 0.87);
    clear: both;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
`;
