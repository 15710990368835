import React from 'react';
import { selectSideDrawerMode } from '../../../store/SideDrawer/selectors';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import SentinelAOISelect from './sentinel-aoi-select';
import SentinelFeatures from './sentinel-features';
import { useSelector } from 'react-redux';

const SentinelLayers = () => {
    const sideDrawerMode = useSelector(selectSideDrawerMode);

    switch (sideDrawerMode) {
        case SideDrawerMode.SATELLITE:
        case SideDrawerMode.SATELLITE_SENTINEL:
        case SideDrawerMode.SATELLITE_LANDSAT:
            return (
                <React.Fragment>
                    <SentinelAOISelect />
                    <SentinelFeatures />
                </React.Fragment>
            );
    }
    return <React.Fragment />;
};

export default SentinelLayers;
