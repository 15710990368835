import { actionTypes as at } from './constants';
import { ListingDTO } from '../../../api/model';
import { Action } from '../../root-reducer';

interface StoreMap {
    activeTileLayerFetching: boolean;
    activeTileLayerLoading: boolean;
    activeTileLayer: ListingDTO | undefined;
    tileLayers: { [id: number]: ListingDTO };
}

const initialState: StoreMap = {
    activeTileLayerFetching: false,
    activeTileLayerLoading: false,
    tileLayers: {},
    activeTileLayer: undefined,
};

// TODO: Delete this reducer.
export default (state: StoreMap = initialState, action: Action): StoreMap => {
    switch (action.type) {
        case at.MAP_FETCH_TILE_LAYER_BY_ID_SUCCESS: {
            const tileLayer = action.payload as ListingDTO;
            return {
                ...state,
                tileLayers: {
                    ...state.tileLayers,
                    [tileLayer.id]: tileLayer,
                },
            };
        }

        case at.MAP_CLEAR_TILE_LAYER: {
            const tileLayerId = action.payload as number;

            delete state.tileLayers[tileLayerId];
            return {
                ...state,
                tileLayers: {
                    ...state.tileLayers,
                },
            };
        }

        case at.MAP_ACTIVE_TILE_LAYER_CHANGED:
            return {
                ...state,
                activeTileLayer: action.payload,
            };

        case at.MAP_ACTIVE_TILE_LAYER_FETCHING:
            return {
                ...state,
                activeTileLayerFetching: action.payload,
            };

        case at.MAP_ACTIVE_TILE_LAYER_LOADING:
            return {
                ...state,
                activeTileLayerLoading: action.payload,
            };

        default:
            return state;
    }
};
