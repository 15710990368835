import React, { useEffect } from 'react';
import styled from 'styled-components';
import OrderList from './order-list';
import NavigationPanel from '../Shared/navigation-panel';
import { PulseLoader } from '../../Shared/pulse-loader';
import NoImageryModal from '../Imagery/Shared/no-imagery-modal';
import UriHelper from '../../../lib/uri-helper';
import { StyledButton } from '../../Shared/styled-button';
import profileIconUrl from '../profile-icon-url';
import Analytics from '../../../lib/user-analytics';
import { selectSatelliteOrders } from '../../../store/Map/Tasks/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { actionFetchSatelliteOrders } from '../../../store/Map/Tasks/actions';
import DashboardContainer from '../Shared/dashboard-container';

const SatelliteOrders = () => {
    const dispatch = useDispatch();
    const orders = useSelector(selectSatelliteOrders);

    useEffect(() => {
        if (!orders) {
            dispatch(actionFetchSatelliteOrders());
        }
    }, [orders, dispatch]);

    if (!orders) return <PulseLoader />;

    return (
        <ProfileTasksContainer>
            <NavigationPanel label="My Satellite Queue" />
            <DashboardContainer>
                {orders.length === 0 ? (
                    <React.Fragment>
                        <NoImageryModal
                            icon={profileIconUrl.satelliteQueueListUrl}
                            title={'It looks like your Satellites Queue is empty'}
                            text={'Check the tutorials to setup your first task!'}
                            mobileText={''}
                        />
                        <Button
                            onClick={() => {
                                UriHelper.navigateToPath('/satellites');
                                Analytics.Event('My Dashboard', 'Clicked tasks search for satellite imagery');
                            }}
                        >
                            Search for satellite imagery
                        </Button>
                    </React.Fragment>
                ) : (
                    <OrderList orders={orders} />
                )}
            </DashboardContainer>
        </ProfileTasksContainer>
    );
};

export default SatelliteOrders;

const ProfileTasksContainer = styled.div`
    @media (max-width: 900px) {
        padding-right: 0px;
    }
`;

const Button = styled(StyledButton)`
    margin: 12px auto;
    display: block;
    z-index: 1000;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;
