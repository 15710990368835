import React from 'react';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Permission } from '../../../api/model';
import SoarHelper from '../../../lib/soar-helper';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import UserHelper from '../../../lib/user-helper';
import { DashboardMode } from '../../../store/Dashboard/model';
import { selectMyProfile } from '../../../store/Account/selectors';
import profileIconUrl from '../profile-icon-url';
import MenuLink from './menu-link';
import NavigationPanel from '../Shared/navigation-panel';
import DashboardContainer from '../Shared/dashboard-container';

interface MenuSubdomainProps {
    dashboardMode?: DashboardMode;
}

const MenuSubdomain = (props: MenuSubdomainProps) => {
    const myProfile = useSelector(selectMyProfile);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isWrite, setIsWrite] = useState(false);
    const { dashboardMode } = props;

    const [userPermission, setUserPermission] = useState(Permission.None);

    useEffect(() => {
        if (myProfile) {
            const permission = UserHelper.getSubdomainPermission(myProfile);
            setUserPermission(permission);
            if (!isMobile && permission < Permission.Review) {
                handleModeSelected(DashboardMode.PROFILE);
            }
        }
    }, [myProfile]);

    useEffect(() => {
        setIsAdmin(userPermission >= Permission.Admin);
        setIsWrite(userPermission >= Permission.Write);
    }, [userPermission]);

    const logout = () => {
        Analytics.Event('My Dashboard', 'Clicked to sign out');
        UserHelper.resetIdToken();
        window.location.href = `${window.location.protocol}//${window.location.host}`;
    };

    const handleModeSelected = (mode: DashboardMode) => {
        UriHelper.navigateToDashboard(mode);
        Analytics.Event('My Dashboard', 'Clicked menu item', mode);
    };

    return (
        <AdminSideBarContainer>
            {isMobile && <NavigationPanel label={'Dashboard'} />}
            <DashboardContainer>
                <FloatingSideBarContainer>
                    <MenuContainer>
                        <AdminUserDetails>
                            <AdminUserName>{`Hello, ${myProfile?.firstName || 'Soar User'}`}</AdminUserName>
                            <p>You have {SoarHelper.permissionsEnumToUIText(userPermission)} access on the page.</p>
                        </AdminUserDetails>
                        <AdminSideBarLinkList>
                            {(isAdmin || isWrite) && (
                                <MenuLink
                                    handleMenuItemClick={() => handleModeSelected(DashboardMode.SUBDOMAIN_OVERVIEW)}
                                    iconLight={profileIconUrl.dashboardLightUrl}
                                    iconDark={profileIconUrl.dashboardDarkUrl}
                                    selected={dashboardMode === DashboardMode.SUBDOMAIN_OVERVIEW}
                                    label="Dashboard"
                                />
                            )}
                            <MenuLink
                                handleMenuItemClick={() => handleModeSelected(DashboardMode.PROFILE)}
                                iconLight={profileIconUrl.profileLightUrl}
                                iconDark={profileIconUrl.profileDarkUrl}
                                selected={dashboardMode === DashboardMode.PROFILE}
                                label="Edit My Profile"
                            />
                            {isAdmin && (
                                <MenuLink
                                    handleMenuItemClick={() => handleModeSelected(DashboardMode.USER_MANAGEMENT)}
                                    iconLight={profileIconUrl.userManagementLightUrl}
                                    iconDark={profileIconUrl.userManagementDarkUrl}
                                    selected={dashboardMode === DashboardMode.USER_MANAGEMENT}
                                    label="User Management"
                                />
                            )}
                            {(isAdmin || isWrite) && (
                                <MenuLink
                                    handleMenuItemClick={() => handleModeSelected(DashboardMode.SATELLITE_QUEUE)}
                                    iconLight={profileIconUrl.satelliteQueueLightUrl}
                                    iconDark={profileIconUrl.satelliteQueueDarkUrl}
                                    selected={dashboardMode === DashboardMode.SATELLITE_QUEUE}
                                    label="Satellite Queue"
                                />
                            )}
                            {(isAdmin || isWrite) && (
                                <MenuLink
                                    handleMenuItemClick={() => handleModeSelected(DashboardMode.DATA_MANAGEMENT)}
                                    iconLight={profileIconUrl.dataManagementLightUrl}
                                    iconDark={profileIconUrl.dataManagementDarkUrl}
                                    selected={dashboardMode === DashboardMode.DATA_MANAGEMENT}
                                    label="Data Management"
                                />
                            )}
                            {isAdmin && (
                                <MenuLink
                                    handleMenuItemClick={() => handleModeSelected(DashboardMode.ACCOUNT_MANAGEMENT)}
                                    iconLight={profileIconUrl.payoutSettingsLightUrl}
                                    iconDark={profileIconUrl.payoutSettingsDarkUrl}
                                    selected={dashboardMode === DashboardMode.ACCOUNT_MANAGEMENT}
                                    label="Account Billing"
                                />
                            )}

                            <MenuLink
                                handleMenuItemClick={logout}
                                iconLight={profileIconUrl.logoutLightUrl}
                                iconDark={profileIconUrl.logoutDarkUrl}
                                selected={false}
                                label="Sign Out"
                            />
                        </AdminSideBarLinkList>
                    </MenuContainer>

                    <AdminSidebarTermsLink onClick={() => handleModeSelected(DashboardMode.TERMS_AND_CONDITION)}>
                        Terms & Conditions
                    </AdminSidebarTermsLink>
                </FloatingSideBarContainer>
                <SideBarSpacer />
            </DashboardContainer>
        </AdminSideBarContainer>
    );
};

export default MenuSubdomain;

const AdminSideBarContainer = styled.div`
    color: white;
    width: 330px;
    margin-right: 15px;

    @media only screen and (max-width: 600px) {
        background-color: transparent;
        width: 100vw;
        margin-right: 0px;
    }
`;

const FloatingSideBarContainer = styled.div`
    position: fixed;

    @media only screen and (max-width: 600px) {
        position: relative;
    }
`;

const SideBarSpacer = styled.div`
    width: 330px;
    height: 20px;
`;

const MenuContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 6px;
`;

const AdminSidebarTermsLink = styled.div`
    text-decoration-line: underline;
    background-color: transparent;
    padding-top: 10px;
    width: 330px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;

    @media only screen and (max-width: 600px) {
        text-align: left;
        padding-left: 1.3rem;
    }
`;

const AdminUserDetails = styled.div`
    color: white;
    width: 330px;
    padding: 10px 5px 0px 5px;

    p {
        color: rgba(255, 255, 255, 0.3);
        text-align: center;
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;

const AdminUserName = styled.h1`
    color: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const AdminSideBarLinkList = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 0px 0px 6px 6px;

    @media only screen and (max-width: 600px) {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 0px;
    }
`;
