import ReactGA from 'react-ga4';
// TODO refer 4550
import * as ReactUA from 'react-ga';

import { Provider } from 'react-redux';
import store from './store/store';
import { history } from './store/history';
// Allowed use of connected-react-router
// eslint-disable-next-line no-restricted-imports
import { ConnectedRouter } from 'connected-react-router';
import HttpsRedirect from 'react-https-redirect';
import ConfiguredRoot from './root-configured';
import ErrorBoundary from './error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import SEO from './components/Helmet/seo';

history.listen((location) => {
    ReactUA.set({ page: location.pathname });
    ReactUA.send({ hitType: 'pageview', page: location.pathname });
    ReactGA.set({ page: location.pathname });
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
});

const Root: React.FC = (_) => {
    return (
        <HttpsRedirect disabled={process.env.NODE_ENV === 'development'}>
            <ErrorBoundary>
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <HelmetProvider>
                            <SEO />
                            <ConfiguredRoot />
                        </HelmetProvider>
                    </ConnectedRouter>
                </Provider>
            </ErrorBoundary>
        </HttpsRedirect>
    );
};

export default Root;
