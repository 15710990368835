import React from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { DomainUserDTO } from '../../../api/model';

export interface Cells {
    name: string;
    selector: string;
    sortable: boolean;
    format?: (row) => void;
    ignoreRowClick?: boolean;
    center?: boolean;
    button?: boolean;
    grow?: number;
    hide?: number | string;
    maxWidth?: string;
    width?: string;
}

interface UsersDataTableProps {
    data: DomainUserDTO[];
    columns: Cells[];
}

const ListUsersDataTable: React.FC<UsersDataTableProps> = (props) => {
    return (
        <DataTableContainer>
            <DataTableCustom
                columns={props.columns}
                data={props.data}
                highlightOnHover
                defaultSortField="timestamp"
                pagination
                count={20}
            />
        </DataTableContainer>
    );
};

export default ListUsersDataTable;

const DataTableContainer = styled.div`
    padding: 0px 10px;
`;

const DataTableCustom = styled(DataTable)`
    background-color: transparent !important;
    overflow: hidden;
    height: auto;

    div {
        color: white !important;
    }

    span {
        color: white !important;
        font-size: 14px;
    }

    select {
        text-align: center;
        align-items: center;
        color: black !important;
        background-color: #eed926;
        border-radius: 3px;
        font-size: 14px;
        opacity: 5;
        height: 35px;
    }

    .rdt_TableRow {
        border-top: 1px solid white;
    }

    .rdt_TableFooter {
        display: flex;
        justify-content: center;
        color: white !important;
    }

    #pagination-first-page {
        background-color: #eed926;
    }

    #pagination-next-page {
        background-color: #eed926;
    }

    #pagination-last-page {
        background-color: #eed926;
    }

    #pagination-previous-page {
        background-color: #eed926;
    }

    @media only screen and (max-width: 600px) {
        .rdt_TableHeader {
            min-height: 0px !important;
            padding: 0px !important;
        }

        .rdt_TableFooter {
            div:first-of-type,
            span:first-of-type {
                display: none !important;
            }
        }

        #pagination-previous-page,
        #pagination-last-page,
        #pagination-next-page,
        #pagination-first-page {
            width: 30px !important;
            height: 30px !important;

            svg {
                width: 17px !important;
                height: 20px !important;
                margin-top: -10px;
            }
        }
    }
`;
