import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import SoarHelper from '../../../lib/soar-helper';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import UserHelper from '../../../lib/user-helper';
import { selectLoggedIn, selectMyProfile } from '../../../store/Account/selectors';
import { DashboardMode } from '../../../store/Dashboard/model';
import profileIconUrl from '../../Dashboard/profile-icon-url';
import { LoginModalMode } from '../../Registration/login-enum';
import LoginRegisterDialog from '../../Registration/login-register-dialog';

const ANIMATION_DURATION = 200; //ms

interface MobileNavigationProfileProps {
    onOpen: () => void;
}

const MobileNavigationProfile = (props: MobileNavigationProfileProps) => {
    const isLoggedIn = useSelector(selectLoggedIn);
    const myProfile = useSelector(selectMyProfile);

    const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);

    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const isSoar = SoarHelper.isSoar();

    useEffect(() => {
        if (isProfileDialogOpen) {
            props.onOpen();
        }
    }, [isProfileDialogOpen, props]);

    const toggleMenu = () => {
        setIsProfileDialogOpen((current) => !current);
        Analytics.Event('Mobile - Profile Navigation', 'Clicked Dropdown');
    };

    const handleClickLogin = () => {
        Analytics.Event('Mobile - Profile Navigation', 'Clicked Login');
        setShowRegister(false);
        setShowLogin(true);
        setIsProfileDialogOpen(false);
    };

    const handleClickSignout = () => {
        Analytics.Event('Mobile - Profile Navigation', 'Clicked sign out');
        UserHelper.resetIdToken();
        window.location.href = `${window.location.protocol}//${window.location.host}`;
    };

    const handleClickRegister = () => {
        Analytics.Event('Mobile - Profile Navigation', 'Clicked To Register');
        setShowLogin(false);
        setShowRegister(true);
        setIsProfileDialogOpen(false);
    };

    const handleClickDashboard = () => {
        Analytics.Event('Mobile - Profile Navigation', 'Clicked To View My Dashboard');
        UriHelper.navigateToDashboard(DashboardMode.DASHBOARD);
    };

    const handleClickMyMaps = () => {
        Analytics.Event('Mobile - Profile Navigation', 'Clicked My Maps');
        UriHelper.navigateToDashboard(DashboardMode.MY_IMAGERY);
    };

    const handleClickProfile = () => {
        setIsProfileDialogOpen(false);
        Analytics.Event('Mobile - Profile Navigation', 'Clicked To View My Profile');
        UriHelper.navigateToPath('/profile/' + myProfile?.userId);
    };

    const handleMyAvatar = () => {
        // TO DO: create new component specific to mobile
        UriHelper.navigateToPath('/dashboard/profile/');
        Analytics.Event('Mobile - Profile Navigation', 'Clicked to open Edit Avatar');
    };

    return (
        <React.Fragment>
            <ProfileIcon
                src={
                    !myProfile
                        ? '/assets/avatars/default-avatar.svg'
                        : myProfile.avatarUrl || UserHelper.getDefaultAvatar(myProfile.userId as string)
                }
                onClick={toggleMenu}
            />

            {isProfileDialogOpen ? (
                <React.Fragment>
                    <ScreenFillContainer onClick={() => toggleMenu()} />
                    <ProfileContainer containerHeight={!myProfile ? '176px' : isSoar ? '423px' : '313px'}>
                        {isLoggedIn ? (
                            <>
                                <MenuList>
                                    <ProfileAvatar>
                                        <ProfileAvatarNav
                                            src={
                                                !myProfile
                                                    ? '/assets/avatars/default-avatar.svg'
                                                    : myProfile.avatarUrl ||
                                                      UserHelper.getDefaultAvatar(myProfile.userId as string)
                                            }
                                        />
                                        <AvatarEditButton
                                            onClick={handleMyAvatar}
                                            src={'/assets/dashboard/edit-icon.png'}
                                        />
                                    </ProfileAvatar>
                                    <ProfileDetails>
                                        <ProfileUserName>
                                            <ProfileName>{myProfile?.name}</ProfileName>
                                            {myProfile?.reputable ? (
                                                <ReputableUserIcon
                                                    alt="Verified"
                                                    title="Verified"
                                                    src="/assets/verified-user-tick.svg"
                                                />
                                            ) : null}
                                        </ProfileUserName>
                                    </ProfileDetails>
                                    {isSoar ? (
                                        <MenuListItem onClick={handleClickProfile}>
                                            <Icon src={profileIconUrl.profileLightUrl} />
                                            My Profile
                                        </MenuListItem>
                                    ) : null}
                                    <MenuListItem onClick={handleClickDashboard}>
                                        <Icon src={profileIconUrl.dataManagementLightUrl} />
                                        My Dashboard
                                    </MenuListItem>
                                    {SoarHelper.isSoar() && (
                                        <MenuListItem onClick={handleClickMyMaps}>
                                            <Icon src={profileIconUrl.imageryLightUrl} />
                                            My Maps
                                        </MenuListItem>
                                    )}
                                    <MenuListItem onClick={handleClickSignout}>
                                        <Icon src={profileIconUrl.logoutLightUrl} />
                                        Sign Out
                                    </MenuListItem>
                                    <TermsContainer>
                                        <TermLink
                                            onClick={() => {
                                                Analytics.Event('Mobile - Profile Navigation', 'Clicked privacy link');
                                                UriHelper.navigateToPath('/privacy');
                                            }}
                                        >
                                            Privacy Policy
                                        </TermLink>
                                        <TermLink
                                            onClick={() => {
                                                Analytics.Event(
                                                    'Mobile - Profile Navigation',
                                                    'Clicked terms and conditions'
                                                );
                                                UriHelper.navigateToPath('/terms');
                                            }}
                                        >
                                            Terms & Conditions
                                        </TermLink>
                                    </TermsContainer>
                                </MenuList>
                            </>
                        ) : (
                            <React.Fragment>
                                <MenuList>
                                    <MenuListItem onClick={handleClickLogin}>Sign in</MenuListItem>
                                    <MenuListItem onClick={handleClickRegister}>Sign up</MenuListItem>
                                </MenuList>
                                <TermsContainer>
                                    <TermLink
                                        onClick={() => {
                                            Analytics.Event('Mobile - Profile Navigation', 'Clicked privacy link');
                                            UriHelper.navigateToPath('/privacy');
                                        }}
                                    >
                                        Privacy Policy
                                    </TermLink>
                                    <TermLink
                                        onClick={() => {
                                            Analytics.Event(
                                                'Mobile - Profile Navigation',
                                                'Clicked terms and conditions'
                                            );
                                            UriHelper.navigateToPath('/terms');
                                        }}
                                    >
                                        Terms & Conditions
                                    </TermLink>
                                </TermsContainer>
                            </React.Fragment>
                        )}
                    </ProfileContainer>
                </React.Fragment>
            ) : null}

            {/* TODO: Login register dialogs */}
            {showLogin ? (
                <LoginRegisterDialog
                    isOpen={showLogin}
                    initialMode={LoginModalMode.LOGIN}
                    onClose={() => setShowLogin(!showLogin)}
                />
            ) : null}

            {showRegister ? (
                <LoginRegisterDialog
                    isOpen={showRegister}
                    initialMode={LoginModalMode.REGISTER}
                    onClose={() => setShowRegister(!showRegister)}
                />
            ) : null}
        </React.Fragment>
    );
};

export default MobileNavigationProfile;

const ProfileIcon = styled.img`
    width: 35px;
    height: 35px;
    margin: 8px;
    border: 1px solid #eed926;
    border-radius: 200px;
`;

const ScreenFillContainer = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(2px);
    background: rgba(0, 0, 0, 0.3);
`;

const OpenAnimation = (height: string | undefined) => keyframes`
from {
    top: 20px;
 }
  0% {
    opacity: 0;
    width: 0;
    height: 0;
  }

  100% {
    opacity : 1;
    width: 80vw;
    height: ${height ? height : '80vh'};
  }
`;

interface ProfileContainerProps {
    containerHeight?: string;
}

const ProfileContainer = styled.div<ProfileContainerProps>`
    animation: ${ANIMATION_DURATION}ms linear 0s ${(props) => OpenAnimation(props.containerHeight)};
    position: absolute;
    right: 0;
    top: 53px;
    width: 80vw;
    height: auto;
    max-height: ${(props) => (props.containerHeight ? props.containerHeight : '80vh')};
    overflow: hidden;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 6px;
    padding: 8px;
    padding-top: 0px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border: 1px solid rgba(255, 255, 255, 0.1);

    p {
        color: white;
    }
`;
const MenuList = styled.ul`
    list-style: none;
    padding: 0;
    animation-timing-function: ease-in-out;
    margin-bottom: 0px;
`;

const MenuListItem = styled.li`
    height: 55px;
    padding: 14px;
    color: white;
    font-size: 16px;
    letter-spacing: 0.8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    margin-left: -8px;
    margin-right: -8px;
`;

const Icon = styled.img`
    width: 20px;
    height: 20px;
    margin: -3px 10px 0px 10px;
`;

const ProfileAvatar = styled.div`
    margin: 12px auto;
    width: 100px;
`;

const ProfileAvatarNav = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #eed926;
`;

const AvatarEditButton = styled.img`
    width: 28px;
    height: 28px;
    border: 3px solid rgba(0, 0, 0, 0.3);
    background-color: rgb(0 0 0 / 67%);
    padding: 1px 1px 3px 3px;
    position: absolute;
    top: 86px;
    margin-left: -30px;
    border-radius: 6px;
`;

const ProfileDetails = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ProfileName = styled.h3`
    color: #eed926;
    font-size: 22px;
    letter-spacing: 0.8px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0px;
`;

const ReputableUserIcon = styled.img`
    width: 13px;
    height: 15px;
    margin-left: 2px;
`;

const ProfileUserName = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
`;

const TermsContainer = styled.div`
    background-color: transparent;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0px 8px 0px;
    justify-content: space-around;
`;

const TermLink = styled.div`
    background-color: transparent;
    color: rgba(255, 255, 255, 0.67);
    text-decoration-line: underline;
    font-size: 13px;
`;
