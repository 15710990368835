import React from 'react';
import styled from 'styled-components';
import { GlobalStyleDrawerContainer } from './drawer-styles';

interface ThinDrawerProps {
    children: React.ReactNode;
}

/**
 * ThinDrawer
 * Wrapper that applies a standardized thin width to the drawer
 * @required children React.ReactNode
 */

const ThinDrawer = (props: ThinDrawerProps) => {
    return <ThinDrawerContainer id="drawer">{props.children}</ThinDrawerContainer>;
};

export default ThinDrawer;

const ThinDrawerContainer = styled(GlobalStyleDrawerContainer)`
    width: 286px;
`;
