import Select from 'react-select';

interface MultiSelectOption {
    value: string;
    label: string;
}

interface MultiselectProps {
    options: MultiSelectOption[];
    onChange: (values: MultiSelectOption[]) => void;
    defaultValue?: MultiSelectOption[];
}

const customStyles = {
    container: (provided) => ({
        ...provided,
        '& :hover': {
            borderColor: '#eed926',
        },
    }),
    control: (provided) => ({
        ...provided,
        backgroundColor: 'none',
        borderColor: 'rgba(255, 255, 255, 0.6)',
        boxShadow: 'none',
        color: 'rgba(255, 255, 255, 0.6)',
        '& :hover': {
            borderColor: '#eed926',
        },
    }),
    menuList: (provided) => ({
        ...provided,
        height: '160px',
    }),
    input: (provided) => ({
        ...provided,
        color: 'rgba(255, 255, 255, 0.6)',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    },
};

export const Multiselect = (props: MultiselectProps) => {
    return (
        <Select
            options={props.options}
            isMulti
            styles={customStyles}
            onChange={props.onChange}
            defaultValue={props.defaultValue}
        />
    );
};
