import styled from 'styled-components';
import ApiAnalytics from '../../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote, ListingDTO } from '../../../api/model';
import ListingHelper from '../../../lib/listing-helper';
import UriHelper from '../../../lib/uri-helper';
import ImageryShare from '../Imagery/imagery-share';

interface BookmarkMasonaryCardProps {
    listing: ListingDTO;
}

const BookmarkMasonaryCard = (props: BookmarkMasonaryCardProps) => {
    return (
        <BookmarkCard>
            <ImageryShare listing={props.listing} />
            <BookmarkImage
                src={ListingHelper.getPreviewUrlForListing(props.listing.id)}
                onClick={() => {
                    UriHelper.navigateToMap(props.listing.id);
                    ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.SEARCH, props.listing.id);
                }}
            />
            <BookmarkCardDetails>
                <BookmarkTitle>{props.listing.title}</BookmarkTitle>
            </BookmarkCardDetails>
        </BookmarkCard>
    );
};

export default BookmarkMasonaryCard;

const BookmarkCard = styled.div`
    width: 100%;

    @media only screen and (max-width: 600px) {
        padding: 3px;
    }
`;

const BookmarkImage = styled.img`
    border: 1px solid transparent;
    background-color: rgb(255 255 255 / 2%);
    border-radius: 6px;
    width: inherit;

    &:hover {
        background: rgba(0, 0, 0, 0.4);
        -webkit-transition: all 0.2s 0s ease-in-out;
        -moz-transition: all 0.2s 0s ease-in-out;
        -o-transition: all 0.2s 0s ease-in-out;
        transition: all 0.2s 0s ease-in-out;
        opacity: 0.5;
        cursor: pointer;
    }
`;

const BookmarkCardDetails = styled.div`
    background-color: rgb(0 0 0 / 45%);
    box-shadow: 0px -2px 2px 0px rgb(0 0 0 / 37%);
    margin: -25px 0px 0px 0px;
    position: absolute;
    width: 100%;

    @media only screen and (max-width: 600px) {
        position: relative;
    }
`;

const BookmarkTitle = styled.p`
    color: rgba(255, 255, 255, 0.87);
    font-size: 16px;
    margin-bottom: 0px;
    margin-left: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
