import styled from 'styled-components';
import { StyledButton } from '../../Shared/styled-button';
import { toast } from 'react-toastify';

interface UploadCalculateProgressControlProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gcpArray: any;
    calc: () => void;
    toggle?: () => void;
}

// Loam library suggests only 6 points but has some strange behaviours when warping, more points seem to make the process complete quicker
const MIN_GCPS = 6;
const MAX_GCPS = 10;

const UploadCalculateProgressControl = (props: UploadCalculateProgressControlProps) => {
    const disabled = props.gcpArray?.length <= MIN_GCPS - 1 || props.gcpArray?.length > MAX_GCPS;
    return (
        <CalculateGCPDetails>
            <CalculateButtonContainer>
                <CalculateButtonDisplay>
                    <CalculateButton
                        onClick={() => {
                            if (!disabled) {
                                props.calc();
                                props.toggle && props.toggle();
                            } else {
                                toast.dark(`Please select between ${MIN_GCPS} and ${MAX_GCPS} points.`);
                            }
                        }}
                        isDisabled={!disabled}
                    >
                        Generate Image
                    </CalculateButton>
                </CalculateButtonDisplay>
            </CalculateButtonContainer>
            <CalculateProgressText>{`GCPS : ${
                props.gcpArray?.length ? props.gcpArray.length : 0
            } / ${MAX_GCPS}`}</CalculateProgressText>
        </CalculateGCPDetails>
    );
};

export default UploadCalculateProgressControl;

interface CalculateButtonStyleProps {
    isDisabled: boolean;
}

const CalculateGCPDetails = styled.div`
    display: flex;
    flex-direction: column;
`;

const CalculateButtonContainer = styled.div`
    width: max-content;
    margin: 0 5px;
`;

const CalculateButtonDisplay = styled.div`
    display: flex;
    flex-direction: row;
`;

const CalculateButton = styled(StyledButton)<CalculateButtonStyleProps>`
    margin: auto;
    opacity: ${(props) => (props.isDisabled ? 1 : 0.8)};
    width: max-content;
    text-transform: none;

    &:hover {
        opacity: 1;
    }
`;

const CalculateProgressText = styled.div`
    color: rgba(255, 255, 255, 0.87);
    margin: 0 auto;
`;
