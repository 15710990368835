import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ApiAnalytics from '../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote, AutocompleteDTO } from '../../api/model';
import GeoUtil from '../../lib/geo-util';
import SoarHelper from '../../lib/soar-helper';
import UriHelper from '../../lib/uri-helper';
import { useFlyTo } from '../../lib/use-fly-to';
import Analytics from '../../lib/user-analytics';
import { actionClearSelectedSearch, actionSetSelectedSearchTerm } from '../../store/Map/MapSelection/actions';
import {
    selectSelectedCategory,
    selectSelectedContinent,
    selectSelectedSearchTerm,
} from '../../store/Map/MapSelection/selectors';
import SearchContainer from './search-container';
import SearchContentCurrentEvents from './search-content-current-events';
import SearchContentExplore from './search-content-explore';
import SearchContentHistory from './search-content-history';
import SearchContentNearbyMaps from './search-content-nearby-maps';
import SearchContentWhatsHot from './search-content-whats-hot';
import SearchInput from './search-input';
import SearchResults from './search-results';

const SearchBar = () => {
    const [hasFocus, setHasFocus] = useState(false);
    const [autocompleteResults, setAutocompleteResults] = useState<AutocompleteDTO[]>([]);
    const [isSearchLoading, setIsSearchLoading] = useState(false);

    const dispatch = useDispatch();
    const selectedContinent = useSelector(selectSelectedContinent);
    const selectedCategory = useSelector(selectSelectedCategory);
    const searchTerm = useSelector(selectSelectedSearchTerm);
    const setSearchTerm = (searchTerm: string | undefined) => dispatch(actionSetSelectedSearchTerm(searchTerm));
    const clearSelectedSearch = useCallback(() => dispatch(actionClearSelectedSearch()), [dispatch]);

    const flyTo = useFlyTo();

    useEffect(() => {
        if (selectedContinent && selectedContinent.continent) {
            setHasFocus(true);
        }
    }, [selectedContinent]);

    useEffect(() => {
        if (selectedContinent?.continent) {
            Analytics.Event('Search Bar', 'Clicked Continent', selectedContinent?.continent.name);
        }
    }, [selectedContinent]);

    useEffect(() => {
        if (selectedCategory?.title) {
            Analytics.Event('Search Bar', 'Clicked Category', selectedCategory.title);
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (searchTerm) {
            Analytics.Event('Search Bar', 'User Searched', searchTerm);
        }
    }, [searchTerm]);

    useEffect(() => {
        if (hasFocus) {
            Analytics.Event('Search Bar', 'Search Open');
        }
    }, [hasFocus]);

    useEffect(() => {
        return () => {
            clearSelectedSearch();
        };
    }, [clearSelectedSearch]);

    const handleSelectListing = (id: number) => {
        clearSelectedSearch();
        setHasFocus(false);
        UriHelper.navigateToMap(id);
        ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.SEARCH, id);
        clearSelectedSearch();
    };

    const handleSelectProfile = (userId: string) => {
        clearSelectedSearch();
        setHasFocus(false);
        Analytics.Event('Search Bar', 'Selected Profile', userId);
        UriHelper.navigateToPath(`/profile/${userId}`);
    };

    const handleSelectAutocomplete = (autocomplete: AutocompleteDTO) => {
        Analytics.Event('Search Bar', `Tag Search ${autocomplete.type}`, autocomplete.title);
        if (autocomplete.type === 'USER' && autocomplete.userId) {
            handleSelectProfile(autocomplete.userId);
        }

        if (autocomplete.type === 'ADDRESS' && autocomplete.geometryWKT) {
            setHasFocus(false);
            const latlngBounds = GeoUtil.latLngFromWKT(autocomplete.geometryWKT);
            flyTo(latlngBounds, true);
        }

        if (autocomplete.type === 'COORDINATE' && autocomplete.latlng) {
            const bounds = autocomplete.latlng.toBounds(5000);
            setHasFocus(false);
            flyTo(bounds, true);
        }
    };

    const handleEnterKeyPress = () => {
        if (searchTerm) {
            const coordinate = GeoUtil.getCoordinateFromSearchTerm(searchTerm);
            if (coordinate) {
                const bounds = coordinate.toBounds(5000);
                setHasFocus(false);
                flyTo(bounds, true);
            }
        }
    };

    const isSoar = SoarHelper.isSoar();

    return (
        <React.Fragment>
            <SearchInput
                handleOnSearchInputHasFocus={(hasFocus) => {
                    setHasFocus(hasFocus);
                }}
                handleAutocompleteResults={(autocompleteResults) => {
                    setAutocompleteResults(autocompleteResults);
                }}
                handleOnSearchLoading={(isLoading) => {
                    setIsSearchLoading(isLoading);
                }}
                handleSearchTerm={(searchTerm) => {
                    setSearchTerm(searchTerm);
                }}
                handleEnterKeyPress={handleEnterKeyPress}
                isLoading={isSearchLoading}
            />

            <PageFillContainer
                onClick={() => {
                    setHasFocus(false);
                    Analytics.Event('Search Bar', 'Search Closed');
                }}
                className={hasFocus ? 'open' : 'closed'}
            />

            <SearchContainer
                hasFocus={hasFocus}
                onClose={() => {
                    setHasFocus(false);
                    Analytics.Event('Search Bar', 'Search Closed');
                }}
            >
                <React.Fragment>
                    {selectedContinent || selectedCategory || searchTerm ? (
                        <React.Fragment>
                            <SearchResults
                                handleSelectListing={handleSelectListing}
                                autoCompleteResults={autocompleteResults}
                                handleSelectAutocomplete={handleSelectAutocomplete}
                            />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {isSoar && <SearchContentWhatsHot handleSelectListing={handleSelectListing} />}

                            <SearchContentHistory handleSelectListing={handleSelectListing} />
                            {isSoar && (
                                <SearchContentCurrentEvents
                                    handleSelectListing={handleSelectListing}
                                    handleSelectProfile={handleSelectProfile}
                                />
                            )}
                            {isSoar && <SearchContentNearbyMaps handleSelectListing={handleSelectListing} />}

                            <SearchContentExplore handleSelectListing={handleSelectListing} />
                        </React.Fragment>
                    )}
                </React.Fragment>
            </SearchContainer>
        </React.Fragment>
    );
};

export default SearchBar;

const PageFillContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0%;
    bottom: 0;
    opacity: 0;
    z-index: 9999;
    backdrop-filter: blur(2px);
    background: rgba(125, 125, 125, 0.3);
    transition: opacity 200ms ease-in-out 0ms;

    &.open {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        opacity: 1;
    }

    &.closed {
        border-top: none;
        opacity: 0;
        user-select: none;
        pointer-events: none;
    }
`;
