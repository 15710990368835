import * as React from 'react';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { DomainDTO } from '../../../api/model';
import DotsText from '../../Shared/dots-text';

import SoarHelper from '../../../lib/soar-helper';
const moment = require('moment');

interface AccountDetailsProps {
    domain?: DomainDTO;
}

const AccountDetails: React.FC<AccountDetailsProps> = (props) => {
    if (!props.domain) return <DotsText text="Loading details" />;
    const { domain } = props;

    return (
        <AccountDetailsContainer>
            <h4>Subscription Details</h4>
            <Row>
                <TableCol md={12}>
                    <TableRow>
                        <TableCellKeyCol md={4}>Subdomain</TableCellKeyCol>
                        <TableCellValueCol md={8}>{domain.name}</TableCellValueCol>
                    </TableRow>
                    {domain.subscription.cancelAt ? (
                        <>
                            <TableRow>
                                <TableCellKeyCol md={4}>Status</TableCellKeyCol>
                                <TableCellValueColRed>CANCELLED</TableCellValueColRed>
                            </TableRow>

                            <TableRow>
                                <TableCellKeyCol md={4}>Active until</TableCellKeyCol>
                                <TableCellValueCol>
                                    {moment(Number(domain.subscription.cancelAt) * 1000).format('MMMM Do YYYY')}
                                </TableCellValueCol>
                            </TableRow>
                        </>
                    ) : (
                        <TableRow>
                            <TableCellKeyCol md={4}>Status</TableCellKeyCol>
                            <TableCellValueCol>{domain.status}</TableCellValueCol>
                        </TableRow>
                    )}

                    <TableRow>
                        <TableCellKeyCol md={4}>Subscription Type</TableCellKeyCol>
                        <TableCellValueCol>{domain.type}</TableCellValueCol>
                    </TableRow>
                    {domain.subscription && (
                        <>
                            <TableRow>
                                <TableCellKeyCol md={4}>Cost per month</TableCellKeyCol>
                                <TableCellValueCol>{`${SoarHelper.formatCurrencyFromCents(
                                    domain.subscription.total,
                                    domain.subscription.currency
                                )} p/month`}</TableCellValueCol>
                            </TableRow>
                            {domain.subscription.nextPaymentAt ? (
                                <TableRow>
                                    <TableCellKeyCol md={4}>Next payment:</TableCellKeyCol>
                                    <TableCellValueCol>
                                        {moment(Number(domain.subscription.nextPaymentAt) * 1000).format(
                                            'MMMM Do YYYY'
                                        )}
                                    </TableCellValueCol>
                                </TableRow>
                            ) : null}
                            {domain.subscription.trialEndAt &&
                            domain.subscription.trialEndAt > new Date().getTime() / 1000 ? (
                                <TableRow>
                                    <TableCellKeyCol md={4}>Trial Ending:</TableCellKeyCol>
                                    <TableCellValueCol>
                                        {moment(Number(domain.subscription.trialEndAt) * 1000).format('MMMM Do YYYY')}
                                    </TableCellValueCol>
                                </TableRow>
                            ) : null}
                        </>
                    )}

                    <TableRow>
                        <TableCellKeyCol md={4}>Member Since:</TableCellKeyCol>
                        <TableCellValueCol>
                            {moment(Number(domain.createdAt) * 1000).format('MMMM Do YYYY')}
                        </TableCellValueCol>
                    </TableRow>
                </TableCol>
            </Row>
        </AccountDetailsContainer>
    );
};

export default AccountDetails;

const AccountDetailsContainer = styled.div`
    margin: 20px 0px;
    padding: 15px;
    background-color: rgb(0, 0, 0, 0.6);
    border-radius: 6px;

    h4 {
        color: white;
    }
`;

const TableCol = styled(Col)`
    .col-md-12 {
        padding-right: 0px;
        padding-left: 0px;
    }
`;

const TableCellKeyCol = styled(Col)`
    color: #eed926;
    font-weight: 600;
    padding: 3px 0px 3px 6px;
    border-right: 1px solid white;
    background-color: rgb(0, 0, 0, 0.6);

    @media only screen and (max-width: 600px) {
        border-right: none;
    }
`;

const TableCellValueCol = styled(Col)`
    color: white;
    padding: 3px 0px 3px 6px;
    background-color: rgb(0, 0, 0, 0.6);
`;

const TableCellValueColRed = styled(Col)`
    color: red;
    padding: 3px 0px 3px 6px;
    background-color: rgb(0, 0, 0, 0.6);
`;
const TableRow = styled.div`
    display: flex;
    border: 1px solid rgba(103, 103, 103);
    border-top: none;
    background-color: none;

    @media only screen and (max-width: 600px) {
        display: block;
        border: 0;
        border-top: 1px solid rgba(103, 103, 103);
        background-color: #191a1a;
    }
`;
