import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import remarkGfm from 'remark-gfm';
import React from 'react';

interface MarkdownViewerProps {
    text: string;
    fontColor?: string;
}

export const MarkdownViewer = ({ text, fontColor }: MarkdownViewerProps): JSX.Element => {
    const plugins = [remarkGfm];

    return (
        <ReactMarkdown
            children={text}
            className="react-markdown-styles"
            remarkPlugins={plugins}
            components={{
                a: LinkRenderer,
                h1: (props) => HeaderRenderer(props, fontColor),
                h2: (props) => HeaderRenderer(props, fontColor),
                h3: (props) => HeaderRenderer(props, fontColor),
                h4: (props) => HeaderRenderer(props, fontColor),
                h5: (props) => HeaderRenderer(props, fontColor),
                h6: (props) => HeaderRenderer(props, fontColor),
                p: (props) => TextRenderer(props, fontColor),
                li: (props) => TableLIRenderer(props, fontColor),
                strong: (props) => BoldRenderer(props, fontColor),
                img: BlockedRenderer,
            }}
        />
    );
};

interface MarkdownViewerStyles {
    fontColor?: string;
}

// h1 - h6
const HeaderRenderer = (props, fontColor) => {
    return <StyledHeader fontColor={fontColor}>{props.children}</StyledHeader>;
};

const StyledHeader = styled.h1<MarkdownViewerStyles>`
    color: ${(props) => (props.fontColor ? props.fontColor : 'white')};
    text-align: justify;
    font-size: 20px;
    word-break: break-word;
`;

// p
const TextRenderer = (props, fontColor) => {
    return <StyledText fontColor={fontColor}>{props.children}</StyledText>;
};

const StyledText = styled.p<MarkdownViewerStyles>`
    color: ${(props) => (props.fontColor ? props.fontColor : 'white')};
    font-size: 16px;
    word-break: break-word;
`;

// li
const TableLIRenderer = (props, fontColor) => {
    return <StyledLI fontColor={fontColor}>{props.children}</StyledLI>;
};

const StyledLI = styled.li<MarkdownViewerStyles>`
    color: ${(props) => (props.fontColor ? props.fontColor : 'white')};
    text-align: justify;
    font-size: 16px;
    margin-bottom: 0px;
    word-break: break-word;

    ::marker {
        color: ${(props) => (props.fontColor ? props.fontColor : 'white')};
    }
`;

// BOLD
const BoldRenderer = (props, fontColor) => {
    return <StyledBold fontColor={fontColor}>{props.children}</StyledBold>;
};

const StyledBold = styled.strong<MarkdownViewerStyles>`
    color: ${(props) => (props.fontColor ? props.fontColor : 'white')};
    text-align: justify;
    font-size: 16px;
    margin-bottom: 0px;
    word-break: break-word;
`;

// LINKS, Force a tag to open in new tab
const LinkRenderer = (props) => {
    return (
        <StyledLink href={props.href} target="_blank" rel="noreferrer">
            {props.children}
        </StyledLink>
    );
};

const StyledLink = styled.a`
    color: #eed926;
    -webkit-text-fill-color: #eed926;
    text-decoration: underline !important;
    word-break: break-word;
    pointer-events: auto;

    &:hover {
        color: rgba(238, 218, 38, 0.87);
        -webkit-text-fill-color: rgba(238, 218, 38, 0.87);
    }
`;

// Could not find much on it but this seems like an easy way to block unwanted markdown been rendered
// BLOCKED MARKDOWN LIST
// img - ![](url)
const BlockedRenderer = () => {
    return <React.Fragment />;
};
