import Api from '../api/api';
import { Permission } from '../api/model';
import Constants from '../constants';

export default class SoarHelper {
    public static isSoar(): boolean {
        return Api.getSubdomain() === Constants.DOMAIN;
    }

    public static apiPermissionToEnum(permission: string): Permission {
        switch (permission) {
            case 'READ':
                return Permission.Read;
            case 'WRITE':
                return Permission.Write;
            case 'REVIEW':
                return Permission.Review;
            case 'ADMIN':
                return Permission.Admin;
            case 'OWNER':
                return Permission.Owner;
            default:
                return Permission.Read;
        }
    }

    public static permissionEnumToString(permission: Permission): string {
        switch (permission) {
            case Permission.Read:
                return 'READ';
            case Permission.Write:
                return 'WRITE';
            case Permission.Review:
                return 'REVIEW';
            case Permission.Admin:
                return 'ADMIN';
            case Permission.Owner:
                return 'OWNER';
            default:
                return 'VIEW';
        }
    }

    public static permissionsEnumToUIText(permission: Permission): string {
        switch (permission) {
            case Permission.Read:
                return 'VIEW';
            case Permission.Write:
                return 'WRITE';
            case Permission.Review:
                return 'WRITE';
            case Permission.Admin:
                return 'ADMIN';
            case Permission.Owner:
                return 'OWNER';
            default:
                return 'VIEW';
        }
    }

    public static formatCurrencyFromCents(amount?: number, currency?: string) {
        if (amount) {
            const currencyLabel = 'USD' === currency ? 'US' : currency ? currency : '';
            return `${currencyLabel}$${(amount / 100).toFixed(2)}`;
        }
        return '';
    }

    public static isValidDomainCharacters = (name: string) => {
        /**
         * \w starts with word char
         * [\w\-] any character or -
         * {0, 61} up to 61 times
         * \w)? end with a word char
         */
        return name.length > 0 && !name.includes(' ') && name.match(/^\w(?:[\w\-]{0,61}\w)?$/); // eslint-disable-line no-useless-escape
    };

    public static isLocalHost() {
        return window.location.hostname?.includes('localhost') || window.location.hostname?.includes('127.0.0.1');
    }
}
