import styled from 'styled-components';
import { PulseLoader } from '../../Shared/pulse-loader';

const MobileProfileLoading = () => {
    return (
        <MobileProfileLoadingContainer>
            <PulseLoader iconSize="25px" />
        </MobileProfileLoadingContainer>
    );
};

export default MobileProfileLoading;

const MobileProfileLoadingContainer = styled.div`
    margin-top: 8px;
`;
