import React from 'react';
import {
    selectDroneImageDistortionTool,
    selectDroneImageOpacity,
    selectImageBounds,
} from '../../../store/Map/Uploads/selectors';
import GeoUtil from '../../../lib/geo-util';
import { actionSetImageBounds } from '../../../store/Map/Uploads/actions';
import ReactDistortableImageOverlay from 'react-leaflet-distortable-imageoverlay';
import { useDispatch, useSelector } from 'react-redux';
import { LatLng } from 'leaflet';

interface RectifiedUploadPreviewProps {
    previewUrl: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mapRef: any;
}

const RectifiedUploadPreview = (props: RectifiedUploadPreviewProps) => {
    const opacity = useSelector(selectDroneImageOpacity);
    const distortionToolMode = useSelector(selectDroneImageDistortionTool);
    const imageBounds = useSelector(selectImageBounds);

    const dispatch = useDispatch();

    if (!props.previewUrl && !imageBounds && !props.mapRef) {
        return <React.Fragment />;
    }

    const distortedBounds = GeoUtil.toDistortedBounds(imageBounds as LatLng[]);

    return (
        <ReactDistortableImageOverlay
            url={props.previewUrl}
            editMode={distortionToolMode}
            onCornersUpdated={(corners) => {
                if (corners) {
                    const mappedCorners: LatLng[] = [corners[1], corners[3], corners[2], corners[0]];
                    dispatch(actionSetImageBounds(mappedCorners));
                }
            }}
            corners={distortedBounds}
            opacity={opacity}
        />
    );
};

export default RectifiedUploadPreview;
