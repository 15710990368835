import { actionTypes as at } from './constants';
import { AppState } from '../../root-reducer';
import ApiListings from '../../../api/api-listings';
import { actionFlyTo, actionFlyToZoom } from '../../App/actions';
import { toast } from 'react-toastify';
import GeoUtil from '../../../lib/geo-util';

export const actionActiveMapFetchById = (tileLayerId: number | string, flyTo?: boolean) => {
    return async (dispatch, getState) => {
        const appState: AppState = getState();
        dispatch(actionActiveMapFetching(true));
        const existingTileLayers = appState.superMapDomain.tileLayers;
        try {
            let id: number;
            if (typeof tileLayerId === 'string') {
                id = Number(tileLayerId);
            } else {
                id = tileLayerId;
            }
            let tileLayer = existingTileLayers[id];
            if (!tileLayer) {
                tileLayer = await ApiListings.getTileLayer(id);
            }

            dispatch({
                type: at.MAP_ACTIVE_TILE_LAYER_CHANGED,
                payload: tileLayer,
            });

            dispatch({
                type: at.MAP_FETCH_TILE_LAYER_BY_ID_SUCCESS,
                payload: tileLayer,
            });

            dispatch(actionActiveMapFetching(false));

            if (flyTo) {
                const boundingBox = tileLayer.distoredGeometry
                    ? GeoUtil.latLngBoundsFromPolygonWKT(tileLayer.distoredGeometry)
                    : tileLayer.boundingBox;
                dispatch(actionFlyTo(boundingBox, undefined, false));
            } else if (appState.appDomain.mapZoom && appState.appDomain.mapZoom > 10) {
                dispatch(actionFlyToZoom(9));
            }
        } catch (err) {
            toast.error('Map with specified ID does not exist');
        }
    };
};

export const actionClearMapById = (tileLayerId: number) => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.MAP_CLEAR_TILE_LAYER,
            payload: tileLayerId,
        });
    };
};

export const actionActiveMapFetching = (fetching: boolean) => {
    return {
        type: at.MAP_ACTIVE_TILE_LAYER_FETCHING,
        payload: fetching,
    };
};

export const actionActiveMapTileLayerLoading = (isLoading: boolean) => {
    return {
        type: at.MAP_ACTIVE_TILE_LAYER_LOADING,
        payload: isLoading,
    };
};

export const actionActiveMapCleared = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.MAP_ACTIVE_TILE_LAYER_CHANGED,
            payload: undefined,
        });
    };
};
