import { actionTypes as at } from './constants';
import { Action } from '../../root-reducer';
import { BaseMap } from './model';

interface StoreMapMobile {
    isInteractionEnabled: boolean;
    baseMap: string;
    showLoadingSpinner: boolean;
    mobileBeginSentinelSearch: boolean; // TODO: Move to 'store/Map/Mobile',
}

const initialState: StoreMapMobile = {
    isInteractionEnabled: true,
    baseMap: BaseMap.GSM_Hybrid.toString(),
    showLoadingSpinner: false,
    mobileBeginSentinelSearch: false,
};

export default (state: StoreMapMobile = initialState, action: Action): StoreMapMobile => {
    switch (action.type) {
        case at.ENABLE_INTERACTION:
            return {
                ...state,
                isInteractionEnabled: true,
            };

        case at.PREVENT_INTERACTION:
            return {
                ...state,
                isInteractionEnabled: false,
            };

        case at.SET_BASE_MAP:
            return {
                ...state,
                baseMap: action.payload,
            };

        case at.SHOW_LOADING_SPINNER:
            return {
                ...state,
                showLoadingSpinner: action.payload
            }

        case at.BEGIN_MOBILE_SENTINEL_SEARCH:
            return {
                ...state,
                mobileBeginSentinelSearch: action.payload,
            };


        default:
            return state;
    }
};
