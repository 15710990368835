import React, { useCallback } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { actionGetSubdomainListings } from '../../../store/Dashboard/actions';
import { selectAdminListingsData, selectLoadingAdminListingData } from '../../../store/Dashboard/selectors';
import { PulseLoader } from '../../Shared/pulse-loader';
import NavigationFilterInput from '../Shared/navigation-filter-input';
import DataManagementNavigation from './data-management-navigation';
import ImageryMasonary from './imagery-masonary';
import { ImagerySort } from './imagery-options';
import DashboardContainer from '../Shared/dashboard-container';

const DataManagement = () => {
    const dispatch = useDispatch();
    const subdomainListings = useSelector(selectAdminListingsData);
    const adminListingsLoading = useSelector(selectLoadingAdminListingData);

    const [filterValue, setFilterValue] = useState<string>('');
    const [sortBy, setSortBy] = useState<string | undefined>(undefined);

    const handleClearFilter = () => {
        setFilterValue('');
    };

    const refresh = useCallback(() => {
        dispatch(actionGetSubdomainListings());
    }, [dispatch]);

    //These are computed values, no need to put it in state
    let filteredList = subdomainListings;
    if (filteredList) {
        if (filterValue.length > 0) {
            filteredList = filteredList.filter((listing) =>
                listing.metadata
                    .concat(listing.review as string)
                    .toLowerCase()
                    .includes(filterValue)
            );
        }
        if (sortBy === ImagerySort.Title) {
            filteredList = filteredList.sort((a, b) => a.title.localeCompare(b.title));
        } else if (sortBy === ImagerySort.LastPosted) {
            filteredList = filteredList.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)); //descending
        } else {
            filteredList = filteredList.sort((a, b) =>
                (a.updatedAt ?? a.createdAt) > (b.updatedAt ?? b.createdAt) ? -1 : 1
            ); //default order by updatedAt desc
        }
    }

    return (
        <React.Fragment>
            <DataManagementNavigation mapsCount={subdomainListings.length} />
            <NavigationFilterInput
                placeholderText="Filter by title, tags, status or description.."
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                setSortValue={setSortBy}
                handleClearFilter={handleClearFilter}
            />
            <ProfileImageryBodyDiv>
                {filteredList && !adminListingsLoading && (
                    <DashboardContainer marginTop={65}>
                        <ImageryMasonary listings={filteredList} onRefresh={refresh} />
                    </DashboardContainer>
                )}
                {adminListingsLoading && (
                    <LoaderContainer>
                        <PulseLoader iconSize="2rem" iconMargin="10px" />
                    </LoaderContainer>
                )}
            </ProfileImageryBodyDiv>
        </React.Fragment>
    );
};

export default DataManagement;

const ProfileImageryBodyDiv = styled.div`
    margin-top: 20px;

    @media (max-width: 900px) {
        flex-direction: column;
    }
`;

const LoaderContainer = styled.div`
    height: calc(100vh - 100px);
`;
