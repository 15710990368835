import React, { useEffect, useState } from 'react';
import { ImageOverlay } from 'react-leaflet';
import { ImageTransform } from '@takor/react-leaflet-image-transform';
import { useSelector } from 'react-redux';
import {
    selectSatelliteAOI,
    selectSatelliteSelectedFeatures,
    selectSatelliteZIndex,
} from '../../../store/Map/SatelliteArchive/selectors';
import GeoUtil from '../../../lib/geo-util';
import SatelliteQualityWatermark from '../satellite-quality-watermark';

const SatelliteFeatures = () => {
    const aoi = useSelector(selectSatelliteAOI);
    const selectedFeatures = useSelector(selectSatelliteSelectedFeatures);
    const zIndex = useSelector(selectSatelliteZIndex);

    const [loadedCount, setLoadedCount] = useState(0);

    useEffect(() => {
        if (selectedFeatures.length < loadedCount) {
            setLoadedCount(selectedFeatures.length);
        }
    }, [selectedFeatures.length, loadedCount]);

    return (
        <React.Fragment>
            {selectedFeatures.length > 0 && aoi ? (
                <React.Fragment>
                    {aoi && selectedFeatures.length > loadedCount ? (
                        <ImageOverlay url="/assets/loading.gif" zIndex={2000} interactive={false} bounds={aoi} />
                    ) : null}
                    {selectedFeatures.map((feature) => {
                        const bounds = GeoUtil.polygonFromPolygonWKT(feature.geometryWKT);
                        return (
                            <ImageTransform
                                key={feature.id}
                                url={feature.previewUrl}
                                bounds={bounds}
                                onload={(e) => {
                                    setLoadedCount((count) => count + 1);
                                    e.setZIndex(1);
                                }}
                                options={{
                                    clip: [
                                        [aoi.getNorth(), aoi.getWest()],
                                        [aoi.getNorth(), aoi.getEast()],
                                        [aoi.getSouth(), aoi.getEast()],
                                        [aoi.getSouth(), aoi.getWest()],
                                        [aoi.getNorth(), aoi.getWest()],
                                    ],
                                    zIndex: (zIndex[feature.id] || 0) + 100000,
                                }}
                            />
                        );
                    })}
                    <SatelliteQualityWatermark bounds={aoi} />;
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
};

export default SatelliteFeatures;
