import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface GCPLoaderProps {
    loadingText?: string;
    isProcessing?: boolean;
}

export const GCPLoader = ({ loadingText, isProcessing }: GCPLoaderProps) => {
    return (
        <ProgressModal isOpen={!!isProcessing}>
            <ProgressModalHeader>{loadingText ? loadingText : 'Processing georeferenced map....'}</ProgressModalHeader>
            <ProgressModalBody>
                {isProcessing && (
                    <React.Fragment>
                        <GCPCircle>
                            <div className="spinner-box">
                                <div className="circle-border">
                                    <div className="circle-core"></div>
                                </div>
                            </div>
                        </GCPCircle>
                        <GCPIcon src="../assets/upload/gcp-icon.svg" />
                    </React.Fragment>
                )}
            </ProgressModalBody>
            <ProgressModalFooter>
                <p>Please standby as this process may take a while depending on file size.</p>
            </ProgressModalFooter>
        </ProgressModal>
    );
};

const ProgressModal = styled(Modal)`
    margin-top: 20%;
    color: white;
    -webkit-text-fill-color: white;

    div.modal-content {
        background-color: transparent !important;
        border: none !important;
    }
`;

const ProgressModalBody = styled(ModalBody)`
    align-self: center;
`;

const ProgressModalHeader = styled(ModalHeader)`
    display: contents;
    width: 100%;
    text-align: center;

    h5 {
        font-size: 2rem;
        color: white;
        -webkit-text-fill-color: white;
        font-style: normal;
        font-weight: bold;
    }
`;

const ProgressModalFooter = styled(ModalFooter)`
    display: contents;

    p {
        max-width: 60%;
        text-align: center;
        font-size: 16px;
        margin: 0 auto;
    }
`;

const GCPIcon = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    margin: -62px 25px;
`;

const Spin = keyframes`
    from {
        transform: rotate(0);
    }
    to{
        transform: rotate(359deg);
    }
`;

const GCPCircle = styled.div`
    text-align: center;
    width: 100%;

    .spinner-box {
        width: 6rem;
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
    }

    .circle-border {
        width: 5.8rem;
        height: 5.8rem;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: transparent;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.397) 33%, #eed926 100%);
        animation: ${Spin} 900ms linear 0s infinite;
    }

    .circle-core {
        width: 100%;
        height: 100%;
        background-color: black;
        border-radius: 50%;
    }
`;
