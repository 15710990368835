import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { useBack } from '../../Shared/use-back';

interface NavigationPanelProps {
    label: string;
    children?: React.ReactNode;
}

const NavigationPanel = (props: NavigationPanelProps) => {
    const { label, children } = props;

    const [goBack] = useBack(SideDrawerMode.MAPS);

    return (
        <React.Fragment>
            <NavigationPanelContainer>
                {isMobile && (
                    <BackArrowContainer>
                        <BackArrow onClick={goBack} />
                    </BackArrowContainer>
                )}
                <Title>
                    <h3>{label}</h3>
                </Title>
                {children}
            </NavigationPanelContainer>
        </React.Fragment>
    );
};

export default NavigationPanel;

const NavigationPanelContainer = styled.div`
    background-color: black;
    height: 60px;
    width: 100%;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    color: white;
    margin: auto;
    flex-direction: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-top: 0px;

    @media only screen and (max-width: 600px) {
        width: 100vw;
        background-color: #191a1a;
        border-radius: 0px;
        flex-direction: row;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        z-index: 1000;
        box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
            0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
    }
`;

const Title = styled.div`
    flex: 1 1 auto;
    padding-right: 40px;
    margin: auto;

    h3 {
        color: white;
        display: flex;
        margin-top: 10px;
        padding-left: 20px;

        @media only screen and (max-width: 600px) {
            justify-content: center;
            align-self: center;
            padding-left: 0px;
            font-size: 1.75rem;
            margin-top: 5px;
        }
    }
`;

const BackArrowContainer = styled.div`
    width: 50px;
    height: 100%;
    pointer-events: all;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BackArrow = styled.button`
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2222%22%20height%3D%2222%22%20viewBox%3D%220%200%2022%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M1%2011H21%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M9.57153%201L0.999955%2011L9.57153%2021%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A');
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    width: 22px;
    height: 22px;
    pointer-events: all;
    cursor: pointer;
    outline: none !important;
`;
