import { useEffect, useState, useCallback } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import MobileMapContainer from './components/Mobile/mobile-map-container';
import Dashboard from './components/Dashboard/dashboard';
import SubdomainPricing from './components/Pricing/SubdomainPricing';
import PrivacyAgreement from './components/PrivacyAgreement/privacy-agreement';
import Terms from './components/PrivacyAgreement/terms';
import LoginRegister from './components/Registration/index';
import { LoginModalMode } from './components/Registration/login-enum';
import LoginRegisterDialog from './components/Registration/login-register-dialog';
import PageError from './components/Shared/page-error';
import UserHelper from './lib/user-helper';
import { SideDrawerMode } from './store/SideDrawer/model';
import { useDispatch, useSelector } from 'react-redux';
import { selectConfig } from './store/App/selectors';
import { DashboardMode } from './store/Dashboard/model';
import Skymap50TermsAndConditions from './components/PrivacyAgreement/skymap50-satellite-terms-and-conditions';
import UriHelper, { usePositionQuery, useQuery, useSentinelQuery } from './lib/uri-helper';
import { actionFlyTo } from './store/App/actions';
import { actionSentinelSelectAOI, actionSentinelSelectFeature } from './store/Map/Sentinel/actions';
import { selectSentinelSelectedFeature } from './store/Map/Sentinel/selectors';
import { actionActiveMapCleared, actionActiveMapFetchById } from './store/Map/SuperMap/actions';
import { HARD_CODED_ANIMATED_LAYER_START_ID } from './store/Map/AnimatedLayer/animated-layer-datasource';
import { actionSetAnimatedLayerActive } from './store/Map/AnimatedLayer/actions';
import { selectAnimatedLayers } from './store/Map/AnimatedLayer/selector';
import { addSentinelParamsToUrl } from './lib/sentinel-service';
import OrderPayment from './components/Dashboard/SatelliteOrders/order-payment';

const MobileRouteMap = () => {
    const query = useQuery();
    const config = useSelector(selectConfig);
    const [position, zoom] = usePositionQuery();

    const dispatch = useDispatch();

    const sentinelShareFeature = useSentinelQuery();
    const selectedSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const [shouldUpdateUri, setShouldUpdateUri] = useState(true);
    const animatedLayers = useSelector(selectAnimatedLayers);

    const handlePositionQueryParameter = useCallback(
        (immediately: boolean) => {
            if (position && zoom) {
                dispatch(actionFlyTo(position, zoom, immediately));
            }
        },
        [position, zoom, dispatch]
    );

    // A link was followed to an explicit location and zoom level eg.  ?pos=-31.22,114.3,3
    useEffect(() => {
        handlePositionQueryParameter(true);
    }, [handlePositionQueryParameter]);

    const setShareFeatures = useCallback(() => {
        if (sentinelShareFeature && !selectedSentinelFeature) {
            dispatch(actionFlyTo(sentinelShareFeature.bbox, undefined, true));
            dispatch(actionSentinelSelectAOI(sentinelShareFeature.bbox));
            dispatch(actionSentinelSelectFeature(sentinelShareFeature));
        }
    }, [dispatch, selectedSentinelFeature, sentinelShareFeature]);

    useEffect(() => {
        setShareFeatures();
    }, [setShareFeatures]);

    useEffect(() => {
        if (selectedSentinelFeature && shouldUpdateUri) {
            addSentinelParamsToUrl(selectedSentinelFeature);
        }
        setShouldUpdateUri(true);
    }, [selectedSentinelFeature, shouldUpdateUri]);

    const clearMap = () => {
        dispatch(actionActiveMapCleared());
        dispatch(actionSetAnimatedLayerActive(undefined));
    };

    const token = UserHelper.validateAndGetIdToken();
    if (config.BEHAVIOURS.FORCED_LOGIN) {
        if (window.location.pathname.startsWith('/password_reset')) {
            return (
                <Route
                    render={(_) => (
                        <LoginRegisterDialog
                            isOpen={true}
                            initialMode={LoginModalMode.PASSWORD_RESET}
                            onClose={() => UriHelper.navigateToPath('/')}
                        />
                    )}
                />
            );
        }
        if (!token) {
            return <Route render={(_) => <LoginRegister subDomain={config.SUBDOMAIN} />} />;
        }
    }

    return (
        <Switch>
            <Route
                exact
                path="/"
                render={(_) => {
                    clearMap();
                    // Handle any old tilelayer links eg. https://soar.earth?tileLayer=3000
                    const oldTileLayerUrlParameter = query.get('tileLayer');
                    if (oldTileLayerUrlParameter) {
                        return <Redirect to={`/maps/${oldTileLayerUrlParameter}`} />;
                    }

                    if (sentinelShareFeature) {
                        return <MobileMapContainer drawerMode={SideDrawerMode.SATELLITE} />;
                    }
                    return <MobileMapContainer drawerMode={SideDrawerMode.MAPS} />;
                }}
            />
            <Redirect exact path="/home" to="/" />
            <Redirect exact path="/index.php" to="/" />
            <Redirect exact path="/index.html" to="/" />

            <Route
                exact
                path="/maps"
                render={(_) => {
                    clearMap();
                    return <MobileMapContainer drawerMode={SideDrawerMode.MAPS} />;
                }}
            />
            <Route
                exact
                path="/maps/:tileLayer"
                render={(props: RouteComponentProps<{ tileLayer: string }>) => {
                    handlePositionQueryParameter(true);
                    dispatch(actionSetAnimatedLayerActive(undefined));
                    const tileLayerId = props.match.params.tileLayer;
                    if (Number(tileLayerId) < HARD_CODED_ANIMATED_LAYER_START_ID) {
                        const pos = UriHelper.tryGetParam(UriHelper.POSITION_URI_KEY);
                        dispatch(actionActiveMapFetchById(tileLayerId, pos === null));
                    }

                    return <MobileMapContainer drawerMode={SideDrawerMode.MAPS} />;
                }}
            />

            <Route
                exact
                path="/animated-maps/:animatedLayerId"
                render={(props: RouteComponentProps<{ animatedLayerId: string }>) => {
                    const animatedLayerId = props.match.params.animatedLayerId;
                    const animatedLayer = animatedLayers.find((t) => t.id === Number(animatedLayerId));
                    if (animatedLayer) {
                        dispatch(actionSetAnimatedLayerActive(animatedLayer));
                        dispatch(actionFlyTo(animatedLayer.boundingBox, undefined, false));
                    }

                    return <MobileMapContainer drawerMode={SideDrawerMode.MAPS} />;
                }}
            />

            <Route
                exact
                path="/satellites/sentinel"
                render={(_) => <MobileMapContainer drawerMode={SideDrawerMode.SATELLITE} />}
            />
            <Route
                exact
                path="/satellites/landsat"
                render={(_) => <MobileMapContainer drawerMode={SideDrawerMode.SATELLITE} />}
            />
            <Route
                exact
                path="/satellites"
                render={(_) => <MobileMapContainer drawerMode={SideDrawerMode.SATELLITE} />}
            />

            <Route
                exact
                path="/satellites/cgstl"
                render={(_) => <MobileMapContainer loginMode={!token ? LoginModalMode.REGISTER : undefined} />}
            />

            <Route
                exact
                path="/profile/:wallet"
                render={(_) => <MobileMapContainer drawerMode={SideDrawerMode.PROFILE} />}
            />
            <Route
                exact
                path="/profile/:wallet/all-maps"
                render={(_) => <MobileMapContainer drawerMode={SideDrawerMode.PROFILE_ALL_MAPS} />}
            />

            <Route
                exact
                path="/login"
                render={(_) => <MobileMapContainer loginMode={!token ? LoginModalMode.LOGIN : undefined} />}
            />

            <Route
                exact
                path="/register"
                render={(_) => <MobileMapContainer loginMode={!token ? LoginModalMode.REGISTER : undefined} />}
            />

            <Route
                exact
                path="/password_reset"
                render={(_) => <MobileMapContainer loginMode={LoginModalMode.PASSWORD_RESET} />}
            />

            <Route
                exact
                path="/forgot_password"
                render={(_) => <MobileMapContainer loginMode={!token ? LoginModalMode.FORGOT_PASSWORD : undefined} />}
            />

            <Route exact path="/terms" render={(_) => <Terms />} />
            <Route path="/skymap50-terms" render={(_) => <Skymap50TermsAndConditions />} />
            <Route exact path="/privacy" render={(_) => <PrivacyAgreement />} />
            <Route
                exact
                path="/order/payment"
                render={(_) => {
                    if (!token) {
                        return <Redirect to="/login" />;
                    }
                    return <OrderPayment />;
                }}
            />
            <Redirect exact path="/task_payment" to="/order/payment" />
            <Redirect from="/browse/:filehash" to="/maps/:filehash" />
            <Redirect exact path="/browse" to="/" />
            <Redirect exact path="/explore" to="/" />

            <Redirect exact path="/my_profile" to="/dashboard" />
            <Redirect exact path="/profile" to="/dashboard" />
            <Route exact path="/dashboard" render={(_) => <Dashboard dashboardMode={DashboardMode.DASHBOARD} />} />
            <Route
                exact
                path="/dashboard/account-settings"
                render={(_) => <Dashboard dashboardMode={DashboardMode.ACCOUNT_SETTINGS} />}
            />

            <Route
                exact
                path="/dashboard/profile"
                render={(_) => <Dashboard dashboardMode={DashboardMode.PROFILE} />}
            />
            <Route
                exact
                path="/dashboard/my-imagery"
                render={(_) => <Dashboard dashboardMode={DashboardMode.MY_IMAGERY} />}
            />
            <Route
                exact
                path="/dashboard/my-bookmarks"
                render={(_) => <Dashboard dashboardMode={DashboardMode.MY_BOOKMARKS} />}
            />
            <Route
                exact
                path="/dashboard/satellite-queue"
                render={(_) => <Dashboard dashboardMode={DashboardMode.SATELLITE_QUEUE} />}
            />
            <Route exact path="/dashboard/payout" render={(_) => <Dashboard dashboardMode={DashboardMode.PAYOUT} />} />

            <Route
                exact
                path="/dashboard/purchase-history"
                render={(_) => <Dashboard dashboardMode={DashboardMode.PURCHASE_HISTORY} />}
            />

            <Route
                exact
                path="/dashboard/sales-history"
                render={(_) => <Dashboard dashboardMode={DashboardMode.SALES_HISTORY} />}
            />

            <Route
                exact
                path="/dashboard/terms-and-condition"
                render={(_) => <Dashboard dashboardMode={DashboardMode.TERMS_AND_CONDITION} />}
            />

            <Route
                exact
                path="/dashboard/overview"
                render={(_) => <Dashboard dashboardMode={DashboardMode.SUBDOMAIN_OVERVIEW} />}
            />
            <Route
                exact
                path="/dashboard/users"
                render={(_) => <Dashboard dashboardMode={DashboardMode.USER_MANAGEMENT} />}
            />
            <Route
                exact
                path="/dashboard/data"
                render={(_) => <Dashboard dashboardMode={DashboardMode.DATA_MANAGEMENT} />}
            />
            <Route
                exact
                path="/dashboard/account"
                render={(_) => <Dashboard dashboardMode={DashboardMode.ACCOUNT_MANAGEMENT} />}
            />

            <Route exact path="/plus" render={(_) => <SubdomainPricing />} />
            <Route exact path="/pricing" render={(_) => <SubdomainPricing />} />
            <Route path="/" render={(_) => <Error404 />} />
        </Switch>
    );
};

const Error404: React.FC = () => {
    return (
        <PageError
            gradient
            background="/assets/backgrounds-large/error-background.jpg"
            credit={{
                avatarUrl: 'https://avatar.soar.earth/ffba502ef2914a1f975b6a7868c722achsf.png/preview',
                location: 'Salt Pans',
                name: 'Kim Shapley',
                profile: 'https://soar.earth/profile/0x95c40c1fa80cb6ece3d39fe643995a6e9e2ed8d3',
            }}
        >
            <h3>Oops...</h3>
            <p>
                It looks like you've got here by accident or we're still <br />
                working on this section of the service.
            </p>
            <button onClick={() => window.open('/', '_self')}>BACK TO HOMEPAGE</button>
        </PageError>
    );
};

export default MobileRouteMap;
