import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import UriHelper from '../../lib/uri-helper';

const PrivacyAgreement = () => {

    const handleClose = () => {
        UriHelper.navigateToPath(`/`)
    }

    return (
        <PrivacyAgreementContainer>
            {isMobile ? <CloseIcon src="/assets/close.png" onClick={() => handleClose()} />  : null}
            <PrivacyAgreementHeader>
                <h3>Privacy Agreement</h3>
                <p>Last updated: Febuary 18th, 2020</p>
            </PrivacyAgreementHeader>
            <PrivacyAgreementBody>
                <ul>
                    This privacy policy (“Policy”) describes how Soar (Australia) Pty Ltd (“Soar”, “Company”, “we”,
                    “our”, or “us”) collects, uses, shares, and stores personal information of users of this website,{' '}
                    <a href="https://soar.earth" target="_blank">
                        https://soar.earth
                    </a>{' '}
                    (the “Site”). This Policy applies to the Site, applications, products and services (collectively,
                    “Services”) on or in which it is posted, linked, or referenced.
                </ul>
                <ul>
                    By using the Services, you accept the terms of this Privacy Policy and our Terms of Service, and
                    consent to our collection, use, disclosure, and retention of your information as described in this
                    Policy.
                </ul>
                <h5>
                    IF YOU DO NOT AGREE WITH ANY PART OF THIS PRIVACY POLICY OR OUR TERMS OF SERVICE, THEN PLEASE DO NOT
                    USE ANY OF THE SERVICES.
                </h5>
                <ul>
                    Please note that this Policy does not apply to information collected through third-party websites or
                    services that you may access through the Services or that you submit to us through email, text
                    message or other electronic message or offline.
                </ul>
                <h5>INFORMATION WE COLLECT</h5>
                <ul>Information we collect from you</ul>
                <ul>Information we collect from you may include:</ul>
                <ul>
                    <li>Network information regarding transactions;</li>
                    <li>Contact information, such as your email address;</li>
                    <li>
                        Feedback and correspondence, such as information you provide in your responses to surveys when
                        you participate in market research activities, report a problem with Service, receive customer
                        support or otherwise correspond with us;
                    </li>
                    <li>
                        Usage information, such as information about how you use the site or the Service and interact
                        with us; and
                    </li>
                    <li>
                        Marketing information, such your preferences for receiving marketing communications and details
                        about how you engage with them.
                    </li>
                </ul>
                <h5>Information we get from others</h5>
                <ul>
                    We may get information about you from other third party sources and we may add this to information
                    we get from your use of the Services. Such information may include:
                </ul>
                <ul>
                    <li>
                        Social Media Widgets: Our Site may include social media features, such as the Facebook “like”
                        button and widgets, such as the “share this” button. These features may collect your personal
                        information and track your use of the Site. These social media features are either hosted by a
                        third party or hosted directly on our Site. Your interactions with these features are governed
                        by the privacy policy of the company providing such functionality.
                    </li>
                </ul>
                <h5>Information automatically collected</h5>
                <ul>
                    We may automatically record certain information about how you use our Site (we refer to this
                    information as “Log Data“). Log Data may include information such as a user’s Internet Protocol (IP)
                    address, device and browser type, operating system, the pages or features of our Site to which a
                    user browsed and the time spent on those pages or features, the frequency with which the Site is
                    used by a user, search terms, the links on our Site that a user clicked on or used, and other
                    statistics. We use this information to administer the Service and we analyse (and may engage third
                    parties to analyse) this information to improve and enhance the Service by expanding its features
                    and functionality and tailoring it to our users’ needs and preferences.
                </ul>
                <ul>
                    We may use cookies or similar technologies to analyse trends, administer the website, track users’
                    movements around the website, and to gather demographic information about our user base. Users can
                    control the use of cookies at the individual browser level. For more information, please see the
                    section entitled “Cookies Policy” below.
                </ul>
                <ul>
                    We also use Google Analytics to help us offer you optimized user experience. You can find more
                    information about Google Analytics’ use of your personal data here at{' '}
                    <a href="https://policies.google.com/privacy?hl=en" target="_blank">
                        https://policies.google.com/privacy?hl=en
                    </a>
                </ul>
                <h5>Information we will never collect</h5>
                <ul>
                    We will never ask you to share your private keys or wallet seed. Never trust anyone or any site that
                    asks you to enter your private keys or wallet seed.
                </ul>
                <h5>USE OF PERSONAL INFORMATION</h5>
                <ul>To provide our service</ul>
                <ul>We will use your personal information in the following ways:</ul>
                <ul>
                    <li>To enable you to access and use the Services;</li>
                    <li>To provide and deliver products and services that you may request; and</li>
                    <li>
                        To send information, including confirmations, technical notices, updates, security alerts, and
                        support and administrative messages.
                    </li>
                </ul>
                <ul>To comply with law</ul>
                <ul>
                    We use your personal information as we believe necessary or appropriate to comply with applicable
                    laws, lawful requests and legal process, such as to respond to subpoenas or requests from government
                    authorities.
                </ul>
                <ul>To communicate with you</ul>
                <ul>
                    We use your personal information to communicate about promotions, upcoming events, and other news
                    about products and services offered by us and our selected partners.
                </ul>
                <ul>To optimize our platform</ul>
                <ul>
                    In order to optimize your user experience, we may use your personal information to operate,
                    maintain, and improve our Services. We may also use your information to respond to your comments and
                    questions regarding the Services and to provide you and other users with general customer service.
                </ul>
                <ul>With your consent</ul>
                <ul>
                    We may use or share your personal information with your consent, such as when you instruct us to
                    take a specific action with respect to your personal information, or you opt into any third party
                    marketing communications.
                </ul>
                <ul>For compliance, fraud prevention, and safety</ul>
                <ul>
                    We may use your personal information to protect, investigate, and deter against fraudulent,
                    unauthorized, or illegal activity.
                </ul>
                <h5>SHARING OF PERSONAL INFORMATION</h5>
                <ul>
                    We do not share or sell the personal information that you provide us with other organizations
                    without your express consent, except as described in this Privacy Policy.
                </ul>
                <ul>We disclose personal information to third parties under the following circumstances:</ul>
                <ul>
                    <li>
                        We may disclose your personal information to our subsidiaries and corporate affiliates for
                        purposes consistent with this Privacy Policy.
                    </li>
                    <li>
                        We may share personal information when we do a business deal, or negotiate a business deal,
                        involving the sale or transfer of all or a part of our business or assets. These deals can
                        include any merger, financing or acquisition.
                    </li>
                    <li>We may share personal information for legal, protection, and safety purposes.</li>
                    <li>We may share information to comply with laws.</li>
                    <li>We may share information to respond to lawful requests and legal processes.</li>
                    <li>
                        We may share information to protect the rights and property of the Company, our agents,
                        customers, and others. This includes enforcing our agreements, policies, and terms of use.
                    </li>
                    <li>
                        We may share information in an emergency. This includes protecting the safety of our employees
                        and agents, our customers, or any person.
                    </li>
                    <li>
                        We may share information with those who need it to do work for us. These recipients may include
                        third party companies and individuals to administer and provide the Service on our behalf (such
                        as customer support, hosting, email delivery and database management services), as well as
                        lawyers, bankers, auditors, and insurers.
                    </li>
                    <li>
                        Other. You may permit us to share your personal information with other companies or entities of
                        your choosing. Those uses will be subject to the privacy policies of the recipient entity or
                        entities.
                    </li>
                </ul>
                <ul>We may also share aggregated and/or anonymized data with others for their own uses.</ul>
                <h5>INTERNATIONAL TRANSFER</h5>
                <ul>
                    The Company has offices outside of the EU and has affiliates and service providers in the United
                    States and in other countries. Your personal information may be transferred to or from the United
                    States or other locations outside of your state, province, country or other governmental
                    jurisdiction where privacy laws may not be as protective as those in your jurisdiction.
                </ul>
                <ul>
                    EU users should read the important information provided below about transfer of personal information
                    outside of the European Economic Area (EEA).
                </ul>
                <h5>HOW INFORMATION IS SECURED</h5>
                <ul>We retain information we collect:</ul>
                <ul>
                    <li>If it is necessary and relevant to fulfil the purposes outlined in this Privacy Policy;</li>
                    <li>To comply with applicable law where required;</li>
                    <li>Prevent fraud;</li>
                    <li>Resolve disputes;</li>
                    <li>Troubleshoot problems;</li>
                    <li>Assist with any investigation;</li>
                    <li>Enforce our Terms of Use; and</li>
                    <li>Other actions permitted by law.</li>
                </ul>
                <ul>
                    To determine the appropriate retention period for personal information, we consider the amount,
                    nature, and sensitivity of the personal information, the potential risk of harm from unauthorized
                    use or disclosure of your personal information, the purposes for which we process your personal
                    information and whether we can achieve those purposes through other means, and the applicable legal
                    requirements.
                </ul>
                <ul>
                    In some circumstances, we may anonymize your personal information (so that it can no longer be
                    associated with you) in which case we may use this information indefinitely without further notice
                    to you.
                </ul>
                <ul>
                    We employ industry standard security measures designed to protect the security of all information
                    submitted through the Services. However, the security of information transmitted through the
                    internet can never be guaranteed. We are not responsible for any interception or interruption of any
                    communications through the internet or for changes to or losses of data. Users of the Services are
                    responsible for maintaining the security of any:
                </ul>{' '}
                <ul>
                    <li>Password</li>
                    <li>Biometrics</li>
                    <li>User ID or</li>
                    <li>
                        Other forms of authentication involved in obtaining access to secure areas of any of our digital
                        services.
                    </li>
                </ul>
                <ul>
                    To protect you and your data, we may suspend your use of any of the Services, without notice,
                    pending an investigation, if any breach of security is suspected.
                </ul>
                <h5>SOAR’S USE OF THE BLOCKCHAIN</h5>
                <ul>
                    Transactions undertaken through Soar are recorded on the Ethereum (ETH) blockchain. The blockchain
                    is an immutable record of transactions that occur on Soar and may not be modified by Soar in any
                    manner.
                </ul>
                <ul>
                    Information recorded on the blockchain by Soar relates to your uploading or downloading of any files
                    or content from the site and includes:{' '}
                </ul>
                <ul>
                    <li>The files hash;</li>
                    <li>Location, date, type of platform used to collect the content and other image data;</li>
                    <li>Title, privacy-description, keywords and other information associated with the content;</li>
                    <li>Content storage address;</li>
                    <li>Wallet addresses; and</li>
                    <li>Sales transactions.</li>
                </ul>
                <ul>
                    The wallet address is effectively pseudonymous within the Soar ecosystem. However, it may be
                    possible that the wallet owner may be identified by content or username details on Soar when
                    combined with blockchain information.
                </ul>
                <ul>
                    Removal of personal data from Soar will not remove the transaction from the blockchain but
                    potentially will remove the ability to relate a wallet address with the owner’s online identity on
                    Soar. Removal of personal information from Soar will include removal of any content previously
                    submitted to the Soar platform.
                </ul>
                <ul>
                    Soar does not store any potentially personally identifiable data other than the wallet address on
                    the blockchain.
                </ul>
                <h5>INFORMATION CHOICES AND CHANGES</h5>
                <ul>Accessing, updating, correcting, and deleting your information</ul>
                <ul>
                    You may access information that you have voluntarily provided through your account on the Service,
                    and to review, correct, or delete it by sending a request to{' '}
                    <a href="mailto:hello@soar.earth?subject=Privacy information">hello@soar.earth</a> and specifying
                    what you require in the body of the email.
                </ul>
                <ul>
                    You can request to change contact choices, opt-out of our sharing with others, and update your
                    personal information and preferences.
                </ul>
                <ul>Tracking technologies generally</ul>
                <ul>
                    Regular cookies may generally be disabled or removed by tools available as part of most commercial
                    browsers, and in some instances blocked in the future by selecting certain settings. For more
                    information, please see the section entitled “Cookies Policy” below.
                </ul>
                <ul>Google Analytics</ul>
                <ul>
                    You may exercise choices regarding the use of cookies from Google Analytics by going to{' '}
                    <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">
                        https://tools.google.com/dlpage/gaoptout
                    </a>{' '}
                    and downloading the Google Analytics Opt-out Browser Add-on.
                </ul>
                <h5>CONTACT INFORMATION</h5>
                <ul>
                    We welcome your comments or questions about this Policy, and you may contact us at{' '}
                    <a href="mailto:hello@soar.earth?subject=Privacy policy questions">hello@soar.earth</a>
                </ul>
                <h5>CHANGES TO THIS PRIVACY POLICY</h5>
                <ul>
                    We may change this privacy policy at any time. We encourage you to periodically review this page for
                    the latest information on our privacy practices. If we make any changes, we will change the Last
                    Updated date above.
                </ul>
                <ul>
                    Any modifications to this Privacy Policy will be effective upon our posting of the new terms and/or
                    upon implementation of the changes to the Site (or as otherwise indicated at the time of posting).
                    In all cases, your continued use of the Site or Services after the posting of any modified Privacy
                    Policy indicates your acceptance of the terms of the modified Privacy Policy.
                </ul>
                <h5>ELIGIBILITY</h5>
                <ul>
                    If you are under the age of majority in your jurisdiction of residence, you may use the Services
                    only with the consent of or under the supervision of your parent or legal guardian. Consistent with
                    the requirements of the Children's Online Privacy Protection Act (COPPA), if we learn that we have
                    received any information directly from a child under age 13 without first receiving his or her
                    parent's verified consent, we will use that information only to respond directly to that child (or
                    his or her parent or legal guardian) to inform the child that he or she cannot use the Site and
                    subsequently we will delete that information.
                </ul>
                <h5>NOTICE TO EU DATA SUBJECTS</h5>
                <h5>Personal information</h5>
                <ul>
                    With respect to EU data subjects, “personal information,” as used in this Privacy Policy, is
                    equivalent to“personal data” as defined in the{' '}
                    <a href="https://gdpr-info.eu/art-4-gdpr/" target="_blank">
                        European Union General Data Protection Regulation
                    </a>{' '}
                    (GDPR).
                </ul>
                <ul>Sensitive data</ul>
                <ul>
                    Some of the information you provide us may constitute sensitive data as defined in the GDPR (also
                    referred to as special categories of personal data), including identification of your race or
                    ethnicity on government-issued identification documents.
                </ul>
                <ul>Legal bases for processing</ul>
                <ul>We only use your personal information as permitted by law.</ul>
                <ul>
                    We are required to inform you of the legal bases of our processing of your personal information,
                    which are described in the table below. If you have questions about the legal basis under which we
                    process your personal information, contact us at hello@soar.earth
                </ul>
                <ul>Use for new purposes</ul>
                <ul>
                    We may use your personal information for reasons not described in this Privacy Policy, where we are
                    permitted by law to do so and where the reason is compatible with the purpose for which we collected
                    it.
                </ul>
                <ul>
                    If we need to use your personal information for an unrelated purpose, we will notify you and explain
                    the applicable legal basis for that use.
                </ul>
                <ul>
                    If we have relied upon your consent for a particular use of your personal information, we will seek
                    your consent for any unrelated purpose.
                </ul>
                <ul>Your rights</ul>
                <ul>
                    Under the GDPR, you have certain rights regarding your personal information. You may ask us to take
                    the following actions in relation to your personal information that we hold to:
                </ul>
                <ul>
                    <li>
                        Opt-out of direct marketing communications which you have previously consented to receive. We
                        may continue to send you Service-related and other non-marketing communications;
                    </li>
                    <li>
                        To provide you with information about our processing of your personal information and give you
                        access to your personal information
                    </li>
                    <li>Update or correct inaccuracies in your personal information;</li>
                    <li>Delete your personal information;</li>
                    <li>
                        Transfer a machine-readable copy of your personal information to you or a third party of your
                        choice;
                    </li>
                    <li>Restrict the processing of your personal information;</li>
                    <li>
                        Object to our reliance on our legitimate interests as the basis of our processing of your
                        personal information that impacts your rights.
                    </li>
                </ul>
                <ul>
                    You can submit these requests by email to{' '}
                    <a href="mailto:hello@soar.earth?subject=Privacy request">hello@soar.earth</a> and describe your
                    requirements in the body of the text.
                </ul>
                <ul>
                    We may request specific information from you to help us confirm your identity and process your
                    request. Applicable law may require or permit us to decline your request. If we decline your
                    request, we will tell you why subject to legal restrictions.
                </ul>
                <ul>
                    If you would like to submit a complaint about our use of your personal information or response to
                    your requests regarding your personal information, you may contact us at{' '}
                    <a href="mailto:hello@soar.earth?subject=Privacy complaint">hello@soar.earth</a> or submit a
                    complaint to the data protection regulator in your jurisdiction.
                </ul>
                <ul>Cross-border data transfer</ul>
                <ul>
                    Please be aware that your personal data may be transferred to, processed, and stored in a different
                    jurisdiction to that in which you reside or access the service. Data protection laws where your data
                    is stored may be different from those in your country of residence. You consent to the transfer of
                    your information, including personal information, as set forth in this Privacy Policy by visiting
                    our site or using our service.
                </ul>
                <h5>COOKIES POLICY</h5>
                <ul>
                    We understand that your privacy is important to you and are committed to being transparent about the
                    technologies we use. In the spirit of transparency, this policy provides detailed information about
                    how and when we use cookies on our Site.
                </ul>
                <ul>Do we use cookies?</ul>
                <ul>
                    Yes, native and third-party cookies may be present on our site from services that are used by Soar.
                </ul>
                <ul>What is a cookie?</ul>
                <ul>
                    A cookie (“Cookie”) is a small text file that is placed on your hard drive by a web page server.
                    Cookies contain information that can later be read by a web server in the domain that issued the
                    cookie to you. Some of the cookies will only be used if you use certain features or select certain
                    preferences, and some cookies will always be used. You can find out more about each cookie by
                    viewing our current cookie list below. We update this list periodically, so there may be additional
                    cookies that are not yet listed. Web beacons, tags and scripts may be used in the Site or in emails
                    to help us to deliver cookies, count visits, understand usage and campaign effectiveness and
                    determine whether an email has been opened and acted upon. We may receive reports based on the use
                    of these technologies by our service/analytics providers on an individual and aggregated basis.
                </ul>
                <ul>How to disable cookies</ul>
                <ul>
                    You can generally activate or later deactivate the use of cookies through functionality built into
                    your web browser. Please note that disabling cookies may impact the performance of the site.
                </ul>
                <ul>
                    If you want to learn more about cookies, or how to control, disable or delete them, please visit{' '}
                    <a href="http://www.aboutcookies.org" target="_blank">
                        http://www.aboutcookies.org
                    </a>{' '}
                    for detailed guidance. In addition, certain third-party advertising networks, including Google,
                    permit users to opt out of or customize preferences associated with your internet browsing. To learn
                    more about this feature from Google, click{' '}
                    <a href="https://adssettings.google.com/u/0/authenticated?hl=en" target="_blank">
                        here
                    </a>
                    .
                </ul>
                <ul>Please note that disabling cookies may impact the performance of the site.</ul>
            </PrivacyAgreementBody>
        </PrivacyAgreementContainer>
    );
};

export default PrivacyAgreement;

const PrivacyAgreementContainer = styled.div`
    padding-right: 50px;
    padding-bottom: 20px;
    padding-top: 60px;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-top: 0px;
        overflow: auto;
        height: 100vh;
    }
`;

const PrivacyAgreementHeader = styled.div`
    -webkit-text-fill-color: white;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.8);
    height: 60px;
    width: 100%;
    border-radius: 6px;
    display: flex;
    color: white;
    margin: auto;
    text-align: center;
    flex-direction: none;
    padding-top: 0px;

    h3 {
        color: inherit;
        justify-content: center;
        align-self: center;
        display: flex;
        padding-left: 20px;
    }

    p {
        color: inherit;
        opacity: 0.8;
        margin: 18px;
    }

    @media only screen and (max-width: 600px) {
        justify-content: none;
        background-color: none;
        border-radius: 0px;
        flex-direction: column;
        border-top: none;
        text-align: left;

        h3 {
            justify-content: start;
            align-self: flex-start;
            padding-left: 10px;
        }

        p {
            margin: 0px 10px;
        }
    }
`;

const PrivacyAgreementBody = styled.div`
    width: 100%;
    padding-left: 22px;
    padding-top: 20px;
    color: white;
    -webkit-text-fill-color: white;

    @media only screen and (max-width: 900px) {
        padding-left: 10px;
        padding-top: 5px;
        padding-right: 5px;
    }

    h5 {
        font-weight: bold;
        color: #eed926;
        -webkit-text-fill-color: #eed926;
        text-align: left;
        font-size: 1.5rem;
        font-weight: 200;
    }

    ul {
        list-style: none;
        padding-inline-start: 0px;

        a {
            color: #eed926;
            -webkit-text-fill-color: #eed926;
            text-decoration: underline;
        }
    }

    li {
        color: inherit;
        -webkit-text-fill-color: inherit;

        a {
            color: #eed926;
            -webkit-text-fill-color: #eed926;
            text-decoration: underline;
        }
    }
`;

const CloseIcon = styled.img`
    margin: 15px;
    width: 22px;
    height: 22px;
    pointer-events: all;
    float: right;
`;