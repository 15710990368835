import React from 'react';
import styled from 'styled-components';
import { ListingDTO, ListingType } from '../../../api/model';
import ActiveMapSpeedIcon from './active-map-speed-icon';

interface ActiveMapSpeedProps {
    listing: ListingDTO;
}

const ActiveMapSpeed = (props: ActiveMapSpeedProps) => {

    if (props.listing.listingType !== ListingType.WMS || props.listing.layerSpeedKbps === undefined) {
        return <React.Fragment />
    }

    return (
        <Container>
            <Text>This data layer is from an external source.</Text>
            <SpeedIconContainer>
                <ActiveMapSpeedIcon speed={props.listing.layerSpeedKbps} />
            </SpeedIconContainer>
        </Container>
    );
};

export default ActiveMapSpeed;


const Container = styled.div`
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 8px 16px 7px 16px;
    display: flex;
`;

const Text = styled.span`
    color: #00A2FF;
    flex: 1;
`;

const SpeedIconContainer = styled.div`
    display: inline-block;
    justify-content: flex-end;
    width: 20px;

    img {
        width: 23px;
        height: 20px;
    }
`;