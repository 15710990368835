import React, { useCallback, useEffect, useState } from 'react';
import { LatLngBounds, LatLng } from 'leaflet';
import { useDispatch } from 'react-redux';
import UriHelper from '../../../lib/uri-helper';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { RectifiedMap } from '../../../api/model';
import SelectFile from './select-file';
import { actionSetSideDrawerModeAction } from '../../../store/SideDrawer/actions';
import {
    actionSetCandidateFileAction,
    actionSetCustomAOIDisplay,
    actionSetDatasetDetails,
    actionSetRectifiedMapAction,
} from '../../../store/Map/Uploads/actions';
import { DatasetDetails } from '../../Upload/Model/model';
import GeoRectifyUtil from '../../Upload/Util/geo-rectify-util';

// @ts-ignore // suppress type error
import loam from 'loam';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const proj4 = require('proj4').default;

enum Errors {
    FileOpen = 'Could not open file',
    FileMetadata = 'Could not extract file metadata',
    Bands = 'Only RGB is supported.  Please ensure raster is RGB',
    NotGeoreferencedTooLarge = 'File has no georeferencing information and is too large to be rectified (max 20MB)',
    JpgTooLargeToGeoReference = 'Unfortunately the image is too large to be rectified (max 20MB)',
    InitializationError = 'Could not read the file, please try again',
}

enum FileType {
    MAP,
    IMAGE,
    INVALID,
}

// ORIGINAL
//const MAX_JPG_SIZE = 20000000; // Set to 20mb max for now until discussed
const MAX_JPG_SIZE = 250000000;

// TODO think this is used in other places and may have cooked something...
const ShareMap = () => {
    const dispatch = useDispatch();
    const setDatasetDetails = (datasetDetails: DatasetDetails) => dispatch(actionSetDatasetDetails(datasetDetails));
    const displayMapAOI = useCallback((bbox?: LatLngBounds) => dispatch(actionSetCustomAOIDisplay(bbox)), [dispatch]);

    const [fileType, setFileType] = useState<FileType | undefined>();
    const [error, setError] = useState<string>('');
    const [file, setFile] = useState<File | undefined>();

    useEffect(() => {
        if (file) {
            const setDrawerMode = (sideDrawerMode: SideDrawerMode) =>
                dispatch(actionSetSideDrawerModeAction(sideDrawerMode));
            const setCandidateFile = (file: File) => dispatch(actionSetCandidateFileAction(file));
            const setRectifiedMap = (rectifiedMap?: RectifiedMap) =>
                dispatch(actionSetRectifiedMapAction(rectifiedMap));

            if (fileType === FileType.MAP) {
                setCandidateFile(file);
                setDrawerMode(SideDrawerMode.SHARE_GEOTIFF);
            }
            if (fileType === FileType.IMAGE) {
                setCandidateFile(file);
                displayMapAOI(undefined);
                setDrawerMode(SideDrawerMode.RECTIFIER);
                setRectifiedMap(undefined);
                UriHelper.navigateToPath('/upload/georectifier');
            }
        }
    }, [dispatch, displayMapAOI, file, fileType]);

    const determineFileType = (file: File) => {
        if (file.name.includes('.ecw')) {
            setFileType(FileType.MAP);
        } else if (GeoRectifyUtil.isValidFileType(file)) {
            loam.open(file)
                .then((dataset) => {
                    Promise.all([
                        dataset.width(),
                        dataset.height(),
                        dataset.count(),
                        dataset.wkt(),
                        dataset.transform(),
                    ])
                        .then(([width, height, count, wkt, geoTransform]) => {
                            // If you have issues with loam, uncomment next line
                            //console.debug('VALUES OF DATASET - W: ', width, ' H: ', height, 'COUNT: ', count, 'WKT: ', wkt, 'TRANSFORM: ', geoTransform, 'NAME: ', file.name);

                            const datasetDetails: DatasetDetails = {
                                width: width ? width : undefined,
                                height: height ? height : undefined,
                                bands: count ? count : undefined,
                                wkt: wkt ? wkt : undefined,
                                transform: geoTransform ? geoTransform : undefined,
                                name: dataset.name,
                                type: dataset.type,
                                preview: URL.createObjectURL(file),
                            };
                            setDatasetDetails(datasetDetails);

                            // Checks bands and throws an error, needs investigating as an image that throws this did display on the platform correctly
                            if (count < 3 || count > 4) {
                                handleSetError(Errors.Bands);
                                return;
                            }

                            // Check if loam was unable to find information and handle it.
                            if (!(width && height && wkt && geoTransform)) {
                                if (file.name.toLowerCase().includes('.tif')) {
                                    // This follows current platform behaviours, if it hits this the image is not ok but we will leave it for the backend
                                    const bbox = evaluateBBOX(file, width, height, wkt, geoTransform);
                                    displayMapAOI(bbox);
                                    setFileType(FileType.MAP);
                                } else if (file && file.size < MAX_JPG_SIZE) {
                                    // makes it possibly a image that can be handled but needs location details
                                    setFileType(FileType.IMAGE);
                                } else {
                                    handleSetError(Errors.JpgTooLargeToGeoReference);
                                    setFileType(FileType.INVALID);
                                }
                                return;
                            }

                            if (file && width && height && wkt && geoTransform) {
                                const bbox = evaluateBBOX(file, width, height, wkt, geoTransform);
                                displayMapAOI(bbox);
                                setFileType(FileType.MAP);
                            } else {
                                setFileType(FileType.INVALID);
                                handleSetError(Errors.NotGeoreferencedTooLarge);
                            }
                        })
                        .catch((_) => {
                            // Image is missing information on the dataset dataset.
                            if (file.size < MAX_JPG_SIZE && !file.name.includes('.tif')) {
                                setFileType(FileType.IMAGE);
                            } else if (file.name.includes('.tif')) {
                                // Follow current behaviour and let the user upload and allow the backend to handle it
                                setFileType(FileType.MAP);
                            } else {
                                handleSetError(Errors.JpgTooLargeToGeoReference);
                            }
                        });
                })
                .catch((error) => {
                    // Loam has failed to initialize
                    console.log(error);
                    handleSetError(Errors.InitializationError);
                });
        } else {
            // Reject the file as not accepted
            handleSetError(Errors.FileOpen);
        }
    };

    const evaluateBBOX = (file, gtifWidth, gtifHeight, gtifWkt, gtifTransform) => {
        const EPSG4326 =
            'GEOGCS["WGS 84",DATUM["WGS_1984",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.01745329251994328,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4326"]]';
        const cornersPx = [
            [0, 0],
            [gtifWidth, 0],
            [gtifWidth, gtifHeight],
            [0, gtifHeight],
        ];
        const cornersGeo = cornersPx.map(([x, y]) => {
            return [
                gtifTransform[0] + gtifTransform[1] * x + gtifTransform[2] * y,
                gtifTransform[3] + gtifTransform[4] * x + gtifTransform[5] * y,
            ];
        });

        const ll = proj4(gtifWkt.replace('_', ' '), EPSG4326, cornersGeo[0]);
        //const lr = proj4(gtifWkt.replace("_", " "), EPSG4326, cornersGeo[1]);
        const ur = proj4(gtifWkt.replace('_', ' '), EPSG4326, cornersGeo[2]);
        //const ul = proj4(gtifWkt.replace("_", " "), EPSG4326, cornersGeo[3]);
        return new LatLngBounds(new LatLng(ll[1], ll[0]), new LatLng(ur[1], ur[0]));
    };

    const handleSetError = (_error: Errors) => {
        setError(_error);
    };

    const onSelectFile = (file: File) => {
        if (file) {
            setFile(file);
            setFileType(undefined);
            determineFileType(file);
        }
    };

    return <SelectFile onSelectFile={(file) => onSelectFile(file)} error={error} setError={(err) => setError(err)} />;
};

export default ShareMap;
