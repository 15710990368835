import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectUserListings } from '../../../store/Map/UserProfile/selectors';
import profileIconUrl from '../../Dashboard/profile-icon-url';

interface MobileProfileNoMapsProps {
    midView?: boolean;
}

const MobileProfileNoMaps = (props: MobileProfileNoMapsProps) => {
    const userListings = useSelector(selectUserListings);

    if (userListings?.length !== 0) {
        return <React.Fragment />;
    }

    return (
        <NoMapsView className={props.midView ? 'mid-view' : ''}>
            <NoImageryIcon src={profileIconUrl.noImagesUrl} />
            <NoMapsTitle className={props.midView ? 'mid-view' : ''}>There are no maps uploaded here yet</NoMapsTitle>
        </NoMapsView>
    );
};

export default MobileProfileNoMaps;

const NoMapsView = styled.div`
    color: white;
    width: 90%;
    margin: auto;
    margin-top: 20px;
    text-align: center;

    &.mid-view {
        margin-top: -20px;
        scale: 0.7;
        width: 100%;
    }
`;

const NoImageryIcon = styled.img`
    width: 70px;
    height: 70px;
    position: initial;
    background: #191a1a;
    box-shadow: 0px 11px 14px -7px rgb(0 0 0 / 30%), 0px 23px 36px 3px rgb(0 0 0 / 24%),
        0px 9px 44px 8px rgb(0 0 0 / 22%);
    left: -15px;
    top: -15px;
    margin-bottom: -50px;
    border: 3px solid #eed926;
    padding: 10px;
    z-index: 99999;
    border-radius: 8px;
`;

const NoMapsTitle = styled.div`
    border-radius: 8px;
    background: #191a1a;
    border: 2px solid #eed926;
    color: white;
    font-size: 20px;
    padding: 60px 30px 60px 30px;
    text-align: center;
    margin: auto;

    &.mid-view {
        padding: 60px 0px 40px 0px;
        font-size: 18px;
    }
`;
