import React, { useState } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import {
    Dropdown as BootstrapDropdown,
    DropdownItem as BootstrapDropdownItem,
    DropdownMenu as BootstrapDropdownMenu,
    DropdownToggle as BootstrapDropdownToggle,
    NavItem as BootstrapNavItem,
    NavLink as BootstrapNavLink,
} from 'reactstrap';
import styled from 'styled-components';
import { UserDTO } from '../../api/model';
import Analytics from '../../lib/user-analytics';
import UserHelper from '../../lib/user-helper';
import NavbarPoints from './Points/navbar-points';

interface NavbarProfileProps {
    userProfile: UserDTO;
    isSoar: boolean;
}

const NavbarProfile = (props: NavbarProfileProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    // I want to be able to click either the toggle or the profile to toggle.
    // We need this timeout latch to prevent a close triggering twice.
    // Placing the toggle only on the NavItem breaks dismiss when clicking outside the dropdown
    const [disableToggle, setDisableToggle] = useState(false);

    const toggleDropdown = () => {
        if (!disableToggle) {
            setDropdownOpen(!dropdownOpen);
            setDisableToggle(true);
            setTimeout(() => {
                setDisableToggle(false);
            }, 100);
        }
    };

    const profileName = props.userProfile.name ? props.userProfile.name : 'Soar User';

    const handleViewProfile = () => {
        Analytics.Event('Navigation', 'Clicked Profile');
    };

    const handleViewDashboard = () => {
        Analytics.Event('Navigation', 'Clicked Dashboard');
    };

    const handleViewImagery = () => {
        Analytics.Event('Navigation', 'Clicked Imagery');
    };
    const onClickLogout = () => {
        Analytics.Event('Navigation', 'Clicked sign out');
        UserHelper.resetIdToken();
        window.location.href = `${window.location.protocol}//${window.location.host}`;
    };

    return (
        <NavContainer>
            <NavItem onClick={toggleDropdown}>
                <NavLink>
                    <Avatar
                        data-cy="profile-avatar"
                        alt="profile-image"
                        src={
                            props.userProfile.avatarUrl ||
                            UserHelper.getDefaultAvatar(props.userProfile.userId as string)
                        }
                    />
                    <AvatarNameLink title={profileName}>{profileName}</AvatarNameLink>
                </NavLink>

                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle color="link" caret className="large-nav" />
                    <DropdownToggle color="link" className="small-nav" />

                    <DropdownMenu right>
                        <DropdownHeader header>
                            Signed in as
                            <br />
                            <Strong>{profileName}</Strong>
                        </DropdownHeader>
                        <DropdownItem divider />

                        {props.isSoar && (
                            <DropdownItemLink>
                                <NavLinkDropdown
                                    onClick={handleViewProfile}
                                    to={`/profile/${props.userProfile.userId}`}
                                    tag={RRNavLinkStyled}
                                >
                                    View my profile
                                </NavLinkDropdown>
                            </DropdownItemLink>
                        )}
                        <DropdownItemLink>
                            <NavLinkDropdown onClick={handleViewDashboard} to="/dashboard" tag={RRNavLinkStyled}>
                                View my dashboard
                            </NavLinkDropdown>
                        </DropdownItemLink>
                        <DropdownItemLink>
                            <NavLinkDropdown
                                onClick={handleViewImagery}
                                to={props.isSoar ? '/dashboard/my-imagery' : '/dashboard/data'}
                                tag={RRNavLinkStyled}
                                style={{ display: props.isSoar ? 'block' : 'none' }}
                            >
                                View my maps
                            </NavLinkDropdown>
                        </DropdownItemLink>
                        <DropdownItem divider />
                        <DropdownItem onClick={() => onClickLogout()}>Sign out</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </NavItem>

            {props.isSoar && <NavbarPoints />}
        </NavContainer>
    );
};

export default NavbarProfile;

const NavContainer = styled.div`
    display: inline-block;
`;

const Dropdown = styled(BootstrapDropdown)``;

const DropdownToggle = styled(BootstrapDropdownToggle)`
    color: white;
    width: 8px;
    padding-left: 0;
    margin-left: -5px;
    margin-top: 12px;
    margin-bottom: 12px;

    &:hover {
        color: #eed926;
    }

    &:after {
        display: block;
    }

    &.large-nav {
        @media (max-width: 1400px) {
            visibility: hidden;
        }
    }

    &.small-nav {
        @media (min-width: 1400px) {
            visibility: hidden;
        }
    }
`;

const DropdownMenu = styled(BootstrapDropdownMenu)`
    width: 320px;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    margin-top: 28px;
    border-radius: 6px;
`;

const DropdownHeader = styled(BootstrapDropdownItem)`
    color: #fff;
    font-weight: bolder;
    font-size: 1.2rem;
`;

const DropdownItem = styled(BootstrapDropdownItem)`
    font-size: 1.1rem;
    color: white;

    &:hover {
        background: #eed926;
        color: #000;
    }
`;

const DropdownItemLink = styled(BootstrapDropdownItem)`
    padding: 0;
`;

const Avatar = styled.img`
    width: 28px;
    height: 28px;
    border-radius: 14px;
    border: 1px solid #eed926;
    margin: -4px 8px 0 0;
    @media (max-width: 1400px) {
        margin-right: 10px;
    }
`;

const AvatarNameLink = styled.span`
    color: white;

    @media (max-width: 1200px) {
        position: sticky;
    }
`;

const NavLink = styled(BootstrapNavLink)`
    color: white;
    cursor: pointer;
    width: 190px;

    // 1401px used so the overflow hidden does not interfere with the collapsing navigation
    @media (min-width: 1401px) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NavLinkDropdown = styled<any>(BootstrapNavLink)`
    font-size: 1.1rem;
    color: white;
    padding: 0.25rem 1.5rem !important;

    &:hover {
        background: #eed926;
        color: #000;
    }
`;

const RRNavLinkStyled = styled(RRNavLink)`
    padding: 0;
`;

const NavItem = styled(BootstrapNavItem)`
    color: white;
    cursor: pointer;
    display: flex;
    user-select: none;
    &:hover {
        color: #eed926;
    }
`;

const Strong = styled.strong`
    display: block;
    margin-top: 3px;
    color: #eed926;
    overflow: hidden;
    text-overflow: ellipsis;
`;
