export const actionTypes = {
    UPDATE_CONFIG: 'CONFIG/UPDATE_CONFIG',
    UPDATE_CONFIG_SUCCESS: 'CONFIG/UPDATE_CONFIG_SUCCESS',
    UPDATE_CONFIG_FAILURE: 'CONFIG/UPDATE_CONFIG_FAILURE',

    APP_MAP_LOADED: 'APP/MAP_LOADED',
    APP_MAP_UNLOADED: 'APP/MAP_UNLOADED',

    FLY_TO: 'MAP/FLY_TO',
    FLY_TO_IMMEDIATELY: 'MAP/FLY_TO_IMMEDIATELY',
    FLY_TO_POSITION: 'MAP/FLY_TO_POSITION',
    FLY_TO_POSITION_INVALIDATE: 'MAP/FLY_TO_INVALIDATE',
    FLY_TO_ZOOM: 'MAP/FLY_TO_ZOOM',

    SHOW_TUTORIAL: 'TUTORIAL/SHOW_TUTORIAL',
    DISMISS_TUTORIAL: 'TUTORIAL/DISMISS_TUTORIAL',
    SHOW_LOGIN_DIALOG: 'LOGIN/SHOW_DIALOG',
    SHOW_NAVBAR_DROPDOWN: 'NAVBAR/SHOW_DROPDOWN',
    FLY_TO_FIX_ANTI_MERIDIAN: 'MAP/FLY_TO_FIX_ANTIMERIDIAN',

    SET_MAP_BOUNDS: 'SET/MAP_BOUNDS',
    SET_MAP_ZOOM: 'SET/MAP_ZOOM',

    SHOW_UPLOAD_DIALOG: 'UPLOAD/SHOW_DIALOG',
};
