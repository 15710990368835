import React from 'react';
import TermsAndConditions from './terms-and-conditions';
import PrivacyAgreement from './privacy-agreement';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import UriHelper from '../../lib/uri-helper';

const Terms = () => {

    const handleClose = () => {
        UriHelper.navigateToPath(`/`)
    }

    return (
        <TermsContainer>
             {isMobile ? <CloseIcon src="/assets/close.png" onClick={() => handleClose()} />  : null}
            <TermsHeader>Terms and Conditions</TermsHeader>
            <TermsAndConditions />
            <PrivacyAgreement />
        </TermsContainer>
    );
};

export default Terms;

const TermsHeader = styled.h1`
    @media only screen and (max-width: 600px) {
        margin: 0px;
        display: none;
    }
`;

const TermsContainer = styled.div`
    @media only screen and (max-width: 600px) {
        flex-direction: column;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-top: 0px;
        overflow: auto;
        height: 100vh;
    }
`;

const CloseIcon = styled.img`
    margin: 15px;
    width: 22px;
    height: 22px;
    pointer-events: all;
    float: right;
`;