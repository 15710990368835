import React, { useState } from 'react';
import styled from 'styled-components';
import FullWidthListingCard from './ListingCard/full-width-listing-card';
import HalfWidthListingCard from './ListingCard/half-width-listing-card';
import ListStyleListingCard from './ListingCard/list-style-listing-card';
import ThirdWidthListingCard from './ListingCard/third-width-listing-card';
import Analytics from '../../../lib/user-analytics';

interface MobileBottomSheetContentProps {
    listings: {
        id: number;
        authorName: string;
        title: string;
    }[];
    onSelectListing: (id: number) => void;
}

const NUMBER_OF_LISTING_TO_SHOW_INCREMENT = 10;

const MobileBottomSheetContent = (props: MobileBottomSheetContentProps) => {
    const [numberOfListingsToShow, setNumberOfListingsToShow] = useState(25);

    if (props.listings === undefined || props.listings.length === 0) {
        return <React.Fragment />;
    }

    if (props.listings.length === 1) {
        return (
            <Container>
                <FullWidthListingCard listing={props.listings[0]} onSelectListing={(id) => props.onSelectListing(id)} />
            </Container>
        );
    }

    if (props.listings.length === 2) {
        return (
            <Container>
                <HalfWidthListingCard listing={props.listings[0]} onSelectListing={(id) => props.onSelectListing(id)} />
                <HalfWidthListingCard listing={props.listings[1]} onSelectListing={(id) => props.onSelectListing(id)} />
            </Container>
        );
    }

    if (props.listings.length === 3) {
        return (
            <React.Fragment>
                <Container>
                    <HalfWidthListingCard
                        listing={props.listings[0]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                    <HalfWidthListingCard
                        listing={props.listings[1]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                </Container>
                <Container>
                    <FullWidthListingCard
                        listing={props.listings[2]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                </Container>
            </React.Fragment>
        );
    }

    if (props.listings.length === 4) {
        return (
            <React.Fragment>
                <Container>
                    <HalfWidthListingCard
                        listing={props.listings[0]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                    <HalfWidthListingCard
                        listing={props.listings[1]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                </Container>
                <Container>
                    <HalfWidthListingCard
                        listing={props.listings[2]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                    <HalfWidthListingCard
                        listing={props.listings[3]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                </Container>
            </React.Fragment>
        );
    }

    if (props.listings.length === 5) {
        return (
            <React.Fragment>
                <Container>
                    <HalfWidthListingCard
                        listing={props.listings[0]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                    <HalfWidthListingCard
                        listing={props.listings[1]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                </Container>
                <Container>
                    <ThirdWidthListingCard
                        listing={props.listings[2]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                    <ThirdWidthListingCard
                        listing={props.listings[3]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                    <ThirdWidthListingCard
                        listing={props.listings[4]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                </Container>
            </React.Fragment>
        );
    }

    if (props.listings.length > 5 && props.listings.length <= 8) {
        return (
            <React.Fragment>
                <Container>
                    <HalfWidthListingCard
                        listing={props.listings[0]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                    <HalfWidthListingCard
                        listing={props.listings[1]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                </Container>
                <Container>
                    <ThirdWidthListingCard
                        listing={props.listings[2]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                    <ThirdWidthListingCard
                        listing={props.listings[3]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                    <ThirdWidthListingCard
                        listing={props.listings[4]}
                        onSelectListing={(id) => props.onSelectListing(id)}
                    />
                </Container>
                <React.Fragment>
                    {props.listings.slice(5, props.listings.length).map((t) => {
                        return (
                            <ListStyleListingCard
                                key={t.id}
                                listing={t}
                                onSelectListing={(id) => props.onSelectListing(id)}
                            />
                        );
                    })}
                </React.Fragment>
            </React.Fragment>
        );
    }

    if (props.listings.length === 8) {
        <React.Fragment>
            <Container>
                <HalfWidthListingCard listing={props.listings[0]} onSelectListing={(id) => props.onSelectListing(id)} />
                <HalfWidthListingCard listing={props.listings[1]} onSelectListing={(id) => props.onSelectListing(id)} />
            </Container>
            <Container>
                <ThirdWidthListingCard
                    listing={props.listings[2]}
                    onSelectListing={(id) => props.onSelectListing(id)}
                />
                <ThirdWidthListingCard
                    listing={props.listings[3]}
                    onSelectListing={(id) => props.onSelectListing(id)}
                />
                <ThirdWidthListingCard
                    listing={props.listings[4]}
                    onSelectListing={(id) => props.onSelectListing(id)}
                />
            </Container>
            <Container>
                <ThirdWidthListingCard
                    listing={props.listings[5]}
                    onSelectListing={(id) => props.onSelectListing(id)}
                />
                <ThirdWidthListingCard
                    listing={props.listings[6]}
                    onSelectListing={(id) => props.onSelectListing(id)}
                />
                <ThirdWidthListingCard
                    listing={props.listings[7]}
                    onSelectListing={(id) => props.onSelectListing(id)}
                />
            </Container>
        </React.Fragment>;
    }
    const handleLoadMoreLink = () => {
        setNumberOfListingsToShow((current) => current + NUMBER_OF_LISTING_TO_SHOW_INCREMENT);
        Analytics.Event('Mobile - Bottom Sheet Explore', 'Clicked Load More');
    };

    return (
        <React.Fragment>
            <Container>
                <HalfWidthListingCard listing={props.listings[0]} onSelectListing={(id) => props.onSelectListing(id)} />
                <HalfWidthListingCard listing={props.listings[1]} onSelectListing={(id) => props.onSelectListing(id)} />
            </Container>
            <Container>
                <ThirdWidthListingCard
                    listing={props.listings[2]}
                    onSelectListing={(id) => props.onSelectListing(id)}
                />
                <ThirdWidthListingCard
                    listing={props.listings[3]}
                    onSelectListing={(id) => props.onSelectListing(id)}
                />
                <ThirdWidthListingCard
                    listing={props.listings[4]}
                    onSelectListing={(id) => props.onSelectListing(id)}
                />
            </Container>
            <Container>
                <ThirdWidthListingCard
                    listing={props.listings[5]}
                    onSelectListing={(id) => props.onSelectListing(id)}
                />
                <ThirdWidthListingCard
                    listing={props.listings[6]}
                    onSelectListing={(id) => props.onSelectListing(id)}
                />
                <ThirdWidthListingCard
                    listing={props.listings[7]}
                    onSelectListing={(id) => props.onSelectListing(id)}
                />
            </Container>
            <React.Fragment>
                {props.listings.slice(8, numberOfListingsToShow).map((t) => {
                    return (
                        <ListStyleListingCard
                            key={t.id}
                            listing={t}
                            onSelectListing={(id) => props.onSelectListing(id)}
                        />
                    );
                })}

                {numberOfListingsToShow < props.listings.length ? (
                    <ShowMoreButton onClick={handleLoadMoreLink}>Load more</ShowMoreButton>
                ) : null}
            </React.Fragment>
        </React.Fragment>
    );
};

export default MobileBottomSheetContent;

const Container = styled.div`
    display: flex;
`;

const ShowMoreButton = styled.button`
    pointer-events: all;
    margin: 0 auto;
    width: 100%;
    align-items: normal;
    background-color: rgba(0, 0, 0, 0);
    border-style: none;
    box-sizing: content-box;
    color: #eed923;
    cursor: pointer;
    display: inline;
    font: inherit;
    height: auto;
    padding: 10px 0px;
    text-decoration: underline;
    -moz-appearance: none;

    &:focus {
        outline: none;
        border: none;
    }
`;
