import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Analytics from '../../../lib/user-analytics';
import { actionSetBaseMap } from '../../../store/Map/Mobile/actions';
import { BaseMap } from '../../../store/Map/Mobile/model';
import { selectBaseMap } from '../../../store/Map/Mobile/selectors';

const MobileBasemapOptions = () => {
    const selectedBasemap = useSelector(selectBaseMap);

    const dispatch = useDispatch();
    const setBaseMap = (baseMap: BaseMap) => dispatch(actionSetBaseMap(baseMap));

    const handleSelectBaseMap = (baseMap: BaseMap) => {
        Analytics.Event('Basemap', 'Changed basemap', baseMap);
        Analytics.Event('Mobile Basemap Options', 'Selected Basemap', baseMap);
        setBaseMap(baseMap);
    };

    return (
        <MobileShareContainer role="dialog" aria-modal="true">
            <MobileBasemapContainerTitle>Choose Basemap</MobileBasemapContainerTitle>
            <MenuList>
                <MenuListItem
                    className={selectedBasemap === BaseMap.OSM_Elevation ? 'selected-base-map' : ''}
                    onClick={() => handleSelectBaseMap(BaseMap.OSM_Elevation)}
                >
                    Open Elevation
                </MenuListItem>
                <MenuListItem
                    className={selectedBasemap === BaseMap.GSM_Satellite ? 'selected-base-map' : ''}
                    onClick={() => handleSelectBaseMap(BaseMap.GSM_Satellite)}
                >
                    Google Satellite
                </MenuListItem>
                <MenuListItem
                    className={selectedBasemap === BaseMap.GSM_Hybrid ? 'selected-base-map' : ''}
                    onClick={() => handleSelectBaseMap(BaseMap.GSM_Hybrid)}
                >
                    Google Hybrid
                </MenuListItem>
                <MenuListItem
                    className={selectedBasemap === BaseMap.GSM_Street ? 'selected-base-map' : ''}
                    onClick={() => handleSelectBaseMap(BaseMap.GSM_Street)}
                >
                    Google Street
                </MenuListItem>
                <MenuListItem
                    className={selectedBasemap === BaseMap.OSM_Street ? 'selected-base-map' : ''}
                    onClick={() => handleSelectBaseMap(BaseMap.OSM_Street)}
                >
                    OpenStreetMaps
                </MenuListItem>
                <MenuListItem
                    className={selectedBasemap === BaseMap.Empty_Black ? 'selected-base-map' : ''}
                    onClick={() => handleSelectBaseMap(BaseMap.Empty_Black)}
                >
                    Dark Blank
                </MenuListItem>
                <MenuListItem
                    className={
                        selectedBasemap === BaseMap.Empty_White
                            ? 'selected-base-map last-basemap-item'
                            : 'last-basemap-item'
                    }
                    onClick={() => handleSelectBaseMap(BaseMap.Empty_White)}
                >
                    White Blank
                </MenuListItem>
            </MenuList>
        </MobileShareContainer>
    );
};

export default MobileBasemapOptions;

const MobileShareContainer = styled.section`
    width: 100%;
    border-radius: 6px;
`;

const MobileBasemapContainerTitle = styled.div`
    color: rgba(255, 255, 255, 0.87);
    padding: 10px 5px;
    margin-left: 6px;
    font-size: 1.25rem;
    font-weight: 500;
`;

const MenuList = styled.ul`
    list-style: none;
    padding: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    margin-bottom: 0px;
`;

const MenuListItem = styled.li`
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    width: 100vw;
    color: rgba(255, 255, 255, 0.87);
    padding: 10px 5px;
    font-size: 16px;
    padding-left: 12px;

    &.selected-base-map {
        background-color: #eed926;
        color: rgba(0, 0, 0, 0.87);
    }

    &.last-basemap-item {
        border-bottom: none;
    }
`;
