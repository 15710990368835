import styled from 'styled-components';
import SoarModal from '../../Shared/soar-modal';
import { StyledButton } from '../../Shared/styled-button';

interface UploadTutorialGuideProps {
    isOpen: boolean;
    toggle: () => void;
    resetGCPS: () => void;
}

const UploadOfferPredictedRectification = (props: UploadTutorialGuideProps) => {
    const { isOpen, toggle, resetGCPS } = props;
    return (
        <SoarModal title="Predicted Rectification" isOpen={isOpen} toggle={toggle} width={'700px !important'}>
            <UploadTutorialContainer>
                <UploadPredictedText>
                    We have identified that your image contains enough information to be rectified immediately.
                    <br />
                    If you are happy with the result you can click the 'Generate Image' button to complete.
                    <br />
                    You can press back to adjust the images position or remove the points and reapply them.
                </UploadPredictedText>
                <UploadTutorialFooter>
                    <UploadTutorialButton onClick={resetGCPS}>Reset points</UploadTutorialButton>
                    <UploadTutorialButton onClick={toggle}>Close to review</UploadTutorialButton>
                </UploadTutorialFooter>
            </UploadTutorialContainer>
        </SoarModal>
    );
};

export default UploadOfferPredictedRectification;

const UploadTutorialContainer = styled.div`
    margin: 1rem;
`;

const UploadTutorialButton = styled(StyledButton)`
    text-transform: none;

    :first-child {
        margin-right: 10px;
    }
`;

const UploadTutorialFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const UploadPredictedText = styled.div`
    color: rgba(255, 255, 255, 0.87);
    font-size: 16px;
    margin: 20px 10px;
`;
