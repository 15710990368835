import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectDatasetDetails, selectImageBounds, selectRectifiedMap } from '../../store/Map/Uploads/selectors';
import { RectifiedMap } from '../../api/model';
import { LatLng, LatLngBounds } from 'leaflet';
import { GCPLoader } from './GCPControls/gcp-loader';
import { selectLoggedIn } from '../../store/Account/selectors';
import UploadSplitScreen from './SplitScreen/upload-split-screen';
import {
    actionSetDatasetDetails,
    actionSetImageBounds,
    actionSetImageManualPosition,
    actionSetRectifiedMapAction,
} from '../../store/Map/Uploads/actions';
import GeoUtil from '../../lib/geo-util';
import GCPTable from './GCPControls/gcp-table';
import GCPHandler from './GCPControls/GCPHandler';
import { StyledButton } from '../Shared/styled-button';
import UploadFullScreen from './FullScreen/upload-full-screen';
import { UploadHighlightedTarget, UploadWorkFlow } from './Model/model';
import UploadLocationDetails from './NoLocation/upload-location-details';
import UploadCalculateProgressControl from './Shared/upload-calculate-progress-control';
import GeoRectifyUtil from './Util/geo-rectify-util';
import UriHelper from '../../lib/uri-helper';
import UploadHelp from './Help/upload-help';
import FileUtil from '../../lib/file-util';
import UploadOfferPredictedRectification from './Shared/upload-offer-predicted-rectification';
import ShareGeoTIFF from './FullScreen/upload-geotiff';
import DownloadGeorectified from './Shared/download-georectified';

// @ts-ignore // suppress type error
import loam from 'loam';

const UploadImagery = () => {
    const isLoggedIn = useSelector(selectLoggedIn);
    const datasetDetails = useSelector(selectDatasetDetails);
    const rectifiedMap = useSelector(selectRectifiedMap);

    const dispatch = useDispatch();

    const [distortableBounds, setDistortableBounds] = useState<LatLng[] | undefined>(undefined);
    const [rectangleBounds, setRectangleBounds] = useState<LatLng[] | undefined>(undefined);
    const [warpedDataset, setWarpedDataset] = useState<RectifiedMap | undefined>(
        rectifiedMap ? rectifiedMap : undefined
    );
    const [gcpArray, setGcpArray] = useState<any[]>([]); // eslint-disable-line @typescript-eslint/no-explicit-any
    const [updateGCPTable, setUpdateGCPTable] = useState<boolean>(false);
    const [hasPredictedGCPPoints, setHasPredictedGCPPoints] = useState<boolean>(false);
    const [toggle, setToggle] = useState<boolean>(false);
    const [selectedGCP, setSelectedGCP] = useState<LatLng[] | undefined>();
    const [highlightTarget, setHighlightTarget] = useState<UploadHighlightedTarget>('EDIT');
    const [isMarkerActive, setIsMarkerActive] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>();
    const [showUploadModal, setShowUploadModal] = useState<boolean>();
    const [isResetting, setIsResetting] = useState<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [imageMapRef, setImageMapRef] = useState<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [targetMapRef, setTargetMapRef] = useState<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [fullscreenMapRef, setFullscreenMapRef] = useState<any>();

    const [uploadWorkFlow, setUploadWorkFlow] = useState<UploadWorkFlow>('SPLITSCREEN');

    const imageBounds = useSelector(selectImageBounds);

    useEffect(() => {
        const resetUpload = () => {
            dispatch(actionSetRectifiedMapAction(undefined));
            dispatch(actionSetImageManualPosition(undefined));
            dispatch(actionSetDatasetDetails(undefined));
            dispatch(actionSetImageBounds(undefined));
            setDistortableBounds(undefined);
            setRectangleBounds(undefined);
            setWarpedDataset(undefined);
            requestAnimationFrame(() => {
                setUploadWorkFlow('SPLITSCREEN');
                setIsResetting(false);
            });
        };

        return () => {
            setIsResetting(true);
            resetUpload();
        };
    }, [dispatch]);

    useEffect(() => {
        if (!datasetDetails || !isLoggedIn) {
            UriHelper.navigateToPath('/upload');
        }
    }, [datasetDetails, isLoggedIn]);

    // TODO Be nice to move this out of the component
    useEffect(() => {
        const readFile = () => {
            if (datasetDetails?.file && !imageBounds && targetMapRef?.current && imageMapRef?.current && !isResetting) {
                const leafletMap = targetMapRef.current.leafletElement;
                const imageMap = imageMapRef.current.leafletElement;

                FileUtil.readXmpData(datasetDetails?.file).then((result) => {
                    const position = FileUtil.parseLocation(result.exif);
                    const imageBounds = FileUtil.calculateBoundingBoxFromExifData(result.xmp, result.exif);
                    if (imageBounds) {
                        const leafletClockPosition = GeoUtil.toLeafletPositionsClock(imageBounds);
                        // Set the distortable bounds and generate predicted GCP points.
                        setDistortableBounds(leafletClockPosition);
                        const predictedPoints = GeoRectifyUtil.generatePredictedGCPPoints(
                            datasetDetails,
                            leafletClockPosition
                        );
                        if (predictedPoints.length) {
                            setGcpArray(predictedPoints);
                            setHasPredictedGCPPoints(true);
                        }

                        // Set the rectangle bounds and zoom to the image bounds.
                        setRectangleBounds(GeoUtil.toLeafletRectangle(imageBounds));
                        const _bbox: LatLngBounds = GeoUtil.getLeafletBbox(imageBounds);
                        leafletMap.fitBounds(_bbox);
                        imageMap.fitBounds(_bbox);

                        // Set the redrag.
                        GeoRectifyUtil.setRedrag(_bbox, imageMap);
                    } else if (position && (position.lat || position.lng)) {
                        // Candidate file is missing details but we can zoom to the location
                        leafletMap.flyTo(position, 10);
                        setUploadWorkFlow('NOLOCATION');
                    } else {
                        setUploadWorkFlow('NOLOCATION');
                    }
                });
            }
        };

        readFile();
    }, [datasetDetails, imageBounds, imageMapRef, isResetting, targetMapRef]);

    // Handles manually uploaded images to display in split screen rectification
    const readManualFile = useCallback(() => {
        if (imageBounds && targetMapRef && targetMapRef.current && imageMapRef && imageMapRef.current) {
            const leafletMap = targetMapRef.current.leafletElement;
            const imageMap = imageMapRef.current.leafletElement;

            // Set the bounds of the image to the distorted bounds of the image.
            const distortedBounds = GeoUtil.toDistortedBounds(imageBounds as LatLng[]);
            setDistortableBounds(distortedBounds);

            // TODO We can add gcp points for an image that has been distorted automatically and it works in certain cases moving the image around or making it smaller,
            //      if the image is made bigger or is distorted out of shape it breaks which is odd because manually adding points and making the image larger works as expected.
            //
            //      Error: Error in GDALWarp: Free disk space available is 2048000000 bytes, whereas 51090959656 are at least necessary
            //      a potential fix is to convert to a vrt first
            //
            // const predictedPoints = GeoRectifyUtil.generatePredictedGCPPoints(distortedBounds);
            // setGCPs(predictedPoints);

            // Set the bounds of the rectangle to the distorted bounds of the image.
            const positionClock = GeoUtil.toDistortablePositionsClock(imageBounds as LatLng[]);
            const rectangle = GeoUtil.toLeafletManualRectangle(positionClock);
            setRectangleBounds(rectangle);

            const _bbox: LatLngBounds = GeoUtil.getLeafletBbox(positionClock);

            // Set the bounds of the leaflet map and image map to the distorted bounds of the image.
            leafletMap.fitBounds(_bbox);
            imageMap.fitBounds(_bbox);

            // Set the bounds of the redrag to the distorted bounds of the image.
            GeoRectifyUtil.setRedrag(_bbox, imageMap);

            // Set the image to be undefined.
            dispatch(actionSetImageManualPosition(undefined));

            // Set the upload work flow to split screen.
            setUploadWorkFlow('SPLITSCREEN');
        }
    }, [dispatch, imageMapRef, imageBounds, targetMapRef]);

    useEffect(() => {
        if (imageBounds && !isResetting) {
            readManualFile();
        }
    }, [imageBounds, isResetting, readManualFile]);

    useEffect(() => {
        if (warpedDataset) {
            setGcpArray(warpedDataset.gcps);
            if (fullscreenMapRef && fullscreenMapRef.current) {
                const leafletMap = fullscreenMapRef.current.leafletElement;
                const corners = warpedDataset.corners;
                const bounds = new LatLngBounds(corners[0], corners[1]).extend(corners[2]).extend(corners[3]);
                leafletMap.fitBounds(bounds);
            }
        }
    }, [fullscreenMapRef, warpedDataset]);

    const updateMarkerPosition = (newPosition: LatLng, index: number): void => {
        if (newPosition) {
            gcpArray[index] = [gcpArray[index][0], newPosition];
            setGcpArray(gcpArray);
            setUpdateGCPTable(true);
        }
    };

    const calcOnFly = () => {
        if (datasetDetails && imageBounds) {
            setIsProcessing(true);
            const cornersPx = datasetDetails.cornersPx;
            if (cornersPx && cornersPx.length > 0) {
                // TODO do we need this???
                const distortedBounds = GeoUtil.toDistortedBounds(imageBounds as LatLng[]);
                setDistortableBounds(distortedBounds);
                const gcpArray = GeoUtil.toGcpArray(
                    imageBounds as LatLng[],
                    datasetDetails.width,
                    datasetDetails.height
                );
                // DO we need this
                setGcpArray(gcpArray);
                loam.open(datasetDetails.file).then((dataset) => {
                    const PIXEL_RESOLUTION = 0.1;
                    GeoRectifyUtil.createDataset2(dataset, PIXEL_RESOLUTION, gcpArray, datasetDetails)
                        .then((warped: RectifiedMap) => {
                            setWarpedDataset(warped);
                            requestAnimationFrame(() => {
                                setUploadWorkFlow('FULLSCREEN');
                            });
                        })
                        .catch((err) => {
                            alert(`Error calculating dataset, ${err}`);
                        })
                        .finally(() => {
                            setIsProcessing(false);
                        });
                });
            }
        }
    };

    const calc = () => {
        if (datasetDetails && distortableBounds) {
            setIsProcessing(true);
            loam.open(datasetDetails.file).then((dataset) => {
                // TODO this seems busted and not needed
                // let pixelResolution = GeoRectifyUtil.calculatePixelResolution(
                //     distortableBounds,
                //     gcps,
                //     datasetDetails.width,
                //     datasetDetails.height,
                //     imageMapRef
                // );
                // if (!pixelResolution) {
                //     pixelResolution = 0.1;
                // }
                // pixelResolution /= 1;
                GeoRectifyUtil.createDataset2(dataset, 0.1, gcpArray, datasetDetails)
                    .then((warped: RectifiedMap) => {
                        setWarpedDataset(warped);
                        requestAnimationFrame(() => {
                            setUploadWorkFlow('FULLSCREEN');
                        });
                    })
                    .catch((err) => {
                        alert(`Error calculating dataset, ${err}`);
                    })
                    .finally(() => {
                        setIsProcessing(false);
                    });
            });
        }
    };

    // TODO works but needs to be refactored
    const isSelectedGCP = (gcp: LatLng[]): boolean => {
        return !!(
            selectedGCP &&
            selectedGCP.length === 2 &&
            gcp.length === 2 &&
            gcp[0].equals(selectedGCP[0]) &&
            gcp[1].equals(selectedGCP[1])
        );
    };

    const mapViewPort = () => {
        switch (uploadWorkFlow) {
            case 'NOLOCATION':
                return (
                    <UploadContainer>
                        <UploadLocationDetails
                            isSelectedGCP={isSelectedGCP}
                            setUploadWorkFlow={() => setUploadWorkFlow('SPLITSCREEN')}
                            previewUrl={datasetDetails?.minimizedPreview ? datasetDetails.minimizedPreview : ''}
                            setRef={setTargetMapRef}
                            mapRef={targetMapRef}
                            calc={calcOnFly}
                        />
                    </UploadContainer>
                );
            case 'SPLITSCREEN':
                return (
                    <React.Fragment>
                        <UploadContainer>
                            <UploadSplitScreen
                                gcpsArray={gcpArray}
                                isSelectedGCP={isSelectedGCP}
                                setImageMapRef={setImageMapRef}
                                setTargetMapRef={setTargetMapRef}
                                previewUrl={datasetDetails?.minimizedPreview ? datasetDetails.minimizedPreview : ''}
                                distortableBounds={distortableBounds}
                                rectangleBounds={rectangleBounds}
                                setWarpedDataset={setWarpedDataset}
                                highlightTarget={highlightTarget}
                                updateMarkerPosition={updateMarkerPosition}
                                calc={calc}
                                isMarkerActive={isMarkerActive}
                            />
                        </UploadContainer>
                        <UploadControls>
                            <GCPTable
                                corners={distortableBounds}
                                gcpsArray={gcpArray}
                                imageWidth={datasetDetails?.width ? datasetDetails.width : 0}
                                imageHeight={datasetDetails?.height ? datasetDetails.height : 0}
                                mapRef={imageMapRef}
                                setSelectedGCP={(gcp: LatLng[] | undefined) => setSelectedGCP(gcp)}
                                selectedGCP={selectedGCP}
                                deleteGCP={(index: number) => {
                                    const tmpArray = gcpArray;
                                    tmpArray.splice(index, 1);
                                    setGcpArray(tmpArray);
                                    setToggle(!toggle);
                                }}
                                isProcessing={!!isProcessing}
                                updateGCPTable={updateGCPTable}
                                setUpdateGCPTable={() => setUpdateGCPTable(false)}
                                resetGCPs={() => {
                                    setGcpArray([]);
                                    setUploadWorkFlow('SPLITSCREEN');
                                }}
                            />
                            <FullScreenButtonContainer>
                                <ContinueButton
                                    onClick={() => {
                                        setUploadWorkFlow('NOLOCATION');
                                    }}
                                >
                                    <BackIcon className="fa fa-chevron-left" />
                                    Back
                                </ContinueButton>
                                <UploadCalculateProgressControl gcpArray={gcpArray} calc={calc} />
                            </FullScreenButtonContainer>
                            <UploadHelp />
                            <GCPHandler
                                active={true}
                                imageMapRef={imageMapRef}
                                targetMapRef={targetMapRef}
                                distortableBounds={distortableBounds}
                                setGcpArray={(gcpArray) => {
                                    setGcpArray(gcpArray);
                                    setToggle(!toggle); //Force rerender
                                }}
                                gcpArray={gcpArray}
                                handleClickedImage={(_) => {
                                    if (!fullscreenMapRef) {
                                        setHighlightTarget('EDIT');
                                    }
                                }}
                                handleClickedMap={(_) => {
                                    if (!fullscreenMapRef) {
                                        setHighlightTarget('CONTROL');
                                    }
                                }}
                                handleIsMarkerActive={setIsMarkerActive}
                            />
                        </UploadControls>
                    </React.Fragment>
                );
            case 'FULLSCREEN':
                return (
                    <React.Fragment>
                        <UploadContainer fullScreenView>
                            <UploadFullScreen
                                gcpArray={gcpArray}
                                isSelectedGCP={isSelectedGCP}
                                previewUrl={datasetDetails?.minimizedPreview ? datasetDetails.minimizedPreview : ''}
                                setWarpedDataset={setWarpedDataset}
                                highlightTarget={highlightTarget}
                                setFullscreenMapRef={setFullscreenMapRef}
                                warpedDataset={warpedDataset ? warpedDataset : rectifiedMap}
                                rectifiedMap={rectifiedMap ? rectifiedMap : undefined}
                                updateMarkerPosition={updateMarkerPosition}
                            />
                        </UploadContainer>
                        <GenerateImageContainer>
                            <FullScreenButtonContainer>
                                <ContinueButton
                                    onClick={() => {
                                        setUploadWorkFlow('SPLITSCREEN');
                                    }}
                                >
                                    Not happy, try again
                                </ContinueButton>
                                {/* <ContinueButton onClick={() => setShowUploadModal(true)}>
                                    Export and upload
                                </ContinueButton> */}
                                {warpedDataset && <DownloadGeorectified tiffBlob={warpedDataset?.blobUrlTIFF} />}
                            </FullScreenButtonContainer>
                        </GenerateImageContainer>
                    </React.Fragment>
                );
            default:
                return <div>Something went wrong</div>;
        }
    };

    return (
        <React.Fragment>
            <GCPLoader isProcessing={isProcessing} />
            {mapViewPort()}
            {showUploadModal && warpedDataset && (
                <ShareGeoTIFF
                    handleShowUploadModal={() => setShowUploadModal(false)}
                    mapUrl={warpedDataset.blobUrlTIFF}
                />
            )}
            <UploadOfferPredictedRectification
                toggle={() => {
                    setHasPredictedGCPPoints(false);
                }}
                isOpen={hasPredictedGCPPoints}
                resetGCPS={() => {
                    setToggle(!toggle);
                }}
            />
        </React.Fragment>
    );
};

export default UploadImagery;

const UploadContainer = styled.div<{ fullScreenView?: boolean }>`
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: ${(props) => (props.fullScreenView ? 'calc(100vh - 60px)' : 'calc(66vh + 90px)')}; // this is shit
`;

const UploadControls = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: calc(100vh - 66vh - 110px);
    margin: 10px;
`;

const ContinueButton = styled(StyledButton)`
    text-transform: none;
    height: fit-content;
`;

const GenerateImageContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const FullScreenButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 6px 14px;
`;

const BackIcon = styled.i`
    font-size: 12px;
    margin-right: 5px;
`;
