import Constants from './constants';

// Ignore list based off: https://gist.github.com/1878283 + https://gist.github.com/impressiver/5092952
export const SENTRY_OPTIONS = {
    environment: 'production',
    tracesSampleRate: 0.25,
    // Will cause a deprecation warning, but the demise of `ignoreErrors` is still under discussion.
    // See: https://github.com/getsentry/raven-js/issues/73
    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
        // Avast extension error
        '_avast_submit',
        'leaflet',
    ],
    denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
    ],
    dsn: Constants.SENTRY_URL,
};

export const LOGROCKET_CONFIG = {
    dom: {
        textSanitizer: true,
        inputSanitizer: true,
    },
    network: {
        requestSanitizer: (request) => {
            // scrub body
            request.body = null;

            // scrub header pair from request
            request.headers['Authorization'] = null;

            // make sure you return the modified request
            return request;
        },
        responseSanitizer: (response) => {
            // scrubs response body
            response.body = null;
            return response;
        },
    },
    browser: {
        urlSanitizer: (url) => {
            let sanitizedUrl = url;

            // redact the path following /ssn/ from the URL
            // eslint-disable-next-line no-useless-escape
            sanitizedUrl = sanitizedUrl.replace(/\/ssn\/([^\/]*)/i, '/ssn/**redacted**');

            // redact the value of the query parameter secret_key
            sanitizedUrl = sanitizedUrl.replace(/secret_key=([^&]*)/, 'secret_key=**redacted**');

            // make sure you return the sanitized URL string
            return sanitizedUrl;
        },
    },
}