import React from 'react';
import styled from 'styled-components';
import ApiFeaturedContent from '../../../../api/api-featured-content';
import ApiGeolocation from '../../../../api/api-geolocation';
import MapViewHistoryHelper from '../../../../lib/map-view-history-helper';
import SoarHelper from '../../../../lib/soar-helper';
import { FixedMasonaryItem } from '../../../Search/search-content-fixed-masonary';
import MobileSearchCarousel from '../Carousel/mobile-search-carousel';
import MobileSearchResults from '../Results/mobile-search-results';
import MobileSearchCurrentEvent from './mobile-search-current-event';

const MobileSearchDiscovery = () => {
    const handleFetchHottest = async () => {
        const request = await ApiFeaturedContent.getHottestMaps().then((results) => {
            const items: FixedMasonaryItem[] = results.map((t) => {
                return {
                    id: t.id,
                    authorName: t.userName,
                    title: t.title,
                };
            });
            return items;
        });
        return request;
    };

    const handleFetchHistory = async () => {
        const recentlyViewed: FixedMasonaryItem[] = await MapViewHistoryHelper.getViewedListingHistory().map((t) => {
            return {
                id: t.id,
                authorName: t.author,
                title: t.title,
            };
        });
        return recentlyViewed;
    };

    const handleFetchMapsNearMe = async () => {
        const mapsNearMe = await ApiGeolocation.getListingsNearMe();
        if (!mapsNearMe || mapsNearMe.listings.length === 0) {
            return;
        }

        const nearbyMaps: FixedMasonaryItem[] = await mapsNearMe.listings.map((t) => {
            return {
                id: t.id,
                authorName: t.userName,
                title: t.title,
            };
        });
        return nearbyMaps;
    };

    return (
        <React.Fragment>
            <MobileSearchResults>
                {SoarHelper.isSoar() ? (
                    <React.Fragment>
                        <MobileSearchCurrentEvent />
                        <MobileSearchCarousel
                            carouselTitle={
                                <React.Fragment>
                                    <MobileSegmentTitleIcon src="/assets/search-content/emoji-fire.svg" />
                                    Discover the <span>hottest</span> maps
                                </React.Fragment>
                            }
                            handleApiRequest={() => handleFetchHottest()}
                            displayDivider
                        />
                        <MobileSearchCarousel
                            carouselTitle={
                                <React.Fragment>
                                    <MobileSegmentTitleIcon src="/assets/search-content/emoji-recent-map.svg" />
                                    Revisit maps you <span>recently viewed</span>
                                </React.Fragment>
                            }
                            handleApiRequest={() => handleFetchHistory()}
                            displayDivider
                        />
                        <MobileSearchCarousel
                            carouselTitle={
                                <React.Fragment>
                                    <MobileSegmentTitleIcon src="/assets/search-content/emoji-recent-map.svg" />
                                    Maps near me
                                </React.Fragment>
                            }
                            handleApiRequest={() => handleFetchMapsNearMe()}
                        />
                    </React.Fragment>
                ) : null}
            </MobileSearchResults>
        </React.Fragment>
    );
};

export default MobileSearchDiscovery;

const MobileSegmentTitleIcon = styled.img`
    width: 16px;
    height: 16px;
    margin: -4px 8px 0 8px;
`;
