import { useInfiniteLoader, useMasonry, usePositioner, useResizeObserver } from 'masonic';
import { useScroller, useSize } from 'mini-virtual-list';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ApiListings from '../../api/api-listings';
import { ListingDTO } from '../../api/model';
import SoarHelper from '../../lib/soar-helper';
import useIntersectionObserver from '../../lib/use-intersection-observer';
import Analytics from '../../lib/user-analytics';
import { PulseLoader } from '../Shared/pulse-loader';
import { SegmentContainer, SegmentTitle, SegmentTitleIcon } from './search-container';
import SearchResultsContinentFilter from './search-results-continent-filter';
import SearchResultsEmptyView from './search-results-empty-view';
import { SelectableListing } from './search-results-masonary';
import SearchResultsMasonaryCard from './search-results-masonary-card';

const LIMIT = 40;
const THRESHOLD = 20;

interface SearchContentExploreProps {
    handleSelectListing: (id: number) => void;
}

const SearchContentExplore = (props: SearchContentExploreProps) => {
    const containerRef = useRef(null);
    const [masonryOverflow, setMasonryOverflow] = useState<'hidden' | 'auto'>('hidden');
    const [items, setItems] = useState<ListingDTO[]>([]);
    const { width, height } = useSize(containerRef);
    const { scrollTop, isScrolling } = useScroller(containerRef);
    const [loading, setLoading] = useState(false);
    const [shouldFetch, setShouldFetch] = useState(true);
    const [count, setCount] = useState<number | undefined>(undefined);

    const positioner = usePositioner({
        width: width,
        columnGutter: 16,
        columnWidth: 260,
    });

    const resizeObserver = useResizeObserver(positioner);
    const intersectionObserver = useIntersectionObserver(containerRef, { threshold: 1 });

    // The masonry scrolls separately from the container.
    // We deactivate the masonry overflow until the user scrolls all the way down which
    // gives a smooth transition from content scrolling to mosaic scrolling
    useEffect(() => {
        if (intersectionObserver && intersectionObserver.intersectionRatio === 1) {
            setMasonryOverflow('auto');
        } else {
            setMasonryOverflow('hidden');
        }
    }, [intersectionObserver]);

    useEffect(() => {
        ApiListings.searchListings(LIMIT, 0).then((res) => {
            setItems(res);
            setCount(res.length);
            setShouldFetch(res.length === LIMIT);
        });
    }, []);

    const selectableListings: SelectableListing[] = items.map((t) => {
        return {
            id: t.id,
            authorName: t.userName,
            title: t.title,
            handleSelectListing: (id) => {
                props.handleSelectListing(id);
                Analytics.Event('Search Bar', 'Clicked Map From Most Recent', id);
            },
        };
    });

    const fetchListings = useCallback(() => {
        const fetching = async () => {
            if (shouldFetch && !loading && count) {
                setLoading(true);
                const res = await ApiListings.searchListings(LIMIT, count);
                setItems((current) => [...current, ...res]);
                setCount(count + res.length);
                setLoading(false);
                setShouldFetch(res.length === LIMIT);
            }
        };

        return fetching();
    }, [count, shouldFetch, loading]);

    const maybeLoadMore = useInfiniteLoader(fetchListings, {
        isItemLoaded: (index, items) => !!items[index],
        threshold: THRESHOLD,
    });

    return (
        <Container>
            {count === undefined ? (
                <PulseLoader />
            ) : count === 0 ? (
                <SearchResultsEmptyView isLoading={false} noMaps={true} />
            ) : (
                <React.Fragment>
                    <SegmentTitle>
                        <SegmentTitleIcon src="/assets/search-content/emoji-explore.svg" />
                        Explore the <span>most recent</span> maps below
                    </SegmentTitle>
                    {SoarHelper.isSoar() && <SearchResultsContinentFilter />}
                </React.Fragment>
            )}

            <MasonicContainer>
                <Masonic ref={containerRef} overflow={masonryOverflow}>
                    {useMasonry<SelectableListing>({
                        positioner,
                        resizeObserver,
                        items: selectableListings,
                        height,
                        scrollTop,
                        isScrolling,
                        overscanBy: 6,
                        render: SearchResultsMasonaryCard,
                        onRender: maybeLoadMore,
                    })}
                </Masonic>
            </MasonicContainer>
        </Container>
    );
};

export default SearchContentExplore;

const Container = styled(SegmentContainer)`
    margin-bottom: 16px;
`;

const MasonicContainer = styled.main`
    display: flex;
    flex-direction: column;
    max-height: 75vh;
    width: 100%;
    overflow: hidden;
`;

const Masonic = styled.div<{ overflow: string }>`
    height: calc(75vh - 116px);
    overflow: ${(props) => props.overflow};
    padding: 8px;
    width: 100%;

    &::-webkit-scrollbar-track {
        margin: 3px 0;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        margin: 3px 0;
        width: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        margin: 3px 0;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: rgba(125, 125, 125, 0.2);
        border-radius: 4px;
    }
`;
