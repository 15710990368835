import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Analytics from '../../lib/user-analytics';
import { selectLoggedIn } from '../../store/Account/selectors';
import { LoginModalMode } from '../Registration/login-enum';
import LoginRegisterDialog from '../Registration/login-register-dialog';
import { StyledDarkButton } from '../Shared/styled-button';

const LandingPageLeftContent = () => {
    const isLoggedIn = useSelector(selectLoggedIn);
    const [showRegister, setShowRegister] = useState<boolean>(false);

    return (
        <React.Fragment>
            <LandingPageLeftContainer>
                <LandingPageImageContainer>
                    <LandingPageImageBackground src="/assets/landing-page/landing_page_image_background.png" />
                    <LandingPageLaptopImage src="/assets/landing-page/landing_page_image.png" />
                </LandingPageImageContainer>
                <LandingPageTitle>Discover your Earth</LandingPageTitle>
                <LandingPageImageText>
                    <span>Soar</span> is the <span>new atlas</span> of the world's maps and satellite imagery,
                    crowd-sourced from individuals, organisations, and the broader community.
                </LandingPageImageText>
                <LandingPageImageText>
                    Unlike other websites, any content you add to <span>Soar</span> will always be your own, we just
                    make it easier to <span>store</span>, <span>display</span>, and <span>share</span> that content
                    online, with everyone.
                </LandingPageImageText>
                <LandingPageImageText>
                    Best of all, it's 100% <span>free!</span>
                </LandingPageImageText>
                {!isLoggedIn ? (
                    <LandingPageRegisterButton
                        onClick={() => {
                            setShowRegister(!showRegister);
                            Analytics.Event('LandingPage', 'Clicked register account');
                        }}
                    >
                        Register Account
                    </LandingPageRegisterButton>
                ) : null}
            </LandingPageLeftContainer>
            <LoginRegisterDialog
                isOpen={showRegister}
                initialMode={LoginModalMode.REGISTER}
                onClose={() => setShowRegister(!showRegister)}
            />
        </React.Fragment>
    );
};

export default LandingPageLeftContent;

const LandingPageLeftContainer = styled.div``;

const LandingPageImageContainer = styled.div`
    width: 100%;
    position: relative;
`;

const LandingPageImageBackground = styled.img`
    width: inherit;
`;

const LandingPageLaptopImage = styled.img`
    position: absolute;
    width: 60%;
    left: 0;
    margin-left: 20%;
    margin-top: 5%;
`;

const LandingPageTitle = styled.h1`
    color: white;
    font-size: 35px;
`;

const LandingPageImageText = styled.div`
    color: white;
    font-size: 24px;
    margin: 0px 30px 10px 30px;
    text-align: center;
    line-height: 30px;

    :nth-child(1) {
        margin-bottom: 0px;
    }

    span {
        color: #eed926;
    }
`;

const LandingPageRegisterButton = styled(StyledDarkButton)`
    border: 1px solid #eed926 !important;
    color: #eed926;
    -webkit-text-fill-color: #eed926;
    border-radius: 8px;
    text-transform: none;
    padding: 5px 20px;
    font-size: 24px;
    margin: 35px auto;

    @media only screen and (max-height: 800px) {
        margin: 20px auto;
    }
`;
