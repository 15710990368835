import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { ListingDTO } from '../../../api/model';
import ShareService from '../../../lib/share-service';
import SoarHelper from '../../../lib/soar-helper';
import Analytics from '../../../lib/user-analytics';
import profileIconUrl from '../profile-icon-url';

interface UrlProps {
    listing: ListingDTO;
}

const ImageryShare = ({ listing }: UrlProps) => {
    const isSoar = SoarHelper.isSoar();

    const handleShareLink = async (mapId: number, href: string, event: string, eventType: string) => {
        const url = ShareService.shareMapUrl(mapId);
        Analytics.Event(event, eventType, mapId);
        const shareURL = `${href}${url}`;
        if (shareURL) {
            window.open(shareURL, '_blank');
        }
    };

    const generateCopyLink = (mapId: number) => {
        const url = ShareService.shareMapUrl(mapId);
        const currentDomain = SoarHelper.isSoar() && url ? url : `${window.location.href}`;
        navigator.clipboard.writeText(currentDomain);
        toast.dark('Copied to clipboard');
        Analytics.Event(`Social Media - Copied To Share - ${currentDomain}`, 'Link', mapId);
    };

    return (
        <ShareContainer className="share">
            {isSoar && (
                <React.Fragment>
                    <ShareLinkButton
                        onClick={() =>
                            handleShareLink(
                                listing.id,
                                'https://www.twitter.com/intent/tweet?hashtags=SoarEarth&url=',
                                'Social Media - Opened To Share',
                                'Twitter'
                            )
                        }
                    >
                        <ShareLinkImage src={profileIconUrl.twitterUrl} title="Share to Twitter" />
                    </ShareLinkButton>
                    <ShareLinkButton
                        onClick={() =>
                            handleShareLink(
                                listing.id,
                                'https://www.facebook.com/sharer.php?u=',
                                'Social Media - Opened To Share',
                                'Facebook'
                            )
                        }
                    >
                        <ShareLinkImage src={profileIconUrl.facebookUrl} title="Share to Facebook" />
                    </ShareLinkButton>
                    <ShareLinkButton
                        onClick={() =>
                            handleShareLink(
                                listing.id,
                                'https://www.linkedin.com/shareArticle?mini=true&url=',
                                'Social Media - Opened To Share',
                                'LinkedIn'
                            )
                        }
                    >
                        <ShareLinkImage src={profileIconUrl.linkedInUrl} title="Share to LinkedIn" />
                    </ShareLinkButton>
                </React.Fragment>
            )}
            <ShareLinkButton onClick={() => generateCopyLink(listing.id)}>
                <ShareLinkImage src={profileIconUrl.shareIcon} title="Copy share link" />
            </ShareLinkButton>
        </ShareContainer>
    );
};

export default ImageryShare;

const ShareContainer = styled.div`
    position: absolute;
    z-index: 105;
    margin: 5px;

    transition: opacity 400ms;

    @media only screen and (max-width: 600px) {
        position: relative;
        margin: 0px 5px -25px 5px;
    }
`;

const ShareLinkImage = styled.img`
    width: 20px;
    height: 20px;
`;

const ShareLinkButton = styled.button`
    border: none;
    background-color: transparent;

    &:hover {
        transform: scale(1.2);
    }

    @media only screen and (max-width: 600px) {
        font-size: 10px;
    }
`;
