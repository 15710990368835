import styled from 'styled-components';
import NoImageryModal from './Shared/no-imagery-modal';

interface ImageryNoResultsMobileProps {
    listingsLength: number;
    hasSearched: boolean;
}

const ImageryNoResultsMobile = (props: ImageryNoResultsMobileProps) => {
    if (props.hasSearched) {
        return <AllLoadedNotification>{`We found ${props.listingsLength} results`}</AllLoadedNotification>;
    }

    return (
        <NoImageryModal
            icon="/assets/floating-drawer-icons/maps-icon-yellow.svg"
            title="You have not added any maps"
            mobileText="Upload maps from a desktop computer"
        />
    );
};

export default ImageryNoResultsMobile;

const AllLoadedNotification = styled.div`
    text-align: center;
    color: #eed926;
    margin: 10px 0px;
    font-size: 18px;
`;
