import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListingDTO } from '../../../../api/model';
import { LatLngBoundsHash } from '../../../../lib/lat-lng-bounds-hash';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import { actionFlyToOnMap } from '../../../../store/App/actions';
import { actionFetchBookmarkedMaps } from '../../../../store/Map/BookMarks/actions';
import { selectBookmarkedMaps } from '../../../../store/Map/BookMarks/selectors';
import { actionFetchListingDictionarySuccess } from '../../../../store/Map/MapSelection/actions';
import { ListingDictionary, ListingGroup } from '../../../../store/Map/MapSelection/model';
import PolygonCluster from '../PolygonCluster/polygon-cluster';

interface MyBookmarksProviderProps {
    leafletMap: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const MyBookmarksProvider = (props: MyBookmarksProviderProps) => {
    const dispatch = useDispatch();
    const bookmarkedMaps = useSelector(selectBookmarkedMaps);

    useEffect(() => {
        if (!bookmarkedMaps) {
            dispatch(actionFetchBookmarkedMaps());
        }
    }, [bookmarkedMaps, dispatch]);

    const handleSetDictionary = (bookmarks: ListingDTO[]) => {
        const tileLayerDictionary: ListingDictionary = new Map<string, ListingGroup>();

        const existingTileLayersForHash = [];

        bookmarks.map((t) => {
            const newValueForHash: ListingGroup = {
                latlngBounds: t.boundingBox,
                tileLayers: [
                    { id: t.id, authorName: t.userName || '', title: t.title || '' },
                    ...existingTileLayersForHash,
                ],
            };
            tileLayerDictionary.set(new LatLngBoundsHash(t.boundingBox).hash, newValueForHash);
        });

        return tileLayerDictionary;
    };

    const onSelectListing = (id: number, listingGroup: ListingGroup) => {
        Analytics.Event('Bookmarks', 'Selected map', id);       
        // Invalidate the listing dictionary so they don't show while the ListingDTO is being fetched
        dispatch(actionFetchListingDictionarySuccess(undefined));

        // FlyTo occurs because otherwise we need to wait for the ListingDTO before the map moves
        if (listingGroup) {
            dispatch(actionFlyToOnMap(listingGroup.latlngBounds));
        }
        UriHelper.navigateToMap(id);
    }

    return (
        <React.Fragment>
            <PolygonCluster
                leafletMap={props.leafletMap}
                listingDictionary={handleSetDictionary(bookmarkedMaps || [])}
                handleSelectListing={onSelectListing}
            />
        </React.Fragment>
    );
};

export default MyBookmarksProvider;
