import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import DrawerHint from '../drawer-hint';

interface SelectFileProps {
    onSelectFile: (file: File) => void;
    error: string;
    setError: (err: string) => void;
}

// TODO Should be able to remove this but leaving for now as integrated in other parts of the platform
const SelectFile = (props: SelectFileProps) => {
    const handleDrop = (files: File[]) => {
        if (files.length === 0) {
            props.setError('Unsupported file type. Only .JPG, .PNG, .TIFF, and .ECW files are accepted');
        } else if (files.length === 1) {
            props.onSelectFile(files[0]);
            props.setError('');
        } else {
            props.setError('Only one file can be uploaded at a time');
        }
    };

    return (
        <Container>
            {props.error ? <DrawerHint error>{props.error}</DrawerHint> : null}
            <Dropzone
                onDrop={handleDrop}
                accept="image/png, image/jpeg,.tiff,.tif,.ecw"
                style={{
                    margin: '8px',
                    border: '1px dashed rgba(255, 255, 255, 0.3)',
                    borderRadius: '6px',
                }}
                activeStyle={{
                    border: '1px dashed #EED926',
                }}
            >
                <DroneImageIcon src="/assets/floating-drawer-icons/upload-drone-image-icon.png" />

                <DropzoneText>Drag and drop your image</DropzoneText>
                <DropzoneAltText>or</DropzoneAltText>
                <Button>CHOOSE FILE</Button>
            </Dropzone>
        </Container>
    );
};

export default SelectFile;

const Container = styled.div`
    margin: 8px;
    padding: 6px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;

    &:hover {
        border: 1px solid #eee308;
    }
    input {
        visibility: hidden;
    }
`;

const DroneImageIcon = styled.img`
    width: 58px;
    height: 39px;
    margin: 32px auto;
    display: block;
`;

const DropzoneText = styled.p`
    color: white;
    text-align: center;
    font-size: 16px;
    margin: 0;
`;

const DropzoneAltText = styled.p`
    color: white;
    text-align: center;
    font-size: 13px;
    margin: 0;
`;

const Button = styled.button`
    margin: 0 auto;
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 1rem;
    background-color: #eed926 !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: black;
    border-radius: 4px;
    margin-top: 6px;
    margin-bottom: 50px;
`;
