import { actionTypes as at } from './constants';
import { Action } from '../root-reducer';
import { LatLng, LatLngBounds } from 'leaflet';
import { Config } from './model';

const defaultConfig: Config = {
    IS_LOADING_CONFIG: true,
    IS_AVAILABLE_FOR_PURCHASE: false,
    SUBDOMAIN: '',
    LOGO: '/assets/logos/soar_logo.png',
    MOBILE_LOGO: '/assets/logos/soar-icon.png',
    MOBILE_LOGO_ACTIVE: '/assets/logos/soar-icon-active.png',
    BEHAVIOURS: {
        FORCED_LOGIN: false,
        ROUTE_AFTER_LOGIN: '',
    },
    FEATURES: {
        SHOPPING_CART: false, // TODO can probably be removed
    },
};

interface StoreApp {
    config: Config;
    maintenance: boolean;

    mapLoaded: boolean;
    mapPosition?: LatLng | LatLngBounds;
    mapPositionZoom: number | undefined;
    shouldMapPositionUpdateImmediately: boolean;
    showTutorial: boolean;
    showLoginDialog: boolean;
    showNavbarDropdown: boolean;
    mapBounds: LatLng | LatLngBounds | undefined;
    mapZoom: number | undefined;
    showUploadDialog: boolean;
    isMobileFullScreen: boolean;
}

const initialAppState: StoreApp = {
    config: defaultConfig,
    maintenance: false,
    mapLoaded: false,
    mapPosition: undefined,
    mapPositionZoom: undefined,
    shouldMapPositionUpdateImmediately: false,
    showTutorial: false,
    showLoginDialog: false,
    showNavbarDropdown: false,
    mapBounds: undefined,
    mapZoom: undefined,
    showUploadDialog: false,
    isMobileFullScreen: false,
};

export default (state: StoreApp = initialAppState, action: Action): StoreApp => {
    switch (action.type) {
        case at.UPDATE_CONFIG:
            return { ...state, maintenance: false };
        case at.UPDATE_CONFIG_SUCCESS:
            return { ...state, maintenance: false, config: action.payload };
        case at.UPDATE_CONFIG_FAILURE:
            return { ...state, maintenance: true };

        case at.APP_MAP_LOADED:
            return {
                ...state,
                mapLoaded: true,
            };

        case at.APP_MAP_UNLOADED:
            return {
                ...state,
                mapLoaded: false,
            };

        case at.FLY_TO:
            return {
                ...state,
                mapPosition: action.payload.position,
                mapPositionZoom: action.payload.zoom,
                shouldMapPositionUpdateImmediately: action.payload.immediately,
            };

        case at.FLY_TO_POSITION:
            return {
                ...state,
                mapPosition: action.payload,
            };

        case at.FLY_TO_POSITION_INVALIDATE:
            return {
                ...state,
                mapPosition: undefined,
                mapPositionZoom: undefined,
                shouldMapPositionUpdateImmediately: false,
            };

        case at.FLY_TO_ZOOM:
            return {
                ...state,
                mapPositionZoom: action.payload,
            };

        case at.SHOW_TUTORIAL:
            return {
                ...state,
                showTutorial: true,
            };


        case at.DISMISS_TUTORIAL:
            return {
                ...state,
                showTutorial: false,
                showNavbarDropdown: false,
            };

        case at.SHOW_LOGIN_DIALOG:
            return {
                ...state,
                showLoginDialog: action.payload,
            };

        case at.SHOW_NAVBAR_DROPDOWN:
            return {
                ...state,
                showNavbarDropdown: action.payload,
            };

        case at.SET_MAP_BOUNDS:
            return {
                ...state,
                mapBounds: action.payload,
            };

        case at.SET_MAP_ZOOM:
            return {
                ...state,
                mapZoom: action.payload,
            };

        case at.SHOW_UPLOAD_DIALOG:
            return {
                ...state,
                showUploadDialog: action.payload,
            };
        default:
            return state;
    }
};
