import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ListingDTO, UserDTO, Review, ListingType } from '../../../api/model';
import prettyBytes from 'pretty-bytes';

interface ProfileProps {
    myProfile?: UserDTO;
    myListings?: ListingDTO[];
}

const MenuUserDetails = (props: ProfileProps) => {
    const { myProfile, myListings } = props;
    const [tileLayers, setTileLayers] = useState<ListingDTO[]>([]);
    const [fileCount, setFileCount] = useState<number>(0);

    useEffect(() => {
        if (myListings) {
            const droneImages = myListings?.filter((l) => {
                return l.listingType === ListingType.IMAGE && l.review === Review.APPROVED;
            });
            const tileLayers = myListings?.filter((l) => {
                return l.listingType === ListingType.TILE_LAYER && l.review === Review.APPROVED;
            });

            let fileCount = 0;
            if (droneImages) fileCount = droneImages.length;
            if (tileLayers) fileCount = fileCount + tileLayers.length;

            setTileLayers(tileLayers);
            setFileCount(fileCount);
        }
    }, [myListings]);

    const listingsFileSize = (): number => {
        if (myListings && myListings.length > 0) {
            return myListings
                .map((listing) => {
                    return listing.filesize || 0;
                })
                .reduce((accumulator, currentValue) => {
                    return accumulator + currentValue;
                });
        }
        return 0;
    };

    return (
        <ProfileUserDetails>
            {myProfile ? (
                <ProfileUserName>{`Hello, ${myProfile.firstName ? myProfile.firstName : 'Soar User'}`}</ProfileUserName>
            ) : null}
            <p>This is your dashboard</p>
            <ProfileUserStats>
                <ProfileFileStatsContainer>
                    <ProfileFileStats>
                        {fileCount ? fileCount : '0'}
                        <ProfileFileStatsLabel>File Count</ProfileFileStatsLabel>
                    </ProfileFileStats>
                    <Divider />
                </ProfileFileStatsContainer>
                <ProfileFileStatsContainer>
                    <ProfileFileStats>
                        {tileLayers ? tileLayers.length : '0'}
                        <ProfileFileStatsLabel>Areas Mapped</ProfileFileStatsLabel>
                    </ProfileFileStats>
                    <Divider />
                </ProfileFileStatsContainer>
                <ProfileFileStatsContainer>
                    <ProfileFileStats>
                        {myListings ? prettyBytes(listingsFileSize()) : '0'}
                        <ProfileFileStatsLabel>Data Stored</ProfileFileStatsLabel>
                    </ProfileFileStats>
                </ProfileFileStatsContainer>
            </ProfileUserStats>
        </ProfileUserDetails>
    );
};

export default MenuUserDetails;

const ProfileUserDetails = styled.div`
    padding-top: 10px;
    color: white;
    width: 330px;

    h1 {
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0px 8px 0px 8px;
    }
    p {
        color: rgba(255, 255, 255, 0.3);
        text-align: center;
    }

    @media only screen and (max-width: 600px) {
        width: 100vw;
        display: block;
        margin: 0 auto;
    }
`;

const ProfileUserName = styled.h1`
    display: block;
    color: inherit;

    @media only screen and (max-width: 600px) {
        flex-direction: row;
        justify-content: center;
        font-size: 2rem;
    }
`;

const ProfileUserStats = styled.div`
    color: inherit;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;

    padding: 10px;
    padding-top: 0px;

    border-top: solid 1px rgba(255, 255, 255, 0.3);
`;

const ProfileFileStatsContainer = styled.div`
    flex: 1 0 auto;
    margin: 10px 0px 0px 10px;
    font-size: 30px;
    color: inherit;
    text-align: center;
`;

const ProfileFileStats = styled.div`
    padding: 5px;
    color: inherit;
    @media only screen and (max-width: 600px) {
        font-size: 2rem;
    }
`;

const ProfileFileStatsLabel = styled.div`
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
`;

const Divider = styled.div`
    border-left: 1px solid white;
    height: 50px;
    float: right;

    opacity: 0.15;

    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: -60px;
`;
