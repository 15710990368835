import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import { actionFlyToOnMap } from '../../../../store/App/actions';
import { actionFetchListingDictionarySuccess } from '../../../../store/Map/MapSelection/actions';
import { ListingGroup } from '../../../../store/Map/MapSelection/model';
import { actionFetchMyMapsListingDictionary } from '../../../../store/Map/MyMapSelection/actions';
import { selectMyMapsListingDictionary } from '../../../../store/Map/MyMapSelection/selectors';
import { selectMyProfile } from '../../../../store/Account/selectors';
import PolygonCluster from '../PolygonCluster/polygon-cluster';

interface MyMapsProviderProps {
    leafletMap: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const MyMapsProvider = (props: MyMapsProviderProps) => {
    const dispatch = useDispatch();
    const profile = useSelector(selectMyProfile);

    useEffect(() => {
        if (profile) {
            dispatch(actionFetchMyMapsListingDictionary(profile.userId));
        }
    }, [profile, dispatch]);

    const listingDictionary = useSelector(selectMyMapsListingDictionary);

    const onSelectListing = (id: number, listingGroup: ListingGroup) => {
        Analytics.Event('My Maps', 'Selected map', id);        
        // Invalidate the listing dictionary so they don't show while the ListingDTO is being fetched
        dispatch(actionFetchListingDictionarySuccess(undefined));

        // FlyTo occurs because otherwise we need to wait for the ListingDTO before the map moves
        if (listingGroup) {
            dispatch(actionFlyToOnMap(listingGroup.latlngBounds));
        }
        UriHelper.navigateToMap(id);
    };

    return (
        <React.Fragment>
            <PolygonCluster
                leafletMap={props.leafletMap}
                listingDictionary={listingDictionary}
                handleSelectListing={onSelectListing}
            />
        </React.Fragment>
    );
};

export default MyMapsProvider;
