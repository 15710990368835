import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { cssTransition, toast } from 'react-toastify';
import styled from 'styled-components';
import { selectActiveAllMapIsLoading } from '../../store/Map/SuperMap/selectors';
import { PulseLoader } from '../Shared/pulse-loader';
import './tilelayer-loading-style.css';

const TOAST_ID = 'toastLoadingId';

const fade = cssTransition({
    enter: 'fadeIn',
    exit: 'fadeOut',
});

const TileLayerLoadingIndicator = () => {
    const isTileLayerLoading = useSelector(selectActiveAllMapIsLoading);

    useEffect(() => {
        if (isTileLayerLoading) {
            toast.dark(
                <ToastContainer>
                    <PulseLoader />
                </ToastContainer>,
                {
                    toastId: TOAST_ID,
                    autoClose: 60000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: false,
                    progress: undefined,
                    closeButton: false,
                    position: toast.POSITION.TOP_CENTER,
                    transition: fade,
                }
            );
        } else {
            toast.dismiss(TOAST_ID);
        }
    }, [isTileLayerLoading]);

    useEffect(() => {
        return () => {
            toast.dismiss(TOAST_ID);
        };
    }, []);

    return <React.Fragment />;
};

export default TileLayerLoadingIndicator;

const ToastContainer = styled.div`
    display: flex;
    justify-content: center;
    i {
        color: white;
        font-size: 25px;
    }
    i.error {
        color: red !important;
    }
`;
