export type BasemapKey =
    | 'Dark Base Map'
    | 'Open Elevation'
    | 'OpenStreetMaps'
    | 'Google Satellite'
    | 'Google Hybrid'
    | 'Google Street'
    | 'Dark Blank'
    | 'White Blank';

export const basemapKeys: BasemapKey[] = [
    'Dark Base Map',
    'Open Elevation',
    'OpenStreetMaps',
    'Google Satellite',
    'Google Hybrid',
    'Google Street',
    'Dark Blank',
    'White Blank',
];
