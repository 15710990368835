import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetSelectedContinent } from '../../../../store/Map/MapSelection/actions';
import { ListingGroup } from '../../../../store/Map/MapSelection/model';
import { selectSelectedContinent, selectContinentListings } from '../../../../store/Map/MapSelection/selectors';
import MobileSearchResultCarousel from '../Carousel/mobile-search-result-carousel';
import { MobileFilterItem } from './mobile-search-results';
import Analytics from '../../../../lib/user-analytics';

const MobileContinentFilter = () => {
    const dispatch = useDispatch();
    const selectedContinent = useSelector(selectSelectedContinent);
    const continentsDictionary = useSelector(selectContinentListings);
    const setSelectedContinent = (continent: ListingGroup | undefined) =>
        dispatch(actionSetSelectedContinent(continent));
    if (!continentsDictionary) {
        return <React.Fragment />;
    }


    return (
        <MobileSearchResultCarousel padding={'5px 0px'}>
            {Array.from(continentsDictionary.values()).map((t) => {
                if (!t.continent) return <React.Fragment />;
                const selectedFilter = selectedContinent?.continent?.id === t.continent.id;
                const handleClickedContinent = () => {
                    selectedFilter ? setSelectedContinent(undefined) : setSelectedContinent(t);
                    Analytics.Event('Mobile - Search Results Continent Filter', 'Clicked Continent Filter');
                }
                return (
                    <MobileFilterItem
                        onClick={handleClickedContinent}
                        key={t.continent.name}
                        borderColor={'#eed926'}
                        backgroundColor={selectedFilter ? 'rgba(238, 218, 38, 0.3)' : ''}
                    >
                        <React.Fragment>{t.continent.name}</React.Fragment>
                    </MobileFilterItem>
                );
            })}
        </MobileSearchResultCarousel>
    );
};

export default MobileContinentFilter;
