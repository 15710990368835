import React from 'react';
import { isMobile } from 'react-device-detect';
import PrivacyAgreement from '../PrivacyAgreement/privacy-agreement';
import TermsAndConditions from '../PrivacyAgreement/terms-and-conditions';
import DashboardContainer from './Shared/dashboard-container';
import NavigationPanel from './Shared/navigation-panel';

const DashboardTermsAndCondition = () => {
    return (
        <React.Fragment>
            {isMobile && <NavigationPanel label="Terms & Conditions" />}
            <DashboardContainer marginTop={isMobile ? 0 : 60}>
                <TermsAndConditions />
                <PrivacyAgreement />
            </DashboardContainer>
        </React.Fragment>
    );
};

export default DashboardTermsAndCondition;
