import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SoarHelper from '../../lib/soar-helper';
import UriHelper from '../../lib/uri-helper';
import Analytics from '../../lib/user-analytics';
import { actionDismissTutorial } from '../../store/App/actions';
import { selectShowTutorial } from '../../store/App/selectors';
import { actionSetSideDrawerModeAction } from '../../store/SideDrawer/actions';
import { SideDrawerMode } from '../../store/SideDrawer/model';
import Drawer from '../Drawer/drawer-control';
import Download from '../Drawer/Satellites/Sentinel/download';
import Share from '../Drawer/Share/Maps';
import { LoginModalMode } from '../Registration/login-enum';
import LoginRegisterDialog from '../Registration/login-register-dialog';
import SearchBar from '../Search/search-bar';
import Tutorial from '../Tutorials/tutorial-starter';
import UploadDialog from '../Upload/upload-dialog';
import MapView from './map-view';

interface MapContainerProps {
    loginMode?: LoginModalMode;
    drawerMode?: SideDrawerMode;
}

const MapContainer = (props: MapContainerProps) => {
    const dispatch = useDispatch();
    const shouldShowTutorial = useSelector(selectShowTutorial);
    const [loginMode, setLoginMode] = useState<LoginModalMode | undefined>(props.loginMode);

    const onLoginDialogClosed = () => {
        setLoginMode(undefined);
        UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
        Analytics.Event('Main View', 'Closed login dialog');
    };

    useEffect(() => {
        if (props.drawerMode) {
            dispatch(actionSetSideDrawerModeAction(props.drawerMode));
        }
    }, [props.drawerMode, dispatch]);

    return (
        <React.Fragment>
            <Drawer />
            <Download />
            <Share />

            {shouldShowTutorial ? (
                <Tutorial
                    onSkip={() => {
                        dispatch(actionDismissTutorial());
                        if (SoarHelper.isSoar()) {
                            dispatch(
                                actionSetSideDrawerModeAction(props.drawerMode ? props.drawerMode : SideDrawerMode.MAPS)
                            );
                        } else {
                            dispatch(
                                actionSetSideDrawerModeAction(
                                    props.drawerMode ? props.drawerMode : SideDrawerMode.SUBDOMAIN_MAPS
                                )
                            );
                        }
                    }}
                />
            ) : null}
            <SearchBar />

            <MapView />
            {loginMode && <LoginRegisterDialog isOpen={true} initialMode={loginMode} onClose={onLoginDialogClosed} />}
            <UploadDialog />
        </React.Fragment>
    );
};

export default MapContainer;
