import React from 'react';
import styled from 'styled-components';
import Analytics from '../../../lib/user-analytics';

const SocialLinks = () => {
    return (
        <React.Fragment>
            <SocialContainer>
                <a
                    href="https://www.facebook.com/SoarEarthOfficial/"
                    title="Facebook"
                    target="_blank"
                    onClick={() => Analytics.Event('LandingPage', 'Clicked footer facebook')}
                >
                    <SocialIcon src="/assets/landing-page/footer-icons/facebook.svg" />
                </a>

                <a
                    href="https://twitter.com/Soar_Earth"
                    title="Twitter"
                    target="_blank"
                    onClick={() => Analytics.Event('LandingPage', 'Clicked footer twitter')}
                >
                    <SocialIcon src="/assets/landing-page/footer-icons/twitter.svg" />
                </a>

                <a
                    href="https://www.linkedin.com/company/soar-earth/"
                    target="_blank"
                    title="Linkedin"
                    onClick={() => Analytics.Event('LandingPage', 'Clicked footer linkedin')}
                >
                    <SocialIcon src="/assets/landing-page/footer-icons/linkedin.svg" />
                </a>

                <a
                    href="https://discord.gg/mDCmf4fgZh"
                    target="_blank"
                    title="Discord"
                    onClick={() => Analytics.Event('LandingPage', 'Clicked footer Discord')}
                >
                    <SocialIcon src="/assets/landing-page/footer-icons/discord.svg" />
                </a>
            </SocialContainer>

            <SocialContainer>
                <a
                    href="https://t.me/SoarEarth"
                    target="_blank"
                    title="Telegram"
                    onClick={() => Analytics.Event('LandingPage', 'Clicked footer Telegram')}
                >
                    <SocialIcon src="/assets/landing-page/footer-icons/telegram.svg" />
                </a>

                <a
                    href="https://www.instagram.com/soar.earth/"
                    target="_blank"
                    title="Instagram"
                    onClick={() => Analytics.Event('LandingPage', 'Clicked footer instagram')}
                >
                    <SocialIcon src="/assets/landing-page/footer-icons/instagram.svg" />
                </a>

                <a
                    href="https://www.youtube.com/channel/UCklyklf0WEeHZtlY-X-q28g"
                    target="_blank"
                    title="Youtube"
                    onClick={() => Analytics.Event('LandingPage', 'Clicked footer youtube')}
                >
                    <SocialIcon src="/assets/landing-page/footer-icons/youtube.svg" />
                </a>

                <a
                    href="https://www.reddit.com/r/soar"
                    target="_blank"
                    title="Reddit"
                    onClick={() => Analytics.Event('LandingPage', 'Clicked footer reddit')}
                >
                    <SocialIcon src="/assets/landing-page/footer-icons/reddit_icon_svg.svg" />
                </a>
            </SocialContainer>
        </React.Fragment>
    );
};

export default SocialLinks;

const SocialContainer = styled.div`
    margin: 0;
    width: 128px;
`;

const SocialIcon = styled.img`
    width: 16px;
    height: 16px;
    opacity: 0.4;
    margin: 8px;
    cursor: pointer;
`;
