import styled from 'styled-components';
import ListingHelper from '../../../../lib/listing-helper';

// TODO: Ideally this would be re-used from '../Shared/mobile-card-item'.
// The user of this component (mobile-bottom-sheet) uses the minified listing while the other has the full ListingDTO
interface HalfWidthListingCardProps {
    listing: {
        id: number;
        authorName: string;
        title: string;
    };
    onSelectListing: (id: number) => void;
}

const HalfWidthListingCard = (props: HalfWidthListingCardProps) => {
    return (
        <MobileCarouselCard
            onClick={() => {
                props.onSelectListing(props.listing.id);
            }}
            key={props.listing.id}
        >
            <MobileCarouselCardImage src={ListingHelper.getPreviewUrlForListing(props.listing.id)} />
            <MobileCarouselCardTitle>{props.listing.title}</MobileCarouselCardTitle>
        </MobileCarouselCard>
    );
};

export default HalfWidthListingCard;

export const MobileCarouselCard = styled.div`
    pointer-events: auto;
    margin: 4px;
    display: flex;
    flex-direction: column;
    width: calc(50vw - 8px);
    border: 1px solid white;
    border: 1px solid rgb(255 255 255 / 10%);
    border-radius: 6px;
    background-color: hsla(0, 100%, 100%, 0.01);
    box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
        0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
`;

interface MobileCarouselCardImageProps {
    imageHeight?: string;
}

export const MobileCarouselCardImage = styled.img<MobileCarouselCardImageProps>`
    height: ${(props) => (props.imageHeight ? props.imageHeight : '120px')};
    object-fit: cover;
    width: inherit;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
`;

export const MobileCarouselCardTitle = styled.div`
    margin: 3px 0;
    font-size: 1rem;
    width: inherit;
    color: rgba(255, 255, 255, 0.87);
    clear: both;
    overflow: hidden;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;
