import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import Root from './root';

import './styles/leaflet.css';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/index.css';
import './components/MapView/map-view.css';

import Constants from './constants';

import TagManager from 'react-gtm-module';
import LogRocket from 'logrocket';
import { SENTRY_OPTIONS, LOGROCKET_CONFIG } from './tracking-configs';
import SoarHelper from './lib/soar-helper';

if (!SoarHelper.isLocalHost()) {
    Sentry.init(SENTRY_OPTIONS);
}

// we can add more conditions here to limit number or recorded sessions
// also we restrict recording by countries in the logrocket settings in admin
if (!SoarHelper.isLocalHost() && Constants.LOG_ROCKET_APP_ID) {
    // https://docs.logrocket.com/docs
    LogRocket.init(Constants.LOG_ROCKET_APP_ID, LOGROCKET_CONFIG);
}

TagManager.initialize({
    gtmId: Constants.GTAG_ID,
    dataLayer: {
        userId: '001',
        userProject: 'project',
    },
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(<Root />);
