import styled from 'styled-components';

export const NotificationsRead = () => {
    return (
        <Container>
            <Row>
                <IconCol>
                    <Icon src="/assets/notifications/no-notifications.png" />
                </IconCol>
                <Col>
                    <Title>You don't have any new notifications</Title>
                    <Subtitle>Click the button below to view your notification history</Subtitle>
                </Col>
            </Row>
        </Container>
    );
};

const Container = styled.div`
    color: rgb(255 255 255 / 67%);
    background-color: transparent;
    margin: 5px;
    text-align: center;
    padding: 5px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const Col = styled.div`
    display: flex;
    flex-direction: column;
`;

const IconCol = styled(Col)`
    width: calc(37px + 4px + 4px);
`;

const Icon = styled.img`
    margin: 4px;
    width: 39px;
    height: 37px;
`;

const Title = styled.p`
    font-size: 1.1rem;
    font-weight: bold;
    color: white;
    text-align: left;
    margin-bottom: 0;
`;

const Subtitle = styled.p`
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.67);
    text-align: left;
    margin-bottom: 0;
`;
