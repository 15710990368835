import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Analytics from '../../../../lib/user-analytics';

const moment = require('moment');

export interface DateRange {
    startDate: Date;
    endDate: Date;
    displayText: string;
}

export const last30Days: DateRange = {
    startDate: moment(new Date()).subtract(1, 'months').toDate(),
    endDate: new Date(),
    displayText: 'Last 30 days',
};

interface LandsatFilterProps {
    dateRange: DateRange;
    onSelectDateRange: (dateRange: DateRange) => void;
}

const LandsatFilter = (props: LandsatFilterProps) => {
    const [dates, setDates] = useState<DateRange[]>([]);
    const [selectedDate, setSelectedDate] = useState<DateRange>(props.dateRange);

    const dateOptions = useCallback(() => {
        Analytics.Event('Satellite - Landsat', 'Date filter changed');
        const previousMonth = moment(new Date()).subtract(1, 'months');
        const beginning = moment(new Date()).year(2016).month('January').date(1);
        const date = beginning;
        const dates: DateRange[] = [];
        while (date.isBefore(previousMonth)) {
            const endDate = date.clone();
            const startDate = date.clone().subtract(1, 'months');
            dates.push({
                startDate: startDate.toDate(),
                endDate: endDate.toDate(),
                displayText: startDate.format('MMMM YYYY'),
            });
            date.add(1, 'months');
        }
        return dates.reverse();
    }, []);

    useEffect(() => {
        setDates(dateOptions());
    }, [dateOptions]);

    const changeDateRange = (date: DateRange) => {
        setSelectedDate(date);
        props.onSelectDateRange(date);
    };

    return (
        <Container>
            <SelectIcon>
                <img src="/assets/sentinel-filter/calendar.svg" />
            </SelectIcon>
            <Select
                value={selectedDate.displayText}
                onChange={(e) => {
                    const displayText = e.target.value as string;
                    const value = dates.find((t) => t.displayText === displayText);
                    if (value) {
                        changeDateRange(value);
                    } else {
                        changeDateRange(last30Days);
                    }
                }}
            >
                <Option value={last30Days.displayText}>{last30Days.displayText}</Option>
                {dates.map((t, index) => {
                    return (
                        <Option key={index} value={t.displayText}>
                            {t.displayText}
                        </Option>
                    );
                })}
            </Select>
        </Container>
    );
};

export default LandsatFilter;

const Container = styled.div`
    min-height: 32px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    display: flex;
    flex-direction: row;
`;

const Select = styled.select`
    background-color: #464646;
    width: 37%;
    background-position: 123px 1px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline-width: 0;
    color: rgba(255, 255, 255, 0.6);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-left: none;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin: 4px 4px 0 4px;
    padding: 0 6px 0 5px;
    height: 22px;
    display: block;
    font-size: 1rem;
    cursor: pointer;

    background-image: url(/assets/arrows/down-arrow.svg);
    background-position: calc(100% - 20px) calc(50%), calc(100% - 15px) calc(50%), 100% 0;
    background-size: 10px 10px, 10px 10px, 2.5em 2.5em;
    background-repeat: no-repeat;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const Option = styled.option`
    color: rgba(255, 255, 255, 0.6);
`;

const SelectIcon = styled.div`
    img {
        height: 16px;
        margin-top: -6px;
        opacity: 0.6;
    }
    height: 22px;
    background-color: #464646;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    margin: 4px 16px 0 8px;
    padding: 0 6px 0 5px;
    border-right: none;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    display: inline-block;
    margin-right: -10px;
    padding-bottom: 0px;
`;
