import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { BoxZoomControl } from '@takor/react-leaflet-box-zoom-control-private';
import { ZoomControl } from 'react-leaflet';
import { selectActiveAllMap } from '../../store/Map/SuperMap/selectors';
import { selectLoggedIn } from '../../store/Account/selectors';
import LoginRegisterDialog from '../Registration/login-register-dialog';
import ShareService from '../../lib/share-service';
import ApiComments from '../../api/api-comments';
import ApiShort from '../../api/api-short';
import {
    Arrow,
    BoxSelect,
    Circle,
    DeleteAll,
    LatLngTool,
    Rectangle,
    Ruler,
    TextFields,
    KMLImport,
    GenericButton,
} from '@takor/react-leaflet-annotation-tools';
import { LatLngBounds } from 'leaflet';
import Analytics from '../../lib/user-analytics';
import './annotations.css';
import {
    actionSetDisplayAnnotations,
    actionSetUpdateAnnotations,
    actionSetResetCommentSection,
    actionSetEditingComment,
} from '../../store/SocialMapping/actions';
import {
    selectActiveAnnotationString,
    selectResetAnnotations,
    selectUpdateAnnotations,
} from '../../store/SocialMapping/selectors';
import { LoginModalMode } from '../Registration/login-enum';
import { useDispatch, useSelector } from 'react-redux';
import UriHelper from '../../lib/uri-helper';
import { BasemapKey, basemapKeys } from '../../store/Map/Basemap/model';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../Shared/soar-modal';
import {
    KMLImportActiveStyle,
    KMLImportStyle,
    BoxZoomControlActiveStyle,
    BoxZoomControlStyle,
    BoxSelectStyle,
    BoxSelectActiveStyle,
    RulerStyle,
    RulerActiveStyle,
    TextFieldsActiveStyle,
    TextFieldsStyle,
    ArrowActiveStyle,
    ArrowStyle,
    CircleActiveStyle,
    CircleStyle,
    RectangleActiveStyle,
    RectangleStyle,
    LatLngToolActiveStyle,
    LatLngToolStyle,
    ExportToolsStyle,
    DeleteAllActiveStyle,
    DeleteAllStyle,
} from './annotations-icon-styles';
import UserHelper from '../../lib/user-helper';

enum Tools {
    ARROW,
    BOX_SELECT,
    CIRCLE,
    DELETE_ALL,
    LATLNG_TOOL,
    RECTANGLE,
    RULER,
    TEXT_FIELDS,
    KML_IMPORT,
}

// TODO: #3044 refactor this file
interface AnnotationProps {
    leafletMap: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const Annotations = (props: AnnotationProps) => {
    const selectedTileLayer = useSelector(selectActiveAllMap);
    const isLoggedIn = useSelector(selectLoggedIn);
    const selectedAnnotationString = useSelector(selectActiveAnnotationString);
    const selectedResetAnnotations = useSelector(selectResetAnnotations);
    const selectedUpdateAnnotations = useSelector(selectUpdateAnnotations);

    const dispatch = useDispatch();
    const setAnnotationString = (annotations: string) => dispatch(actionSetDisplayAnnotations(annotations));
    const setUpdateAnnotations = (updateAnnotations: boolean) =>
        dispatch(actionSetUpdateAnnotations(updateAnnotations));
    const setResetAnnotations = (resetAnnotations: boolean) => dispatch(actionSetResetCommentSection(resetAnnotations));
    const setEditingComment = (editingComment: boolean) => dispatch(actionSetEditingComment(editingComment));

    const [KMLImportToolRef, setKMLImportToolRef] = useState<any>(null); // eslint-disable-line @typescript-eslint/no-explicit-any
    const [KMLImportActive, setKMLImportActive] = useState(false);
    const [activeMeasurementTools, setActiveMeasurementTools] = useState({});
    const [measurementsActive, setMeasurementsActive] = useState<boolean>(false);
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
    const [shareLinksGenerating, setShareLinksGenerating] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');
    const [requestLogin, setRequestLogin] = useState<boolean>();
    const [commentAnnotations, setCommentAnnotations] = useState<any | undefined>(undefined); // eslint-disable-line @typescript-eslint/no-explicit-any
    const [deleteAllActive, setDeleteAllActive] = useState<boolean>(false);

    let annotationTools: any[] = []; // eslint-disable-line @typescript-eslint/no-explicit-any

    useEffect(() => {
        if (selectedUpdateAnnotations) {
            setDeleteAllActive(true);
            handleDeleteAll();
            setCommentAnnotations(selectedAnnotationString ? JSON.parse(selectedAnnotationString) : undefined);
            setUpdateAnnotations(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUpdateAnnotations]);

    useEffect(() => {
        if (selectedResetAnnotations) {
            setDeleteAllActive(true);
            handleDeleteAll();
            setCommentAnnotations(undefined);
            setAnnotationString('');
            setResetAnnotations(false);
            setEditingComment(false);
            setMeasurementsActive(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedResetAnnotations]);

    useEffect(() => {
        if (commentAnnotations && annotationTools) {
            setAnnotationString(JSON.stringify(commentAnnotations));
            Promise.resolve()
                .then(() => {
                    Object.keys(annotationTools).map((key) => {
                        const annotation = commentAnnotations[Tools[key]];
                        if (annotation) {
                            annotationTools[key].import(annotation);
                        }
                    });

                    const kmlAnnotation = commentAnnotations[Tools.KML_IMPORT];
                    if (kmlAnnotation) {
                        KMLImportToolRef.import(kmlAnnotation);
                    }

                    props.leafletMap.leafletElement.closePopup();
                })
                .then(() => {
                    if (commentAnnotations['mapBounds']) {
                        props.leafletMap.leafletElement.fitBounds(
                            new LatLngBounds(
                                commentAnnotations['mapBounds']._northEast,
                                commentAnnotations['mapBounds']._southWest
                            )
                        );
                    }

                    if (commentAnnotations['zoomLevel']) {
                        props.leafletMap.leafletElement.setZoom(commentAnnotations['zoomLevel']);
                    }
                })
                .then(() => {
                    const doAnnotationsExist = UserHelper.doAnnotationsExist(commentAnnotations);
                    if (doAnnotationsExist) {
                        setMeasurementsActive(true);
                    }

                    setUpdateAnnotations(false);
                    setResetAnnotations(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commentAnnotations]);

    useEffect(() => {
        const params = ShareService.parseQueryString(window.location.href);
        const commentId = params['annotations'];
        if (commentId) {
            ApiComments.getCommentById(commentId).then((comment) => {
                const annotations = JSON.parse(comment.annotations);
                Promise.resolve()
                    .then(() => {
                        Object.keys(annotationTools).map((key) => {
                            const annotation = annotations[Tools[key]];
                            if (annotation) {
                                annotationTools[key].import(annotation);
                            }
                        });
                        const kmlAnnotation = annotations[Tools.KML_IMPORT];
                        if (kmlAnnotation && KMLImportToolRef) {
                            KMLImportToolRef.import(kmlAnnotation);
                        }
                        props.leafletMap.leafletElement.closePopup();
                    })
                    .then(() => {
                        if (annotations['mapBounds']) {
                            props.leafletMap.leafletElement.fitBounds(
                                new LatLngBounds(
                                    annotations['mapBounds']._northEast,
                                    annotations['mapBounds']._southWest
                                )
                            );
                        }
                        if (annotations['zoomLevel']) {
                            props.leafletMap.leafletElement.setZoom(annotations['zoomLevel']);
                        }
                    });

                setMeasurementsActive(true);
            });
        }
        // Remove the annotations param from the uri so it does not reapply
        UriHelper.removeParameterFromUri('annotations');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    props.leafletMap.leafletElement.on('mouseup', () => {
        if (deleteAllActive) {
            return;
        }

        if (annotationTools.length > 0) {
            const geoAll = {};

            if (geoAll && measurementsActive) {
                geoAll['mapBounds'] = props.leafletMap.leafletElement.getBounds();
                geoAll['zoomLevel'] = props.leafletMap.leafletElement.getZoom();

                requestAnimationFrame(() => {
                    Object.keys(annotationTools).map((key) => {
                        const exported = annotationTools[key].export();
                        if (exported && exported.length) {
                            geoAll[Tools[key]] = exported;
                        }
                    });

                    if (KMLImportToolRef) {
                        geoAll[Tools.KML_IMPORT] = KMLImportToolRef.export();
                    }

                    const doAnnotationsExist = UserHelper.doAnnotationsExist(geoAll);
                    if (doAnnotationsExist) {
                        setMeasurementsActive(true);
                    }
                    setAnnotationString(JSON.stringify(geoAll));
                });
            }
        }
    });

    useEffect(() => {
        if (shareLinksGenerating && isLoggedIn) {
            setModalMessage('Generating share link ...');
            setShowInfoModal(true);
            let layerUrl = '';
            if (selectedTileLayer && selectedTileLayer.id) {
                layerUrl = `/maps/${selectedTileLayer.id}`;
            }

            const geoAll = {};

            geoAll['mapBounds'] = props.leafletMap.leafletElement.getBounds();
            geoAll['zoomLevel'] = props.leafletMap.leafletElement.getZoom();

            Object.keys(annotationTools).map((key) => {
                const exported = annotationTools[key].export();
                if (exported && exported.length) {
                    geoAll[Tools[key]] = exported;
                }
            });
            geoAll[Tools.KML_IMPORT] = KMLImportToolRef.export();

            //add basemap on sharelink
            let basemapParam = '';
            const params = new URLSearchParams(window.location.search);
            const urlSelectedBaseMap = params.get(UriHelper.BASEMAP_URI_KEY);

            const isBasemapKey = (key: string): key is BasemapKey => {
                return basemapKeys.indexOf(key as any) !== -1; // eslint-disable-line @typescript-eslint/no-explicit-any
            };

            if (urlSelectedBaseMap && isBasemapKey(decodeURIComponent(urlSelectedBaseMap))) {
                basemapParam = `&${UriHelper.BASEMAP_URI_KEY}=${urlSelectedBaseMap.replaceAll(' ', '+')}`;
            }

            ApiComments.createComment({
                annotations: JSON.stringify(geoAll),
                text: [''],
            }).then((res) => {
                const uri = `${window.location.origin}${layerUrl}?${UriHelper.ANNOTATION_URI_KEY}=${res.id}${basemapParam}`;
                ApiShort.minify(uri, 'annotations')
                    .then((shortUri) => {
                        const textField = document.createElement('textarea');
                        textField.innerText = shortUri.url;
                        document.body.appendChild(textField);
                        textField.select();
                        document.execCommand('copy');
                        textField.remove();
                        setShowInfoModal(true);
                        setModalMessage('Copied share URL to clipboard');
                        Analytics.Event('Annotation Tool Event', 'Generated annotation export', shortUri);
                    })
                    .catch((_) => {
                        setModalMessage('Error generating sharelink, Please try again later');
                    })
                    .finally(() => {
                        setShareLinksGenerating(false);
                    });
            });
        } else if (shareLinksGenerating && !isLoggedIn) {
            setRequestLogin(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shareLinksGenerating]);

    const handleRemoval = (measurementType) => {
        const _activeMeasurementTools = activeMeasurementTools;
        activeMeasurementTools[measurementType] = false;

        let allRemoved = true;
        for (const key in activeMeasurementTools) {
            if (activeMeasurementTools[key]) allRemoved = false;
        }
        if (allRemoved && measurementsActive) {
            setMeasurementsActive(false);
        }
        setActiveMeasurementTools(_activeMeasurementTools);
    };

    const reactivateLeafletTools = (tool, areOtherToolsActive: boolean) => {
        // eslint-disable-line @typescript-eslint/no-explicit-any
        Object.keys(annotationTools).map((key) => {
            annotationTools[key].otherToolsActive(areOtherToolsActive);
        });
        tool.otherToolsActive(false);
    };

    const haltLeafletToolsExcept = (saveThisTool) => {
        if (saveThisTool) {
            saveThisTool.otherToolsActive(false);
        }
        Object.keys(annotationTools).map((key) => {
            if (annotationTools[key] !== saveThisTool) {
                annotationTools[key].stop();
            }
            annotationTools[key].otherToolsActive(true);
        });
    };

    const setToolActiveRecord = (measurementType: number) => {
        const _activeMeasurementTools = activeMeasurementTools;
        activeMeasurementTools[measurementType] = true;
        setActiveMeasurementTools(_activeMeasurementTools);
    };

    const handleDeleteAll = () => {
        Object.keys(annotationTools).map((key) => {
            annotationTools[key].removeAll();
        });
        if (KMLImportToolRef && KMLImportActive) {
            KMLImportToolRef.removeAll();
            setKMLImportActive(false);
        }
        setCommentAnnotations(undefined);
        setAnnotationString('');
        annotationTools = [];
        setMeasurementsActive(false);
        setDeleteAllActive(false);
    };

    const handleToolStart = (measurementType: number) => {
        haltLeafletToolsExcept(annotationTools[measurementType]);
        setToolActiveRecord(measurementType);
    };

    const onToolCompletion = (areOtherToolsActive: boolean, measurementType: number) => {
        reactivateLeafletTools(annotationTools[measurementType], areOtherToolsActive);
        if (!measurementsActive) {
            setMeasurementsActive(true);
        }
    };

    return (
        <React.Fragment>
            <div id="annotation-tool-tutorial-container" />
            <div id="basemap-tool-tutorial-container" />
            <div id="satellite-overlay-tool-tutorial-container" />
            <div id="pinned-tool-tutorial-container" />
            <div id="location-tool-tutorial-container" />
            <LoginRegisterDialog
                isOpen={(!isLoggedIn && requestLogin) || false}
                initialMode={LoginModalMode.LOGIN}
                onClose={() => {
                    setRequestLogin(false);
                    if (!isLoggedIn) {
                        setShareLinksGenerating(false);
                    }
                }}
            />

            <KMLImport
                position="topright"
                title="KML Import"
                onAllRemoved={() => setKMLImportActive(false)}
                kmlLoaded={() => setKMLImportActive(true)}
                onToolCompletion={() => {
                    setKMLImportActive(true);
                    Analytics.Event('Annotation Tool Event', 'Clicked KML Tool');
                }}
                activeStyle={KMLImportActiveStyle}
                style={KMLImportStyle}
                ref={(r) => {
                    if (r != null) setKMLImportToolRef(r);
                }}
            />

            <ZoomControl position="topright" zoomOutText="" className="mapview-zoom-controls" />

            <BoxZoomControl
                position="topright"
                sticky={false}
                activeStyle={BoxZoomControlActiveStyle}
                style={BoxZoomControlStyle}
            />

            <BoxSelect
                position="topright"
                sticky={false}
                title="Area measurement"
                scaleActive={false}
                style={BoxSelectStyle}
                activeStyle={BoxSelectActiveStyle}
                disableEdit={isMobile}
                onAllRemoved={() => handleRemoval(Tools.BOX_SELECT)}
                onToolCompletion={() => onToolCompletion(false, Tools.BOX_SELECT)}
                ref={(r) => {
                    if (r != null) annotationTools[Tools.BOX_SELECT] = r;
                }}
                onClick={() => handleToolStart(Tools.BOX_SELECT)}
            />

            <Ruler
                position="topright"
                sticky={false}
                title="Ruler"
                scaleActive={false}
                disableEdit={isMobile}
                onAllRemoved={() => handleRemoval(Tools.RULER)}
                onToolCompletion={() => onToolCompletion(false, Tools.RULER)}
                ref={(r) => {
                    if (r != null) annotationTools[Tools.RULER] = r;
                }}
                onClick={() => handleToolStart(Tools.RULER)}
                style={RulerStyle}
                activeStyle={RulerActiveStyle}
            />

            <TextFields
                position="topright"
                sticky={false}
                title="Text field"
                scaleActive={false}
                disableEdit={isMobile}
                onAllRemoved={() => handleRemoval(Tools.TEXT_FIELDS)}
                onToolCompletion={() => onToolCompletion(false, Tools.TEXT_FIELDS)}
                ref={(r) => {
                    if (r != null) annotationTools[Tools.TEXT_FIELDS] = r;
                }}
                onClick={() => handleToolStart(Tools.TEXT_FIELDS)}
                activeStyle={TextFieldsActiveStyle}
                style={TextFieldsStyle}
            />

            <Arrow
                position="topright"
                title="Arrow"
                sticky={false}
                scaleActive={false}
                disableEdit={isMobile}
                onAllRemoved={() => handleRemoval(Tools.ARROW)}
                onToolCompletion={() => onToolCompletion(false, Tools.ARROW)}
                ref={(r) => {
                    if (r != null) annotationTools[Tools.ARROW] = r;
                }}
                onClick={() => handleToolStart(Tools.ARROW)}
                activeStyle={ArrowActiveStyle}
                style={ArrowStyle}
            />

            <Circle
                position="topright"
                sticky={false}
                title="Circle"
                scaleActive={false}
                disableEdit={isMobile}
                onAllRemoved={() => handleRemoval(Tools.CIRCLE)}
                onToolCompletion={() => onToolCompletion(false, Tools.CIRCLE)}
                ref={(r) => {
                    if (r != null) annotationTools[Tools.CIRCLE] = r;
                }}
                onClick={() => handleToolStart(Tools.CIRCLE)}
                activeStyle={CircleActiveStyle}
                style={CircleStyle}
            />

            <Rectangle
                position="topright"
                sticky={false}
                title="Rectangle"
                scaleActive={false}
                disableEdit={isMobile}
                onAllRemoved={() => handleRemoval(Tools.RECTANGLE)}
                onToolCompletion={() => onToolCompletion(false, Tools.RECTANGLE)}
                ref={(r) => {
                    if (r != null) annotationTools[Tools.RECTANGLE] = r;
                }}
                onClick={() => handleToolStart(Tools.RECTANGLE)}
                activeStyle={RectangleActiveStyle}
                style={RectangleStyle}
            />

            <LatLngTool
                position="topright"
                sticky={false}
                title="Latitude/Longitude"
                scaleActive={false}
                disableEdit={isMobile}
                onAllRemoved={() => handleRemoval(Tools.LATLNG_TOOL)}
                onToolCompletion={() => onToolCompletion(false, Tools.LATLNG_TOOL)}
                ref={(r) => {
                    if (r != null) annotationTools[Tools.LATLNG_TOOL] = r;
                }}
                onClick={() => handleToolStart(Tools.LATLNG_TOOL)}
                activeStyle={LatLngToolActiveStyle}
                style={LatLngToolStyle}
            />

            {measurementsActive || KMLImportActive ? (
                <GenericButton
                    position="topright"
                    sticky={false}
                    title="Export Share Link"
                    buttonId="export-button"
                    onClick={() => setShareLinksGenerating(true)}
                    style={ExportToolsStyle}
                />
            ) : null}

            {measurementsActive || KMLImportActive ? (
                <DeleteAll
                    title="Delete All"
                    position="topright"
                    onClick={() => {
                        setDeleteAllActive(true);
                        handleDeleteAll();
                        requestAnimationFrame(() => {
                            setMeasurementsActive(false);
                            setAnnotationString('');
                        });
                    }}
                    activeStyle={DeleteAllActiveStyle}
                    style={DeleteAllStyle}
                />
            ) : null}

            <SoarModal
                title="Export share links"
                isOpen={showInfoModal}
                toggle={() => setShowInfoModal(false)}
                width="300px"
            >
                <StyledModalBody>{modalMessage}</StyledModalBody>
                <StyledModalFooter>
                    {!shareLinksGenerating && (
                        <InfoModalButton onClick={() => setShowInfoModal(false)}>OK</InfoModalButton>
                    )}
                </StyledModalFooter>
            </SoarModal>
        </React.Fragment>
    );
};

export default Annotations;

const InfoModalButton = styled.button`
    display: inline-block;
    padding: 2px 10px 2px 10px;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: black;
    -webkit-text-fill-color: black;
    border-radius: 4px;
    background-color: gray;
    width: -webkit-fill-available;
    width: -moz-available;

    :not(:disabled) {
        cursor: pointer;
        background-color: #eed926 !important;
    }
`;
