import { actionTypes as at } from './constants';
import { BaseMap } from './model';

export const actionMobilePreventMapInteraction = () => {
    return {
        type: at.PREVENT_INTERACTION,
    };
};

export const actionMobileEnableMapInteraction = () => {
    return {
        type: at.ENABLE_INTERACTION,
    };
};

export const actionSetBaseMap = (baseMap: BaseMap) => {
    return {
        type: at.SET_BASE_MAP,
        payload: baseMap.toString(),
    };
};

export const actionSetShowMobileLoadingSpinner = (showSpinner: boolean) => {
    return {
        type: at.SHOW_LOADING_SPINNER,
        payload: showSpinner
    };
};

function sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

// TODO:  Move to 'store/Map/Mobile`
export const actionBeginMobileSentinelSearch = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.BEGIN_MOBILE_SENTINEL_SEARCH,
            payload: true,
        });
        await sleep(100);
        dispatch(actionResetBeginMobileSentinelSearch());
    };
};

// TODO:  Move to 'store/Map/Mobile`
export const actionResetBeginMobileSentinelSearch = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.BEGIN_MOBILE_SENTINEL_SEARCH,
            payload: false,
        });
        await sleep(100);
        dispatch(actionResetBeginMobileSentinelSearch());
    };
};