import { combineReducers } from 'redux';
// Allowed location for using 'connected-react-router'
// eslint-disable-next-line no-restricted-imports
import { connectRouter } from 'connected-react-router';

import appReducer from './App/reducer';
import sentinelReducer from './Map/Sentinel/reducer';
import purchasesReducer from './Purchases/reducer';
import subDomainReducer from './Dashboard/reducer';
import sideDrawerReducer from './SideDrawer/reducer';
import mapTileLayerReducer from './Map/TileLayer/reducer';
import satelliteArchiveReducer from './Map/SatelliteArchive/reducer';
import tasksReducer from './Map/Tasks/reducer';
import favouritesReducer from './Map/Favourites/reducer';
import bookmarkReducer from './Map/BookMarks/reducer';
import subdomainTileLayerReducer from './Map/Subdomain/reducer';
import superMapReducer from './Map/SuperMap/reducer';
import userProfileReducer from './Map/UserProfile/reducer';
import uploadReducer from './Map/Uploads/reducer';
import mobileReducer from './Map/Mobile/reducer';
import searchReducer from './Search/reducer';
import socialMappingReducer from './SocialMapping/reducer';
import satelliteOverlayReducer from './Map/SatelliteOverlay/reducer';
import mapSelectionReducer from './Map/MapSelection/reducer';
import notificationReducer from './Notifications/reducer';
import animatedLayerReducer from './Map/AnimatedLayer/reducer';
import basemapReducer from './Map/Basemap/reducer';
import myMapSelectionReducer from './Map/MyMapSelection/reducer';
import accountReducer from './Account/reducer';

import { history } from './history';

export const state = combineReducers({
    router: connectRouter(history),
    appDomain: appReducer,
    accountDomain: accountReducer,
    subDomain: subDomainReducer,
    sentinelDomain: sentinelReducer,
    purchasesDomain: purchasesReducer,
    sideDrawerDomain: sideDrawerReducer,
    mapTileLayerDomain: mapTileLayerReducer,
    satelliteArchiveDomain: satelliteArchiveReducer,
    tasksDomain: tasksReducer,
    favouritesDomain: favouritesReducer,
    bookmarkDomain: bookmarkReducer,
    subdomainTileLayerDomain: subdomainTileLayerReducer,
    superMapDomain: superMapReducer,
    userProfileDomain: userProfileReducer,
    uploadDomain: uploadReducer,
    mobileDomain: mobileReducer,
    searchDomain: searchReducer,
    socialMappingDomain: socialMappingReducer,
    satelliteOverlayDomain: satelliteOverlayReducer,
    mapSelectionDomain: mapSelectionReducer,
    myMapSelectionDomain: myMapSelectionReducer,
    notificationDomain: notificationReducer,
    animatedLayerDomain: animatedLayerReducer,
    basemapDomain: basemapReducer,
});

export type AppState = ReturnType<typeof state>;

export interface Action {
    type: string;
    payload?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}
