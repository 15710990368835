import React, { useEffect, useState } from 'react';
import ApiGeolocation, { NearbyListingsDTO } from '../../api/api-geolocation';
import ListingHelper from '../../lib/listing-helper';
import Analytics from '../../lib/user-analytics';
import { SegmentContainer, SegmentTitle, SegmentTitleIcon } from './search-container';
import SearchContentCarousel, { CarouselItem } from './search-content-carousel';


interface SearchContentNearbyMapsProps {
    handleSelectListing: (id: number) => void;
}

const SearchContentNearbyMaps = (props: SearchContentNearbyMapsProps) => {

    const [nearbyListings, setNearbyListings] = useState<NearbyListingsDTO | undefined>(undefined);

    useEffect(() => {
        ApiGeolocation.getListingsNearMe().then(result => {
            setNearbyListings(result);
        })
    }, []);

    if (!nearbyListings || nearbyListings.listings.length === 0) return <React.Fragment />

    const carouselItems: CarouselItem[] = nearbyListings.listings.map(t => {
        return {
            id: t.id,
            author: t.userName,
            previewUrl: ListingHelper.getPreviewUrlForListing(t.id) as string,
            title: t.title
        }
    })

    return (
        <SegmentContainer>
            <SegmentTitle>
                <SegmentTitleIcon src="/assets/search-content/emoji-recent-map.svg" />
                Maps near <span>{nearbyListings.location.city}, {nearbyListings.location.regionName}
                </span></SegmentTitle>
            <SearchContentCarousel
                handleSelectListing={(id) => {
                    props.handleSelectListing(id)
                    Analytics.Event('Search Bar', 'Clicked Map From Most Nearby Maps', id);
                }}
                height='270px'
                items={carouselItems} />
        </SegmentContainer>
    );
};

export default SearchContentNearbyMaps;