import React from 'react';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ApiUser from '../../../api/api-user';
import { BillingInfoDTO } from '../../../api/model';
import Analytics from '../../../lib/user-analytics';
import UserHelper from '../../../lib/user-helper';
import { actionGetMyProfile } from '../../../store/Account/actions';
import { selectMyProfile } from '../../../store/Account/selectors';
import profileIconUrl from '../profile-icon-url';
import EditInput, { ProfileInputType } from '../Shared/edit-input';
import NavigationButton from '../Shared/navigation-button';
import PayoutNavigation from './payout-navigation';
import DashboardContainer from '../Shared/dashboard-container';

const Payout = () => {
    const dispatch = useDispatch();

    const myProfile = useSelector(selectMyProfile);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [phone, setPhoneNumber] = useState<string>('');
    const [paypal, setPaypal] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [postcode, setPostcode] = useState<string>('');

    useEffect(() => {
        if (myProfile) {
            setFirstName(myProfile.billingInfo?.firstName ? myProfile.billingInfo.firstName : '');
            setLastName(myProfile.billingInfo?.lastName ? myProfile.billingInfo.lastName : '');
            setCompany(myProfile.billingInfo?.company ? myProfile.billingInfo.company : '');
            setPhoneNumber(myProfile.billingInfo?.phone ? myProfile.billingInfo.phone : '');
            setPaypal(myProfile.billingInfo?.paypal ? myProfile.billingInfo.paypal : '');
            setAddress(myProfile.billingInfo?.address ? myProfile.billingInfo.address : '');
            setCity(myProfile.billingInfo?.city ? myProfile.billingInfo.city : '');
            setCountry(myProfile.billingInfo?.country ? myProfile.billingInfo.country : '');
            setPostcode(myProfile.billingInfo?.postCode ? myProfile.billingInfo.postCode : '');
        }
    }, [myProfile]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const billingInfo: BillingInfoDTO = {
            firstName: firstName,
            lastName: lastName,
            company: company,
            phone: phone,
            paypal: paypal,
            address: address,
            city: city,
            country: country,
            postCode: postcode,
        };

        setIsSubmitting(true);

        const token = UserHelper.validateAndGetIdToken();
        if (!token) {
            toast.error('Error updating paypal details', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        } else {
            ApiUser.putBillingInfo(billingInfo)
                .then((_) => {
                    setIsSubmitting(false);
                    dispatch(actionGetMyProfile());
                    toast.dark('Paypal details updated', {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: false,
                    });
                    Analytics.Event('My Dashboard', 'Clicked to update paypal details');
                })
                .catch(() => {
                    toast.error('Error updating paypal details', {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: false,
                    });
                });
        }
    };

    return (
        <React.Fragment>
            <PayoutNavigation
                handleSubmit={(e) => handleSubmit(e)}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
            />
            <DashboardContainer>
                <ProfilePayoutBodyDiv>
                    <ProfilePayoutSectionDiv>
                        <h3>Payout Preference</h3>
                        <EditInput
                            inputType={ProfileInputType.STANDARD}
                            title="PayPal"
                            label="0000 0000 0000 0000"
                            value={paypal}
                            setInputValue={setPaypal}
                        />
                        <PayPalDetails>
                            <a
                                href="https://www.paypal.com/au/webapps/mpp/faster-and-safer-way-to-buy-and-sell2?gclid=EAIaIQobChMI3bOprKH-4QIVWSUrCh1NGAaTEAAYASAAEgK28fD_BwE&gclsrc=aw.ds"
                                target="_blank"
                            >
                                <img src={profileIconUrl.paypalUrl} />
                            </a>
                            <PayPalDetailsText>
                                <p style={{ marginTop: '16px' }}>
                                    We use{' '}
                                    <a
                                        href="https://www.paypal.com/au/webapps/mpp/faster-and-safer-way-to-buy-and-sell2?gclid=EAIaIQobChMI3bOprKH-4QIVWSUrCh1NGAaTEAAYASAAEgK28fD_BwE&gclsrc=aw.ds"
                                        target="_blank"
                                        className="underlined"
                                    >
                                        PayPal
                                    </a>{' '}
                                    to pay you for any drone images you sell.
                                </p>
                                <p>
                                    Don't have a PayPal account?{' '}
                                    <a
                                        href="https://www.paypal.com/au/webapps/mpp/faster-and-safer-way-to-buy-and-sell2?gclid=EAIaIQobChMI3bOprKH-4QIVWSUrCh1NGAaTEAAYASAAEgK28fD_BwE&gclsrc=aw.ds"
                                        target="_blank"
                                        className="underlined"
                                    >
                                        Register one now.
                                    </a>
                                </p>
                            </PayPalDetailsText>
                        </PayPalDetails>
                    </ProfilePayoutSectionDiv>
                    <ProfilePayoutSectionDiv>
                        <h3>Payment Information</h3>
                        <EditInput
                            inputType={ProfileInputType.STANDARD}
                            title="First Name"
                            label="First Name"
                            value={firstName}
                            setInputValue={setFirstName}
                        />
                        <EditInput
                            inputType={ProfileInputType.STANDARD}
                            title="Last Name"
                            label="Last Name"
                            value={lastName}
                            setInputValue={setLastName}
                        />
                        <EditInput
                            inputType={ProfileInputType.STANDARD}
                            title="Company"
                            label="Company Name"
                            value={company}
                            setInputValue={setCompany}
                        />
                        <EditInput
                            inputType={ProfileInputType.STANDARD}
                            title="Phone Number"
                            label="Phone Number"
                            value={phone}
                            setInputValue={setPhoneNumber}
                        />
                    </ProfilePayoutSectionDiv>
                    <ProfilePayoutSectionDiv>
                        <h3>Payment Address</h3>
                        <EditInput
                            inputType={ProfileInputType.STANDARD}
                            title="Address"
                            label="Full Address"
                            value={address}
                            setInputValue={setAddress}
                        />
                        <EditInput
                            inputType={ProfileInputType.STANDARD}
                            title="City"
                            label="City"
                            value={city}
                            setInputValue={setCity}
                        />
                        <EditInput
                            inputType={ProfileInputType.STANDARD}
                            title="Country"
                            label="Country"
                            value={country}
                            setInputValue={setCountry}
                        />
                        <EditInput
                            inputType={ProfileInputType.NUMBER}
                            title="Postcode"
                            label="Postcode"
                            value={postcode}
                            setInputValue={setPostcode}
                        />
                    </ProfilePayoutSectionDiv>
                    {isMobile && (
                        <ProfilePayoutMobileButtonContainer>
                            <NavigationButton
                                isSubmitting={isSubmitting}
                                setIsSubmitting={setIsSubmitting}
                                label="APPLY CHANGES"
                                onClick={(e) => handleSubmit(e)}
                                active={true}
                            />
                        </ProfilePayoutMobileButtonContainer>
                    )}
                </ProfilePayoutBodyDiv>
            </DashboardContainer>
        </React.Fragment>
    );
};

export default Payout;

const ProfilePayoutBodyDiv = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 8px;

    @media (max-width: 900px) {
        flex-direction: column;
    }
`;

const ProfilePayoutSectionDiv = styled.div`
    color: white;
    flex-basis: 100%;
    padding-right: 10px;

    @media only screen and (max-width: 600px) {
        padding-top: 10px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid white;
    }

    h3 {
        font-size: 24px;
        color: white;
        -webkit-text-fill-color: white;
        text-align: left;
        padding-bottom: 24px;

        @media only screen and (max-width: 600px) {
            text-align: inherit;
        }
    }
`;

const PayPalDetails = styled.div`
    display: flex;
`;

const PayPalDetailsText = styled.div`
    padding-top: 5px;

    p {
        font-size: 10px;
        line-height: 13px;
        color: white;
        margin: 0px 5px 0px 0px !important;

        a {
            color: #eed926;
        }
    }
`;

const ProfilePayoutMobileButtonContainer = styled.div`
    margin: auto;
    padding-bottom: 30px;
`;
