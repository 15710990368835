import React, { ReactNode, RefObject, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Map as LeafletMap, MapProps } from 'react-leaflet';
import { LatLng, LatLngBounds, Map } from 'leaflet';
import { useSelector } from 'react-redux';
import { RectifiedMap } from '../../../api/model';
import { selectFlyToMapPosition } from '../../../store/App/selectors';
import UploadManualMarker from '../../MapView/UploadPreview/upload-manual-marker';
import { UploadMapPosition } from '../Model/model';
import Basemaps from '../../MapView/basemaps';

const WORLD_ZOOM = 2.5;
const WORLD_CENTER: [number, number] = [34.5, 0];
const MAX_ZOOM_LEVEL = 28;
const IMAGE_MIN_ZOOM = 3;
const MARKER_SIZE = 24;

// Level to zoom to when focusing on a point
const FLYTO_ZOOM_LEVEL = 14;

interface UploadMapViewProps {
    type?: string;
    ref?: RefObject<LeafletMap>;
    setRef?: (reference: RefObject<LeafletMap>) => void;
    position: UploadMapPosition;
    baseMap?: boolean;
    uploadMarker?: boolean;
    highlightTarget?: string;
    warpedDataset?: RectifiedMap;
    children?: ReactNode;
    borderColor?: string;
}

const GeorectifierUploadMap = (props: UploadMapViewProps) => {
    const { children, setRef, baseMap, type, borderColor } = props;

    const mapPosition = useSelector(selectFlyToMapPosition);

    const imageMapRef = useRef<LeafletMap>(null);
    const targetMapRef = useRef<LeafletMap>(null);
    const fullscreenMapRef = useRef<LeafletMap>(null);

    useEffect(() => {
        if (setRef && type === 'IMAGE') {
            setRef(imageMapRef);
        }

        if (setRef && type === 'MAP') {
            setRef(targetMapRef);
        }

        if (props.setRef && type === 'FULL') {
            props.setRef(fullscreenMapRef);
        }
    }, [props, setRef, type]);

    let mapRef: React.RefObject<LeafletMap<MapProps, Map>>;
    if (type === 'IMAGE') {
        mapRef = imageMapRef;
    } else if (type === 'MAP') {
        mapRef = targetMapRef;
    } else {
        mapRef = fullscreenMapRef;
    }

    useEffect(() => {
        const flyToPosition = (position: LatLng, zoomLevel: number, immediately: boolean) => {
            if (immediately) {
                mapRef?.current?.leafletElement.setView(position, zoomLevel, { animate: false });
            } else {
                mapRef?.current?.leafletElement.flyTo(position, zoomLevel);
            }
        };

        const flyToBounds = (bounds: LatLngBounds, immediately: boolean) => {
            if (immediately) {
                mapRef?.current?.leafletElement.fitBounds(bounds, { animate: false });
            } else {
                mapRef?.current?.leafletElement.flyToBounds(bounds);
            }
        };

        if (mapRef.current) {
            if (mapPosition instanceof LatLng) {
                const position = mapPosition as LatLng;
                flyToPosition(position, FLYTO_ZOOM_LEVEL, true);
            }

            if (mapPosition instanceof LatLngBounds) {
                const bounds = mapPosition as LatLngBounds;
                flyToBounds(bounds, true);
            }
        } else {
            console.error('ERROR: Attempted flyTo before map was available');
        }
    }, [mapPosition, mapRef]);

    return (
        <UploadMapViewContainer position={props.position} borderColor={borderColor}>
            <LeafletMap
                ref={mapRef}
                zoomSnap={0.01}
                maxBoundsViscosity={1.0}
                maxZoom={MAX_ZOOM_LEVEL}
                zoom={WORLD_ZOOM}
                center={WORLD_CENTER}
                minZoom={IMAGE_MIN_ZOOM}
                className="leaflet-map"
                id="georectifier-map-image"
                zoomControl={true}
                preferCanvas={true}
            >
                {children}

                {baseMap ? <Basemaps mapRef={mapRef?.current} /> : null}

                {props.uploadMarker && <UploadManualMarker leafletMap={targetMapRef} />}
            </LeafletMap>
        </UploadMapViewContainer>
    );
};

export default GeorectifierUploadMap;

interface MapBaseProps {
    isActive?: boolean;
    position?: string;
    borderColor?: string;
}

// TODO margins are all crap needs refactoring
const MapBase = styled.div<MapBaseProps>`
    right: ${(props) => (props.position === 'RIGHT' || props.position === 'RIGHT_SEARCH' ? 0 : '')};
    position: absolute;
    border: ${(props) => (props.borderColor ? `4px solid ${props.borderColor}` : '4px solid gray')};
    cursor: ${(props) => (props.isActive ? 'crosshair !important;' : 'not-allowed !important;')};
    border-radius: 6px;
    top: 80px;
    height: ${(props) => (props.position === 'FULL' ? 'calc(100vh - 90px - 80px)' : '66vh')};
    width: ${(props) => (props.position === 'FULL' ? '100%' : 'calc(50vw - 25px)')};
    width: ${(props) => (props.position === 'FULL' ? '-moz-available' : 'calc(50vw - 25px)')};
    width: ${(props) => (props.position === 'FULL' ? '-webkit-fill-available' : 'calc(50vw - 25px)')};
    overflow: hidden;
    margin: 10px;
    padding: 0;
    // SHit needs refactor
    &:first-child {
        margin: ${(props) => props.position !== 'FULL' && '10px 10px 10px 25px'};
    }

    /* Change cursor when over entire map */
    .leaflet-container {
        cursor: crosshair !important;
    }

    .leaflet-top.leaflet-left {
        right: 0;
        left: auto;

        .leaflet-control-zoom.leaflet-bar.leaflet-control {
            margin: 5px;

            .leaflet-control-zoom-out {
                border-bottom-left-radius: 6px !important;
                border-bottom-right-radius: 6px !important;
            }
        }
    }

    .leaflet-control-layers {
        background: transparent;
    }

    .leaflet-control-layers-toggle {
        background-color: rgba(0, 0, 0, 0.8) !important;
        border-radius: 6px;
    }

    .marker-selected {
        color: white;
        background: rgba(0, 128, 0, 0.87);
        border-radius: 12px;
        width: ${MARKER_SIZE}px !important;
        height: ${MARKER_SIZE}px !important;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.5) !important;
        padding-top: 2px;
    }

    .marker-target {
        color: white;
        background: rgba(255, 0, 0, 0.87);
        border-radius: 12px;
        width: ${MARKER_SIZE}px !important;
        height: ${MARKER_SIZE}px !important;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.5) !important;
        padding-top: 2px;
    }

    .marker-selected.active {
        color: black;
        background: #eed926;
    }

    .marker-target.active {
        color: black;
        background: #eed926;
    }
`;

const UploadMapViewContainer = styled(MapBase)<MapBaseProps>`
    right: ${(props) => (props.position === 'RIGHT' || props.position === 'RIGHT_SEARCH' ? 0 : '')};
`;
