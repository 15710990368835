import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import SoarModal from '../../Shared/soar-modal';
import MobileReportMapMenu from '../../Shared/ReportMap/report-map-content';

const MobileReportMap = () => {
    const [reportIsOpen, setReportIsOpen] = useState<boolean>(false);

    return (
        <React.Fragment>
            <ReportIcon className={'fa fa-ellipsis-h'} onClick={() => setReportIsOpen(!reportIsOpen)} />
            {reportIsOpen ? (
                <SoarModal
                    isOpen={reportIsOpen}
                    toggle={() => setReportIsOpen(!reportIsOpen)}
                    title={'Report this map'}
                >
                    <MobileReportMapMenu handleReportIsOpen={() => setReportIsOpen(!reportIsOpen)} />
                </SoarModal>
            ) : null}
        </React.Fragment>
    );
};

export default MobileReportMap;

const ReportIcon = styled.i`
    font-size: 16px;
    color: rgba(255, 255, 255, 0.87);
    pointer-events: all;
    padding: 3px 0px 5px 5px;
`;
