import { DomainDTO, DomainUserDTO, ListingDTO } from '../../api/model';
import { Action } from '../root-reducer';
import { actionTypes as at } from './constants';
import { IncomingMap, StatusReport } from './model';

interface StoreDashboard {
    adminUserData: DomainUserDTO[];
    adminListingsData: ListingDTO[];
    subdomainInfo?: DomainDTO;
    uploadToSuperMapStatusReport?: StatusReport;
    uploadToSuperMapError?: string;
    adminListingLoading: boolean;
    reuploadMapId?: string;
    setMap?: IncomingMap;
}

const initialState: StoreDashboard = {
    adminListingsData: [],
    adminUserData: [],
    adminListingLoading: false,
};

export default (state: StoreDashboard = initialState, action: Action): StoreDashboard => {
    switch (action.type) {
        case at.API_GET_ADMIN_USER_DATA:
            return { ...state, adminUserData: [] };
        case at.API_GET_ADMIN_USER_DATA_SUCCESS:
            return { ...state, adminUserData: action.payload };
        case at.API_GET_ADMIN_USER_DATA_ERROR:
            return { ...state, adminUserData: [] };

        case at.API_GET_SUBDOMAIN_LISTINGS:
            return { ...state, adminListingsData: [], adminListingLoading: true };
        case at.API_GET_SUBDOMAIN_LISTINGS_SUCCESS:
            return { ...state, adminListingsData: action.payload, adminListingLoading: false };
        case at.API_GET_SUBDOMAIN_LISTINGS_ERROR:
            return { ...state, adminListingsData: [], adminListingLoading: false };
        case at.API_SET_MAP:
            return { ...state, setMap: action.payload };

        case at.API_GET_SUBDOMAIN:
            return { ...state, subdomainInfo: undefined };
        case at.API_GET_SUBDOMAIN_SUCCESS:
            return { ...state, subdomainInfo: action.payload };

        case at.API_UPLOAD_TO_SUPERMAP_ERROR:
            return { ...state, uploadToSuperMapError: action.payload };

        case at.API_UPLOAD_TO_SUPERMAP_STATUS_REPORT:
            return { ...state, uploadToSuperMapStatusReport: action.payload };

        case at.API_REUPLOAD_MAP_ID:
            return { ...state, reuploadMapId: action.payload };
        default:
            return state;
    }
};
