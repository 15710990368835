import { useEffect, useLayoutEffect, useRef } from 'react';
import { LatLngBounds } from 'leaflet';
import { SatelliteAOI } from '@takor/react-leaflet-annotation-tools';

interface AOISelectProps {
    isActive: boolean;
    onAOISelected: (aoi: LatLngBounds) => void;
    onAOIReset: () => void;
    aoiParameters: {
        maxArea: number;
        minArea: number;
        minWidth: number;
        minHeight: number;
        maxWidth: number;
        maxHeight: number;
    };
}

const AOISelect = (props: AOISelectProps) => {
    const AOISelectControlRef = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any

    useEffect(() => {
        if (props.isActive) {
            AOISelectControlRef.current.start(props.aoiParameters);
        }
    }, [props.isActive, props.aoiParameters]);

    useLayoutEffect(() => {
        const ref = AOISelectControlRef.current;
        return () => {
            if (ref !== null) {
                ref.removeAll();
            }
        };
    }, [AOISelectControlRef]);

    return (
        <SatelliteAOI
            fillColor="transparent"
            position="topright"
            sticky={false}
            scaleActive={false}
            ref={AOISelectControlRef}
            receiveSelectedAOI={(aoi) => {
                if (aoi) {
                    const latLngBounds: LatLngBounds = aoi;
                    props.onAOISelected(latLngBounds);
                } else {
                    props.onAOIReset();
                }
            }}
        />
    );
};

export default AOISelect;
