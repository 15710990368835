import { actionTypes as at } from './constants';
import { SentinelFeature, SentinelMetadata } from './model';
import { LatLngBounds } from 'leaflet';
import { cancelFeatures, getFeatures } from '../../../lib/sentinel-service';
import SatelliteUtil from '../../../lib/satellite-util';

export const actionSentinelBeginBoxSelect = () => {
    return {
        type: at.SENTINEL_BEGIN_BOX_SELECT,
    };
};

export const actionSentinelEndBoxSelect = () => {
    return {
        type: at.SENTINEL_END_BOX_SELECT,
    };
};

export const actionSentinelSelectAOI = (bbox: LatLngBounds) => {
    cancelFeatures('Changing Sentinel Search AOI');
    return {
        type: at.SENTINEL_SELECTED_AOI,
        payload: bbox,
    };
};

export const actionSentinelMapZoom = (zoom: number) => {
    return {
        type: at.SENTINEL_MAP_ZOOM,
        payload: zoom,
    };
};

export const actionSentinelResetSelectedAOI = () => {
    return {
        type: at.SENTINEL_RESET_SELECTED_AOI,
    };
};

export const actionSentinelSelectFeature = (feature: SentinelFeature | undefined) => {
    return {
        type: at.SENTINEL_SELECTED_FEATURE,
        payload: feature,
    };
};

export const actionSentinelResetSelectedFeature = () => {
    return {
        type: at.SENTINEL_RESET_SELECTED_FEATURE,
    };
};

export const actionSentinelOpenDownload = () => {
    return {
        type: at.SENTINEL_OPEN_DOWNLOAD,
    };
};

export const actionSentinelOpenShare = () => {
    return {
        type: at.SENTINEL_OPEN_SHARE,
    };
};

export const actionSentinelChangeOpacity = (payload: { id: number; opacity: number } | undefined) => {
    return {
        type: at.SENTINEL_SET_OPACITY,
        payload,
    };
};

export const actionSentinelCloseDownload = () => {
    return {
        type: at.SENTINEL_CLOSE_DOWNLOAD,
    };
};

export const actionSentinelCloseShare = () => {
    return {
        type: at.SENTINEL_CLOSE_SHARE,
    };
};

export const actionSentinelFetchFeatures = (
    bounds: LatLngBounds,
    startDate: Date,
    endDate: Date,
    sensor: SentinelMetadata
) => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.SENTINEL_FETCH_FEATURES,
        });
        try {
            const results = await getFeatures(bounds, startDate, endDate, sensor);
            dispatch(actionSentinelFetchFeaturesSuccess(results));
        } catch (err) {
            SatelliteUtil.sendSentinelSlackMessage(
                `ERROR: ${sensor.satellite.toUpperCase()} ${sensor.key}`,
                `FAILED FIS: ${err}`,
                `BBOX: ${bounds.toBBoxString()}`
            );
            dispatch(actionSentinelFetchFeaturesError(err));
        }
    };
};

export const actionSentinelFetchFeaturesSuccess = (features: SentinelFeature[] | undefined) => {
    return {
        type: at.SENTINEL_FETCH_FEATURES_SUCCESS,
        payload: features,
    };
};

export const actionSentinelFetchFeaturesError = (error: Error | string | undefined) => {
    return {
        type: at.SENTINEL_FETCH_FEATURES_ERROR,
        payload: error,
    };
};

export const actionSentinelClearFeatures = () => {
    return {
        type: at.SENTINEL_CLEAR_FEATURES,
    };
};

export const actionSentinelFeatureLoading = (isLoading: boolean) => {
    return {
        type: at.SENTINEL_FEATURE_LOADING,
        payload: isLoading,
    };
};
