import { LatLng, LatLngBounds } from 'leaflet';
import ApiListings from '../../../api/api-listings';
import { Category, TileLayerHash } from '../../../api/model';
import { LatLngBoundsHash } from '../../../lib/lat-lng-bounds-hash';
import { actionTypes as at } from './constants';
import { ListingDictionary, ListingGroup } from './model';

import australia from '../../../components/MapView/Continents/australia.json';
import asia from '../../../components/MapView/Continents/asia.json';
import europe from '../../../components/MapView/Continents/europe.json';
import northAmerica from '../../../components/MapView/Continents/north_america.json';
import southAmerica from '../../../components/MapView/Continents/south_america.json';
import africa from '../../../components/MapView/Continents/africa.json';
import GeoUtil from '../../../lib/geo-util';
import { actionActiveMapCleared } from '../SuperMap/actions';
import { actionUpdateActiveSearchPosition } from "../../Search/actions";
import { isMobile } from 'react-device-detect';

export const actionFetchListingDictionary = (categories?: string[], userId?: string) => {
    return async (dispatch, getState) => {
        dispatch(actionFetchListingDictionarySuccess(undefined));
        dispatch(actionFetchListingDictionaryIsLoading(true));
        const tileLayerHash: TileLayerHash[] = await ApiListings.getMinimizedListings(
            categories ? categories : undefined,
            userId ? userId : undefined
        );

        const { listingDictionary, globalMaps } = organiseListingsHashToClusterDictionary(tileLayerHash);
        const continentDictionary = organiseListingDictionaryToContinents(tileLayerHash);

        dispatch(actionFetchGlobalListingsSuccess(globalMaps));
        dispatch(actionFetchContinentListingsSuccess(continentDictionary));
        dispatch(actionFetchListingDictionaryIsLoading(false));
        dispatch(actionFetchListingDictionarySuccess(listingDictionary));
    };
};

interface ContinentGroup {
    id: number;
    name: string;
    bounds: LatLngBounds;
    geoJSON: GeoJSON.GeoJsonObject | GeoJSON.GeoJsonObject[];
    iconUrl: string;
}

const organiseListingDictionaryToContinents = (listingHash: TileLayerHash[]): ListingDictionary => {
    const globalGroup: ContinentGroup = {
        id: 1,
        name: 'Global Maps',
        bounds: new LatLngBounds(new LatLng(90.0, 180.0), new LatLng(-90.0, -180.0)),
        geoJSON: [],
        iconUrl: '/assets/continent-icons/global.svg',
    };

    const australiaGroup: ContinentGroup = {
        id: 2,
        name: 'Oceania',
        bounds: new LatLngBounds(new LatLng(-5.4, 109.8), new LatLng(-53.1, 185.4)),
        geoJSON: australia as GeoJSON.GeoJsonObject | GeoJSON.GeoJsonObject[],
        iconUrl: '/assets/continent-icons/australia.svg',
    };

    const asiaGroup: ContinentGroup = {
        id: 3,
        name: 'Asia',
        bounds: new LatLngBounds(new LatLng(77.2, 39.5), new LatLng(-2.5, 184.0)),
        geoJSON: asia as GeoJSON.GeoJsonObject | GeoJSON.GeoJsonObject[],
        iconUrl: '/assets/continent-icons/asia.svg',
    };

    const europeGroup: ContinentGroup = {
        id: 4,
        name: 'Europe',
        bounds: new LatLngBounds(new LatLng(76.8, -27.6), new LatLng(35.7, 70.0)),
        geoJSON: europe as GeoJSON.GeoJsonObject | GeoJSON.GeoJsonObject[],
        iconUrl: '/assets/continent-icons/europe.svg',
    };

    const northAmericaGroup: ContinentGroup = {
        id: 5,
        name: 'North America',
        bounds: new LatLngBounds(new LatLng(80.0, -171.0), new LatLng(13.6, -26.35)),
        geoJSON: northAmerica as GeoJSON.GeoJsonObject | GeoJSON.GeoJsonObject[],
        iconUrl: '/assets/continent-icons/north-america.svg',
    };

    const southAmericaGroup: ContinentGroup = {
        id: 6,
        name: 'South America',
        bounds: new LatLngBounds(new LatLng(13.0, -88.0), new LatLng(-56.0, -33.3)),
        geoJSON: southAmerica as GeoJSON.GeoJsonObject | GeoJSON.GeoJsonObject[],
        iconUrl: '/assets/continent-icons/south-america.svg',
    };

    const africaGroup: ContinentGroup = {
        id: 7,
        name: 'Africa',
        bounds: new LatLngBounds(new LatLng(37.3, -17.7), new LatLng(-37.7, 54.4)),
        geoJSON: africa as GeoJSON.GeoJsonObject | GeoJSON.GeoJsonObject[],
        iconUrl: '/assets/continent-icons/africa.svg',
    };

    const continents: ContinentGroup[] = [
        globalGroup,
        australiaGroup,
        asiaGroup,
        europeGroup,
        northAmericaGroup,
        southAmericaGroup,
        africaGroup,
    ];

    const listingDictionary = organiseListingDictionaryToContinentGroup(listingHash, continents);

    return listingDictionary;
};

const organiseListingDictionaryToContinentGroup = (
    listingHash: TileLayerHash[],
    continents: ContinentGroup[]
): ListingDictionary => {
    const listingGroups: ListingGroup[] = continents.map((t) => {
        return {
            latlngBounds: t.bounds,
            tileLayers: [],
            continent: {
                id: t.id,
                name: t.name,
                geoJSON: t.geoJSON,
                iconUrl: t.iconUrl,
            },
        };
    });

    listingHash.forEach((value) => {
        const bounds = value.latlngBounds;
        const listing = {
            id: value.listingId,
            authorName: value.author || '',
            title: value.title || '',
        };

        listingGroups.forEach((group) => {
            if (group.continent && group.continent.name === 'Global Maps') {
                if (GeoUtil.isApproximatelyGlobalBounds(bounds)) {
                    group.tileLayers.push(...[listing]);
                }
            } else {
                if (GeoUtil.approximatelyContains(group.latlngBounds, bounds)) {
                    group.tileLayers.push(...[listing]);
                }
            }
        });
    });

    const continentDictionary: ListingDictionary = new Map<string, ListingGroup>();
    listingGroups.forEach((t) => {
        const continentHash = new LatLngBoundsHash(t.latlngBounds);
        continentDictionary.set(continentHash.hash, t);
    });
    return continentDictionary;
};

const organiseListingsHashToClusterDictionary = (
    listingsHash: TileLayerHash[]
): { listingDictionary: ListingDictionary; globalMaps: ListingGroup } => {
    const tileLayerDictionary: ListingDictionary = new Map<string, ListingGroup>();
    let globalMaps: ListingGroup = {
        latlngBounds: new LatLngBounds(new LatLng(-85, -180), new LatLng(85, 180)),
        tileLayers: [],
    };

    listingsHash
        .map((t) => {
            return {
                hash: new LatLngBoundsHash(t.latlngBounds).hash,
                latlngBounds: t.latlngBounds,
                listingId: t.listingId,
                author: t.author,
                title: t.title,
            };
        })
        .sort((a, b) => a.hash.localeCompare(b.hash))
        .forEach((t) => {
            if (t.latlngBounds.getNorth() > 84 && t.latlngBounds.getSouth() < -84) {
                const existingGlobalMaps = globalMaps.tileLayers;
                globalMaps = {
                    latlngBounds: globalMaps.latlngBounds,
                    tileLayers: [
                        { id: t.listingId, authorName: t.author || '', title: t.title || '' },
                        ...existingGlobalMaps,
                    ],
                };
            }

            const existingValueForHash = tileLayerDictionary.get(t.hash);
            const existingTileLayersForHash = existingValueForHash ? existingValueForHash.tileLayers || [] : [];
            const newValueForHash: ListingGroup = {
                latlngBounds: t.latlngBounds,
                tileLayers: [
                    { id: t.listingId, authorName: t.author || '', title: t.title || '' },
                    ...existingTileLayersForHash,
                ],
            };
            tileLayerDictionary.set(t.hash, newValueForHash);
        });

    return {
        listingDictionary: tileLayerDictionary,
        globalMaps: globalMaps,
    };
};

const actionFetchListingDictionaryIsLoading = (isLoading: boolean) => {
    return {
        type: at.FETCH_TILE_LAYER_DICTIONARY_LOADING,
        payload: isLoading,
    };
};

export const actionFetchListingDictionarySuccess = (dictionary: ListingDictionary | undefined) => {
    return {
        type: at.FETCH_TILE_LAYER_DICTIONARY_SUCCESS,
        payload: dictionary,
    };
};

export const actionFetchGlobalListingsSuccess = (dictionary: ListingGroup | undefined) => {
    return {
        type: at.FETCH_GLOBAL_TILELAYER_SUCCESS,
        payload: dictionary,
    };
};

export const actionFetchContinentListingsSuccess = (dictionary: ListingDictionary | undefined) => {
    return {
        type: at.FETCH_CONTINENT_TILELAYER_SUCCESS,
        payload: dictionary,
    };
};

export const actionSetVisibleListingGroups = (listingGroups: ListingGroup[]) => {
    return {
        type: at.SET_VISIBLE_TILE_LAYER_GROUPS,
        payload: listingGroups,
    };
};

export const actionSetBestFittingListingGroups = (listingGroups: ListingGroup[]) => {
    return {
        type: at.SET_BEST_FITTING_TILE_LAYER_GROUPS,
        payload: listingGroups,
    };
};

export const actionSetHighlightedListingGroup = (listingGroup: ListingGroup | undefined) => {
    return {
        type: at.SET_HIGHLIGHTED_TILE_LAYER_GROUP,
        payload: listingGroup,
    };
};

export const actionSetHighlightedListingId = (listingId: number | undefined) => {
    return {
        type: at.SET_HIGHLIGHTED_TILE_LAYER_ID,
        payload: listingId,
    };
};

export const actionSetHighlightedContinent = (listingGroup: ListingGroup | undefined) => {
    return {
        type: at.SET_HIGHLIGHTED_CONTINENT,
        payload: listingGroup,
    };
};

export const actionSetSelectedContinent = (listingGroup: ListingGroup | undefined) => {
    return {
        type: at.SET_SELECTED_CONTINENT,
        payload: listingGroup,
    };
};

export const actionSetSelectedCategory = (category: Category | undefined) => {
    return {
        type: at.SET_SELECTED_CATEGORY,
        payload: category,
    };
};

export const actionSetSelectedSearchTerm = (searchTerm: string | undefined) => {
    return {
        type: at.SET_SELECTED_SEARCH_TERM,
        payload: searchTerm,
    };
};

export const actionClearSelectedSearch = () => {
    return (dispatch, getState) => {
        dispatch(actionSetSelectedCategory(undefined));
        dispatch(actionSetSelectedContinent(undefined));
        dispatch(actionSetSelectedSearchTerm(undefined));
        dispatch(actionActiveMapCleared());

        // We want desktop to clear the marker with this action but not on mobile
        if (!isMobile) {
            dispatch(actionUpdateActiveSearchPosition(undefined));
        }
    };
};

export const actionClearSelectedListing = (clearListing: boolean) => {
    return {
        type: at.SET_CLEAR_SELECTED_LISTING,
        payload: clearListing,
    };
};
