import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import ApiComments from '../../../../api/api-comments';
import { CommentResultsDTO } from '../../../../api/model';
import {
    actionSetEditingComment,
    actionSetReplyingToComment,
    actionSetResetCommentSection,
    actionSetSelectedCommentId,
} from '../../../../store/SocialMapping/actions';
import ActiveListingCommentItem from './comment';

interface CommentListProps {
    listingId: number;
    commentUpdate?: boolean;
    hideSubtitle?: boolean;
    setCommentCount?: (commentCount: number | undefined) => void;
}

export const CommentList = (props: CommentListProps) => {
    const { listingId, commentUpdate, hideSubtitle, setCommentCount } = props;
    const [commentList, setCommentLists] = useState<CommentResultsDTO[]>([]);
    const [isLoadingComments, setIsLoadingComments] = useState<boolean>(true);

    const dispatch = useDispatch();

    //reset these redux states when leaving a map
    useEffect(() => {
        return () => {
            dispatch(actionSetSelectedCommentId(''));
            dispatch(actionSetReplyingToComment(false));
            dispatch(actionSetEditingComment(false));
            dispatch(actionSetResetCommentSection(true));
        };
    }, [dispatch]);

    useEffect(() => {
        const getComments = () => {
            setCommentLists([]);
            ApiComments.getCommentsForListingById(listingId.toString())
                .then((comments) => {
                    if (comments) {
                        setCommentLists(comments.reverse());
                        const activeCommentCount = comments.filter((comment) => comment.userName).length;
                        setCommentCount && setCommentCount(activeCommentCount ? activeCommentCount : undefined);
                    }
                })
                .finally(() => {
                    setIsLoadingComments(false);
                });
        };
        if (isLoadingComments) {
            getComments();
        }
    }, [commentList.length, isLoadingComments, listingId, setCommentCount]);

    useEffect(() => {
        if (commentUpdate) {
            setIsLoadingComments(true);
        }
    }, [commentUpdate]);

    const comments = commentList.filter((comment) => comment.createdAt !== undefined).length;

    const deletedComment = (comment: CommentResultsDTO) => {
        const children = comment.children?.filter((comment) => comment.children).length;
        if (!comment.userId && children === 0) {
            return true;
        }
        return false;
    };

    return (
        <React.Fragment>
            {isLoadingComments && isMobile ? (
                <CommentContainer>
                    <CommentSpinner />
                </CommentContainer>
            ) : (
                <React.Fragment>
                    {!hideSubtitle && (
                        <React.Fragment>
                            {comments > 0 ? <Separator /> : null}
                            <CommentsSubtitle>
                                {comments > 0 ? `${comments} Comment${comments > 1 ? 's' : ''}` : null}
                            </CommentsSubtitle>
                        </React.Fragment>
                    )}
                    <ActiveCommentListContainer className={isMobile && comments > 0 ? 'mobile-comment-list' : ''}>
                        {commentList.map((comment) => {
                            if (deletedComment(comment)) {
                                return;
                            }
                            return (
                                <ActiveListingCommentItem
                                    key={comment.id}
                                    listingId={listingId.toString()}
                                    comment={comment}
                                    handleCommentUpdate={(update) => {
                                        setIsLoadingComments(update);
                                    }}
                                />
                            );
                        })}
                    </ActiveCommentListContainer>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const ActiveCommentListContainer = styled.div`
    margin: 0 2px 0 12px;
`;

const Separator = styled.div`
    width: 103%;
    height: 1px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin: 12px 0 5px 0;
`;

const CommentsSubtitle = styled.h6`
    text-align: left;
    font-size: 11px;
    margin: 0 0 0 12px;
    color: rgba(255, 255, 255, 0.4);
    width: 100%;
`;

const CommentContainer = styled.div`
    text-align: center;
    height: 45px;
`;

const CommentSpinner = styled(Spinner)`
    margin-top: 10px;
    color: #eed926;
    height: 2rem;
    width: 2rem;
`;
