import React from 'react';
import styled from 'styled-components';
import { GlobalStyleDrawerContainer } from './drawer-styles';

interface DrawerProps {
    children: React.ReactNode;
}

/**
 * Drawer
 * Wrapper that applies a standardized wide width to the drawer
 * @required children React.ReactNode
 */
const Drawer = (props: DrawerProps) => {
    return <DrawerContainer>{props.children}</DrawerContainer>;
};

export default Drawer;

const DrawerContainer = styled(GlobalStyleDrawerContainer)`
    width: 500px;
`;
