import React from 'react';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';

interface ReportMapMenuProps {
    children: ReactNode;
    toggleRef: HTMLInputElement;
}

const ReportMapMenu = (props: ReportMapMenuProps) => {
    return props.toggleRef
        ? createPortal(
              <React.Fragment>
                  <ReportArrow fadeIn={props.toggleRef ? true : false} />
                  <ReportOptions fadeIn={props.toggleRef ? true : false}>{props.children}</ReportOptions>
              </React.Fragment>,
              props.toggleRef
          )
        : null;
};

export default ReportMapMenu;

interface ReportOptionsProps {
    fadeIn: boolean;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ReportArrow = styled.div<ReportOptionsProps>`
    position: fixed;
    margin-left: 35px;
    margin-top: -22px;
    width: 20px;
    height: 20px;
    animation: ${fadeIn} 200ms ease-in-out;
    border-width: 16px 0px 16px 16px;
    border-style: solid;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.85);
    transform: rotate(180deg);
`;

const ReportOptions = styled.div<ReportOptionsProps>`
    background: rgba(0, 0, 0, 0.85);
    color: white;
    position: fixed;
    margin-left: 55px;
    margin-top: -40px;
    width: 300px;
    height: 230px;
    border-radius: 6px;
    animation: ${fadeIn} 200ms ease-in-out;
`;
