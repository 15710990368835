import React, { useEffect, useState } from 'react';
import { UserDTO } from '../../../api/model';
import EditProfileAvatar from './edit-profile-avatar';
import styled from 'styled-components';
import profileIconUrl from '../profile-icon-url';
import FileUtil from '../../../lib/file-util';
import UserHelper from '../../../lib/user-helper';
import SoarModal, { StyledModalBody } from '../../Shared/soar-modal';

interface ProfileEditAvatarProps {
    myProfile: UserDTO;
    avatarFile: File | string | undefined;
    setAvatarFile: (avatarFile: File | undefined) => void;
    setHasChangedAvatar: (changed: boolean) => void;
}

export const ProfileEditAvatar = (props: ProfileEditAvatarProps) => {
    const [openEditor, setOpenEditor] = useState<boolean>(false);
    const [avatarPreview, setAvatarPreview] = useState<string>('');
    const toggle = () => setOpenEditor(!openEditor);

    useEffect(() => {
        if (props.avatarFile) {
            FileUtil.fileToBase64Image(props.avatarFile as File).then((base64Image) => {
                setAvatarPreview(base64Image);
            });
        }
    }, [props.avatarFile]);

    return (
        <React.Fragment>
            <AvatarEditContainer>
                {props.avatarFile ? (
                    <AvatarProfilePage src={avatarPreview} />
                ) : (
                    <AvatarProfilePage
                        src={
                            props.myProfile.avatarUrl
                                ? props.myProfile.avatarUrl
                                : UserHelper.getDefaultAvatar(props.myProfile.userId as string)
                        }
                    />
                )}
                {props.avatarFile && <p>Apply changes to save</p>}
                <AvatarEditButton onClick={toggle} src={profileIconUrl.cameraUrl} />
            </AvatarEditContainer>

            <SoarModal title="Edit your profile picture" isOpen={openEditor} toggle={toggle} width="360px">
                <StyledModalBody>
                    <EditProfileAvatar
                        myProfile={props.myProfile}
                        onAvatarUnchanged={toggle}
                        onAvatarUpdated={(file) => {
                            props.setAvatarFile(file);
                        }}
                        setHasChangedAvatar={props.setHasChangedAvatar}
                    />
                </StyledModalBody>
            </SoarModal>
        </React.Fragment>
    );
};

const AvatarEditContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.3);
    -webkit-text-fill-color: rgba(255, 255, 255, 0.3);
    width: 160px;

    p {
        margin: auto;
    }

    @media only screen and (max-width: 600px) {
        position: relative;
    }
`;

const AvatarProfilePage = styled.img`
    width: 150px;
    height: 150px;
    border-radius: 120px;
    border: 1px solid #eed926;
    margin: 10px;
`;

const AvatarEditButton = styled.img`
    width: 30px;
    height: 30px;
    background-color: #eed926 !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
    color: black;
    border-radius: 6px;
    z-index: 50;
    margin-top: 120px;
    margin-left: 130px;
    position: absolute;

    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
`;
