import { LatLngBounds, LatLng } from 'leaflet';
import MathUtil from '../../../../lib/math-util';
import { ListingGroup } from '../../../../store/Map/MapSelection/model';

export interface ClusterWeight {
    // When the bounding box is inside the viewport, this will be a number 0..1
    // Where 0 means the bounding box is tiny compared to the viewport and 1 means its exactly equal
    insideViewportWeight: number;

    // When the bounding box completely covers the viewport, this will be a number 0..1
    // Where 0 means the bounding box is enormous compared to the viewport and 1 means its exactly equal
    coveringViewportWeight: number;

    // When the bounding box intersects the viewport, this will be a number 0..1
    // Where 0 means the bounding box has no intersection area and 1 means the intersection area covers the viewport
    intersectingViewportWeight: number;

    // A number 0..1 that represents how close the center of the viewport is to the bounding box
    // Where 0 means the centers are very far apart
    //       0.5 means the center is exactly on the viewport edge and
    //       1 means they are have the same center
    centroidWeight: number;
}

export default class PolygonClusterUtil {
    // There is no science behind these biases. I just tweaked the values until it 'felt' right against production data
    private static INSIDE_VIEWPORT_BIAS = 1000.0;
    private static MOST_RECENT_BIAS = 0.2;
    private static INTERSECTING_VIEWPORT_BIAS = 0.1;
    private static COVERING_VIEWPORT_BIAS = 0.01;

    /**
     * This function attempts to answer the question, for a given list of clustered listings, which is the most relevant to this viewport
     * @param listings
     * @param viewport
     * @returns
     */
    public static sortListingGroupsByBestFit(listings: ListingGroup[], viewport: LatLngBounds): ListingGroup[] {
        const wrappedViewport = PolygonClusterUtil.wrapLatLngBounds(viewport);

        const weightedListings = listings
            .map((t, index) => {
                const clusterWeight = PolygonClusterUtil.clusterWeight(t.latlngBounds, wrappedViewport);
                const bestIntersectionWeight = Math.max(
                    clusterWeight.coveringViewportWeight * PolygonClusterUtil.COVERING_VIEWPORT_BIAS,
                    Math.min(clusterWeight.insideViewportWeight * PolygonClusterUtil.INSIDE_VIEWPORT_BIAS, 1.0),
                    clusterWeight.intersectingViewportWeight * PolygonClusterUtil.INTERSECTING_VIEWPORT_BIAS
                );
                const mostRecentWeight =
                    MathUtil.remap(0, listings.length, 0, 1, index) * PolygonClusterUtil.MOST_RECENT_BIAS;
                const weight = clusterWeight.centroidWeight * bestIntersectionWeight * mostRecentWeight;

                return {
                    listingGroup: t,
                    isInsideViewport: clusterWeight.insideViewportWeight > 0,
                    isCoveringViewport: clusterWeight.coveringViewportWeight > 0,
                    clusterWeight: weight,
                };
            })
            .filter((t) => {
                return t.clusterWeight > 1e-7;
            })
            .sort((a, b) => {
                // Sort by closest to 1
                return Math.abs(1 - a.clusterWeight) - Math.abs(1 - b.clusterWeight);
            });

        return weightedListings.map((t) => t.listingGroup);
    }

    public static wrapLatLngBounds(boundingBox: LatLngBounds): LatLngBounds {
        const north = Math.min(boundingBox.getNorth(), 87.0);
        const south = Math.max(boundingBox.getSouth(), -87.0);
        const east = Math.max(boundingBox.getEast(), -179.9);
        const west = Math.min(boundingBox.getWest(), 179.9);

        return new LatLngBounds(new LatLng(south, west), new LatLng(north, east));
    }

    /**
     * Checks if the bounding box is completely inside the viewport
     * @param boundingBox
     * @param viewport
     * @returns true if and only if the bounding box is completely inside the viewport
     */
    public static isBoundingBoxCompletelyInsideViewport(boundingBox: LatLngBounds, viewport: LatLngBounds): boolean {
        return (
            viewport.contains(boundingBox.getNorthEast()) &&
            viewport.contains(boundingBox.getNorthWest()) &&
            viewport.contains(boundingBox.getSouthWest()) &&
            viewport.contains(boundingBox.getSouthEast())
        );
    }

    /**
     * Checks if the bounding box completely covers the viewport
     * @param boundingBox
     * @param viewport
     * @returns true if and only if the bounding box completely covers the viewport
     */
    public static isBoundingBoxCompletelyCoveringViewport(boundingBox: LatLngBounds, viewport: LatLngBounds): boolean {
        return (
            boundingBox.contains(viewport.getNorthEast()) &&
            boundingBox.contains(viewport.getNorthWest()) &&
            boundingBox.contains(viewport.getSouthWest()) &&
            boundingBox.contains(viewport.getSouthEast())
        );
    }

    /**
     * Checks if the bounding box intersects the viewport
     * @param boundingBox
     * @param viewport
     * @returns true if and only if the bounding box intersects the viewport (eg the intersection has an area)
     */
    public static isBoundingBoxIntersectingViewport(boundingBox: LatLngBounds, viewport: LatLngBounds): boolean {
        return (
            viewport.intersects(boundingBox) &&
            !PolygonClusterUtil.isBoundingBoxCompletelyInsideViewport(boundingBox, viewport) &&
            !PolygonClusterUtil.isBoundingBoxCompletelyCoveringViewport(boundingBox, viewport)
        );
    }

    /**
     * The length * width area of the intersection between the bounding box and the viewport
     * @param boundingBox
     * @param viewport
     * @returns
     */
    public static cartesianIntersectionArea(boundingBox: LatLngBounds, viewport: LatLngBounds): number {
        const left = Math.max(boundingBox.getWest() + 180, viewport.getWest() + 180) - 180;
        const right = Math.min(boundingBox.getEast() + 180, viewport.getEast() + 180) - 180;
        const bottom = Math.max(boundingBox.getSouth() + 90, viewport.getSouth() + 90) - 90;
        const top = Math.min(boundingBox.getNorth() + 90, viewport.getNorth() + 90) - 90;

        if (left < right && bottom < top) {
            return Math.abs(left - right) * Math.abs(top - bottom);
        } else {
            return 0;
        }
    }

    /**
     * The area of the bounding box given by length * width
     * @param boundingBox
     * @returns
     */
    public static cartesianArea(boundingBox: LatLngBounds): number {
        const length = Math.abs(boundingBox.getEast() - boundingBox.getWest());
        const height = Math.abs(boundingBox.getNorth() - boundingBox.getSouth());
        return length * height;
    }

    /**
     * The ratio of intersection between the bounding box and the viewport.
     * It will equal 1 iff the viewport and boundingbox are exactly equal
     * It will trend to 0 when the ratio between the boundingbox is much larger than the viewport
     *
     * @param boundingBox
     * @param viewport
     * @returns
     */
    public static intersectionRatio(boundingBox: LatLngBounds, viewport: LatLngBounds): number {
        const boundingBoxArea = PolygonClusterUtil.cartesianArea(boundingBox);
        const viewportArea = PolygonClusterUtil.cartesianArea(viewport);

        if (PolygonClusterUtil.isBoundingBoxCompletelyInsideViewport(boundingBox, viewport)) {
            return boundingBoxArea / viewportArea;
        } else if (PolygonClusterUtil.isBoundingBoxCompletelyCoveringViewport(boundingBox, viewport)) {
            return 1 / (boundingBoxArea / viewportArea);
        } else {
            const intersectionArea = PolygonClusterUtil.cartesianIntersectionArea(boundingBox, viewport);
            return intersectionArea / viewportArea;
        }
    }

    /**
     * A value between 0 and 1 that represents how far away the center of the bounding box is from the viewport
     * @param boundingBox
     * @param viewport
     */
    public static weightedDistanceFromCenter(boundingBox: LatLngBounds, viewport: LatLngBounds): number {
        const pythagorasDistanceBetween = (a: LatLng, b: LatLng): number => {
            const deltaLat = Math.abs(a.lat - b.lat);
            const deltaLng = Math.abs(a.lng - b.lng);
            return Math.sqrt(deltaLat * deltaLat + deltaLng * deltaLng);
        };

        const boundingBoxCenter = boundingBox.getCenter();
        const viewportCenter = viewport.getCenter();
        const topLeft = viewport.getNorthEast();

        const distanceViewportToEdge = pythagorasDistanceBetween(viewportCenter, topLeft);
        const distanceBoundingBoxToEdge = pythagorasDistanceBetween(boundingBoxCenter, topLeft);

        return (
            Math.min(distanceBoundingBoxToEdge, distanceViewportToEdge) /
            Math.max(distanceBoundingBoxToEdge, distanceViewportToEdge)
        );
    }

    public static clusterWeight(boundingBox: LatLngBounds, viewport: LatLngBounds): ClusterWeight {
        const weightedDistance = PolygonClusterUtil.weightedDistanceFromCenter(boundingBox, viewport);
        const intersectionRatio = PolygonClusterUtil.intersectionRatio(boundingBox, viewport);

        const weight: ClusterWeight = {
            centroidWeight: weightedDistance,
            coveringViewportWeight: PolygonClusterUtil.isBoundingBoxCompletelyCoveringViewport(boundingBox, viewport)
                ? intersectionRatio
                : 0,
            insideViewportWeight: PolygonClusterUtil.isBoundingBoxCompletelyInsideViewport(boundingBox, viewport)
                ? intersectionRatio
                : 0,
            intersectingViewportWeight: PolygonClusterUtil.isBoundingBoxIntersectingViewport(boundingBox, viewport)
                ? intersectionRatio
                : 0,
        };

        return weight;
    }
}
