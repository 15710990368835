import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ModalBody, ModalFooter, Spinner } from 'reactstrap';
import styled from 'styled-components';
import ShareService from '../../../../lib/share-service';
import Analytics from '../../../../lib/user-analytics';
import { actionSentinelCloseShare } from '../../../../store/Map/Sentinel/actions';
import { selectIsSentinelShareOpen, selectSentinelSelectedFeature } from '../../../../store/Map/Sentinel/selectors';
import SoarModal from '../../../Shared/soar-modal';

const Share = () => {
    const dispatch = useDispatch();
    const isOpenProps = useSelector(selectIsSentinelShareOpen);
    const sentinelFeature = useSelector(selectSentinelSelectedFeature);

    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [shareURI, setShareURI] = useState('');
    const [isGeneratingLink, setIsGeneratingLink] = useState<boolean>(false);
    const [shareURIError, setShareURIError] = useState<string | undefined>();
    const [previewImageFailed, setPreviewImageFailed] = useState<boolean>(false);

    useEffect(() => {
        if (sentinelFeature && isOpenProps) {
            setIsGeneratingLink(true);
            ShareService.shareSentinelFeatureUrl(sentinelFeature)
                .then((miniShareURI) => {
                    setShareURI(miniShareURI);
                    setIsGeneratingLink(false);
                    setShareURIError(undefined);
                })
                .catch(() => {
                    setShareURIError('Failed to generate share links');
                    setIsGeneratingLink(false);
                });
        }
    }, [isOpenProps, sentinelFeature]);

    const handleClickShareToTwitter = () => {
        const shareLink = 'https://www.twitter.com/intent/tweet?hashtags=SoarEarth&url=' + shareURI;
        Analytics.Event('Social Media - Clicked to share', 'Twitter', shareLink);
        window.open(shareLink, '_blank');
    };

    const handleClickShareToFacebook = () => {
        const shareLink = 'https://www.facebook.com/sharer.php?u=' + shareURI;
        Analytics.Event('Social Media - Clicked to share', 'Facebook', shareLink);
        window.open(shareLink, '_blank');
    };

    const handleClickShareToLinkedin = () => {
        const shareLink = 'https://www.linkedin.com/shareArticle?mini=true&url=' + shareURI;
        Analytics.Event('Social Media - Clicked to share', 'LinkedIn', shareLink);
        window.open(shareLink, '_blank');
    };

    useEffect(() => {
        setIsOpen(isOpenProps);
    }, [isOpenProps]);

    const handleCopy = () => {
        if (shareURI) {
            const textField = document.createElement('textarea');
            textField.innerText = decodeURIComponent(shareURI);
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            toast.dark('Copied to clipboard');
            Analytics.Event('Social Media - Clicked to share', 'Link', shareURI);
        }
    };

    const handleCanClose = () => {
        if (!isGeneratingLink) {
            dispatch(actionSentinelCloseShare());
            setPreviewImageFailed(false);
        }
    };

    return (
        <React.Fragment>
            {isOpen && (
                <SoarModal title={'Share'} isOpen={isOpen} toggle={() => handleCanClose()}>
                    {sentinelFeature && (
                        <SharePreview>
                            {previewImageFailed ? (
                                <SharePreviewImageFailed src="/assets/image-unavailable/preview_unavailable_large.png" />
                            ) : (
                                <SharePreviewImage
                                    src={sentinelFeature.sharelinkPreviewUrl}
                                    onError={() => setPreviewImageFailed(true)}
                                />
                            )}
                        </SharePreview>
                    )}
                    {isGeneratingLink ? (
                        <React.Fragment>
                            <ShareModalBody>
                                <ShareBodyTitle>Share this with your network</ShareBodyTitle>
                                <ShareLinkGeneratingSpinnerContainer>
                                    <ShareLinkGeneratingSpinner />
                                </ShareLinkGeneratingSpinnerContainer>
                            </ShareModalBody>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {shareURIError ? (
                                <ShareLinkError>{shareURIError}</ShareLinkError>
                            ) : (
                                <>
                                    <ShareModalBody>
                                        <ShareBodyTitle>Share this with your network</ShareBodyTitle>
                                        <ShareContainer>
                                            <ShareButton className="twitter" onClick={handleClickShareToTwitter}>
                                                <ShareIcon src="/assets/floating-drawer-satellite-icons/share-icons/twitter.png" />
                                                <ShareLabel>Twitter</ShareLabel>
                                            </ShareButton>

                                            <ShareButton className="facebook" onClick={handleClickShareToFacebook}>
                                                <ShareIcon src="/assets/floating-drawer-satellite-icons/share-icons/facebook.png" />
                                                <ShareLabel>Facebook</ShareLabel>
                                            </ShareButton>

                                            <ShareButton className="linkedin" onClick={handleClickShareToLinkedin}>
                                                <ShareIcon src="/assets/floating-drawer-satellite-icons/share-icons/linkedin.png" />
                                                <ShareLabel>LinkedIn</ShareLabel>
                                            </ShareButton>
                                        </ShareContainer>
                                    </ShareModalBody>

                                    <ShareModalFooter>
                                        <ShareFooterLabel>Or copy link</ShareFooterLabel>
                                        <ShareLinkContainer className={shareURI ? '' : 'no-link'} onClick={handleCopy}>
                                            <ShareLinkLabel>{shareURI}</ShareLinkLabel>
                                            <ShareLinkIcon src="/assets/floating-drawer-satellite-icons/share-icons/copy.png" />
                                        </ShareLinkContainer>
                                    </ShareModalFooter>
                                </>
                            )}
                        </React.Fragment>
                    )}
                </SoarModal>
            )}
        </React.Fragment>
    );
};

export default Share;

const ShareModalBody = styled(ModalBody)`
    border-top: none;
    border-bottom: none;
    padding: 0px 20px;
`;

const ShareBodyTitle = styled.div`
    font-family: Exo 2;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    /* or 24px */
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: #ffffff;
    margin-bottom: 20px;

    @media (max-width: 650px) {
        display: none;
    }
`;

const ShareContainer = styled.div`
    width: 100%;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 10px;
`;

const ShareButton = styled.div`
    width: 114px;
    border-radius: 6px;
    padding: 7px 5px;
    margin: 5px;
    font-size: 18px;
    text-align: center;
    display: inline-block;
    cursor: pointer;

    @media (max-width: 400px) {
        width: 100%;
        margin: 5px 0px;
    }

    &.twitter {
        background: #00c4ff;
        color: #ffffff;
    }

    &.facebook {
        background: #3a529c;
        color: #ffffff;
    }

    &.linkedin {
        background: #ffffff;
        color: #191a1a;
    }
`;

const ShareIcon = styled.img`
    display: inline;
    margin-top: -6px;
`;

const ShareLabel = styled.div`
    margin-left: 7.5px;
    border-radius: 6px;

    color: inherit;
    display: inline;
`;

const SharePreview = styled.div`
    display: flex;
    padding: 20px;
    border-top: none;
    border-bottom: none;

    @media (max-width: 650px) {
        display: none;
    }
`;

const SharePreviewImageFailed = styled.img`
    margin: auto;
`;

const SharePreviewImage = styled.img`
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 150px;
    border-radius: 6px;
`;

const ShareModalFooter = styled(ModalFooter)`
    color: white;
    display: block;
    text-align: center;
    border-top: none;

    div.modal-footer {
        margin: 0 !important;
        text-align: center;
    }
`;

const ShareFooterLabel = styled.div`
    color: white;
    margin-bottom: 10px;
    font-size: 16px;
    margin-right: 0px !important;
`;

const ShareLinkContainer = styled.div`
    width: 100%;
    margin-left: 0px !important;
    display: inline;
    text-align: center;
    cursor: pointer;

    &.no-link {
        cursor: wait !important;
        border: 1px solid rgb(255 255 255 / 0.3);
        border-right: none;
        padding: 8px 0px 8px 5px;
        border-radius: 6px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
`;

const ShareLinkLabel = styled.div`
    display: inline;
    background: linear-gradient(90.02deg, rgba(255, 255, 255, 0.6) 75.23%, rgba(255, 255, 255, 0) 96.41%);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 60%;
    padding: 8px 0px 8px 5px;
    margin-right: -10px;
    border: 1px solid rgb(255 255 255 / 0.3);
    border-right: none;
    border-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    text-align: center;
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
`;

const ShareLinkIcon = styled.img`
    display: inline;
    background: #eed926;
    height: 35px;
    padding: 8px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-bottom: 5px;
`;

const ShareLinkGeneratingSpinnerContainer = styled.div`
    width: 100%;
    text-align: center;
`;

const ShareLinkGeneratingSpinner = styled(Spinner)`
    color: #eed926;
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
`;

const ShareLinkError = styled.div`
    background-color: rgba(0, 0, 0, 0.85);
    border: 1px solid rgb(255 255 255 / 0.3);
    border-top: none;
    border-bottom: none;
    padding: 20px 20px;
    color: red;
    font-size: 15px;
    text-align: center;
`;
