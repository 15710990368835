import { AppState } from '../../root-reducer';
import { BaseMap } from './model';

export const selectIsMobileInteractionEnabled = (state: AppState) => state.mobileDomain.isInteractionEnabled;

export const selectBaseMap = (state: AppState) => {
    const baseMap = state.mobileDomain.baseMap;
    if (baseMap === BaseMap.Empty_Black.toString()) return BaseMap.Empty_Black;
    if (baseMap === BaseMap.Empty_White.toString()) return BaseMap.Empty_White;
    if (baseMap === BaseMap.GSM_Hybrid.toString()) return BaseMap.GSM_Hybrid;
    if (baseMap === BaseMap.GSM_Satellite.toString()) return BaseMap.GSM_Satellite;
    if (baseMap === BaseMap.GSM_Street.toString()) return BaseMap.GSM_Street;
    if (baseMap === BaseMap.OSM_Elevation.toString()) return BaseMap.OSM_Elevation;
    if (baseMap === BaseMap.OSM_Street.toString()) return BaseMap.OSM_Street;
    return BaseMap.GSM_Hybrid;
};

export const selectShowMobileLoadingSpinner = (state: AppState) => state.mobileDomain.showLoadingSpinner;

export const selectBeingMobileSentinelSearch = (state: AppState) => state.mobileDomain.mobileBeginSentinelSearch;

