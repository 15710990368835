import React from 'react';
import styled from 'styled-components';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';

const LogoAndCopyright = () => {
    return (
        <React.Fragment>
            <SoarLogo src="/assets/logos/soar_logo.png" />
            <Copyright>© 2023 Soar.Earth Ltd. All rights reserved.</Copyright>

            <TermsContainer>
                <TermsLink
                    href="/terms"
                    onClick={() => {
                        Analytics.Event('LandingPage', 'Clicked Footer Terms of service');
                        UriHelper.navigateToPath('/terms');
                    }}
                >
                    Terms of service
                </TermsLink>

                <TermsLink
                    href="/privacy"
                    onClick={() => {
                        Analytics.Event('LandingPage', 'Clicked Footer Privacy policy');
                        UriHelper.navigateToPath('/terms');
                    }}
                >
                    Privacy Policy
                </TermsLink>
            </TermsContainer>
        </React.Fragment>
    );
};

export default LogoAndCopyright;

const SoarLogo = styled.img`
    margin: 0 auto;
    display: block;
    margin-bottom: 22px;
`;

const Copyright = styled.span`
    color: rgba(255, 255, 255, 0.3);
`;

const TermsContainer = styled.div`
    margin-top: 12px;
`;

const FooterLink = styled.a`
    margin: 0 auto;
    display: block;
    color: rgba(255, 255, 255, 0.3);
    text-decoration: none;
    text-align: left;

    &:hover {
        color: rgba(255, 255, 255, 0.3);
        text-decoration: underline;
    }
`;

const TermsLink = styled(FooterLink)`
    margin-right: 30px;
    margin-left: 30px;
    display: inline-block;

    &:hover {
        color: rgba(255, 255, 255, 0.3);
        text-decoration: underline;
    }
`;
