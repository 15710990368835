import { LatLng } from 'leaflet';
import styled from 'styled-components';

interface GCPCardProps {
    key: number | string;
    id: number;
    gcp: LatLng[];
    active: boolean;
    pixel: { x?: number; y?: number } | undefined;
    setSelectedGCP: (gcp: LatLng[]) => void;
    deleteGCP: (index: number) => void;
}

export const GCPCard = (props: GCPCardProps): JSX.Element => {
    return (
        <GCPCardContainer onMouseOver={() => props.setSelectedGCP(props.gcp)} active={props.active}>
            <UploadMarkerCounter singleDigit={props.id < 9 ? true : false}>{props.id + 1}</UploadMarkerCounter>
            <UploadPositionText>
                <p>{props.gcp[2].lat}</p>
                <p>{props.gcp[2].lng}</p>
            </UploadPositionText>
            <UploadArrow src="../assets/upload/upload-arrow.svg" />
            <UploadPositionText>
                <p>{props.gcp[3].lat}</p>
                <p>{props.gcp[3].lng}</p>
            </UploadPositionText>
            <Delete active={props.active} onClick={() => props.deleteGCP(props.key as number)}>
                X
            </Delete>
        </GCPCardContainer>
    );
};

interface CellProps {
    active?: boolean;
    singleDigit?: boolean;
    onMouseOver?: () => void;
}

const GCPCardContainer = styled.div<CellProps>`
    background-color: ${(props) => (props.active ? 'rgba(69, 69, 69, 0.8)' : '#454545')};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.4rem;
    border-radius: 6px;
    color: white;
    border: 1px solid transparent;
    border: ${(props) => (props.active ? '1px solid rgb(238, 217, 38 , 0.67)' : '1px solid transparent')};
`;

const UploadPositionText = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 2px;
    width: 125px;

    p {
        color: white;
        font-size: 13px;
        margin-bottom: 0px;
    }
`;

const Delete = styled.button<CellProps>`
    background-color: transparent;
    color: ${(props) => (props.active ? 'red' : 'inherit')};
    border: none;
    font-size: 25px;
    padding: 2px;
    margin-right: 5px;

    &:focus {
        outline: none;
    }
`;

const UploadMarkerCounter = styled.p<CellProps>`
    margin: ${(props) => (props.singleDigit ? '8px' : '5px')};
    font-weight: bold;
    color: rgb(0 0 0 / 67%);
    background-color: #eed926;
    padding: 2px 9px;
    border-radius: 260px;
`;

const UploadArrow = styled.img``;
