import React, { useEffect, useState } from 'react';

import {
    PageContainer,
    TutorialTitle,
    TutorialContainer,
    TutorialCaption,
    TutorialFooter,
    ButtonNext,
    ButtonSkip,
    TutorialImage,
    ArrowSvg,
    ArrowPath,
    Target,
    TargetBorderHighlight,
    TutorialBodyContainer,
    TutorialBodyText,
} from './tutorial-styles';

interface TutorialStepProps {
    title: string;
    body?: string;
    caption?: string;
    imageUrl?: string;
    component?: JSX.Element;
    target: string;
    customArrowPath?: {
        mlChange: number;
        mtChange: number;
        cl: number;
        ct: number;
        up: number;
        down: number;
        widthChange: number;
        heightChange: number;
    };
}

interface TutorialProps {
    steps: Array<TutorialStepProps>;
    stepIndex: number;
    customTutorial?: React.ElementType;
    nextStep: () => void;
    previousStep: () => void;
    onSkip: () => void;
}

enum screenPosition {
    Left = 'LEFT',
    Right = 'RIGHT',
    Top = 'TOP',
    Bottom = 'BOTTOM',
}

const Tutorial = (props: TutorialProps) => {
    const [step, setStep] = useState<TutorialStepProps>(props.steps[0]);
    const [target, setTarget] = useState<DOMRect>();
    const [targetExists, setTargetExists] = useState(true);
    const [el, setEl] = useState<HTMLElement>();
    const [title, setTitle] = useState<DOMRect>();
    const [position, setPosition] = useState<screenPosition>(screenPosition.Left);

    const TITLE_HEIGHT = (7.5 * window.innerHeight) / 100;
    const TARGET_PADDING = 10;
    const ARROW_PADDING = TARGET_PADDING + 20;
    // TODO: These cannot be constants as they are calculated from a variable
    const TITLE_CENTER = title ? title.left + title.width / 2 : 0;
    const TARGET_WIDTH_CENTER = target ? target.left + target.width / 2 : 0;
    const TARGET_HEIGHT_CENTER = target ? target.top + target.height / 2 : 0;

    //=================================================
    //  FOR ARROW POSITIONING
    const [pathWidth, setPathWidth] = useState(0);
    const [pathHeight, setPathHeight] = useState(0);

    const [ml, setMl] = useState(0);
    const [mt, setMt] = useState(0);
    const [cl, setCl] = useState(0);
    const [ct, setCt] = useState(0);
    const [up, setUp] = useState(0);
    const [down, setDown] = useState(0);
    //=================================================

    useEffect(() => {
        const el = document.getElementsByClassName('leaflet-map');
        const leafletMap = el[0].childNodes[0] as HTMLElement;
        leafletMap.setAttribute('style', 'filter: blur(2px) brightness(80%)');

        return () => {
            const el = document.getElementsByClassName('leaflet-map');
            const leafletMap = el[0].childNodes[0] as HTMLElement;
            leafletMap.setAttribute('style', 'filter: none');
        };
    }, []);

    useEffect(() => {
        setStep(props.steps[props.stepIndex]);
    }, [props.stepIndex, props.steps]);

    useEffect(() => {
        setEl(document.getElementById(step.target) ?? document.getElementById('_no-target') ?? undefined);
        setTitle(document.getElementById('tutorial-title')?.getBoundingClientRect());
    }, [step, step.target]);

    useEffect(() => {
        if (el?.getClientRects().length && el?.getClientRects().length === 1) {
            requestAnimationFrame(() => {
                setTarget(el?.getBoundingClientRect());

                if (el === document.getElementById('_no-target') || el === undefined) {
                    setTargetExists(false);
                } else {
                    setTargetExists(true);
                }
            });
        }
    }, [el, step, step.target]);

    useEffect(() => {
        const thresh = window.innerWidth / 7;

        if (target && targetExists && title) {
            if (title.left - target.left > thresh && title.left - target.right > thresh) {
                setPosition(screenPosition.Left);
            } else if (target.right - title.right > thresh && target.left - title.right > thresh) {
                setPosition(screenPosition.Right);
            } else if (title.top > target.top) {
                setPosition(screenPosition.Top);
            } else if (title.top < target.top) {
                setPosition(screenPosition.Bottom);
            }
        }
    }, [target, targetExists, title]);

    useEffect(() => {
        if (target && targetExists && title) {
            const bodyEl = document.getElementById('tutorial-body')?.getBoundingClientRect();
            const captionEl = document.getElementById('tutorial-caption')?.getBoundingClientRect();

            const imageTop = step.imageUrl
                ? document.getElementById('tutorial-image')?.getBoundingClientRect().top || 0
                : title.top;
            const bodyTop = bodyEl?.height ? bodyEl?.top : title.top;
            const bodyHeight = bodyEl?.height ? bodyEl?.height : title.height;
            const captionTop = captionEl?.height ? captionEl?.top : bodyTop;
            const captionHeight = captionEl?.height ? captionEl?.height : bodyHeight;
            let width = 0;
            let height = 0;

            if (position === screenPosition.Left) {
                width = title.left - target.left - target.width - ARROW_PADDING;
                height = title.top + TITLE_HEIGHT / 2 - TARGET_HEIGHT_CENTER;

                setMl(target.left + target.width + ARROW_PADDING);
                setMt(TARGET_HEIGHT_CENTER);
                setCl(width / 2);
                setCt(0);
                setUp(0);
                setDown(height);
            } else if (position === screenPosition.Right) {
                width = title.left + title.width - target.left + ARROW_PADDING;
                height = title.top + TITLE_HEIGHT / 2 - TARGET_HEIGHT_CENTER;

                setMl(target.left - ARROW_PADDING);
                setMt(TARGET_HEIGHT_CENTER);
                setCl(width / 2);
                setCt(0);
                setUp(0);
                setDown(height);
            } else if (position === screenPosition.Top) {
                width = TITLE_CENTER - TARGET_WIDTH_CENTER;
                height = imageTop - target.top - target.height - ARROW_PADDING - TARGET_PADDING;

                setMl(target.left + target.width / 2);
                setMt(target.top + target.height + ARROW_PADDING);
                setCl(0);
                setCt(0);
                setUp(0);
                setDown(height);
            } else if (position === screenPosition.Bottom) {
                width = TITLE_CENTER - TARGET_WIDTH_CENTER;
                height = captionTop + captionHeight - target.top + ARROW_PADDING + TARGET_PADDING;

                setMl(target.left + target.width / 2);
                setMt(target.top - ARROW_PADDING);
                setCl(0);
                setCt(0);
                setUp(0);
                setDown(height);
            }

            setPathWidth(width);
            setPathHeight(height);
        }
    }, [step, target, position, targetExists, title, ARROW_PADDING, TITLE_HEIGHT, TARGET_HEIGHT_CENTER, TITLE_CENTER, TARGET_WIDTH_CENTER]);

    return (
        <React.Fragment>
            <div
                id="_no-target"
                style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    marginLeft: '-500px',
                    marginTop: '-500px',
                    transform: 'translateY(100%)',
                }}
            />

            <PageContainer>
                {target ? (
                    <div>
                        <Target
                            targetPadding={TARGET_PADDING}
                            top={target.top}
                            left={target.left}
                            width={target.width}
                            height={target.height}
                        ></Target>
                        <TargetBorderHighlight
                            targetPadding={TARGET_PADDING}
                            top={target.top}
                            left={target.left}
                            width={target.width}
                            height={target.height}
                        />
                    </div>
                ) : null}

                {target && targetExists && !step.customArrowPath ? (
                    <ArrowSvg xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <marker id="chevron" markerWidth="5" markerHeight="5" refX="1.1" refY="2.5" orient="auto">
                                <polygon points="0.0, 2.5, 2.1739130434782608, 0.0, 2.717391304347826, 0.625, 1.0869565217391304, 2.5, 2.717391304347826, 4.375, 2.1739130434782608, 5.0"
                                    fill="white" strokeWidth="0.5px" />
                            </marker>
                        </defs>

                        <ArrowPath
                            markerStart="url(#chevron)"
                            ml={ml}
                            mt={mt}
                            cl={cl}
                            ct={ct}
                            up={up}
                            down={down}
                            width={pathWidth}
                            height={pathHeight}
                        ></ArrowPath>
                    </ArrowSvg>
                ) : null}

                {step.customArrowPath ? (
                    <ArrowSvg xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <marker id="chevron" markerWidth="5" markerHeight="5" refX="1.1" refY="2.5" orient="auto">
                                <polygon points="0.0, 2.5, 2.1739130434782608, 0.0, 2.717391304347826, 0.625, 1.0869565217391304, 2.5, 2.717391304347826, 4.375, 2.1739130434782608, 5.0"
                                    fill="white" strokeWidth="0.5px" />
                            </marker>
                        </defs>

                        <ArrowPath
                            markerStart="url(#chevron)"
                            ml={ml + step.customArrowPath.mlChange}
                            mt={mt + step.customArrowPath.mtChange}
                            cl={step.customArrowPath.cl}
                            ct={step.customArrowPath.ct}
                            up={step.customArrowPath.up}
                            down={step.customArrowPath.down}
                            width={pathWidth + step.customArrowPath.widthChange}
                            height={pathHeight + step.customArrowPath.heightChange}
                        ></ArrowPath>
                    </ArrowSvg>
                ) : null}

                <TutorialContainer>
                    {step.imageUrl && <TutorialImage id="tutorial-image" src={step.imageUrl} />}
                    <TutorialTitle id="tutorial-title" height={TITLE_HEIGHT}>
                        {step.title}
                    </TutorialTitle>
                    <TutorialBodyContainer>
                        <TutorialBodyText id="tutorial-body">{step.body}</TutorialBodyText>
                        {step.component && step.component}
                    </TutorialBodyContainer>
                    <TutorialCaption id="tutorial-caption">{step.caption}</TutorialCaption>

                    <TutorialFooter>
                        {props.stepIndex === 0 ? (
                            <React.Fragment>
                                <ButtonSkip onClick={props.onSkip}>SKIP TUTORIAL</ButtonSkip>
                                <ButtonNext onClick={props.nextStep}>START TUTORIAL</ButtonNext>
                            </React.Fragment>
                        ) : null}

                        {props.stepIndex === 1 ? (
                            <React.Fragment>
                                <ButtonSkip onClick={props.onSkip}>SKIP TUTORIAL</ButtonSkip>
                                <ButtonNext onClick={props.nextStep}>NEXT STEP</ButtonNext>
                            </React.Fragment>
                        ) : null}

                        {props.stepIndex === props.steps.length - 1 ? (
                            <React.Fragment>
                                <ButtonSkip onClick={props.previousStep}>PREVIOUS STEP</ButtonSkip>

                                <ButtonNext onClick={props.nextStep}>FINISH TUTORIAL</ButtonNext>
                            </React.Fragment>
                        ) : null}

                        {props.stepIndex > 1 && props.stepIndex < props.steps.length - 1 ? (
                            <React.Fragment>
                                <ButtonSkip onClick={props.previousStep}>PREVIOUS STEP</ButtonSkip>
                                <ButtonNext onClick={props.nextStep}>NEXT STEP</ButtonNext>
                            </React.Fragment>
                        ) : null}
                    </TutorialFooter>
                </TutorialContainer>
            </PageContainer>
        </React.Fragment>
    );
};

export default Tutorial;
