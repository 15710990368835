import React from 'react';
import styled from 'styled-components';
import CompanyLinks from './company-links';
import DiscoverLinks from './discover-links';
import LogoAndCopyright from './logo-and-copyright';
import ProductLinks from './product-links';
import ResourcesLinks from './resources-links';
import SatelliteImageryLinks from './satellite-links';
import SocialLinks from './social-links';

const Footer = () => {
    return (
        <Container>
            <Left>
                <LogoAndCopyright />
            </Left>
            <Right>
                <SitemapRow>
                    <SitemapColumn>
                        <SitemapHeader>Discover</SitemapHeader>
                        <DiscoverLinks />
                    </SitemapColumn>

                    <SitemapColumn>
                        <SitemapHeader>Satellites</SitemapHeader>
                        <SatelliteImageryLinks />
                    </SitemapColumn>

                    <SitemapColumn>
                        <SitemapHeader>Product</SitemapHeader>
                        <ProductLinks />
                    </SitemapColumn>

                    <SitemapColumn>
                        <SitemapHeader>Resources</SitemapHeader>
                        <ResourcesLinks />
                    </SitemapColumn>
                </SitemapRow>
                <SitemapRow>
                    <SitemapColumn>
                        <SitemapHeader>Company</SitemapHeader>
                        <CompanyLinks />
                    </SitemapColumn>

                    <SitemapColumn>
                        <SitemapHeader>Social Media</SitemapHeader>
                        <SocialLinks />
                    </SitemapColumn>
                </SitemapRow>
            </Right>
        </Container>
    );
};

export default Footer;

const Container = styled.div`
    margin: 40px 80px 0 80px;
    height: 480px;
    padding: 90px 30px 120px 30px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-radius: 10px 10px 0px 0px;
    background-color: hsla(0, 0%, 100%, 0.05);
    text-align: center;
    display: flex;
    flex-direction: horizontal;
`;

const Left = styled.div`
    width: 20%;
    height: 100%;
`;

const Right = styled.div`
    width: 80%;
    height: 100%;
    padding: 0 80px;
    display: flex;
    flex-direction: column;
`;

const SitemapRow = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
`;

const SitemapColumn = styled.div`
    width: 25%;
    height: 100%;
`;

const SitemapHeader = styled.h6`
    color: rgba(255, 255, 255, 0.4);
    font-size: 1.1rem;
    text-decoration: underline;
    font-weight: 600;
    text-align: left;
`;

export const SitemapLink = styled.button`
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    text-align: left;
    font-size: 1rem;
    display: block;
    box-sizing: content-box;
    perspective-origin: 0 0;
    background: none !important;
    border: none;
    padding: 0 !important;
    cursor: pointer;

    &:focus {
        outline: thin dotted;
    }

    &:hover {
        text-decoration: underline;
        color: rgba(255, 255, 255, 0.6);
    }
`;
