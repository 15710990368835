import React from 'react';
import styled from 'styled-components';
import UriHelper from '../../lib/uri-helper';
import Analytics from '../../lib/user-analytics';
import { StyledButton } from '../Shared/styled-button';

const LandingPageRightContent = () => {
    return (
        <React.Fragment>
            <SoarLogo src="/assets/logos/soar_logo_large.png" />
            <SoarExploreButton
                onClick={() => {
                    UriHelper.navigateToPath('/maps');
                    Analytics.Event('LandingPage', 'Clicked explore the atlas');
                }}
            >
                <SoarExploreImage src="/assets/landing-page/landing_page_globe.png" alt="Landing page globe" />
                <p> Explore the Atlas </p>
            </SoarExploreButton>
        </React.Fragment>
    );
};

export default LandingPageRightContent;

const SoarLogo = styled.img`
    width: 100%;
    max-width: 500px;
`;

const SoarExploreButton = styled(StyledButton)`
    margin: 64px auto 0px auto;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    width: 400px;
    vertical-align: middle;

    p {
        text-transform: none;
        font-size: 30px;
        margin-top: 20px;
    }

    @media only screen and (max-height: 800px) {
        max-width: 330px;
        padding: 4px 20px;
        font-size: 28px;
    }
`;

const SoarExploreImage = styled.img`
    max-width: 90px;
    max-height: 90px;
`;
