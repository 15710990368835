import PageError from './components/Shared/page-error';

const PageNotFoundError = () => {
    return (
        <PageError
            gradient
            background="/assets/backgrounds-large/error-background.jpg"
            credit={{
                avatarUrl: 'https://avatar.soar.earth/ffba502ef2914a1f975b6a7868c722achsf.png/preview',
                location: 'Salt Pans',
                name: 'Kim Shapley',
                profile: 'https://soar.earth/profile/0x95c40c1fa80cb6ece3d39fe643995a6e9e2ed8d3',
            }}
        >
            <h3>Oops...</h3>
            <p>
                It looks like you've got here by accident or we're still <br />
                working on this section of the service.
            </p>
            <button onClick={() => window.open('/', '_self')}>BACK TO HOMEPAGE</button>
        </PageError>
    );
};

export default PageNotFoundError;
