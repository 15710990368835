import { useState } from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import styled from 'styled-components';

export enum ProfileInputType {
    STANDARD,
    DROPDOWN,
    NUMBER,
    PASSWORD,
    TEXTAREA,
}

interface ProfileSharedEditInputProps {
    inputType?: ProfileInputType;
    title: string;
    name?: string;
    label?: string;
    value: string | number | undefined;
    setInputValue: (value: string) => void;
    required?: boolean;
}

const EditInput = (props: ProfileSharedEditInputProps) => {
    const [error, setError] = useState<string | undefined>();

    const checkLengthAndSet = (value: string, maxLength: number) => {
        if (value.length > maxLength) {
            setError(`Maximum length needs to be ${maxLength}`);
            return;
        }
        props.setInputValue(value);
        if (value.length < 1 && props.required) {
            setError(`This field is required`);
        } else {
            setError(undefined);
        }
    };

    if (props.inputType === ProfileInputType.TEXTAREA) {
        const max = 2500;
        return (
            <SharedInputContainer>
                <FormGroup>
                    <SharedInputLabel for={`${props.title}input`}>{props.title}</SharedInputLabel>
                    <SharedTextAreaInput
                        type="textarea"
                        name={props.name ? props.name : props.title}
                        placeholder={props.label}
                        value={props.value}
                        onChange={(e) => checkLengthAndSet(e.target.value, max)}
                    />
                </FormGroup>
                {props.value && typeof props.value === 'string' && (
                    <SharedCharacterLimitTextArea>
                        {!error ? (
                            <>
                                <SharedCharacterLimitDescriptor>Characters &nbsp; </SharedCharacterLimitDescriptor>
                                <SharedCharacterLimitCount>
                                    {props.value.length}/{max}
                                </SharedCharacterLimitCount>
                            </>
                        ) : (
                            <SharedError>{error}</SharedError>
                        )}
                    </SharedCharacterLimitTextArea>
                )}
            </SharedInputContainer>
        );
    }

    if (props.inputType === ProfileInputType.PASSWORD) {
        return (
            <SharedInputContainer>
                <Form>
                    <FormGroup>
                        <SharedInputLabel for={`${props.title}input`}>{props.title}</SharedInputLabel>
                        <SharedInput
                            className="shared-input"
                            type="password"
                            name={props.name ? props.name : props.title}
                            placeholder={props.label}
                            value={props.value}
                            onChange={() => props.setInputValue}
                        />
                    </FormGroup>
                </Form>
            </SharedInputContainer>
        );
    }

    // TODO fix this as not right...
    if (props.inputType === ProfileInputType.NUMBER) {
        return (
            <SharedInputContainer>
                <FormGroup>
                    <SharedInputLabel for={`${props.title}input`}>{props.title}</SharedInputLabel>
                    <SharedInput
                        className="shared-input"
                        type="number"
                        name={props.name ? props.name : props.title}
                        placeholder={props.label}
                        value={props.value}
                        onChange={(e) => props.setInputValue(e.target.value)}
                    />
                </FormGroup>
            </SharedInputContainer>
        );
    }

    if (props.inputType === ProfileInputType.STANDARD) {
        return (
            <SharedInputContainer>
                <FormGroup>
                    <SharedInputLabel for={`${props.title}input`}>{props.title}</SharedInputLabel>
                    <SharedInput
                        className="shared-input"
                        type="text"
                        name={props.name ? props.name : props.title}
                        placeholder={props.label}
                        value={props.value}
                        onChange={(e) => props.setInputValue(e.target.value)}
                    />
                </FormGroup>
            </SharedInputContainer>
        );
    }

    const max = 100;
    return (
        <SharedInputContainer>
            <FormGroup>
                <SharedInputLabel for={`${props.title}input`}>{props.title}</SharedInputLabel>
                <SharedInput
                    className="shared-input"
                    type="text"
                    name={props.name ? props.name : props.title}
                    placeholder={props.label}
                    value={props.value}
                    onChange={(e) => checkLengthAndSet(e.target.value, max)}
                />

                <SharedCharacterLimit>
                    {error ? (
                        <SharedError>{error}</SharedError>
                    ) : props.value && typeof props.value === 'string' ? (
                        <>
                            <SharedCharacterLimitDescriptor>Characters</SharedCharacterLimitDescriptor>
                            <SharedCharacterLimitCount>
                                {props.value.length}/{max}
                            </SharedCharacterLimitCount>
                        </>
                    ) : null}
                </SharedCharacterLimit>
            </FormGroup>
        </SharedInputContainer>
    );
};

export default EditInput;

const SharedInputContainer = styled.div`
    color: white;
    -webkit-text-fill-color: white;
`;

const SharedInputLabel = styled(Label)`
    color: inherit;
`;

const SharedInput = styled(Input)`
    background-color: transparent !important;
    color: rgba(255, 255, 255, 0.6) !important;
    border: 1px solid rgb(255 255 255 / 0.3) !important;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;

    :hover {
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }

    :active,
    :focus {
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }

    &.shared-input {
        background-color: transparent !important;
        color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgb(255 255 255 / 0.3) !important;
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6);

        :hover {
            border: 1px solid rgba(238 227 8 / 0.6) !important;
            border-color: rgba(238 227 8 / 0.6) !important;
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
        }

        :active,
        :focus {
            border: 1px solid rgba(238 227 8 / 0.6) !important;
            border-color: rgba(238 227 8 / 0.6) !important;
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
        }
    }
`;

const SharedCharacterLimitTextArea = styled.div`
    margin-top: -10px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 10px;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.8);
`;

const SharedCharacterLimit = styled.div`
    padding-top: 2px;
    padding-bottom: 7px;
    font-size: 10px;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.8);
`;

const SharedError = styled.div`
    display: inline-block;

    -webkit-text-fill-color: red;
`;

const SharedCharacterLimitCount = styled.div`
    display: inline;
    float: right;
`;
const SharedCharacterLimitDescriptor = styled.div`
    display: inline;
    float: left;
`;

const SharedTextAreaInput = styled(Input)`
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 150px !important;
    resize: none;
    color: rgba(255, 255, 255, 0.6) !important;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6);

    :hover,
    :active,
    :focus {
        background-color: transparent;
        border: 1px solid rgba(238, 217, 38, 0.6);
        box-shadow: 0 0 0 0.1rem rgba(238, 217, 38, 0.3);
        color: rgba(255, 255, 255, 0.6) !important;
    }

    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #343a40;
    }
    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #888;
        border-radius: 6px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
        margin-top: 2px;
        margin-bottom: 0;
    }
`;
