import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ListingDTO } from '../../../api/model';
import ListingHelper from '../../../lib/listing-helper';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import UserHelper from '../../../lib/user-helper';
import { actionClearSelectedListing } from '../../../store/Map/MapSelection/actions';
import MobileSearchOptions from './mobile-card-share-options';

interface MobileSearchCardProps {
    listing: ListingDTO;
}

const MobileMasonryCard = (props: MobileSearchCardProps) => {
    const dispatch = useDispatch();
    const clearSelectedListing = (clearListing: boolean) => dispatch(actionClearSelectedListing(clearListing));

    const handleSelectedProfile = (userId: string) => {
        clearSelectedListing(true);
        Analytics.Event('Mobile - Search Bar', 'Selected Profile', userId);
        UriHelper.navigateToPath(`/profile/${userId}`);
    };

    const handleSelectedListing = (mapId: number) => {
        clearSelectedListing(true);
        Analytics.Event('Mobile - Search Bar', 'Selected Listing', mapId);
        UriHelper.navigateToPath(`/maps/${mapId}`);
    };

    return (
        <MobileSearchCardContainer>
            <MobileSearchCardUser>
                <MobileSearchCardAvatar
                    src={props.listing.avatarUrl || UserHelper.getDefaultAvatar(props.listing.userId as string)}
                    onClick={() => handleSelectedProfile(props.listing.userId)}
                />
                <MobileSearchCardTitle>{props.listing.title}</MobileSearchCardTitle>
            </MobileSearchCardUser>
            <MobileSearchCardImage
                src={ListingHelper.getPreviewUrlForListing(props.listing.id)}
                onClick={() => handleSelectedListing(props.listing.id)}
            />
            <MobileSearchCardShare>
                <MobileSearchOptions listing={props.listing} />
            </MobileSearchCardShare>
        </MobileSearchCardContainer>
    );
};

export default MobileMasonryCard;

const MobileSearchCardContainer = styled.div`
    padding: 5px;
    margin: 10px 5px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(255 255 255 / 10%);
    border-radius: 6px;
    background-color: hsla(0, 100%, 100%, 0.01);
    box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
        0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
`;

const MobileSearchCardImage = styled.img`
    height: 260px;
    width: 100%;
    object-fit: contain;
    border-radius: 6px;
    background-color: hsla(0, 100%, 100%, 0.02);
    box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
        0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
`;

const MobileSearchCardUser = styled.div`
    display: flex;
    flex-direction: row;
    margin: 5px 5px 10px 5px;
    justify-content: flex-start;
`;

const MobileSearchCardShare = styled.div`
    margin: 10px 0px 3px 5px;
`;

const MobileSearchCardAvatar = styled.img`
    border-radius: 200px;
    width: 24px;
    height: 24px;
    margin-right: 5px;
`;

const MobileSearchCardTitle = styled.div`
    color: rgba(255, 255, 255, 0.87);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
