import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import { SideDrawerMode } from '../../../../store/SideDrawer/model';
import { selectLoggedIn, selectMyProfile } from '../../../../store/Account/selectors';
import { LoginModalMode } from '../../../Registration/login-enum';
import LoginRegisterDialog from '../../../Registration/login-register-dialog';
import { StyledButton } from '../../../Shared/styled-button';
import UserHelper from '../../../../lib/user-helper';

const DrawerItemListNoResults = () => {
    const isLoggedIn = useSelector(selectLoggedIn);
    const [mode, setMode] = useState<SideDrawerMode | undefined>(undefined);
    const [showLogin, setShowLogin] = useState(false);
    const myProfile = useSelector(selectMyProfile);

    const onSelected = (mode: SideDrawerMode) => {
        setMode(mode);
        if (!isLoggedIn) {
            setShowLogin(true);
        }
    };

    useEffect(() => {
        if (mode && isLoggedIn) {
            UriHelper.navigateToDrawer(mode);
        }
    }, [mode, isLoggedIn]);

    return (
        <React.Fragment>
            {showLogin && (
                <LoginRegisterDialog
                    isOpen={true}
                    initialMode={LoginModalMode.LOGIN}
                    onClose={() => setShowLogin(false)}
                />
            )}
            <Button
                onClick={() => {
                    UriHelper.navigateToPath('/satellites');
                    Analytics.Event('Category', 'Clicked search for satellite imagery', 'Existing User');
                }}
            >
                Search for satellite imagery
            </Button>

            {UserHelper.hasWritePermissionsForSubdomain(myProfile) && (
                <Button
                    onClick={() => {
                        onSelected(SideDrawerMode.SHARE_MAP);
                        Analytics.Event('My Maps', 'Clicked upload a map', 'Existing User');
                    }}
                >
                    <MyMapsUploadIcon src="/assets/floating-drawer-icons/maps-icon-black.svg" />
                    Add your maps!
                </Button>
            )}
        </React.Fragment>
    );
};

export default DrawerItemListNoResults;

const Button = styled(StyledButton)`
    margin: 12px auto;
    display: block;
    width: 100%;
    text-transform: uppercase;
    height: 45px;
`;

const MyMapsUploadIcon = styled.img`
    width: 25px;
    margin: -4px 7px 0px 5px;
`;
