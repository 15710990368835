import React from 'react';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { CSWTaskStatus, AnalyticsAction, AnalyticsNote, OrderDTO } from '../../../api/model';
import Analytics from '../../../lib/user-analytics';
import PriceWithCurrency from '../../Shared/price-with-currency';
import profileIconUrl from '../profile-icon-url';
import OrderStatus from './order-status';
import styled from 'styled-components';
import ApiAnalytics from '../../../api/api-analytics';
import { StyledButton } from '../../Shared/styled-button';
import UriHelper from '../../../lib/uri-helper';
import ApiOrder from '../../../api/api-order';

const moment = require('moment');

interface OrderItemProps {
    task: OrderDTO;
}

const OrderItem = (props: OrderItemProps) => {
    const { task } = props;
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const { id, files } = task;
    const { paidByOtherMethod, paidByStripe, paidByVoucher, tax, amount } = task.payment;

    const handleClickView = () => {
        Analytics.Event('My Dashboard', 'Clicked to view satellite task', task.id);
        UriHelper.navigateToPath(`/task/${task.id}`);
    };

    const handleClickDownload = (taskFileId: string) => {
        Analytics.Event('My Dashboard', 'Clicked to download satellite task', taskFileId);
        ApiAnalytics.postAnalyticsListing(AnalyticsAction.DOWNLOAD, AnalyticsNote.DASHBOARD_TASK, task.id, true);
        ApiOrder.getOrderDownloadLink(id, taskFileId)
            .then((downloadLink) => {
                const link = document.createElement('a');
                link.href = downloadLink;
                link.download = 'true';
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => console.log(err));
    };

    const typeStringForProvider = (type: string): string => {
        if (type === 'CSW' || type === 'CSW_CUSTOM') return 'New Collection';
        if (type === 'CSW_ARCHIVE' || type === 'CSW_CUSTOM_ARCHIVE') return 'Archival Imagery';
        if (type === 'CSW_CUSTOM_URGENT') return 'Urgent Collection';
        if (type === 'CSW_CUSTOM_STEREO') return 'Stereo Collection';
        if (type === 'CSW_CUSTOM_MONITORING') return 'Monitoring Collection';
        return 'Collection';
    };

    const totalPaid = paidByOtherMethod + paidByStripe + paidByVoucher;

    const onClickPayment = () => {
        Analytics.Event('My Dashboard', 'Clicked to view task payment', id);
        UriHelper.navigateToPath(`/order/payment?id=${id}`);
    };

    const handleReceiptClicked = (task: OrderDTO) => {
        const receiptUrl = task.payment.receiptUrl ? task.payment.receiptUrl : task.payment.invoiceUrl;
        if (receiptUrl) {
            window.open(receiptUrl, 'popUpWindow', 'height=600,width=700,left=10,top=10,scrollbars=yes,menubar=no');
            Analytics.Event('My Dashboard', 'Clicked to view receipt');
        }
    };

    const handleClickedInvoice = () => {
        const invoiceUrl = task.payment.invoiceUrl ? task.payment.invoiceUrl : task.payment.receiptUrl;
        if (invoiceUrl) {
            window.open(invoiceUrl, 'popUpWindow', 'height=600,width=700,left=10,top=10,scrollbars=yes,menubar=no');
            Analytics.Event('My Dashboard', 'Clicked to view invoice');
        }
    };

    return (
        <>
            <TaskListTr>
                <TaskImageTd>
                    <div>{task.status && <OrderStatus status={task.status} />}</div>

                    <img src={profileIconUrl.satelliteImageUrl} />
                </TaskImageTd>
                <td>{typeStringForProvider(task.provider)}</td>
                <td>{task.orderNumber}</td>
                <td>{moment(task.created).format('MMM Do YYYY')}</td>
                <td>{task.effectiveAreaInKm?.toFixed(2)} km²</td>
                <td>
                    {task.payment.paidByVoucher !== 0 && (
                        <PriceWithCurrency value={task.payment.paidByVoucher / 100} currency={task.payment.currency} />
                    )}
                </td>
                <td>
                    {task.payment.paidByStripe !== 0 && (
                        <PriceWithCurrency value={task.payment.paidByStripe / 100} currency={task.payment.currency} />
                    )}
                </td>
                <td>
                    <PriceWithCurrency value={task.payment.amount / 100} currency={task.payment.currency} />
                </td>
                <td>
                    {task.payment.receiptUrl ? (
                        <TaskButton onClick={() => handleReceiptClicked(task)}>
                            <TaskButtonDetails className="receipt">
                                <span>
                                    <i className="fa fa-external-link" />
                                </span>
                                RECEIPT
                            </TaskButtonDetails>
                        </TaskButton>
                    ) : (
                        'NO RECEIPT'
                    )}
                </td>
                <td>
                    {task.status === CSWTaskStatus.REVIEW_SOAR && paidByStripe === 0 && totalPaid !== tax + amount && (
                        <TaskButton onClick={onClickPayment}>
                            <TaskButtonDetails className="view">
                                <i className="fa fa-cc-stripe" />
                                <span>Pay via Stripe</span>
                            </TaskButtonDetails>
                        </TaskButton>
                    )}
                    <TaskButtons>
                        {task.status === CSWTaskStatus.DELIVERED && (
                            <TaskButton onClick={handleClickView}>
                                <TaskButtonDetails className="view">
                                    <i className="fa fa-eye " />
                                    <span>VIEW</span>
                                </TaskButtonDetails>
                            </TaskButton>
                        )}
                        {/* this wont show as /v1/orders do not return data for files[], you need to get individual order such as /v1/orders/1562 to get the files[] 
                        Suggestion is to put the download option on /task/{id}
                        */}
                        {task.status === CSWTaskStatus.DELIVERED && files && files.length > 0 && (
                            <TaskButtonDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
                                <TaskDropdownToggle caret>
                                    <i className="fa fa-download" />
                                    <span>DOWNLOAD</span>
                                </TaskDropdownToggle>
                                <DropdownMenu>
                                    {files.map((item) => {
                                        const extension = item.fileName.substr(item.fileName.lastIndexOf('.') + 1);
                                        return (
                                            <DropdownItem
                                                key={`task_file_${item.id}`}
                                                onClick={() => handleClickDownload(item.id)}
                                            >
                                                .{extension}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </TaskButtonDropdown>
                        )}
                        {task.status !== CSWTaskStatus.REJECTED && task.payment.invoiceUrl && (
                            <TaskButton onClick={handleClickedInvoice}>
                                <TaskButtonDetails className="invoice">
                                    <i className="fa fa-external-link" />
                                    <span>INVOICE</span>
                                </TaskButtonDetails>
                            </TaskButton>
                        )}
                    </TaskButtons>
                    {task.status === CSWTaskStatus.REJECTED && task.noteRejected ? (
                        <TaskRejectionNotes>{task.noteRejected}</TaskRejectionNotes>
                    ) : null}
                </td>
            </TaskListTr>
        </>
    );
};

export default OrderItem;

const TaskListTr = styled.tr`
    color: white !important;

    td {
        color: white !important;
        vertical-align: inherit !important;
    }
`;

const TaskImageTd = styled.td`
    padding: 10px;
    width: 50px !important;

    img {
        width: 40px;
    }
`;

const TaskButtons = styled.div`
    display: flex;
    flex-direction: row;
`;

const TaskButton = styled(StyledButton)`
    margin: 5px !important;
    padding: 5px 10px !important;
    font-size: 12px !important;

    .view,
    .receipt,
    .invoice {
        @media only screen and (max-width: 1350px) {
            span {
                display: none;
            }
        }
    }
`;

const TaskButtonDetails = styled.div`
    display: flex;
    flex-direction: row;
    width: max-content;
    i {
        margin: 3px;
    }
`;

const TaskDropdownToggle = styled(DropdownToggle)`
    margin: 5px !important;
    padding: 5px 10px !important;
    font-size: 12px !important;
    display: block;
    background-color: #eed926 !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: black;
    -webkit-text-fill-color: black;

    i {
        margin-right: 3px;
    }

    span {
        font-size: 12px !important;
    }

    :hover {
        background-image: none !important;
        transition: none !important;
    }

    ::after {
        color: black;
        font-size: 12px;
    }
`;

const TaskRejectionNotes = styled.div`
    overflow: auto;
    color: white;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const TaskButtonDropdown = styled(ButtonDropdown)`
    span {
        color: black !important;
    }

    @media only screen and (max-width: 1300px) {
        span {
            display: none;
        }
    }
`;
