import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import SoarHelper from '../../../lib/soar-helper';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { TruncatedText } from '../../Shared/truncated-text';
import { ListingDTO } from '../../../api/model';

interface ActiveAnimatedLayerMenuProps {
    animatedLayer: ListingDTO;
}

/*
    TODO:
        This component is very similar to `active-map-menu.tsx`.
        There should be more component re-use, especially with the styles

    Notes:
        Because these animated layers don't have a backend I removed
        the commenting, up-voting and share links. I also removed pinning and
        opacity control, mostly because of time constraints (the redux stores will need updating)
*/

const ActiveAnimatedLayerMenu = (props: ActiveAnimatedLayerMenuProps) => {
    const { animatedLayer } = props;

    const MAX_DESCRIPTION_LENGTH = 315;

    const isSoar = SoarHelper.isSoar();

    const handleShowProfile = () => {
        Analytics.Event('Navigation - SideBar - Opened Map', 'Clicked View Profile', animatedLayer.userId);
        UriHelper.navigateToPath(`/profile/${animatedLayer.userId}`);
    };

    return (
        <React.Fragment>
            <Title href={`/maps/${animatedLayer.id}`}>{animatedLayer.title}</Title>
            <Subtitle>
                by&nbsp;
                {isSoar ? (
                    <UserProfileLink onClick={handleShowProfile} title="Open Profile">
                        {animatedLayer.userName ?? 'Soar User'}
                    </UserProfileLink>
                ) : (
                    <React.Fragment>{animatedLayer.userName ?? 'Soar User'}</React.Fragment>
                )}
                {animatedLayer.reputable ? (
                    <ReputableUserIcon alt="Verified" title="Verified" src="/assets/verified-user-tick.svg" />
                ) : null}
            </Subtitle>
            <UploadDate>{moment(animatedLayer.dateUploaded).fromNow()}</UploadDate>
            <Content>
                <TruncatedText text={animatedLayer.description} length={MAX_DESCRIPTION_LENGTH} />
            </Content>

            <TagContainer>
                {animatedLayer.tags &&
                    animatedLayer.tags.map((tag, index) => {
                        return <Tag key={`${tag}-${index}`}>{tag}</Tag>;
                    })}
            </TagContainer>
        </React.Fragment>
    );
};

export default ActiveAnimatedLayerMenu;

const Title = styled.a`
    flex: 0 1 auto;
    margin: 12px 0 4px 12px;
    display: inline-block;
    color: white;
    font-weight: 700;
    font-size: 30px;
    overflow-wrap: break-word;
    text-decoration: none;

    :hover {
        color: rgba(255, 255, 255, 0.8);
    }
`;

const Subtitle = styled.h6`
    flex: 0 1 auto;
    margin-left: 12px;
    color: white;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
`;

const UserProfileLink = styled.button`
    color: #eed926;
    background: none !important;
    border: none;
    padding: 0 !important;
    text-decoration: underline;
    cursor: pointer;
`;

const ReputableUserIcon = styled.img`
    margin-left: 4px;
    margin-bottom: 2px;
    width: 16px;
    height: 16px;
`;

const UploadDate = styled.span`
    margin-left: 12px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
    line-height: 22px;
`;

const Content = styled.div`
    flex: 1 1 auto;
    margin: 12px;
    margin-right: 0;
    padding-right: 12px;
    text-align: left;
    font-weight: 400;
    line-height: 20.8px;
    color: white;
    word-break: break-word;
    max-width: 430px;
`;

const TagContainer = styled.div`
    flex: 0 1 auto;
    text-align: left;
    display: flex;
    margin: 0 12px 0 12px;
    flex-wrap: wrap;
    max-height: 55px;
    overflow: hidden;
`;

const Tag = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    padding: 1px 3px 3px 3px;
    line-height: 14px;
    opacity: 0.5;
    margin: 0 2px 2px 0;
    color: white;
    width: auto;
    word-break: break-all;
    font-size: 12px;
`;
