import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import UserHelper from '../../../../lib/user-helper';
import { CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe } from 'react-stripe-elements';
import PriceWithCurrency from '../../../Shared/price-with-currency';
import Analytics, { ConversionEvent } from '../../../../lib/user-analytics';
import DrawerHint from '../../drawer-hint';
import { Button, Content, Divider, Title } from '../satellite-drawer-styles';
interface SatelliteOrderCreditCardDetailsProps {
    totalPrice: number; // in dollars
    totalTax?: number;
    currency?: string;
    handleStripeTokenSubmitted: (stripeToken: string) => void;
    stripe: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    error?: Error;
    termsAndConditionsHref: string;
}

const SatelliteOrderCreditCardDetails = (props: SatelliteOrderCreditCardDetailsProps) => {
    const [stripeError, setStripeError] = useState<Error | undefined>(undefined);
    const [stripeLoading, setStripeLoading] = useState(false);
    const [stripeReady, setStripeReady] = useState(false);

    const isFree = props.totalPrice === 0;

    const handleSubmit = (e) => {
        e.preventDefault();

        Analytics.Event('Satellite', 'Submitted CC');

        if (isFree) {
            props.handleStripeTokenSubmitted('FREE');
            return;
        }
        // Precondition: Stripe has initialized
        if (props.stripe === undefined) {
            setStripeError(Error("Stripe.js hasn't loaded"));
            return;
        }

        // Precondition: User has sign in credentials
        const accessToken = UserHelper.validateAndGetIdToken();
        if (accessToken === undefined) {
            setStripeError(Error('You are not signed in.  Please sign in and try again')); // Should never happen
            return;
        }

        setStripeLoading(true);
        setStripeError(undefined);

        props.stripe
            .createToken()
            .then((payload) => {
                if (payload.error) {
                    throw new Error(payload.error.message);
                }

                if (!payload.token) {
                    // Unsure if this can happen - no error and no token from stripe;
                    throw new Error('There was an error with the payment gateway.  Please try again');
                }

                return payload.token.id;
            })
            .then((stripeToken) => {
                setStripeError(undefined);
                if (props.currency && props.totalPrice.toString()) {
                    Analytics.ConversionWithValue(
                        ConversionEvent.COMPLETE_ORDER,
                        props.currency,
                        props.totalPrice.toString()
                    );
                } else {
                    Analytics.Conversion(ConversionEvent.COMPLETE_ORDER);
                }
                props.handleStripeTokenSubmitted(stripeToken);
            })
            .catch((error) => {
                setStripeError(error);
                setStripeLoading(false);
            });
    };

    useEffect(() => {
        setStripeLoading(false);
    }, [props.error]);

    return (
        <Content>
            <Title>Payment Details</Title>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Label>Card holder name</Label>

                <TextField name="cc-name" type="text" id="name" placeholder="Name on card" />

                <Label>Card Number</Label>
                <SoarCreditNumberElement
                    onReady={() => setStripeReady(true)}
                    style={{
                        base: {
                            color: 'rgba(255, 255, 255, 0.6)',
                            '::placeholder': {
                                color: 'rgba(255, 255, 255, 0.6)',
                            },
                        },
                    }}
                />
                <CreditCardIcons src="/assets/payments/mastercard-visa-americanexpress.png" />

                <ExpiryAndCVCContainer>
                    <ExpiryContainer>
                        <Label>Expiry</Label>
                        <SoarCardExpiryElement
                            style={{
                                base: {
                                    color: 'rgba(255, 255, 255, 0.6)',
                                    '::placeholder': {
                                        color: 'rgba(255, 255, 255, 0.6)',
                                    },
                                },
                            }}
                        />
                    </ExpiryContainer>

                    <CVCContainer>
                        <Label>CVC</Label>
                        <SoarCardCVCElement
                            style={{
                                base: {
                                    color: 'rgba(255, 255, 255, 0.6)',
                                    '::placeholder': {
                                        color: 'rgba(255, 255, 255, 0.6)',
                                    },
                                },
                            }}
                        />
                    </CVCContainer>
                </ExpiryAndCVCContainer>

                {stripeError ? <DrawerHint error>{stripeError.message}</DrawerHint> : null}

                <DrawerHint>
                    By clicking 'Complete Order', you will be charged the total price of:&nbsp;
                    <PurchasePrice>
                        <PriceWithCurrency value={props.totalPrice} currency={props.currency} />
                    </PurchasePrice>
                    {props.totalTax ? ' inc. GST' : ''}
                </DrawerHint>
            </Form>

            <Divider />

            <SubmitButton disabled={stripeLoading || !stripeReady} onClick={handleSubmit}>
                {stripeLoading ? <i className="fa fa-spinner fa-spin"></i> : 'Complete Order'}
            </SubmitButton>

            {props.error ? <ErrorText>{props.error.message.replace('400: ', '')}</ErrorText> : null}
        </Content>
    );
};

export default injectStripe(SatelliteOrderCreditCardDetails);

const Form = styled.form`
    padding: 12px;
`;

const Label = styled.label`
    color: white;
    font-size: 16px;
`;

const TextField = styled.input`
    background-color: transparent;
    width: 100%;
    height: 40px !important;
    padding: 0px 12px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    font-size: 16px;
    outline: none;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 0.6) !important;

    :-webkit-autofill {
        :hover {
            border: 1px solid rgba(238 227 8 / 0.6) !important;
            border-color: rgba(238 227 8 / 0.6) !important;
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
            -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
        }
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgba(255, 255, 255, 0.3) !important;
    }

    :hover {
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }

    :focus {
        color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }
`;

const CreditCardIcons = styled.img`
    max-height: 30px;
    float: right;
`;

const ExpiryAndCVCContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 12px;
`;

const ExpiryContainer = styled.div`
    flex: 1;
    margin: 0 18px 0 0;
    width: 120px;
`;

const CVCContainer = styled.div`
    flex: 1;
    width: 120px;
`;

const ErrorText = styled.p`
    margin-top: 12px;
    color: red;
`;

const SubmitButton = styled(Button)`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const PurchasePrice = styled.span`
    color: #eed926;
`;

const CreditCardCSS = `
    text-align: left;
    display: block;
    height: 40px;
    padding: 12px 12px;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 1.2em;
    color: rgba(255, 255, 255, 0.6) !important;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-family: 'Exo 2', sans-serif !important;

    :hover {
        border: 1px solid rgba(238 227 8 / 0.6) !important; 
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }

    :active,
    :focus,
    :focus-visible {
        color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }
`;

const SoarCreditNumberElement = styled(CardNumberElement)`
    ${CreditCardCSS}
    width: 100%;
`;

const SoarCardExpiryElement = styled(CardExpiryElement)`
    ${CreditCardCSS}
    width: 90px;
`;

const SoarCardCVCElement = styled(CardCVCElement)`
    ${CreditCardCSS}
    width: 90px;
`;
