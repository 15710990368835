import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectVisibleListingGroups, selectListingDictionaryIsLoading } from '../../store/Map/MapSelection/selectors';
import { PulseLoader } from '../Shared/pulse-loader';
import CategoryGlobalMaps from './Category/category-global-maps';
import CategoryMapsList from './Category/category-maps-list';
import DrawerItemListNoResults from './Shared/Drawer/drawer-item-list-no-results';

interface DrawerItemListProps {
    disableCategoryGlobalMaps?: boolean;
}

const DrawerItemList = (props: DrawerItemListProps) => {
    const visibleTileLayers = useSelector(selectVisibleListingGroups);
    const isLoading = useSelector(selectListingDictionaryIsLoading);

    return (
        <DrawerItemListResults>
            {isLoading ? (
                <PulseLoader iconSize="1.5rem" alignLoader="inherit" />
            ) : (
                <React.Fragment>
                    {visibleTileLayers.length > 0 ? (
                        <React.Fragment>
                            {!props.disableCategoryGlobalMaps ? <CategoryGlobalMaps /> : null}
                            <CategoryMapsList visibleMaps={visibleTileLayers} />
                        </React.Fragment>
                    ) : (
                        <DrawerItemListNoResults />
                    )}
                </React.Fragment>
            )}
        </DrawerItemListResults>
    );
};

export default DrawerItemList;

const DrawerItemListResults = styled.div`
    margin: 8px;
`;
