import React from 'react';
import { LatLng } from 'leaflet';
import { actionSetDroneImageDistortionCorners } from '../../../store/Map/Uploads/actions';
import {
    selectDroneImagePreviewUrl,
    selectDroneImagePositions,
    selectDroneImageDistortionTool,
    selectDroneImageOpacity,
} from '../../../store/Map/Uploads/selectors';
import ReactDistortableImageOverlay from 'react-leaflet-distortable-imageoverlay';
import GeoUtil from '../../../lib/geo-util';
import { useDispatch, useSelector } from 'react-redux';

const mapCorners = (corners: LatLng[]): number[][] => {
    return GeoUtil.toDistortablePositionsClock(corners);
};

const DroneUploadPreview = () => {
    const previewUrl = useSelector(selectDroneImagePreviewUrl);
    const positions = useSelector(selectDroneImagePositions);
    const distortionToolMode = useSelector(selectDroneImageDistortionTool);
    const opacity = useSelector(selectDroneImageOpacity);

    const dispatch = useDispatch();
    const setDistortionCorners = (corners: number[][]) => dispatch(actionSetDroneImageDistortionCorners(corners));

    if (previewUrl && positions) {
        const corners: LatLng[] = GeoUtil.toLeafletPositionsClock(positions);
        return (
            <ReactDistortableImageOverlay
                url={previewUrl}
                editMode={distortionToolMode}
                onCornersUpdated={(corners) => setDistortionCorners(mapCorners(corners))}
                corners={corners}
                opacity={opacity}
            />
        );
    } else {
        return <React.Fragment />;
    }
};

export default DroneUploadPreview;
