import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';
import { LatLng } from 'leaflet';
import { RectifiedMap } from '../../../api/model';
import { LatLngBounds } from 'leaflet';
import { DatasetDetails } from '../../../components/Upload/Model/model';

interface StoreUpload {
    droneImagePreviewUrl: string | undefined;
    droneImagePositions: number[][] | undefined;
    droneImageDistortionTool: 'none' | 'rotate' | 'translate' | 'distort' | 'scale';
    droneImageOpacity: number;
    droneImageDistortionCorners: number[][];
    imageManualPosition: LatLng | undefined;
    rectifiedMap: RectifiedMap | undefined;
    candidateFile: File | undefined;
    datasetDetails: DatasetDetails | undefined;
    imageBounds: LatLng[] | number[][] | undefined;
    customAOIDisplay: LatLngBounds | undefined;
    mouseActive: boolean;
}

const initialState: StoreUpload = {
    droneImagePreviewUrl: undefined,
    droneImagePositions: undefined,
    droneImageDistortionTool: 'none',
    droneImageOpacity: 1.0,
    droneImageDistortionCorners: [],
    imageManualPosition: undefined,
    rectifiedMap: undefined,
    candidateFile: undefined,
    datasetDetails: undefined,
    imageBounds: undefined,
    customAOIDisplay: undefined,
    mouseActive: false
};

export default (state: StoreUpload = initialState, action: Action): StoreUpload => {
    switch (action.type) {
        case at.ADD_DRONE_IMAGE_FOR_UPLOAD:
            return {
                ...state,
                droneImagePreviewUrl: action.payload.droneImagePreviewUrl,
                droneImagePositions: action.payload.droneImagePositions,
            };

        case at.CLEAR_DRONE_IMAGE_FOR_UPLOAD:
            return {
                ...state,
                droneImagePreviewUrl: undefined,
                droneImagePositions: undefined,
                droneImageDistortionTool: 'none',
                imageManualPosition: undefined,
                droneImageOpacity: 1.0,
                droneImageDistortionCorners: [],
            };

        case at.SET_RECTIFIED_MAP:
            return {
                ...state,
                rectifiedMap: action.payload
            }

        case at.SET_CANDIDATE_FILE:
            return {
                ...state,
                candidateFile: action.payload
            }

        case at.SET_DATASET_DETAILS:
            return {
                ...state,
                datasetDetails: action.payload
            };

        case at.SET_DRONE_IMAGE_DISTORTION_TOOL:
            return {
                ...state,
                droneImageDistortionTool: action.payload,
            };

        case at.SET_DRONE_IMAGE_OPACITY:
            return {
                ...state,
                droneImageOpacity: action.payload,
            };

        case at.SET_DRONE_IMAGE_DISTORTION_CORNERS:
            return {
                ...state,
                droneImageDistortionCorners: action.payload,
            };

        case at.SET_IMAGE_MANUAL_POSITION:
            return {
                ...state,
                imageManualPosition: action.payload,
            };

        case at.SET_IMAGE_BOUNDS:
            return {
                ...state,
                imageBounds: action.payload
            };

        case at.SET_CUSTOM_AOI_DISPLAY:
            return {
                ...state,
                customAOIDisplay: action.payload
            };
        case at.SET_MOUSE_ACTIVE:
            return {
                ...state,
                mouseActive: action.payload
            }
        default:
            return state;
    }
};
