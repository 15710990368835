import styled from 'styled-components';
import { ListingDTO } from '../../../api/model';
import { SentinelFeature } from '../../../store/Map/Sentinel/model';
import ShareContainer from './SharingContainer/share-container';

interface MobileShareLinksProps {
    listing?: ListingDTO;
    sentinelFeature?: SentinelFeature;
}

const MobileShareLinks = (props: MobileShareLinksProps) => {
    return (
        <ShareContainer listing={props.listing} sentinelFeature={props.sentinelFeature}>
            <MobileShareIcon />
        </ShareContainer>
    );
};

export default MobileShareLinks;

const MobileShareIcon = styled.div`
    margin-right: 5px;
    margin-top: 2px;
    opacity: 0.67;
    width: 16px;
    height: 17px;
    background-image: url('/assets/sharing/icon-share-white.svg');
    background-repeat: no-repeat;
    pointer-events: all;
`;
