import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled, { keyframes } from 'styled-components';
import { ListingDTO } from '../../../api/model';
import ShareService from '../../../lib/share-service';
import SoarHelper from '../../../lib/soar-helper';
import Analytics from '../../../lib/user-analytics';
import { SentinelFeature } from '../../../store/Map/Sentinel/model';
import ShareLink from '../../Drawer/Maps/Sharing/share-link';
import { PulseLoader } from '../../Shared/pulse-loader';

interface MobileShareSocialProps {
    fade: boolean;
    onClose: () => void;
    listing?: ListingDTO;
    sentinelFeature?: SentinelFeature;
}

const MobileShareSocial = (props: MobileShareSocialProps) => {
    const [isCreatingSentinelShortUrl, setIsCreatingSentinelShortUrl] = useState<boolean>(false);
    const [sentinelShareURI, setSentinelShareURI] = useState<string>('');

    useEffect(() => {
        if (props.sentinelFeature) {
            setIsCreatingSentinelShortUrl(true);
            ShareService.shareSentinelFeatureUrl(props.sentinelFeature)
                .then((miniShareURI) => {
                    setSentinelShareURI(miniShareURI);
                })
                .finally(() => {
                    setIsCreatingSentinelShortUrl(false);
                });
        }
    }, [props.sentinelFeature]);

    const handleShareLink = async (
        href: string,
        event: string,
        eventType: string,
        mapId?: number | undefined,
        sentinelFeature?: SentinelFeature | undefined
    ) => {
        if (mapId) {
            const short = ShareService.shareMapUrl(mapId);
            Analytics.Event(event, eventType, mapId);

            const shareURL = `${href}${short}`;
            if (shareURL) {
                window.open(shareURL, '_blank');
            }
        } else if (sentinelFeature) {
            // TODO tried to remove the short url to generate a sharelink for sentinel images but twitter and facebook
            // did not like it so leaving for now to be updated once the short url becomes deprecated DP - 6/4/2023
            const shareURL = `${href}${sentinelShareURI}`;
            if (shareURL) {
                window.open(shareURL, '_blank');
                Analytics.Event('Social Media - Clicked to share', 'LinkedIn', shareURL);
            } else {
                toast.error('Sharing is currently unavailable, Please try again');
            }
        } else {
            toast.error('Unable to share at this time');
        }
    };

    const generateCopyLink = async (mapId?: number | undefined, sentinelFeature?: SentinelFeature | undefined) => {
        if (mapId) {
            const short = await ShareService.shareMapUrl(mapId);
            // Check if a pathname exists after removing the slash if so use that otherwise use the default /maps
            const pathName = window.location.pathname.replace('/', '') ? window.location.pathname : '/maps';
            const currentDomain =
                SoarHelper.isSoar() && short ? short : `${window.location.origin}${pathName}/${mapId}`;
            navigator.clipboard.writeText(currentDomain);
            toast.dark('Copied to clipboard');
            Analytics.Event(`Social Media - Copied To Share - ${currentDomain}`, 'Link', mapId);
        } else if (sentinelFeature) {
            const currentDomain =
                SoarHelper.isSoar() && sentinelShareURI ? sentinelShareURI : `${window.location.href}`;
            const textField = document.createElement('textarea');
            textField.innerText = decodeURIComponent(currentDomain);
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            toast.dark('Copied to clipboard');
            Analytics.Event('Social Media - Clicked to share', 'Link', currentDomain);
        } else {
            toast.error('Unable to share at this time');
        }
    };

    return (
        <MobileShareContainer role="dialog" aria-modal="true" className={props.fade ? 'fade-in' : 'fade-out'}>
            <MobileShareContainerTitle>Share this Map</MobileShareContainerTitle>
            <MobileShareIcons>
                {!isCreatingSentinelShortUrl ? (
                    <React.Fragment>
                        {!SoarHelper.isSoar() ? (
                            <ShareLink
                                title={'Copy share link'}
                                handleOnClick={() => generateCopyLink(props.listing?.id, props.sentinelFeature)}
                                icon={'fa fa-share'}
                            />
                        ) : (
                            <React.Fragment>
                                <ShareLink
                                    title={'Share to Twitter'}
                                    handleOnClick={() =>
                                        handleShareLink(
                                            'https://www.twitter.com/intent/tweet?hashtags=SoarEarth&url=',
                                            'Social Media - Opened To Share',
                                            'Twitter',
                                            props.listing ? props.listing.id : undefined,
                                            props.sentinelFeature
                                        )
                                    }
                                    icon={'fa fa-twitter'}
                                />
                                <Divider>|</Divider>
                                <ShareLink
                                    title={'Share to Facebook'}
                                    handleOnClick={() =>
                                        handleShareLink(
                                            'https://www.facebook.com/sharer.php?u=',
                                            'Social Media - Opened To Share',
                                            'Facebook',
                                            props.listing ? props.listing.id : undefined,
                                            props.sentinelFeature
                                        )
                                    }
                                    icon={'fa fa-facebook'}
                                />
                                <Divider>|</Divider>
                                <ShareLink
                                    title={'Share to LinkedIn'}
                                    handleOnClick={() =>
                                        handleShareLink(
                                            'https://www.linkedin.com/shareArticle?mini=true&url=',
                                            'Social Media - Opened To Share',
                                            'LinkedIn',
                                            props.listing ? props.listing.id : undefined,
                                            props.sentinelFeature
                                        )
                                    }
                                    icon={'fa fa-linkedin'}
                                />
                                <Divider>|</Divider>
                                <ShareLink
                                    title={'Copy share link'}
                                    handleOnClick={() => generateCopyLink(props.listing?.id, props.sentinelFeature)}
                                    icon={'fa fa-share'}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : (
                    <ShareLoaderContainer>
                        <PulseLoader />
                    </ShareLoaderContainer>
                )}
            </MobileShareIcons>
            <MobileShareClose onClick={props.onClose}>CLOSE</MobileShareClose>
        </MobileShareContainer>
    );
};

export default MobileShareSocial;

const FadeIn = keyframes`
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const FadeOut = keyframes`
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(20px);
    }
`;

const MobileShareContainer = styled.section`
    width: 100%;
    background-color: #191a1a;
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.3);

    .fade-in {
        animation: ${FadeIn} 0.5s;
        animation-fill-mode: both;
    }

    .fade-in {
        animation: ${FadeOut} 0.5s;
        animation-fill-mode: both;
    }
`;

const MobileShareContainerTitle = styled.div`
    color: rgba(255, 255, 255, 0.87);
    padding: 14px 10px;
    font-size: 1.25rem;
    line-height: 1.25;
    font-weight: 600;
    border-bottom: 1px solid rgb(255 255 255 / 0.3);
    text-align: center;
`;

const MobileShareIcons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 20px 0px;

    i {
        margin: 0px;
    }
`;

const MobileShareClose = styled.div`
    border-top: 1px solid rgb(255 255 255 / 0.3);
    cursor: pointer;
    padding: 0.75rem;
    display: block;
    width: 100%;
    font-size: 1rem;
    text-align: center;
    color: rgba(255, 255, 255, 0.87);
`;

const Divider = styled.div`
    color: rgba(255, 255, 255, 0.3);
`;

const ShareLoaderContainer = styled.div`
    height: 21px;
`;
