import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutocompleteDTO } from '../../../api/model';
import GeoUtil from '../../../lib/geo-util';
import UriHelper from '../../../lib/uri-helper';
import { useFlyTo } from '../../../lib/use-fly-to';
import Analytics from '../../../lib/user-analytics';
import {
    actionSetSelectedSearchTerm,
    actionClearSelectedSearch,
    actionClearSelectedListing,
} from '../../../store/Map/MapSelection/actions';
import {
    selectSelectedContinent,
    selectSelectedCategory,
    selectSelectedSearchTerm,
    selectClearListing,
} from '../../../store/Map/MapSelection/selectors';
import SearchResultsLoadingIndicator from '../../Search/search-results-loading-indicator';
import MobileSearchDiscovery from './Discovery/mobile-search-discovery';
import MobileSearchInput from './mobile-search-input';
import MobileSearchResults from './Results/mobile-search-results';
import MobileSearchTouchContainer from './mobile-search-touch-container';

interface MobileSearchProps {
    setIsContainerOpen: (isContainerOpen: boolean) => void;
}

const MobileSearchBar = (props: MobileSearchProps) => {
    const [hasFocus, setHasFocus] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const searchInputRef = useRef<any>(null); // eslint-disable-line @typescript-eslint/no-explicit-any

    const [autocompleteResults, setAutocompleteResults] = useState<AutocompleteDTO[]>([]);
    const [isSearchLoading, setIsSearchLoading] = useState(false);

    const dispatch = useDispatch();
    const selectedContinent = useSelector(selectSelectedContinent);
    const selectedCategory = useSelector(selectSelectedCategory);
    const searchTerm = useSelector(selectSelectedSearchTerm);
    const selectedClearListing = useSelector(selectClearListing);
    const setSearchTerm = (searchTerm: string | undefined) => dispatch(actionSetSelectedSearchTerm(searchTerm));
    const clearSelectedListing = useCallback(
        (clear: boolean) => dispatch(actionClearSelectedListing(clear)),
        [dispatch]
    );
    const clearSelectedSearch = useCallback(() => dispatch(actionClearSelectedSearch()), [dispatch]);

    const flyTo = useFlyTo();

    useEffect(() => {
        if (selectedContinent && selectedContinent.continent) {
            setHasFocus(true);
        }
    }, [selectedContinent]);

    useEffect(() => {
        if (selectedContinent?.continent) {
            Analytics.Event('Mobile - Search Bar', 'Clicked Continent', selectedContinent?.continent.name);
        }
    }, [selectedContinent]);

    useEffect(() => {
        if (selectedCategory?.title) {
            Analytics.Event('Mobile - Search Bar', 'Clicked Category', selectedCategory.title);
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (searchTerm) {
            Analytics.Event('Mobile - Search Bar', 'User Searched', searchTerm);
        }
    }, [searchTerm]);

    useEffect(() => {
        if (hasFocus) {
            Analytics.Event('Mobile - Search Bar', 'Search Open');
        }
    }, [hasFocus]);

    useEffect(() => {
        const handleSelectedListing = () => {
            setHasFocus(false);
            clearSelectedSearch();
            setIsSearchOpen(false);
            clearSelectedListing(false);
        };

        if (selectedClearListing) {
            handleSelectedListing();
        }
    }, [clearSelectedListing, clearSelectedSearch, selectedClearListing]);

    const handleSelectAutocomplete = (autocomplete: AutocompleteDTO) => {
        Analytics.Event('Mobile - Search Bar', `Tag Search ${autocomplete.type}`, autocomplete.title);
        clearSelectedListing(true);
        if (autocomplete.type === 'USER' && autocomplete.userId) {
            Analytics.Event('Mobile - Search Bar', 'Selected Profile', autocomplete.userId);
            UriHelper.navigateToPath(`/profile/${autocomplete.userId}`);
        }

        if (autocomplete.type === 'ADDRESS' && autocomplete.geometryWKT) {
            setHasFocus(false);
            const latlngBounds = GeoUtil.latLngFromWKT(autocomplete.geometryWKT);
            flyTo(latlngBounds, true);
        }

        if (autocomplete.type === 'COORDINATE' && autocomplete.latlng) {
            const bounds = autocomplete.latlng.toBounds(5000);
            setHasFocus(false);
            flyTo(bounds, true);
        }
    };

    const handleEnterKeyPress = () => {
        if (searchTerm) {
            const coordinate = GeoUtil.getCoordinateFromSearchTerm(searchTerm);
            if (coordinate) {
                const bounds = coordinate.toBounds(5000);
                props.setIsContainerOpen(false);
                clearSelectedSearch();
                setIsSearchOpen(false);
                clearSelectedListing(true);
                setHasFocus(false);
                flyTo(bounds, true);
            }
        }
    };

    useEffect(() => {
        if (hasFocus) {
            setIsSearchOpen(true);
        }
    }, [hasFocus]);

    useEffect(() => {
        props.setIsContainerOpen(isSearchOpen);
    }, [isSearchOpen, props]);

    return (
        <React.Fragment>
            <MobileSearchInput
                handleOnSearchInputHasFocus={(hasFocus) => {
                    setHasFocus(hasFocus);
                }}
                handleAutocompleteResults={(autocompleteResults) => {
                    setAutocompleteResults(autocompleteResults);
                }}
                handleOnSearchLoading={(isLoading) => {
                    setIsSearchLoading(isLoading);
                }}
                handleSearchTerm={(searchTerm) => {
                    setSearchTerm(searchTerm);
                }}
                handleCloseSearch={() => {
                    setIsSearchOpen(false);
                }}
                handleEnterKeyPress={handleEnterKeyPress}
                searchInputRef={searchInputRef}
            />
            <MobileSearchTouchContainer
                isSearchOpen={isSearchOpen}
                searchInputRef={searchInputRef}
                handleIsSearchOpen={setIsSearchOpen}
                handleHasFocus={setHasFocus}
                filtersAvailable={selectedContinent || selectedCategory || searchTerm ? true : false}
            >
                <SearchResultsLoadingIndicator isLoading={isSearchLoading} />
                {selectedContinent || selectedCategory || searchTerm ? (
                    <MobileSearchResults
                        autoCompleteResults={autocompleteResults}
                        handleSelectAutocomplete={handleSelectAutocomplete}
                    />
                ) : (
                    <MobileSearchDiscovery />
                )}
            </MobileSearchTouchContainer>
        </React.Fragment>
    );
};

export default MobileSearchBar;
